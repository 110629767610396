body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.checkboxToggle {
  appearance: none;
  min-width: 37px;
  background-color: #00000033;
  height: 21px;
  position: relative;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  border-radius: 11px;
}
/* html[data-theme="dark"] .checkboxToggle {
  background-color: #2a2f32;
} */
.checkboxToggle::before {
  content: "";
  position: absolute;
  width: 17px;
  border-radius: 50%;
  top: 2px;
  height: 17px;
  background-color: #2a2f32;
  transition: 0.23s ease-in-out;
  margin-left: 1px;
}
/* html[data-theme="dark"] .checkboxToggle::before {
  background-color: #f2f2f2;
} */

.checkboxToggle:checked {
  background-color: #0596fc;
}
/* html[data-theme="dark"] .checkboxToggle:checked {
  background-color: #0596fc !important;
} */

.checkboxToggle:checked:before {
  background-color: #f2f2f2;
  margin-left: 19px;
}

/* * {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card {
  margin: 1em auto;
  width: 95%;
  box-shadow: 0 1px 3px 1px #aaa;
  min-height: 200px;
  padding: 20px;
}

.card p {
  margin: 0.6em;
}

.btn {
  padding: 10px 12px;
  text-align: center;
  display: block;
  min-width: 120px;
  margin: 0.4em 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
}

.btn.btn-warning {
  background-color: #b4e014;
}

.btn.btn-danger {
  background-color: #e02214;
  color: #fff;
}

form label {
  text-transform: capitalize;
}

form input {
  padding: 10px 14px;
  width: 90%;
  margin: .7em auto;
  text-transform: capitalize;
  display: block;
}

input[type="checkbox"] {
  position: relative;
  width: 80px !important;
  height: 30px !important;
  background-color: #ccc;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline;
}
 .form{
   display: grid;
   grid-template-columns: 1fr 1fr;
 } */
