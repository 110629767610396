.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
}
.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}
.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__placeholder,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #555;
}
.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
}
.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--classic.select2-container--open
  .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option[role="group"] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled="true"] {
  color: grey;
}
.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}

@media print {
  *,
  *:before,
  *:after {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("https://assets.sendle.com/assets/bootstrap/glyphicons-halflings-regular-35054c4e1bf08a04f531b2b8510910f29431c22cfbcfb3571c6e2d4dc8cb57c1.eot");
  src: url("https://assets.sendle.com/assets/bootstrap/glyphicons-halflings-regular-35054c4e1bf08a04f531b2b8510910f29431c22cfbcfb3571c6e2d4dc8cb57c1.eot?#iefix")
      format("embedded-opentype"),
    url("https://assets.sendle.com/assets/bootstrap/glyphicons-halflings-regular-4511059bac53be6b8d5d9a60139058a3ee67becaa2df739e2e67dd27f22324f8.woff2")
      format("woff2"),
    url("https://assets.sendle.com/assets/bootstrap/glyphicons-halflings-regular-b08ae9af0bd2e112a8d6b543e83159dc9b3ce5596fe97ffb8b8330db8afc4b08.woff")
      format("woff"),
    url("https://assets.sendle.com/assets/bootstrap/glyphicons-halflings-regular-7e35b4a8ce4d46ea22f0ef6c244b5ec1aa20e4dd957f050b9906f27bfd10e237.ttf")
      format("truetype"),
    url("https://assets.sendle.com/assets/bootstrap/glyphicons-halflings-regular-c99a82879186f821e6d138718cfafd879d97bff09a4681e4cd9f393f76b20fed.svg#glyphicons_halflingsregular")
      format("svg");
}
.glyphicon,
.panel-loading:after,
.panel-loading-plain:after,
.btn-loading:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-asterisk:before {
  content: "\002a";
}
.glyphicon-plus:before {
  content: "\002b";
}
.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac";
}
.glyphicon-minus:before {
  content: "\2212";
}
.glyphicon-cloud:before {
  content: "\2601";
}
.glyphicon-envelope:before {
  content: "\2709";
}
.glyphicon-pencil:before {
  content: "\270f";
}
.glyphicon-glass:before {
  content: "\e001";
}
.glyphicon-music:before {
  content: "\e002";
}
.glyphicon-search:before {
  content: "\e003";
}
.glyphicon-heart:before {
  content: "\e005";
}
.glyphicon-star:before {
  content: "\e006";
}
.glyphicon-star-empty:before {
  content: "\e007";
}
.glyphicon-user:before {
  content: "\e008";
}
.glyphicon-film:before {
  content: "\e009";
}
.glyphicon-th-large:before {
  content: "\e010";
}
.glyphicon-th:before {
  content: "\e011";
}
.glyphicon-th-list:before {
  content: "\e012";
}
.glyphicon-ok:before {
  content: "\e013";
}
.glyphicon-remove:before {
  content: "\e014";
}
.glyphicon-zoom-in:before {
  content: "\e015";
}
.glyphicon-zoom-out:before {
  content: "\e016";
}
.glyphicon-off:before {
  content: "\e017";
}
.glyphicon-signal:before {
  content: "\e018";
}
.glyphicon-cog:before {
  content: "\e019";
}
.glyphicon-trash:before {
  content: "\e020";
}
.glyphicon-home:before {
  content: "\e021";
}
.glyphicon-file:before {
  content: "\e022";
}
.glyphicon-time:before {
  content: "\e023";
}
.glyphicon-road:before {
  content: "\e024";
}
.glyphicon-download-alt:before {
  content: "\e025";
}
.glyphicon-download:before {
  content: "\e026";
}
.glyphicon-upload:before {
  content: "\e027";
}
.glyphicon-inbox:before {
  content: "\e028";
}
.glyphicon-play-circle:before {
  content: "\e029";
}
.glyphicon-repeat:before {
  content: "\e030";
}
.glyphicon-refresh:before {
  content: "\e031";
}
.glyphicon-list-alt:before {
  content: "\e032";
}
.glyphicon-lock:before {
  content: "\e033";
}
.glyphicon-flag:before {
  content: "\e034";
}
.glyphicon-headphones:before {
  content: "\e035";
}
.glyphicon-volume-off:before {
  content: "\e036";
}
.glyphicon-volume-down:before {
  content: "\e037";
}
.glyphicon-volume-up:before {
  content: "\e038";
}
.glyphicon-qrcode:before {
  content: "\e039";
}
.glyphicon-barcode:before {
  content: "\e040";
}
.glyphicon-tag:before {
  content: "\e041";
}
.glyphicon-tags:before {
  content: "\e042";
}
.glyphicon-book:before {
  content: "\e043";
}
.glyphicon-bookmark:before {
  content: "\e044";
}
.glyphicon-print:before {
  content: "\e045";
}
.glyphicon-camera:before {
  content: "\e046";
}
.glyphicon-font:before {
  content: "\e047";
}
.glyphicon-bold:before {
  content: "\e048";
}
.glyphicon-italic:before {
  content: "\e049";
}
.glyphicon-text-height:before {
  content: "\e050";
}
.glyphicon-text-width:before {
  content: "\e051";
}
.glyphicon-align-left:before {
  content: "\e052";
}
.glyphicon-align-center:before {
  content: "\e053";
}
.glyphicon-align-right:before {
  content: "\e054";
}
.glyphicon-align-justify:before {
  content: "\e055";
}
.glyphicon-list:before {
  content: "\e056";
}
.glyphicon-indent-left:before {
  content: "\e057";
}
.glyphicon-indent-right:before {
  content: "\e058";
}
.glyphicon-facetime-video:before {
  content: "\e059";
}
.glyphicon-picture:before {
  content: "\e060";
}
.glyphicon-map-marker:before {
  content: "\e062";
}
.glyphicon-adjust:before {
  content: "\e063";
}
.glyphicon-tint:before {
  content: "\e064";
}
.glyphicon-edit:before {
  content: "\e065";
}
.glyphicon-share:before {
  content: "\e066";
}
.glyphicon-check:before {
  content: "\e067";
}
.glyphicon-move:before {
  content: "\e068";
}
.glyphicon-step-backward:before {
  content: "\e069";
}
.glyphicon-fast-backward:before {
  content: "\e070";
}
.glyphicon-backward:before {
  content: "\e071";
}
.glyphicon-play:before {
  content: "\e072";
}
.glyphicon-pause:before {
  content: "\e073";
}
.glyphicon-stop:before {
  content: "\e074";
}
.glyphicon-forward:before {
  content: "\e075";
}
.glyphicon-fast-forward:before {
  content: "\e076";
}
.glyphicon-step-forward:before {
  content: "\e077";
}
.glyphicon-eject:before {
  content: "\e078";
}
.glyphicon-chevron-left:before {
  content: "\e079";
}
.glyphicon-chevron-right:before {
  content: "\e080";
}
.glyphicon-plus-sign:before {
  content: "\e081";
}
.glyphicon-minus-sign:before {
  content: "\e082";
}
.glyphicon-remove-sign:before {
  content: "\e083";
}
.glyphicon-ok-sign:before {
  content: "\e084";
}
.glyphicon-question-sign:before {
  content: "\e085";
}
.glyphicon-info-sign:before {
  content: "\e086";
}
.glyphicon-screenshot:before {
  content: "\e087";
}
.glyphicon-remove-circle:before {
  content: "\e088";
}
.glyphicon-ok-circle:before {
  content: "\e089";
}
.glyphicon-ban-circle:before {
  content: "\e090";
}
.glyphicon-arrow-left:before {
  content: "\e091";
}
.glyphicon-arrow-right:before {
  content: "\e092";
}
.glyphicon-arrow-up:before {
  content: "\e093";
}
.glyphicon-arrow-down:before {
  content: "\e094";
}
.glyphicon-share-alt:before {
  content: "\e095";
}
.glyphicon-resize-full:before {
  content: "\e096";
}
.glyphicon-resize-small:before {
  content: "\e097";
}
.glyphicon-exclamation-sign:before {
  content: "\e101";
}
.glyphicon-gift:before {
  content: "\e102";
}
.glyphicon-leaf:before {
  content: "\e103";
}
.glyphicon-fire:before {
  content: "\e104";
}
.glyphicon-eye-open:before {
  content: "\e105";
}
.glyphicon-eye-close:before {
  content: "\e106";
}
.glyphicon-warning-sign:before {
  content: "\e107";
}
.glyphicon-plane:before {
  content: "\e108";
}
.glyphicon-calendar:before {
  content: "\e109";
}
.glyphicon-random:before {
  content: "\e110";
}
.glyphicon-comment:before {
  content: "\e111";
}
.glyphicon-magnet:before {
  content: "\e112";
}
.glyphicon-chevron-up:before {
  content: "\e113";
}
.glyphicon-chevron-down:before {
  content: "\e114";
}
.glyphicon-retweet:before {
  content: "\e115";
}
.glyphicon-shopping-cart:before {
  content: "\e116";
}
.glyphicon-folder-close:before {
  content: "\e117";
}
.glyphicon-folder-open:before {
  content: "\e118";
}
.glyphicon-resize-vertical:before {
  content: "\e119";
}
.glyphicon-resize-horizontal:before {
  content: "\e120";
}
.glyphicon-hdd:before {
  content: "\e121";
}
.glyphicon-bullhorn:before {
  content: "\e122";
}
.glyphicon-bell:before {
  content: "\e123";
}
.glyphicon-certificate:before {
  content: "\e124";
}
.glyphicon-thumbs-up:before {
  content: "\e125";
}
.glyphicon-thumbs-down:before {
  content: "\e126";
}
.glyphicon-hand-right:before {
  content: "\e127";
}
.glyphicon-hand-left:before {
  content: "\e128";
}
.glyphicon-hand-up:before {
  content: "\e129";
}
.glyphicon-hand-down:before {
  content: "\e130";
}
.glyphicon-circle-arrow-right:before {
  content: "\e131";
}
.glyphicon-circle-arrow-left:before {
  content: "\e132";
}
.glyphicon-circle-arrow-up:before {
  content: "\e133";
}
.glyphicon-circle-arrow-down:before {
  content: "\e134";
}
.glyphicon-globe:before {
  content: "\e135";
}
.glyphicon-wrench:before {
  content: "\e136";
}
.glyphicon-tasks:before {
  content: "\e137";
}
.glyphicon-filter:before {
  content: "\e138";
}
.glyphicon-briefcase:before {
  content: "\e139";
}
.glyphicon-fullscreen:before {
  content: "\e140";
}
.glyphicon-dashboard:before {
  content: "\e141";
}
.glyphicon-paperclip:before {
  content: "\e142";
}
.glyphicon-heart-empty:before {
  content: "\e143";
}
.glyphicon-link:before {
  content: "\e144";
}
.glyphicon-phone:before {
  content: "\e145";
}
.glyphicon-pushpin:before {
  content: "\e146";
}
.glyphicon-usd:before {
  content: "\e148";
}
.glyphicon-gbp:before {
  content: "\e149";
}
.glyphicon-sort:before {
  content: "\e150";
}
.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}
.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}
.glyphicon-sort-by-order:before {
  content: "\e153";
}
.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}
.glyphicon-sort-by-attributes:before {
  content: "\e155";
}
.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}
.glyphicon-unchecked:before {
  content: "\e157";
}
.glyphicon-expand:before {
  content: "\e158";
}
.glyphicon-collapse-down:before {
  content: "\e159";
}
.glyphicon-collapse-up:before {
  content: "\e160";
}
.glyphicon-log-in:before {
  content: "\e161";
}
.glyphicon-flash:before {
  content: "\e162";
}
.glyphicon-log-out:before {
  content: "\e163";
}
.glyphicon-new-window:before {
  content: "\e164";
}
.glyphicon-record:before {
  content: "\e165";
}
.glyphicon-save:before {
  content: "\e166";
}
.glyphicon-open:before {
  content: "\e167";
}
.glyphicon-saved:before {
  content: "\e168";
}
.glyphicon-import:before {
  content: "\e169";
}
.glyphicon-export:before {
  content: "\e170";
}
.glyphicon-send:before {
  content: "\e171";
}
.glyphicon-floppy-disk:before {
  content: "\e172";
}
.glyphicon-floppy-saved:before {
  content: "\e173";
}
.glyphicon-floppy-remove:before {
  content: "\e174";
}
.glyphicon-floppy-save:before {
  content: "\e175";
}
.glyphicon-floppy-open:before {
  content: "\e176";
}
.glyphicon-credit-card:before {
  content: "\e177";
}
.glyphicon-transfer:before {
  content: "\e178";
}
.glyphicon-cutlery:before {
  content: "\e179";
}
.glyphicon-header:before {
  content: "\e180";
}
.glyphicon-compressed:before {
  content: "\e181";
}
.glyphicon-earphone:before {
  content: "\e182";
}
.glyphicon-phone-alt:before {
  content: "\e183";
}
.glyphicon-tower:before {
  content: "\e184";
}
.glyphicon-stats:before {
  content: "\e185";
}
.glyphicon-sd-video:before {
  content: "\e186";
}
.glyphicon-hd-video:before {
  content: "\e187";
}
.glyphicon-subtitles:before {
  content: "\e188";
}
.glyphicon-sound-stereo:before {
  content: "\e189";
}
.glyphicon-sound-dolby:before {
  content: "\e190";
}
.glyphicon-sound-5-1:before {
  content: "\e191";
}
.glyphicon-sound-6-1:before {
  content: "\e192";
}
.glyphicon-sound-7-1:before {
  content: "\e193";
}
.glyphicon-copyright-mark:before {
  content: "\e194";
}
.glyphicon-registration-mark:before {
  content: "\e195";
}
.glyphicon-cloud-download:before {
  content: "\e197";
}
.glyphicon-cloud-upload:before {
  content: "\e198";
}
.glyphicon-tree-conifer:before {
  content: "\e199";
}
.glyphicon-tree-deciduous:before {
  content: "\e200";
}
.glyphicon-cd:before {
  content: "\e201";
}
.glyphicon-save-file:before {
  content: "\e202";
}
.glyphicon-open-file:before {
  content: "\e203";
}
.glyphicon-level-up:before {
  content: "\e204";
}
.glyphicon-copy:before {
  content: "\e205";
}
.glyphicon-paste:before {
  content: "\e206";
}
.glyphicon-alert:before {
  content: "\e209";
}
.glyphicon-equalizer:before {
  content: "\e210";
}
.glyphicon-king:before {
  content: "\e211";
}
.glyphicon-queen:before {
  content: "\e212";
}
.glyphicon-pawn:before {
  content: "\e213";
}
.glyphicon-bishop:before {
  content: "\e214";
}
.glyphicon-knight:before {
  content: "\e215";
}
.glyphicon-baby-formula:before {
  content: "\e216";
}
.glyphicon-tent:before {
  content: "\26fa";
}
.glyphicon-blackboard:before {
  content: "\e218";
}
.glyphicon-bed:before {
  content: "\e219";
}
.glyphicon-apple:before {
  content: "\f8ff";
}
.glyphicon-erase:before {
  content: "\e221";
}
.glyphicon-hourglass:before {
  content: "\231b";
}
.glyphicon-lamp:before {
  content: "\e223";
}
.glyphicon-duplicate:before {
  content: "\e224";
}
.glyphicon-piggy-bank:before {
  content: "\e225";
}
.glyphicon-scissors:before {
  content: "\e226";
}
.glyphicon-bitcoin:before {
  content: "\e227";
}
.glyphicon-btc:before {
  content: "\e227";
}
.glyphicon-xbt:before {
  content: "\e227";
}
.glyphicon-yen:before {
  content: "\00a5";
}
.glyphicon-jpy:before {
  content: "\00a5";
}
.glyphicon-ruble:before {
  content: "\20bd";
}
.glyphicon-rub:before {
  content: "\20bd";
}
.glyphicon-scale:before {
  content: "\e230";
}
.glyphicon-ice-lolly:before {
  content: "\e231";
}
.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}
.glyphicon-education:before {
  content: "\e233";
}
.glyphicon-option-horizontal:before {
  content: "\e234";
}
.glyphicon-option-vertical:before {
  content: "\e235";
}
.glyphicon-menu-hamburger:before {
  content: "\e236";
}
.glyphicon-modal-window:before {
  content: "\e237";
}
.glyphicon-oil:before {
  content: "\e238";
}
.glyphicon-grain:before {
  content: "\e239";
}
.glyphicon-sunglasses:before {
  content: "\e240";
}
.glyphicon-text-size:before {
  content: "\e241";
}
.glyphicon-text-color:before {
  content: "\e242";
}
.glyphicon-text-background:before {
  content: "\e243";
}
.glyphicon-object-align-top:before {
  content: "\e244";
}
.glyphicon-object-align-bottom:before {
  content: "\e245";
}
.glyphicon-object-align-horizontal:before {
  content: "\e246";
}
.glyphicon-object-align-left:before {
  content: "\e247";
}
.glyphicon-object-align-vertical:before {
  content: "\e248";
}
.glyphicon-object-align-right:before {
  content: "\e249";
}
.glyphicon-triangle-right:before {
  content: "\e250";
}
.glyphicon-triangle-left:before {
  content: "\e251";
}
.glyphicon-triangle-bottom:before {
  content: "\e252";
}
.glyphicon-triangle-top:before {
  content: "\e253";
}
.glyphicon-console:before {
  content: "\e254";
}
.glyphicon-superscript:before {
  content: "\e255";
}
.glyphicon-subscript:before {
  content: "\e256";
}
.glyphicon-menu-left:before {
  content: "\e257";
}
.glyphicon-menu-right:before {
  content: "\e258";
}
.glyphicon-menu-down:before {
  content: "\e259";
}
.glyphicon-menu-up:before {
  content: "\e260";
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.428571429;
  color: #33424f;
  background-color: #fff;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #008dcc;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #005880;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle,
.profile-image img {
  border-radius: 50%;
}
hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
[role="button"] {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: 400;
  line-height: 1;
  color: #777777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 18px;
  margin-bottom: 9px;
}
h1 small,
h1 .small,
.h1 small,
.h1 .small,
h2 small,
h2 .small,
.h2 small,
.h2 .small,
h3 small,
h3 .small,
.h3 small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 9px;
  margin-bottom: 9px;
}
h4 small,
h4 .small,
.h4 small,
.h4 .small,
h5 small,
h5 .small,
.h5 small,
.h5 .small,
h6 small,
h6 .small,
.h6 small,
.h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 24px;
}
h2,
.h2 {
  font-size: 22px;
}
h3,
.h3 {
  font-size: 16px;
}
h4,
.h4 {
  font-size: 14px;
}
h5,
.h5 {
  font-size: 13px;
}
h6,
.h6 {
  font-size: 12px;
}
p {
  margin: 0 0 9px;
}
.lead {
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 19.5px;
  }
}
small,
.small {
  font-size: 92%;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase,
.initialism {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-muted {
  color: #777777;
}
.text-primary {
  color: #337ab7;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #286090;
}
.text-success {
  color: #3c763d;
}
a.text-success:hover,
a.text-success:focus {
  color: #2b542c;
}
.text-info {
  color: #31708f;
}
a.text-info:hover,
a.text-info:focus {
  color: #245269;
}
.text-warning {
  color: #8a6d3b;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #66512c;
}
.text-danger {
  color: #d94c4c;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #c82a2a;
}
.bg-primary {
  color: #fff;
}
.bg-primary {
  background-color: #337ab7;
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #286090;
}
.bg-success {
  background-color: #dff0d8;
}
a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3;
}
.bg-info {
  background-color: #d9edf7;
}
a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee;
}
.bg-warning {
  background-color: #fcf8e3;
}
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5;
}
.bg-danger {
  background-color: #f2dede;
}
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9;
}
.page-header {
  padding-bottom: 8px;
  margin: 36px 0 18px;
  border-bottom: 1px solid #eeeeee;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 9px;
}
ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
dl {
  margin-top: 0;
  margin-bottom: 18px;
}
dt,
dd {
  line-height: 1.428571429;
}
dt {
  font-weight: 700;
}
dd {
  margin-left: 0;
}
.dl-horizontal dd:before,
.dl-horizontal dd:after {
  display: table;
  content: " ";
}
.dl-horizontal dd:after {
  clear: both;
}
@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
}
.initialism {
  font-size: 90%;
}
blockquote {
  padding: 9px 18px;
  margin: 0 0 18px;
  font-size: 16.25px;
  border-left: 5px solid #eeeeee;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.428571429;
  color: #777777;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: "\2014 \00A0";
}
.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eeeeee;
  border-left: 0;
}
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
.blockquote-reverse .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before,
blockquote.pull-right .small:before {
  content: "";
}
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
.blockquote-reverse .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after,
blockquote.pull-right .small:after {
  content: "\00A0 \2014";
}
address {
  margin-bottom: 18px;
  font-style: normal;
  line-height: 1.428571429;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}
pre {
  display: block;
  padding: 8.5px;
  margin: 0 0 9px;
  font-size: 12px;
  line-height: 1.428571429;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container:before,
.container:after {
  display: table;
  content: " ";
}
.container:after {
  clear: both;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-fluid:before,
.container-fluid:after {
  display: table;
  content: " ";
}
.container-fluid:after {
  clear: both;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.row:before,
.row:after {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
}
.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.login-form,
.password-reset-form,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-1 {
  width: 8.3333333333%;
}
.col-xs-2 {
  width: 16.6666666667%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-4 {
  width: 33.3333333333%;
}
.col-xs-5 {
  width: 41.6666666667%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-7 {
  width: 58.3333333333%;
}
.col-xs-8 {
  width: 66.6666666667%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-10 {
  width: 83.3333333333%;
}
.col-xs-11 {
  width: 91.6666666667%;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-pull-1 {
  right: 8.3333333333%;
}
.col-xs-pull-2 {
  right: 16.6666666667%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-4 {
  right: 33.3333333333%;
}
.col-xs-pull-5 {
  right: 41.6666666667%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-7 {
  right: 58.3333333333%;
}
.col-xs-pull-8 {
  right: 66.6666666667%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-10 {
  right: 83.3333333333%;
}
.col-xs-pull-11 {
  right: 91.6666666667%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-push-1 {
  left: 8.3333333333%;
}
.col-xs-push-2 {
  left: 16.6666666667%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-4 {
  left: 33.3333333333%;
}
.col-xs-push-5 {
  left: 41.6666666667%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-7 {
  left: 58.3333333333%;
}
.col-xs-push-8 {
  left: 66.6666666667%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-10 {
  left: 83.3333333333%;
}
.col-xs-push-11 {
  left: 91.6666666667%;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}
.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}
.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}
.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}
.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .login-form,
  .password-reset-form,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8,
  .login-form,
  .password-reset-form {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.3333333333%;
  }
  .col-sm-push-2 {
    left: 16.6666666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.3333333333%;
  }
  .col-sm-push-5 {
    left: 41.6666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.3333333333%;
  }
  .col-sm-push-8 {
    left: 66.6666666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.3333333333%;
  }
  .col-sm-push-11 {
    left: 91.6666666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2,
  .login-form,
  .password-reset-form {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.3333333333%;
  }
  .col-md-pull-2 {
    right: 16.6666666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.3333333333%;
  }
  .col-md-pull-5 {
    right: 41.6666666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.3333333333%;
  }
  .col-md-pull-8 {
    right: 66.6666666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.3333333333%;
  }
  .col-md-pull-11 {
    right: 91.6666666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.3333333333%;
  }
  .col-md-push-2 {
    left: 16.6666666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.3333333333%;
  }
  .col-md-push-5 {
    left: 41.6666666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.3333333333%;
  }
  .col-md-push-8 {
    left: 66.6666666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.3333333333%;
  }
  .col-md-push-11 {
    left: 91.6666666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.3333333333%;
  }
  .col-lg-push-2 {
    left: 16.6666666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.3333333333%;
  }
  .col-lg-push-5 {
    left: 41.6666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.3333333333%;
  }
  .col-lg-push-8 {
    left: 66.6666666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.3333333333%;
  }
  .col-lg-push-11 {
    left: 91.6666666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}
table {
  background-color: transparent;
}
table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}
caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #777777;
  text-align: left;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 18px;
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 1rem;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fff;
}
.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}
.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 13.5px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 18px;
  font-size: 19.5px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="radio"][disabled],
input.disabled[type="radio"],
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input.disabled[type="checkbox"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 13px;
  line-height: 1.428571429;
  color: #33424f;
}
.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.428571429;
  color: #33424f;
  background-color: #fff;
  background-image: none;
  border: 1px solid #82909b;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-moz-placeholder {
  color: #9fa7ae;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #9fa7ae;
}
.form-control::-webkit-input-placeholder {
  color: #9fa7ae;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control {
  height: auto;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input.form-control[type="date"],
  input.form-control[type="time"],
  input.form-control[type="datetime-local"],
  input.form-control[type="month"] {
    line-height: 40px;
  }
  input.input-sm[type="date"],
  .input-group-sm > .form-control[type="date"],
  .input-group-sm > .input-group-addon[type="date"],
  .input-group-sm > .input-group-btn > .btn[type="date"],
  .input-group-sm input[type="date"],
  input.input-sm[type="time"],
  .input-group-sm > .form-control[type="time"],
  .input-group-sm > .input-group-addon[type="time"],
  .input-group-sm > .input-group-btn > .btn[type="time"],
  .input-group-sm input[type="time"],
  input.input-sm[type="datetime-local"],
  .input-group-sm > .form-control[type="datetime-local"],
  .input-group-sm > .input-group-addon[type="datetime-local"],
  .input-group-sm > .input-group-btn > .btn[type="datetime-local"],
  .input-group-sm input[type="datetime-local"],
  input.input-sm[type="month"],
  .input-group-sm > .form-control[type="month"],
  .input-group-sm > .input-group-addon[type="month"],
  .input-group-sm > .input-group-btn > .btn[type="month"],
  .input-group-sm input[type="month"] {
    line-height: 30px;
  }
  input.input-lg[type="date"],
  .input-group-lg > .form-control[type="date"],
  .input-group-lg > .input-group-addon[type="date"],
  .input-group-lg > .input-group-btn > .btn[type="date"],
  .input-group-lg input[type="date"],
  input.input-lg[type="time"],
  .input-group-lg > .form-control[type="time"],
  .input-group-lg > .input-group-addon[type="time"],
  .input-group-lg > .input-group-btn > .btn[type="time"],
  .input-group-lg input[type="time"],
  input.input-lg[type="datetime-local"],
  .input-group-lg > .form-control[type="datetime-local"],
  .input-group-lg > .input-group-addon[type="datetime-local"],
  .input-group-lg > .input-group-btn > .btn[type="datetime-local"],
  .input-group-lg input[type="datetime-local"],
  input.input-lg[type="month"],
  .input-group-lg > .form-control[type="month"],
  .input-group-lg > .input-group-addon[type="month"],
  .input-group-lg > .input-group-btn > .btn[type="month"],
  .input-group-lg input[type="month"] {
    line-height: 45px;
  }
}
.form-group {
  margin-bottom: 15px;
}
.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}
.radio label,
.checkbox label {
  min-height: 18px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}
.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
.form-control-static {
  min-height: 31px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}
.form-control-static.input-lg,
.input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn,
.form-control-static.input-sm,
.input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-right: 0;
  padding-left: 0;
}
.input-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
select.input-sm,
.input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px;
}
textarea.input-sm,
.input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select.input-sm[multiple],
.input-group-sm > .form-control[multiple],
.input-group-sm > .input-group-addon[multiple],
.input-group-sm > .input-group-btn > .btn[multiple] {
  height: auto;
}
.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}
.form-group-sm textarea.form-control,
.form-group-sm select.form-control[multiple] {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 30px;
  min-height: 30px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.input-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 45px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.3333333;
  border-radius: 6px;
}
select.input-lg,
.input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 45px;
  line-height: 45px;
}
textarea.input-lg,
.input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select.input-lg[multiple],
.input-group-lg > .form-control[multiple],
.input-group-lg > .input-group-addon[multiple],
.input-group-lg > .input-group-btn > .btn[multiple] {
  height: auto;
}
.form-group-lg .form-control {
  height: 45px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.form-group-lg select.form-control {
  height: 45px;
  line-height: 45px;
}
.form-group-lg textarea.form-control,
.form-group-lg select.form-control[multiple] {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 45px;
  min-height: 35px;
  padding: 11px 16px;
  font-size: 17px;
  line-height: 1.3333333;
}
.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 50px;
}
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  pointer-events: none;
}
.input-lg + .form-control-feedback,
.input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.input-sm + .form-control-feedback,
.input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d;
}
.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}
.has-success .form-control-feedback {
  color: #3c763d;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b;
}
.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}
.has-warning .form-control-feedback {
  color: #8a6d3b;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #d94c4c;
}
.has-error .form-control {
  border-color: #d94c4c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #c82a2a;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eba0a0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eba0a0;
}
.has-error .input-group-addon {
  color: #d94c4c;
  background-color: #f2dede;
  border-color: #d94c4c;
}
.has-error .form-control-feedback {
  color: #d94c4c;
}
.has-feedback label ~ .form-control-feedback {
  top: 23px;
}
.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #66839c;
}
@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 25px;
}
.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  display: table;
  content: " ";
}
.form-horizontal .form-group:after {
  clear: both;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 17px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.428571429;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn.focus:active,
.btn.active:focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.btn-default,
.btn-close {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus,
.btn-close:focus,
.btn-default.focus,
.focus.btn-close {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover,
.btn-close:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-close:active,
.btn-default.active,
.active.btn-close,
.open > .btn-default.dropdown-toggle,
.open > .dropdown-toggle.btn-close {
  color: #333;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-close:active:hover,
.btn-default:active:focus,
.btn-close:active:focus,
.btn-default.focus:active,
.btn-close.focus:active,
.btn-default.active:hover,
.active.btn-close:hover,
.btn-default.active:focus,
.active.btn-close:focus,
.btn-default.active.focus,
.active.focus.btn-close,
.open > .btn-default.dropdown-toggle:hover,
.open > .dropdown-toggle.btn-close:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .dropdown-toggle.btn-close:focus,
.open > .btn-default.dropdown-toggle.focus,
.open > .dropdown-toggle.focus.btn-close {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default.disabled:hover,
.disabled.btn-close:hover,
.btn-default.disabled:focus,
.disabled.btn-close:focus,
.btn-default.disabled.focus,
.disabled.focus.btn-close,
.btn-default[disabled]:hover,
.btn-close[disabled]:hover,
.btn-default[disabled]:focus,
.btn-close[disabled]:focus,
.btn-default.focus[disabled],
.focus.btn-close[disabled],
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-close:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-close:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .focus.btn-close {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge,
.btn-close .badge {
  color: #fff;
  background-color: #333;
}
.btn-primary {
  color: #fff;
  background-color: #ff6800;
  border-color: transparent;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #cc5300;
  border-color: rgba(0, 0, 0, 0);
}
.btn-primary:hover {
  color: #fff;
  background-color: #cc5300;
  border-color: rgba(0, 0, 0, 0);
}
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #cc5300;
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary.focus:active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #a84500;
  border-color: rgba(0, 0, 0, 0);
}
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary.focus[disabled],
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #ff6800;
  border-color: transparent;
}
.btn-primary .badge {
  color: #ff6800;
  background-color: #fff;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #398439;
}
.btn-success:active:hover,
.btn-success:active:focus,
.btn-success.focus:active,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success.focus[disabled],
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #269abc;
}
.btn-info:active:hover,
.btn-info:active:focus,
.btn-info.focus:active,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info.focus[disabled],
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #d58512;
}
.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning.focus:active,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning.focus[disabled],
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
}
.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger.focus:active,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger.focus[disabled],
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}
.btn-link {
  font-weight: 400;
  color: #008dcc;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #005880;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.3333333;
  border-radius: 3px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input.btn-block[type="submit"],
input.btn-block[type="reset"],
input.btn-block[type="button"] {
  width: 100%;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 13px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #33424f;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.428571429;
  color: #777777;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  float: left;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  margin-left: -5px;
}
.btn-toolbar:before,
.btn-toolbar:after {
  display: table;
  content: " ";
}
.btn-toolbar:after {
  clear: both;
}
.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}
.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .btn-group {
  float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}
.btn-group > .btn-lg + .dropdown-toggle,
.btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn .caret {
  margin-left: 0;
}
.btn-lg .caret,
.btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}
.dropup .btn-lg .caret,
.dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after {
  display: table;
  content: " ";
}
.btn-group-vertical > .btn-group:after {
  clear: both;
}
.btn-group-vertical > .btn-group > .btn {
  float: none;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical
  > .btn-group:first-child:not(:last-child)
  > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical
  > .btn-group:last-child:not(:first-child)
  > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  display: table-cell;
  float: none;
  width: 1%;
}
.btn-group-justified > .btn-group .btn {
  width: 100%;
}
.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group .form-control:focus {
  z-index: 3;
}
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  color: #33424f;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #82909b;
  border-radius: 4px;
}
.input-group-addon.input-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}
.input-group-addon.input-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 10px 16px;
  font-size: 17px;
  border-radius: 6px;
}
.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav:before,
.nav:after {
  display: table;
  content: " ";
}
.nav:after {
  clear: both;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
.nav > li.disabled > a {
  color: #777777;
}
.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #008dcc;
}
.nav .nav-divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.nav > li > a > img {
  max-width: none;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #ddd;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
.nav-pills > li {
  float: left;
}
.nav-pills > li > a {
  border-radius: 4px;
}
.nav-pills > li + li {
  margin-left: 2px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #337ab7;
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}
.nav-justified,
.nav-tabs.nav-justified {
  width: 100%;
}
.nav-justified > li,
.nav-tabs.nav-justified > li {
  float: none;
}
.nav-justified > li > a,
.nav-tabs.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center;
}
.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 768px) {
  .nav-justified > li,
  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .nav-justified > li > a,
  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}
.nav-tabs-justified,
.nav-tabs.nav-justified {
  border-bottom: 0;
}
.nav-tabs-justified > li > a,
.nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}
.nav-tabs-justified > .active > a,
.nav-tabs.nav-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}
@media (min-width: 768px) {
  .nav-tabs-justified > li > a,
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .nav-tabs-justified > .active > a,
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 18px;
  border: 1px solid transparent;
}
.navbar:before,
.navbar:after {
  display: table;
  content: " ";
}
.navbar:after {
  clear: both;
}
@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}
.navbar-header:before,
.navbar-header:after {
  display: table;
  content: " ";
}
.navbar-header:after {
  clear: both;
}
@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}
.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
}
.navbar-collapse:before,
.navbar-collapse:after {
  display: table;
  content: " ";
}
.navbar-collapse:after {
  clear: both;
}
.navbar-collapse.in {
  overflow-y: auto;
}
@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}
@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}
@media (min-width: 768px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}
.container > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .container > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}
@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}
.navbar-brand {
  float: left;
  height: 50px;
  padding: 16px 15px;
  font-size: 17px;
  line-height: 18px;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-brand > img {
  display: block;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}
.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-right: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.navbar-toggle:focus {
  outline: 0;
}
.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}
.navbar-nav {
  margin: 8px -15px;
}
.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 18px;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 18px;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}
@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-nav > li > a {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.navbar-form {
  padding: 10px 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .navbar-form .form-control-static {
    display: inline-block;
  }
  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn,
  .navbar-form .input-group .form-control {
    width: auto;
  }
  .navbar-form .input-group > .form-control {
    width: 100%;
  }
  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .radio,
  .navbar-form .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .radio label,
  .navbar-form .checkbox label {
    padding-left: 0;
  }
  .navbar-form .radio input[type="radio"],
  .navbar-form .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}
@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }
  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.navbar-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
.navbar-btn.btn-sm,
.btn-group-sm > .navbar-btn.btn {
  margin-top: 10px;
  margin-bottom: 10px;
}
.navbar-btn.btn-xs,
.btn-group-xs > .navbar-btn.btn {
  margin-top: 14px;
  margin-bottom: 14px;
}
.navbar-text {
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }
}
@media (min-width: 768px) {
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}
.navbar-default {
  background-color: white;
  border-color: transparent;
}
.navbar-default .navbar-brand {
  color: #33424f;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #1f2830;
  background-color: transparent;
}
.navbar-default .navbar-text {
  color: #777;
}
.navbar-default .navbar-nav > li > a {
  color: #33424f;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #333;
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #eeeeee;
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #eeeeee;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #33424f;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #eeeeee;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
}
.navbar-default .navbar-toggle {
  border-color: #ddd;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: transparent;
}
.navbar-default .navbar-link {
  color: #33424f;
}
.navbar-default .navbar-link:hover {
  color: #333;
}
.navbar-default .btn-link {
  color: #33424f;
}
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #333;
}
.navbar-default .btn-link[disabled]:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:hover,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}
.navbar-inverse {
  background-color: #222;
  border-color: #090909;
}
.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: transparent;
}
.navbar-inverse .navbar-text {
  color: #9d9d9d;
}
.navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #090909;
}
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #090909;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #090909;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #090909;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #090909;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: transparent;
  }
}
.navbar-inverse .navbar-toggle {
  border-color: #333;
}
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010;
}
.navbar-inverse .navbar-link {
  color: #9d9d9d;
}
.navbar-inverse .navbar-link:hover {
  color: #fff;
}
.navbar-inverse .btn-link {
  color: #9d9d9d;
}
.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
  color: #fff;
}
.navbar-inverse .btn-link[disabled]:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}
.breadcrumb {
  padding: 8px 0;
  margin-bottom: 18px;
  list-style: none;
  background-color: white;
  border-radius: 4px;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #33424f;
  content: "> ";
}
.breadcrumb > .active {
  color: #33424f;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 18px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  color: #008dcc;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  z-index: 2;
  color: #005880;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #67cdcc;
  border-color: #67cdcc;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.pager {
  padding-left: 0;
  margin: 18px 0;
  text-align: center;
  list-style: none;
}
.pager:before,
.pager:after {
  display: table;
  content: " ";
}
.pager:after {
  clear: both;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
.pager li > a:hover,
.pager li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  color: #777777;
  cursor: not-allowed;
  background-color: #fff;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}
a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.label-default {
  background-color: #777777;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}
.label-primary {
  background-color: #337ab7;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}
.label-success {
  background-color: #5cb85c;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}
.label-info {
  background-color: #5bc0de;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}
.label-warning {
  background-color: #f0ad4e;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}
.label-danger {
  background-color: #d9534f;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777777;
  border-radius: 10px;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-xs .badge,
.btn-group-xs > .btn .badge {
  top: 0;
  padding: 1px 5px;
}
.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #008dcc;
  background-color: #fff;
}
.list-group-item > .badge {
  float: right;
}
.list-group-item > .badge + .badge {
  margin-right: 5px;
}
.nav-pills > li > a > .badge {
  margin-left: 3px;
}
a.badge:hover,
a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eeeeee;
}
.jumbotron h1,
.jumbotron .h1 {
  color: inherit;
}
.jumbotron p {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 200;
}
.jumbotron > hr {
  border-top-color: #d5d5d5;
}
.container .jumbotron,
.container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}
.jumbotron .container {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .container .jumbotron,
  .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
  }
  .jumbotron h1,
  .jumbotron .h1 {
    font-size: 59px;
  }
}
.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 18px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}
.thumbnail .caption {
  padding: 9px;
  color: #33424f;
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #008dcc;
}
.alert {
  padding: 15px;
  margin-bottom: 18px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  color: #009a57;
  background-color: #e5f6ef;
  border-color: #e5f6ef;
}
.alert-success hr {
  border-top-color: #d2efe3;
}
.alert-success .alert-link {
  color: #00673a;
}
.alert-info {
  color: #00819f;
  background-color: #e5f5f9;
  border-color: #e5f5f9;
}
.alert-info hr {
  border-top-color: #d0edf4;
}
.alert-info .alert-link {
  color: #00586c;
}
.alert-warning {
  color: #b78827;
  background-color: #fff8eb;
  border-color: #fff8eb;
}
.alert-warning hr {
  border-top-color: #ffefd2;
}
.alert-warning .alert-link {
  color: #8d691e;
}
.alert-danger {
  color: #e93d45;
  background-color: #fcebec;
  border-color: #fcebec;
}
.alert-danger hr {
  border-top-color: #f9d5d7;
}
.alert-danger .alert-link {
  color: #da1922;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  height: 18px;
  margin-bottom: 18px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  background-color: #57bde3;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}
.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-success {
  background-color: #5cb85c;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-info {
  background-color: #5bc0de;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-warning {
  background-color: #f0ad4e;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-danger {
  background-color: #d9534f;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.media {
  margin-top: 15px;
}
.media:first-child {
  margin-top: 0;
}
.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}
.media-body {
  width: 10000px;
}
.media-object {
  display: block;
}
.media-object.img-thumbnail {
  max-width: none;
}
.media-right,
.media > .pull-right {
  padding-left: 10px;
}
.media-left,
.media > .pull-left {
  padding-right: 10px;
}
.media-left,
.media-right,
.media-body {
  display: table-cell;
  vertical-align: top;
}
.media-middle {
  vertical-align: middle;
}
.media-bottom {
  vertical-align: bottom;
}
.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.media-list {
  padding-left: 0;
  list-style: none;
}
.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid transparent;
}
.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
  color: #777777;
  cursor: not-allowed;
  background-color: #eeeeee;
}
.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}
.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
  color: #777777;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active .list-group-item-heading > .small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > .small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #c7ddef;
}
a.list-group-item,
button.list-group-item {
  color: #555;
}
a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333;
}
a.list-group-item:hover,
a.list-group-item:focus,
button.list-group-item:hover,
button.list-group-item:focus {
  color: #555;
  text-decoration: none;
  background-color: #eff9f9;
}
button.list-group-item {
  width: 100%;
  text-align: left;
}
.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}
a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d;
}
a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit;
}
a.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:hover,
button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}
a.list-group-item-success.active,
a.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active,
button.list-group-item-success.active:hover,
button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}
.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}
a.list-group-item-info,
button.list-group-item-info {
  color: #31708f;
}
a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit;
}
a.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:hover,
button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}
a.list-group-item-info.active,
a.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active,
button.list-group-item-info.active:hover,
button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}
.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}
a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b;
}
a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}
a.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:hover,
button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}
a.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active,
button.list-group-item-warning.active:hover,
button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}
.list-group-item-danger {
  color: #d94c4c;
  background-color: #f2dede;
}
a.list-group-item-danger,
button.list-group-item-danger {
  color: #d94c4c;
}
a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}
a.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:hover,
button.list-group-item-danger:focus {
  color: #d94c4c;
  background-color: #ebcccc;
}
a.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active,
button.list-group-item-danger.active:hover,
button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #d94c4c;
  border-color: #d94c4c;
}
.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}
.panel {
  margin-bottom: 18px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
  padding: 15px;
}
.panel-body:before,
.panel-body:after {
  display: table;
  content: " ";
}
.panel-body:after {
  clear: both;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: inherit;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}
.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel
  > .panel-collapse
  > .list-group:first-child
  .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel
  > .panel-heading
  + .panel-collapse
  > .list-group
  .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group + .panel-footer {
  border-top-width: 0;
}
.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}
.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-right: 15px;
  padding-left: 15px;
}
.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  td:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  th:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  td:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  th:first-child {
  border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  td:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  th:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  td:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  th:last-child {
  border-top-right-radius: 3px;
}
.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  td:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  th:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  td:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  th:first-child {
  border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  td:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  th:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  td:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  th:last-child {
  border-bottom-right-radius: 3px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}
.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}
.panel > .table-responsive {
  margin-bottom: 0;
  border: 0;
}
.panel-group {
  margin-bottom: 18px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel-heading {
  border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}
.panel-default {
  border-color: #ddd;
}
.panel-default > .panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}
.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}
.panel-primary {
  border-color: #337ab7;
}
.panel-primary > .panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7;
}
.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #fff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7;
}
.panel-success {
  border-color: #d6e9c6;
}
.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}
.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}
.panel-info {
  border-color: #bce8f1;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}
.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}
.panel-warning {
  border-color: #faebcc;
}
.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}
.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}
.panel-danger {
  border-color: #d94c4c;
}
.panel-danger > .panel-heading {
  color: #d94c4c;
  background-color: #f2dede;
  border-color: #d94c4c;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d94c4c;
}
.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #d94c4c;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d94c4c;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-lg {
  padding: 24px;
  border-radius: 6px;
}
.well-sm {
  padding: 9px;
  border-radius: 3px;
}
.close {
  float: right;
  font-size: 19.5px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header:before,
.modal-header:after {
  display: table;
  content: " ";
}
.modal-header:after {
  clear: both;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.428571429;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571429;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 12px;
  filter: alpha(opacity=0);
  opacity: 0;
}
.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}
.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}
.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571429;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 13px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover > .arrow:after {
  content: "";
  border-width: 10px;
}
.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}
.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}
.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0;
}
.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}
.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 13px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
  line-height: 1;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -moz-transition: -moz-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
  }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    left: 0;
  }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    left: 0;
  }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.carousel-control.left {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.0001) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}
.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.0001) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.0001) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.0001) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}
.carousel-control:hover,
.carousel-control:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  filter: alpha(opacity=90);
  opacity: 0.9;
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}
.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}
.carousel-control .icon-prev:before {
  content: "\2039";
}
.carousel-control .icon-next:before {
  content: "\203a";
}
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-caption .btn {
  text-shadow: none;
}
@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}
.clearfix:after {
  clear: both;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs {
  display: none !important;
}
.visible-sm {
  display: none !important;
}
.visible-md {
  display: none !important;
}
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.breadcrumb {
  margin-bottom: 10px;
}
.form label:not(.sds-form-element__label) {
  color: #82909b;
  font-size: 11px;
  margin-bottom: 3px;
}
.form .checkbox label,
.form .radio label {
  font-size: 13px;
  line-height: 20px;
}
.form .form-control {
  padding: 6px 9px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .form .form-control {
    font-size: 13px;
  }
}
.form .has-error .control-label,
.form .has-error .radio,
.form .has-error .checkbox,
.form .has-error .radio-inline,
.form .has-error .checkbox-inline,
.form .has-error.radio label,
.form .has-error.checkbox label,
.form .has-error.radio-inline label,
.form .has-error.checkbox-inline label {
  color: #82909b;
  font-size: 12px;
}
.form .has-error .help-block a {
  color: #d94c4c;
  text-decoration: underline;
}
.input-group-btn button {
  height: 40px;
  border: 1px solid #82909b;
}
.form-control-static {
  padding-top: 0;
  padding-bottom: 5px;
  min-height: 0;
}
.form-control-static strong {
  margin-right: 0.5rem;
}
.modal-dialog {
  margin-bottom: 65px;
  outline: none;
}
a {
  cursor: pointer;
}
.panel-heading {
  line-height: 1.1em;
}
.panel-heading .insurance-claim {
  display: inline-block;
  padding: 5px 15px !important;
  float: right;
  margin-top: -34px;
  margin-right: 15px;
}
.btn {
  outline: none;
}
.alert a {
  color: inherit;
  text-decoration: underline;
}
.icon,
#tracking-tools #modal-search form:before,
#support #modal-search form:before,
#tracking-batch #modal-search form:before,
.navbar-form.search-form:after {
  background: url(./assets/icons.svg)
    no-repeat no-repeat;
  background-size: 1650px 100px;
  display: inline-block;
  margin-right: 8px;
  height: 26px;
  width: 26px;
}
.icon-international {
  height: 64px;
  width: 64px;
  margin-bottom: 0;
  margin-top: 16px;
  background: url(./assets/icontwo.svg)
    no-repeat no-repeat white;
  background-size: 797px 469px;
  content: "";
  display: block;
  position: relative;
  margin: 0 auto;
}
.icon-badge-eligible {
  background: url(./assets/verify.svg)
    no-repeat transparent;
  background-size: 15px 18px;
  height: 18px;
  width: 15px;
}
.icon.icon-search,
#tracking-tools #modal-search form:before,
#support #modal-search form:before,
#tracking-batch #modal-search form:before,
.navbar-form.search-form:after {
  background-position: -360px 0;
  height: 19px;
  width: 19px;
}
.icon-send-item {
  background: url(./assets/whitebg.svg)
    no-repeat transparent;
  height: 16px;
  width: 17px;
}
.icon-receive-item {
  background-position: -320px -1px;
}
.icon-upgrade {
  background-position: -280px -40px;
}
.icon-edit {
  background-position: -642px 0;
}
.icon-edit.current {
  background-position: -642px -35px;
}
.icon-edit.complete {
  background-position: -642px -71px;
}
.icon-payment {
  background-position: -682px 0;
}
.icon-payment.current {
  background-position: -682px -35px;
}
.icon-payment.complete {
  background-position: -682px -71px;
}
.icon-pickup {
  background-position: -722px 0;
}
.icon-pickup.current {
  background-position: -722px -35px;
}
.icon-pickup.complete {
  background-position: -722px -71px;
}
.icon-transit {
  background-position: -762px 0;
}
.icon-transit.current {
  background-position: -762px -35px;
}
.icon-transit.complete {
  background-position: -762px -71px;
}
.icon-parcel {
  background-position: -802px 0;
}
.icon-parcel.current {
  background-position: -802px -35px;
}
.icon-parcel.complete {
  background-position: -802px -71px;
}
.icon-tick {
  background-position: -842px 0;
}
.icon-tick.active {
  background-position: -842px -71px;
}
.icon-cancelled {
  background-position: -360px -41px;
}
.icon-lost {
  background-position: -360px -41px;
}
.icon-parcel-satchel {
  background-position: -1134px 0;
  width: 50px;
  height: 50px;
}
.icon-parcel-shoebox {
  background-position: -881px 0;
  width: 50px;
  height: 50px;
}
.icon-parcel-briefcase {
  background-position: -982px 0;
  width: 50px;
  height: 50px;
}
.icon-parcel-carry-on {
  background-position: -932px 0;
  width: 50px;
  height: 50px;
}
.icon-parcel-check-in {
  background-position: -1033px 0;
  width: 50px;
  height: 50px;
}
.icon-parcel-satchel-filled {
  background-position: -1084px -50px;
  width: 50px;
  height: 50px;
}
.icon-parcel-shoebox-filled {
  background-position: -440px -50px;
  width: 50px;
  height: 50px;
}
.icon-parcel-briefcase-filled {
  background-position: -540px -50px;
  width: 50px;
  height: 50px;
}
.icon-parcel-carry-on-filled {
  background-position: -490px -50px;
  width: 50px;
  height: 50px;
}
.icon-parcel-check-in-filled {
  background-position: -591px -50px;
  width: 50px;
  height: 50px;
}
.icon-parcel-250g {
  background-position: 0 0;
}
.icon-parcel-500g {
  background-position: -57px 0;
}
.icon-parcel-1kg {
  background-position: -114px 0;
}
.icon-parcel-1_5kg {
  background-position: -171px 0;
}
.icon-parcel-250g-filled {
  background-position: 0 -114px;
}
.icon-parcel-500g-filled {
  background-position: -57px -114px;
}
.icon-parcel-1kg-filled {
  background-position: -114px -114px;
}
.icon-parcel-1_5kg-filled {
  background-position: -171px -114px;
}
.icon-truck {
  background-position: 0px -285px;
}
.icon-truck-hover {
  background-position: -57px -285px;
}
.icon-globe {
  background-position: -114px -285px;
}
.icon-globe-hover {
  background-position: -171px -285px;
}
.icon-laptop {
  background-position: -228px -228px;
}
.icon-laptop-hover {
  background-position: -285px -228px;
}
.icon-grey-chevron {
  background-position: -409px -20px;
  height: 12px;
  width: 9px;
}
.icon-download {
  background-position: -407px -77px;
  height: 12px;
  width: 10px;
}
.icon-chopper {
  background-position: -1590px 0;
  height: 26px;
  width: 36px;
}
.icon-email-love {
  background-position: -884px -54px;
  height: 40px;
  width: 40px;
}
.icon-padlock {
  background-position: -935px -54px;
  height: 40px;
  width: 28px;
}
.icon-location-pin {
  background-position: -968px -54px;
  height: 40px;
  width: 38px;
}
.icon-link:before {
  content: "";
  float: left;
  height: 26px;
  width: 26px;
  margin-right: 8px;
  background: url(./assets/icons.svg)
    no-repeat;
  background-size: 1650px 100px;
}
.icon-link.btn {
  line-height: 30px;
}
.icon-link-dashboard:before {
  background-position: 0 0;
}
.icon-link-dashboard.active:before {
  background-position: 0 -40px;
}
.icon-link-sending:before {
  background-position: -160px 0;
}
.icon-link-sending.active:before {
  background-position: -160px -40px;
}
.icon-link-receiving:before {
  background-position: -120px 0;
}
.icon-link-receiving.active:before {
  background-position: -120px -40px;
}
.icon-link-billing:before {
  background-position: -40px 0;
}
.icon-link-billing.active:before {
  background-position: -40px -40px;
}
.icon-link-settings:before {
  background-position: -80px 0;
}
.icon-link-settings.active:before {
  background-position: -80px -40px;
}
.icon-link-support:before {
  background-position: -200px 0;
}
.icon-link-support.active:before {
  background-position: -200px -40px;
}
.icon-add-item:before {
  background-position: -400px -40px;
}
.icon-twitter:before {
  background-position: -360px -69px;
}
.icon-link-connect:before {
  background-position: -240px -1px;
}
.icon-link-connect.active:before,
.icon-link-connect.btn-primary:before {
  background-position: -240px -40px;
}
.icon-link-settings:before {
  background-position: -80px 0;
}
.icon-link-settings.active:before,
.icon-link-settings.btn-primary:before {
  background-position: -80px -40px;
}
.icon-link-block {
  text-align: center;
}
.icon-link-block:before {
  content: "";
  display: block;
  height: 50px;
  width: 50px;
  margin: 0 auto 15px auto;
  background: url(./assets/icons.svg)
    no-repeat;
  background-size: 1650px 100px;
}
.icon-location-lg:before {
  background-position: -1086px 0;
}
.icon-location-lg:hover:before {
  background-position: -1086px -50px;
}
.icon-payment-lg:before {
  background-position: -1136px 0;
}
.icon-payment-lg:hover:before {
  background-position: -1136px -50px;
}
.icon-send-lg:before {
  background-position: -1186px 0;
}
.icon-send-lg:hover:before {
  background-position: -1186px -50px;
}
.icon-parcel-width {
  background-position: -1275px 0;
  width: 50px;
  height: 67px;
}
.icon-parcel-height {
  background-position: -1333px 0;
  width: 60px;
  height: 67px;
}
.icon-parcel-length {
  background-position: -1401px 0;
  width: 60px;
  height: 67px;
}
.icon-parcel-weight {
  background-position: -1470px 0;
  width: 60px;
  height: 67px;
}
.icon-ellipsis {
  background-position: -1535px 0;
  width: 60px;
  height: 67px;
}
.icon-id {
  background-position: -172px -227px;
}
.icon-closed-box {
  background-position: -60px -230px;
}
.icon-closing-box {
  background-position: 0 -229px;
}
.form-element:after {
  content: "";
  float: right;
  height: 14px;
  width: 13px;
  margin-left: 8px;
  background: url(./assets/search.svg)
    no-repeat;
  background-size: 120px 112px;
  display: none;
}
.form-element.external-link:after {
  display: inline-block;
  float: none;
  margin-left: 4px;
  background-position: -14px -71px;
}
.form-element.form-icon-search:after {
  display: block;
  height: 20px;
  width: 20px;
  background-position: -4px -45px;
  background-size: 140px 162px;
  margin: -2px 0;
}
.field-group > .form-group:focus-within .form-element.form-icon-search:after {
  background-position: -26px -45px;
}
.has-error .form-element.form-icon-search:after {
  background-position: -70px -45px;
}
.disabled .form-element.form-icon-search:after {
  background-position: -49px -45px;
}
.form-element.icon-inline:after {
  float: none;
  display: inline-block;
  margin-left: 4px;
}
@-moz-document url-prefix() {
  .form-element:after {
    background-size: 90px 112px;
  }
  .form-element.external-link:after {
    background-position: 0px -71px;
  }
  .form-element.form-icon-search:after {
    background-position: -2px -45px;
  }
  .field-group > .form-group:focus-within .form-element.form-icon-search:after {
    background-position: -25px -45px;
  }
}
.icon.icon-people,
#tracking-tools #modal-search form.icon-people:before,
#support #modal-search form.icon-people:before,
#tracking-batch #modal-search form.icon-people:before,
.icon-people.navbar-form.search-form:after {
  background: url(./assets/png-icon-set-a48837a4745cabf7e9746aa4f563f8842d939382c2f062adf3645788e4af16db.png)
    no-repeat no-repeat;
  background-size: 131px 40px;
  background-position: 0 0;
  height: 40px;
  width: 49px;
}
.icon.icon-book,
#tracking-tools #modal-search form.icon-book:before,
#support #modal-search form.icon-book:before,
#tracking-batch #modal-search form.icon-book:before,
.icon-book.navbar-form.search-form:after {
  background: url(./assets/png-icon-set-a48837a4745cabf7e9746aa4f563f8842d939382c2f062adf3645788e4af16db.png)
    no-repeat no-repeat;
  background-size: 131px 40px;
  background-position: -49px 0;
  height: 40px;
  width: 42px;
}
.icon.icon-computer,
#tracking-tools #modal-search form.icon-computer:before,
#support #modal-search form.icon-computer:before,
#tracking-batch #modal-search form.icon-computer:before,
.icon-computer.navbar-form.search-form:after {
  background: url(./assets/png-icon-set-a48837a4745cabf7e9746aa4f563f8842d939382c2f062adf3645788e4af16db.png)
    no-repeat no-repeat;
  background-size: 131px 40px;
  background-position: -91px 0;
  height: 40px;
  width: 40px;
}
.drop-off-details:before {
  content: "";
  float: left;
  height: 33px;
  width: 27px;
  background: url(./assets/bus.svg)
    no-repeat;
  background-size: 92px 38px;
  display: block;
}
.drop-off-details.map:before {
  background-position: 0 0;
}
.drop-off-details.box:before {
  background-position: -33px 0;
}
.drop-off-details.truck:before {
  background-position: -66px 0;
}
.unified .parcel-size:before {
  background: url(./assets/box.svg)
    no-repeat no-repeat white;
  background-size: 840px 502px;
  background-size: 956px 573px;
  content: "";
  display: block;
  margin: 0 auto 5px auto;
  height: 64px;
  width: 64px;
}
.unified .parcel-size.parcel-size-satchel:before {
  background-position: 0 0;
}
.unified .parcel-size.parcel-size-satchel:hover:before {
  background-position: 0 -64px;
}
.unified .parcel-size.parcel-size-satchel.selected:before {
  background-position: 0 -127.5px;
}
.unified .parcel-size.parcel-size-satchel-filled:before {
  background-position: 0 -192px;
}
.unified .parcel-size.parcel-size-handbag:before {
  background-position: -828.5px 0;
}
.unified .parcel-size.parcel-size-handbag:hover:before {
  background-position: -828.5px -64px;
}
.unified .parcel-size.parcel-size-handbag.selected:before {
  background-position: -828.5px -128px;
}
.unified .parcel-size.parcel-size-handbag-filled:before {
  background-position: -828.5px -191.5px;
}
.unified .parcel-size.parcel-size-shoebox:before {
  background-position: -63.5px 0;
}
.unified .parcel-size.parcel-size-shoebox:hover:before {
  background-position: -63.5px -63.5px;
}
.unified .parcel-size.parcel-size-shoebox.selected:before {
  background-position: -63.5px -127.5px;
}
.unified .parcel-size.parcel-size-shoebox-filled:before {
  background-position: -63.5px -192px;
}
.unified .parcel-size.parcel-size-briefcase:before {
  background-position: -127.5px 0;
}
.unified .parcel-size.parcel-size-briefcase:hover:before {
  background-position: -127.5px -64px;
}
.unified .parcel-size.parcel-size-briefcase.selected:before {
  background-position: -127.5px -127.5px;
}
.unified .parcel-size.parcel-size-briefcase-filled:before {
  background-position: -127.5px -192px;
}
.unified .parcel-size.parcel-size-carry-on:before {
  background-position: -191px 0;
}
.unified .parcel-size.parcel-size-carry-on:hover:before {
  background-position: -191px -63.5px;
}
.unified .parcel-size.parcel-size-carry-on.selected:before {
  background-position: -191px -127.5px;
}
.unified .parcel-size.parcel-size-carry-on-filled:before {
  background-position: -191px -191px;
}
.unified .parcel-size.parcel-size-check-in:before {
  background-position: -254.5px 0;
}
.unified .parcel-size.parcel-size-check-in:hover:before {
  background-position: -254.5px -63.5px;
}
.unified .parcel-size.parcel-size-check-in.selected:before {
  background-position: -254.5px -127.5px;
}
.unified .parcel-size.parcel-size-check-in-filled:before {
  background-position: -255px -192px;
}
.unified .parcel-size.parcel-size-250g:before {
  background-position: -318.5px 0;
}
.unified .parcel-size.parcel-size-250g:hover:before {
  background-position: -318.5px -63.5px;
}
.unified .parcel-size.parcel-size-250g.selected:before {
  background-position: -318.5px -127.5px;
}
.unified .parcel-size.parcel-size-250g-filled:before {
  background-position: -318.5px -192px;
}
.unified .parcel-size.parcel-size-500g:before {
  background-position: -382.5px 0;
}
.unified .parcel-size.parcel-size-500g:hover:before {
  background-position: -382.5px -63.5px;
}
.unified .parcel-size.parcel-size-500g.selected:before {
  background-position: -382.5px -127.5px;
}
.unified .parcel-size.parcel-size-500g-filled:before {
  background-position: -382.5px -192px;
}
.unified .parcel-size.parcel-size-1kg:before {
  background-position: -446px 0;
}
.unified .parcel-size.parcel-size-1kg:hover:before {
  background-position: -446px -63.5px;
}
.unified .parcel-size.parcel-size-1kg.selected:before {
  background-position: -446px -127.5px;
}
.unified .parcel-size.parcel-size-1kg-filled:before {
  background-position: -446px -192px;
}
.unified .parcel-size.parcel-size-2kg-filled:before {
  background-position: -573px -192px;
}
.unified .parcel-size.parcel-size-1_5kg:before {
  background-position: -510px 0;
}
.unified .parcel-size.parcel-size-1_5kg:hover:before {
  background-position: -510px -63.5px;
}
.unified .parcel-size.parcel-size-1_5kg.selected:before {
  background-position: -510px -127.5px;
}
.unified .parcel-size.parcel-size-1_5kg-filled:before {
  background-position: -510px -192px;
}
.unified .parcel-size.parcel-size-micro:before,
.unified .parcel-size.parcel-size-micro-filled:before,
.unified .parcel-size.parcel-size-micro:hover:before,
.unified .parcel-size.parcel-size-micro.selected:before {
  background: url(./assets/box2.svg)
    no-repeat no-repeat white;
  background-size: 318px 255px;
  content: "";
  display: block;
  margin: 0 auto 5px auto;
  height: 64px;
  width: 64px;
  background-position: 0 0;
}
.unified .parcel-size.parcel-size-micro:hover:before {
  background-position: 0 -64px;
}
.unified .parcel-size.parcel-size-micro.selected:before {
  background-position: 0 -127.5px;
}
.unified .parcel-size.parcel-size-micro-filled:before {
  background-position: 0 -191px;
}
.unified .parcel-size.parcel-size-mini:before,
.unified .parcel-size.parcel-size-mini-filled:before,
.unified .parcel-size.parcel-size-mini:hover:before,
.unified .parcel-size.parcel-size-mini.selected:before {
  background: url(./assets/box2.svg)
    no-repeat no-repeat white;
  background-size: 318px 255px;
  content: "";
  display: block;
  margin: 0 auto 5px auto;
  height: 64px;
  width: 64px;
  background-position: -63.5px 0;
}
.unified .parcel-size.parcel-size-mini:hover:before {
  background-position: -63.5px -63.5px;
}
.unified .parcel-size.parcel-size-mini.selected:before {
  background-position: -63.5px -127.5px;
}
.unified .parcel-size.parcel-size-mini-filled:before {
  background-position: -63px -191px;
}
.unified .parcel-size.parcel-size-small:before,
.unified .parcel-size.parcel-size-small-filled:before,
.unified .parcel-size.parcel-size-small:hover:before,
.unified .parcel-size.parcel-size-small.selected:before {
  background: url(./assets/box2.svg)
    no-repeat no-repeat white;
  background-size: 318px 255px;
  content: "";
  display: block;
  margin: 0 auto 5px auto;
  height: 64px;
  width: 64px;
  background-position: -127px 0;
}
.unified .parcel-size.parcel-size-small:hover:before {
  background-position: -127px -64px;
}
.unified .parcel-size.parcel-size-small.selected:before {
  background-position: -127px -127.5px;
}
.unified .parcel-size.parcel-size-small-filled:before {
  background-position: -127px -191px;
}
.unified .parcel-size.parcel-size-medium:before,
.unified .parcel-size.parcel-size-medium-filled:before,
.unified .parcel-size.parcel-size-medium:hover:before,
.unified .parcel-size.parcel-size-medium.selected:before {
  background: url(./assets/box2.svg)
    no-repeat no-repeat white;
  background-size: 318px 255px;
  content: "";
  display: block;
  margin: 0 auto 5px auto;
  height: 64px;
  width: 64px;
  background-position: -190.5px 0;
}
.unified .parcel-size.parcel-size-medium:hover:before {
  background-position: -190.5px -64px;
}
.unified .parcel-size.parcel-size-medium.selected:before {
  background-position: -190.5px -127.5px;
}
.unified .parcel-size.parcel-size-medium-filled:before {
  background-position: -191px -191px;
}
.unified .parcel-size.parcel-size-large:before,
.unified .parcel-size.parcel-size-large-filled:before,
.unified .parcel-size.parcel-size-large:hover:before,
.unified .parcel-size.parcel-size-large.selected:before {
  background: url(./assets/box2.svg)
    no-repeat no-repeat white;
  background-size: 318px 255px;
  content: "";
  display: block;
  margin: 0 auto 5px auto;
  height: 64px;
  width: 64px;
  background-position: -254.5px 0;
}
.unified .parcel-size.parcel-size-large:hover:before {
  background-position: -254.5px -64px;
}
.unified .parcel-size.parcel-size-large.selected:before {
  background-position: -254.5px -127.5px;
}
.unified .parcel-size.parcel-size-large-filled:before {
  background-position: -254px -191px;
}
.unified .parcel-size.parcel-size-custom {
  display: block;
}
.unified .parcel-size.parcel-size-custom:before {
  background-position: -573.5px 0;
}
.unified .parcel-size.parcel-size-custom:hover:before {
  background-position: -573.5px -63.5px;
}
.unified .parcel-size.parcel-size-custom.selected:before {
  background-position: -573.5px -127.5px;
}
.unified .parcel-size.parcel-size-custom-filled:before {
  background-position: -573.5px -192px;
}
@media (min-width: 768px) {
  .unified .parcel-size {
    width: 100px;
    height: auto;
    margin: 5px 10px 5px 0;
  }
}
.unified .first-mile-option {
  background: none;
  border: none;
  outline: none;
}
.unified .first-mile-option:before {
  background: url(./assets/box.svg)
    no-repeat no-repeat white;
  background-size: 840px 502px;
  content: "";
  display: block;
  height: 56px;
  width: 56px;
}
@media (min-width: 768px) {
  .unified .first-mile-option:before {
    float: left;
  }
}
.unified .first-mile-option.first-mile-option-pickup:before {
  background-position: -672px 0;
}
.unified .first-mile-option.first-mile-option-pickup:hover:before {
  background-position: -672px -56px;
}
.unified .first-mile-option.first-mile-option-pickup.selected:before {
  background-position: -672px -112px;
}
.unified .first-mile-option.first-mile-option-pickup-filled:before {
  background-position: -672px -168px;
}
.unified .first-mile-option.first-mile-option-drop-off:before {
  background-position: -616px 0;
}
.unified .first-mile-option.first-mile-option-drop-off:hover:before {
  background-position: -616px -56px;
}
.unified .first-mile-option.first-mile-option-drop-off.selected:before {
  background-position: -616px -112px;
}
.unified .first-mile-option.first-mile-option-drop-off-filled:before {
  background-position: -616px -168px;
}
.unified .order-form-icons:before {
  background: url(./assets/box.svg)
    no-repeat no-repeat white;
  background-size: 840px 502px;
  content: "";
  display: block;
  margin: 0 auto 10px auto;
  height: 64px;
  width: 64px;
}
.unified .order-form-icons.delivery-truck:before {
  background-position: -505px -287px;
}
.unified .order-form-icons.payment-method:before {
  background-position: -785px -287px;
}
.unified .caret,
.unified .select2-selection__arrow [role="presentation"] {
  border: none;
  width: 10px;
  height: 10px;
}
.unified .caret:before,
.unified .select2-selection__arrow [role="presentation"]:before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-left: -4px;
  background: url(./assets/search.svg)
    no-repeat;
  background-position: -0px -80px;
}
.unified button[aria-expanded="true"] .caret:before,
.unified
  .select2-container--open
  .select2-selection__arrow
  [role="presentation"]:before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-left: -4px;
  background: url(./assets/search.svg)
    no-repeat;
  background-position: -16px -80px;
}
.unified .caret:before,
.unified button[aria-expanded="true"] .caret:before {
  position: absolute;
  top: 11px;
}
.unified .select2-selection__arrow [role="presentation"],
.unified
  .select2-container--open
  .select2-selection__arrow
  [role="presentation"] {
  border: none !important;
}
.unified .select2-selection__arrow [role="presentation"]:before,
.unified
  .select2-container--open
  .select2-selection__arrow
  [role="presentation"]:before {
  margin-left: 0;
  position: absolute;
  top: -5px;
  left: -4px;
}
.unified .warning.glyphicon,
.unified .warning.panel-loading:after,
.unified .warning.panel-loading-plain:after,
.unified .warning.btn-loading:after {
  color: #f8ce46;
  width: 0px;
  height: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #000;
}
.unified .warning.glyphicon:after,
.unified .warning.panel-loading:after,
.unified .warning.panel-loading-plain:after,
.unified .warning.btn-loading:after {
  content: "\e209";
  margin-left: -6px;
}
.unified .warning.glyphicon:before {
  content: none;
}
.unified .warning.input {
  margin-right: 8px;
}
.stamp.stamp-premium {
  /* background: url(https://assets.sendle.com/assets/dashboard/premium-stamps-98b1614ba765faa96c7647704a29ac34af75a10af53141f604bf1a91391ec36d.svg) */
    /* no-repeat; */
  background-size: 368px 129px;
  display: inline-block;
  width: 87px;
  height: 87px;
  background-position: -26px -20px;
}
.stamp.stamp-dashboard-csv {
  /* background: url(https://assets.sendle.com/assets/dashboard/dashboard-csv-stamp-cb1b47b3310933d5b2ee63f2e66609be614765b5db2f7189441969bcc90b79a3.svg) */
    /* no-repeat; */
  display: inline-block;
  width: 79px;
  height: 79px;
}
html {
  /* height: 100%;https://assets.sendle.com/assets/dashboard/dashboard-csv-stamp-cb1b47b3310933d5b2ee63f2e66609be614765b5db2f7189441969bcc90b79a3.svg */
}
body {
  padding-top: 132px;
  min-width: 320px;
  height: 100%;
}
@media (min-width: 768px) {
  body {
    padding-top: 73px;
  }
}
.container {
  max-width: 100%;
}
.container-fluid {
  position: relative;
  min-height: 100%;
}
body.fat-navbar {
  padding-top: 90px;
}
@media (min-width: 768px) {
  body.fat-navbar {
    padding-top: 103px;
  }
}
.sidebar {
  display: none;
}
@media (min-width: 768px) {
  .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f7f8fa;
    width: 200px;
    min-height: 780px;
  }
}
@media (min-width: 768px) {
  .fat-navbar .sidebar {
    top: 102px;
  }
}
.main {
  padding: 0 10px 0 10px;
}
@media (min-width: 768px) {
  .main {
    padding: 0 40px 20px 40px;
    margin: 0 0 0 200px;
  }
}
.main .page-header {
  margin-top: 10px;
}
.main .page-body {
  padding: 0px 15px 15px 15px;
}
.main .page-body .form-group.submit-group {
  border-top: 0;
  text-align: left;
  padding: 0 0 0 0;
  margin: 0 0 10px 0 !important;
}
.main-child--full-width {
  margin: 0 -10px;
}
@media (min-width: 768px) {
  .main-child--full-width {
    margin: 0 -40px 0 -40px;
  }
}
.main-child--flush-header {
  margin-top: -20px;
}
.main.under:before,
.ie9 .main:before {
  content: "";
  box-shadow: -1px -1px 6px 1px rgba(200, 200, 200, 0.8);
  position: fixed;
  width: 100%;
  height: 1px;
  top: 57px;
  margin-left: -10px;
  margin-right: -10px;
  z-index: 80;
}
@media (min-width: 768px) {
  .main.under:before,
  .ie9 .main:before {
    top: 72px;
    margin-left: -37px;
    margin-right: 0;
  }
}
.profile-image img {
  -ms-interpolation-mode: nearest-neighbor;
  display: block;
  overflow: hidden;
}
.action-subnav {
  margin: 21px 0 25px 0;
}
.action-subnav .pull-left > div {
  display: inline-block;
}
.action-subnav .pull-right .batch-button {
  display: none;
}
@media (min-width: 768px) {
  .action-subnav .pull-right .batch-button {
    display: inline-block;
  }
}
address p {
  margin: 0 0 3px 0;
}
.group {
  overflow: visible;
}
.group:before,
.group:after {
  content: "";
  display: table;
}
.group:after {
  clear: both;
}
.style-guide .heading {
  margin-left: 240px;
  padding-top: 7px;
}
.style-guide.main.under .divided::before {
  content: "";
  display: block;
  height: 80px;
  margin: -80px 0 0;
}
.style-guide.main.under .sidebar {
  top: 0;
}
.style-guide.main.under .sidebar .nav-footer {
  position: relative;
}
.style-guide.main.under #navbar nav {
  position: relative;
}
section.tld {
  padding-bottom: 1.5rem;
}
.tld-banner {
  background-color: #f7f8fa;
  padding: 1rem;
  display: flex;
  border-radius: 0.5rem;
}
.tld-banner__image-section {
  opacity: 1;
}
.tld-banner__image-desktop {
  display: block;
}
.tld-banner__image-mobile {
  display: none;
}
.tld-banner__text-section {
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
  max-width: 800px;
}
.tld-banner__title {
  padding-top: 0.5rem;
  font-weight: bold;
  font-size: 1.25rem;
  color: #33424f;
}
.tld-banner__description {
  padding-top: 0.5rem;
  color: #33424f;
  font-size: 0.875rem;
}
@media (max-width: 768px) {
  .tld-banner__text-section {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .tld-banner__image-section .tld-banner__image-mobile {
    display: block;
  }
  .tld-banner__image-section .tld-banner__image-mobile img {
    width: 50px;
  }
  .tld-banner__image-section .tld-banner__image-desktop {
    display: none;
  }
}
.sandbox-banner {
  top: 0;
  min-height: 30px;
  border-width: 0 0 1px;
  border: 1px solid transparent;
  color: #30414e;
  background-color: #fbeeee;
  border-bottom: 3px solid #e44144;
  text-align: center;
  height: 90px;
}
@media (min-width: 600px) {
  .sandbox-banner {
    height: 80px;
  }
}
.sandbox-icon {
  text-align: left;
  display: inline-block;
  font-size: 13px;
  padding-top: 0px;
  padding-right: 10px;
  color: red;
  background: url(./assets/hands.svg)
    no-repeat;
  background-size: 46px 46px;
  width: 46px;
  height: 41px;
  margin: auto;
}
.sandbox-banner-text {
  line-height: 1.5;
  text-align: center;
  display: inline-block;
}
@media (min-width: 600px) {
  .sandbox-banner-text {
    line-height: 1;
    text-align: left;
  }
}
body.sandbox .container.sandbox-nav-pad {
  padding-top: 105px;
}
@media (min-width: 600px) {
  body.sandbox .container.sandbox-nav-pad {
    padding-top: 80px;
  }
}
body.sandbox .flash-padding {
  padding: 20px 0 0 0;
}
@media (min-width: 600px) {
  body.sandbox .flash-padding {
    padding: 16px 190px 0px 190px;
  }
}
body.sandbox .sandbox-login-navbar {
  padding-top: 145px;
}
@media (min-width: 600px) {
  body.sandbox .sandbox-login-navbar {
    padding-top: 81px;
  }
}
body.sandbox.logged-out-page .sandbox-banner {
  height: 137px;
}
@media (min-width: 600px) {
  body.sandbox.logged-out-page .sandbox-banner {
    height: 90px;
  }
}
body.sandbox-navbar {
  padding-top: 270px;
}
@media (min-width: 600px) {
  body.sandbox-navbar {
    padding-top: 183px;
  }
}
body.sandbox-navbar .sandbox-banner {
  height: 139px;
}
@media (min-width: 600px) {
  body.sandbox-navbar .sandbox-banner {
    height: 80px;
  }
}
@media (min-width: 600px) {
  body.as-admin-sandbox-navbar .sidebar {
    top: 175px;
  }
}
body.as-admin-sandbox-navbar .sandbox-banner {
  height: 132px;
}
@media (min-width: 600px) {
  body.as-admin-sandbox-navbar .sandbox-banner {
    height: 80px;
  }
}
body.as-admin-sandbox-navbar {
  padding-top: 240px;
}
@media (min-width: 600px) {
  body.as-admin-sandbox-navbar {
    padding-top: 175px;
  }
}
body.sandbox-tracking .sandbox-banner {
  height: 180px;
}
@media (min-width: 600px) {
  body.sandbox-tracking .sandbox-banner {
    height: 90px;
  }
}
body.sandbox-tracking .sandbox-banner-text {
  line-height: 1.3;
}
@media (min-width: 600px) {
  body.sandbox-tracking .sandbox-banner-text {
    line-height: 0.1;
  }
}
body.sandbox-basic .sandbox-banner-text {
  padding: 0 5px 0 5px;
}
body.sandbox-basic .sandbox-banner {
  margin-left: -10px;
  margin-right: -10px;
  height: 160px;
}
@media (min-width: 600px) {
  body.sandbox-basic .sandbox-banner {
    margin-left: -40px;
    margin-right: -40px;
    height: 80px;
  }
}
.twitter-typeahead {
  width: 100%;
}
.twitter-typeahead .tt-menu {
  padding: 10px;
  background: #f2f5f8;
  border: 1px solid #dce8f2;
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
}
.twitter-typeahead .prompt-message {
  font-size: 12px;
  border-bottom: 1px solid #dce8f2;
  padding-bottom: 4px;
  margin-bottom: 4px;
}
.twitter-typeahead .empty-message {
  font-size: 13px;
  font-weight: bold;
}
.twitter-typeahead .tt-suggestion {
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  padding: 5px 0;
}
.twitter-typeahead .tt-suggestion p {
  margin: 0;
}
.twitter-typeahead .tt-suggestion:hover {
  background: #ebf0f4;
}
code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}
pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}
@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #f5f2f0;
}
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}
.token.punctuation {
  color: #999;
}
.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}
.token.function {
  color: #dd4a68;
}
.token.regex,
.token.important,
.token.variable {
  color: #e90;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
pre.language-markup {
  border: 0;
  border-radius: 0;
  padding: 0;
  background: #f4f4f4;
}
code[class*="language-"],
pre[class*="language-"] {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  text-shadow: none;
  position: relative;
}
.copy-button {
  cursor: pointer;
  border: 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 6px 10px 10px;
  color: #999;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  outline-width: 0;
}
.copy-button:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  background-size: contain;
  /* background: url(https://assets.sendle.com/assets/dashboard/clipboard.svg) */
    /* no-repeat; */
  position: relative;
  top: 3px;
}
.account-plans .panel-header {
  margin-bottom: 16px;
}
.account-plans .panel-header .current-plan {
  font-size: 22px;
  font-weight: bold;
}
.account-plans .panel-body p {
  font-size: 14px;
}
.account-plans .panel-body .account-pricing {
  width: 100%;
  text-align: center;
  padding: 16px 0;
  margin-bottom: 24px;
}
.account-plans .panel-body .account-pricing a {
  font-size: 16px;
}
.account-plans .panel-footing p {
  font-size: 14px;
  color: #6d767e;
}
.account-plans .panel-footing .account-contact-box {
  position: relative;
  display: inline-block;
  background: transparent;
  padding: 8px 15px 8px 25px;
  margin-top: 24px;
  border: 1px solid #e9edf0;
  border-radius: 1px;
}
.account-plans .panel-footing .account-contact-box:before {
  content: "";
  position: absolute;
  width: 4px;
  left: 5px;
  top: 4px;
  bottom: 4px;
  background: #e9edf0;
  border-radius: 1px;
}
.gtee-panel {
  height: 100%;
  border: 1px solid #e9edf0;
  padding: 24px;
  text-align: center;
  pointer-events: none;
}
@media (max-width: 991px) {
  .gtee-panel {
    margin-bottom: 15px;
  }
}
.gtee-panel img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.gtee-panel .panel-heading {
  padding: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.33;
  margin: 1em 0 0.25em;
}
.gtee-panel .panel-body {
  padding: 0;
}
.gtee-panel:hover {
  border-color: #57bde3;
}
.gtee-panel a {
  pointer-events: all;
}
.account-saved-parcel-sizes {
  padding-left: 30px;
}
.account-saved-parcel-sizes__description {
  max-width: 1140px;
  line-height: 1.5;
  font-size: 1rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1060px) {
  .account-saved-parcel-sizes {
    border-top: 0.5rem solid #e9edf0;
    border-top: none;
  }
  .account-saved-parcel-sizes.sds-table--bold {
    border-top: 0.5rem solid #33424f;
  }
  .account-saved-parcel-sizes.sds-table--subtle {
    border-top-width: 0.0625rem;
  }
  .account-saved-parcel-sizes.sds-table--headless {
    border-top-width: 0.0625rem;
  }
  .account-saved-parcel-sizes .sds-table__table {
    display: block;
  }
  .account-saved-parcel-sizes .sds-table__table thead,
  .account-saved-parcel-sizes .sds-table__table tbody,
  .account-saved-parcel-sizes .sds-table__table tfoot {
    display: block;
  }
  .account-saved-parcel-sizes .sds-table__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .account-saved-parcel-sizes .sds-table__row .sds-table__cell:first-of-type,
  .account-saved-parcel-sizes .sds-table__row .sds-table__header:first-of-type {
    padding-left: 1rem;
  }
  .account-saved-parcel-sizes .sds-table__row .sds-table__cell:last-of-type,
  .account-saved-parcel-sizes .sds-table__row .sds-table__header:last-of-type {
    padding-right: 1rem;
  }
  .account-saved-parcel-sizes .sds-table__row--header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  .account-saved-parcel-sizes .sds-table__cell {
    flex-basis: 100%;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .account-saved-parcel-sizes .sds-table__cell:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .account-saved-parcel-sizes.sds-table--spacious .sds-table__row {
    padding: 0.75rem 0;
  }
  .account-saved-parcel-sizes.sds-table--spacious .sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .account-saved-parcel-sizes.sds-table--compact .sds-table__row {
    padding: 0.25rem 0;
  }
  .account-saved-parcel-sizes.sds-table--compact .sds-table__cell {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .account-saved-parcel-sizes .saved-parcel-sizes-table {
    border-top: 0.0625rem solid #e9edf0;
  }
  .account-saved-parcel-sizes .saved-parcel-sizes-table__actions-dropdown {
    flex-basis: 30%;
    order: -1;
  }
  .account-saved-parcel-sizes .saved-parcel-sizes-table__name {
    flex-basis: 50%;
    order: -1;
  }
}
@media screen and (min-width: 400px) {
  .account-saved-parcel-sizes .saved-parcel-sizes-table .dropdown-menu {
    min-width: max-content;
  }
}
.saved-parcel-size-drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.saved-parcel-size-drawer__success-message {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}
.saved-parcel-size-drawer__success-heading {
  margin: 0.5rem 0;
}
.saved-parcel-size-drawer__form .select2-container {
  width: 100% !important;
}
.saved-parcel-size-drawer__form .sds-form-element {
  margin-bottom: 1rem;
}
.saved-parcel-size-drawer__weight {
  padding: 0;
}
.saved-parcel-size-drawer__fields {
  clear: both;
  display: flex;
  align-items: start;
  gap: 0.5rem;
}
.saved-parcel-size-drawer__group-label {
  margin-bottom: 0.5rem;
  float: left;
  width: 100%;
  font-size: 1rem;
  border-bottom: none;
}
.saved-parcel-size-drawer__operator {
  margin-top: 0.5rem;
}
.manage-saved-sizes {
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
}
.manage-saved-sizes__cog {
  color: #33424f;
  height: 1rem;
  margin-right: 0.5rem;
}
.manage-saved-sizes__link {
  font-size: 0.875rem;
}
.account-tabs {
  border-bottom: 0;
  padding: 15px 0 0 15px;
  background: #f4f4f4;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}
.account-tabs .nav-tabs {
  border-bottom: 0;
}
.account-tabs .nav-tabs > li a {
  color: #33424f;
}
.account-tabs .nav-tabs > li.active a {
  position: relative;
  background: #ff6800;
  color: white;
  font-weight: bold;
  border: 1px solid #ff6800;
  border-radius: 1px;
  margin-right: 2px;
  line-height: 1.42857143;
}
@media (min-width: 992px) {
  .account-tabs .nav-tabs > li.active a:after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 40%;
    border-top: 8px solid #ff6800;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 0;
  }
}
.account-dropdown-nav {
  padding: 0 15px 10px;
  margin: 0 -15px 24px;
  border-bottom: 1px solid #b4bfc9;
}
.account-dropdown-nav label {
  color: #7a8691;
}
.account-dropdown-nav .dropdown.open > .btn-default.dropdown-toggle,
.account-dropdown-nav .dropdown.open > .dropdown-toggle.btn-close {
  background: #f7f8fa;
}
.account-dropdown-nav .dropdown.open > .btn-default.dropdown-toggle:hover,
.account-dropdown-nav .dropdown.open > .dropdown-toggle.btn-close:hover {
  background-color: #e9edf0;
}
.account-dropdown-nav .dropdown-toggle {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background-color: #f7f8fa;
  border: 0;
  color: #7a8691;
  font-weight: 600;
  font-size: 15px;
  border-radius: 2px;
}
.account-dropdown-nav .dropdown-toggle[aria-expanded] {
  color: #7a8691;
}
.account-dropdown-nav .dropdown-toggle[aria-expanded] > .caret:before {
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}
.account-dropdown-nav .dropdown-menu {
  top: calc(100% + 4px);
  padding: 16px;
  border: 0;
  width: 100%;
  font-size: 15px;
}
.account-dropdown-nav .dropdown-menu > li {
  margin-bottom: 1em;
}
.account-dropdown-nav .dropdown-menu > li:last-child {
  margin-bottom: 0px;
}
.account-dropdown-nav .dropdown-menu > li > a {
  background: transparent;
  border-left: 4px solid transparent;
  padding: 0 16px;
  color: #7a8691;
}
.account-dropdown-nav .dropdown-menu > .active > a {
  border-color: #ff6800;
  color: #ff6800;
  font-weight: bold;
}
.account-settings {
  max-width: 620px;
}
.account-settings.row.unified {
  margin-left: 0;
}
.account-settings.row.unified .panel-formgroup .panel-heading {
  font-weight: bold;
}
.account-settings.row.unified .form-group {
  margin-bottom: 24px;
}
.account-settings.row.unified .form-group .hint-block:last-of-type {
  margin-bottom: 0;
}
.account-settings.row.unified .pickup-address .panel-formgroup {
  margin: 0 44px 24px 0;
}
.account-settings.row.unified .pickup-address .address-fields-container {
  display: flex;
}
.account-settings.row.unified
  .pickup-address
  .address-fields-container
  .address-fields {
  width: 50%;
  padding-right: 15px;
}
@media screen and (max-width: 770px) {
  .account-settings.row.unified .pickup-address .address-fields-container {
    flex-direction: column;
  }
  .account-settings.row.unified
    .pickup-address
    .address-fields-container
    .address-fields {
    width: 100%;
    padding-right: 0;
  }
  .account-settings.row.unified
    .pickup-address
    .address-fields-container
    .address-location {
    width: 100%;
  }
}
.account-settings a {
  color: #008dcc;
}
.account-settings .row--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.account-settings .row--flex [class^="col-"] {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .account-settings .row--flex .col-md-4 {
    width: 33%;
  }
}
.account-settings .plan {
  color: #ff6800;
}
.account-settings .btn {
  font-weight: normal;
}
.account-settings .btn-default,
.account-settings .btn-close {
  font-weight: 700;
}
.account-settings .panel-formgroup .panel-heading {
  font-weight: bold;
}
.account-settings .panel-formgroup .panel-body {
  padding: 0px;
}
.account-settings .help-panel {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  background: transparent;
  margin-bottom: 24px;
  padding: 16px 16px 16px 32px;
  color: #33424f;
  border: 1px solid #008dcc;
  border-radius: 2px;
}
.account-settings .help-panel:before {
  content: "";
  position: absolute;
  width: 8px;
  left: 8px;
  top: 8px;
  bottom: 8px;
  background: #008dcc;
  border-radius: 2px;
}
.account-settings .help-panel .content {
  color: inherit;
}
.account-settings .help-panel .content:last-of-type {
  margin-bottom: 0;
}
.account-settings .notice-panel-fix {
  border: 1px solid #ffbe37;
}
.account-settings .notice-panel-fix:before {
  background: #ffbe37;
}
.account-settings .info-panel {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  background: transparent;
  margin-bottom: 24px;
  padding: 16px 16px 16px 32px;
  color: #33424f;
  border: 1px solid #e9edf0;
  border-radius: 2px;
}
.account-settings .info-panel:before {
  content: "";
  position: absolute;
  width: 8px;
  left: 8px;
  top: 8px;
  bottom: 8px;
  background: #e9edf0;
  border-radius: 2px;
}
.account-settings .info-panel .content {
  color: inherit;
}
.account-settings .info-panel .content:last-of-type {
  margin-bottom: 0;
}
.account-settings .panel.panel-formgroup.user-details-form__measurement-units {
  margin-bottom: 1rem;
}
.account-settings .panel.panel-formgroup .image-display {
  display: flex;
  margin-bottom: 0;
}
.account-settings .panel.panel-formgroup .image-display img {
  flex: 0;
  align-self: flex-start;
  width: 105px;
  max-width: 100%;
}
.account-settings .panel.panel-formgroup .image-display .image-requirements {
  padding-left: 1.5em;
  align-self: center;
  line-height: 1.53;
}
.account-settings.row.unified .form-group .checkbox {
  padding: 0;
}
.account-settings.row.unified .form-group .checkbox.checkbox--highlight {
  background: #f7f8fa;
  padding-left: 14px;
}
.account-settings.row.unified
  .form-group
  .checkbox.checkbox--highlight
  > label {
  color: #7a8691;
}
.account-settings.row.unified
  .form-group
  .checkbox.checkbox--highlight
  > label
  .checkbox-input {
  left: 17px;
}
.account-settings.row.unified .form-group .checkbox > label {
  height: auto;
  padding: 16px 24px 16px 60px;
  position: relative;
  color: #33424f;
  line-height: 1.6;
}
.account-settings.row.unified .form-group .checkbox > label:hover {
  cursor: pointer;
}
.account-settings.row.unified
  .form-group
  .checkbox
  > label:hover
  .checkbox-input {
  border-color: #008dcc;
}
.account-settings.row.unified .form-group .checkbox span {
  font-size: 15px;
}
.account-settings.row.unified .form-group .checkbox .checkbox-input {
  cursor: pointer;
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  background: #fff;
  border: 1px solid #b4bfc9;
  border-radius: 2px;
}
.account-settings.row.unified .form-group .checkbox .checkbox-input:hover {
  border-color: #008dcc;
}
.account-settings.row.unified .form-group .checkbox .checkbox-input:after {
  opacity: 0;
  content: "";
  position: absolute;
  width: 70%;
  height: 35%;
  background: #008dcc;
  top: 5px;
  left: 3px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  border-radius: 1px;
  transform: rotate(-45deg);
}
.account-settings.row.unified .form-group .checkbox input[type="checkbox"] {
  opacity: 0.001;
}
.account-settings.row.unified
  .form-group
  .checkbox
  input[type="checkbox"]:checked
  + .checkbox-input {
  background: #008dcc;
  border: 1px solid #008dcc;
}
.account-settings.row.unified
  .form-group
  .checkbox
  input[type="checkbox"]:checked
  + .checkbox-input:after {
  opacity: 1;
}
.account-settings.row.unified
  .form-group
  .checkbox
  input[type="checkbox"]:focus
  + .checkbox-input,
.account-settings.row.unified
  .form-group
  .checkbox
  input[type="checkbox"]:active
  + .checkbox-input {
  border-color: #008dcc;
  box-shadow: 0 0 0 1px #008dcc;
}
.account-settings .form-horizontal .image-group {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  height: auto;
  border: 0;
}
.account-settings .form-horizontal .image-group .control-label {
  top: 0;
  left: 0;
}
.account-settings .form-horizontal .image-group .field {
  margin: 0;
  padding: 6px 8px;
  border: 1px solid #a5b1be;
  border-radius: 2px;
  font-size: 15px;
}
.account-settings .form-horizontal .image-group .field .btn {
  border-color: #a5b1be;
  padding: 2px 12px;
}
.account-settings .form-horizontal .image-group .field > span {
  vertical-align: middle;
  font-size: inherit;
}
.account-settings .form-horizontal .image-group .field .help-block:empty {
  margin: 0;
}
.account-settings .form-horizontal .image-group .btn-fileinput {
  margin-right: 10px;
}
.account-settings .form-horizontal .image-group.has-error {
  border: 1px solid #d94c4c;
}
.account-settings .user-details-form__divider {
  margin: 1.5rem 0;
  border-top: 1px solid #e9edf0;
}
.account-contact {
  float: left;
  position: relative;
  top: 0;
  left: 0;
}
@media screen and (max-width: 766px) {
  .account-contact {
    position: absolute;
    left: 140px;
    width: 100%;
  }
}
#btn-account-contact {
  height: 41px;
  border: 1px solid #e9edf0;
  border-radius: 4px;
  color: #33424f;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  padding: 9px 16px 11px 10px;
  text-align: left;
  margin: 15px 15px 0px 15px;
  display: inline-block;
  float: left;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: none;
}
#btn-account-contact .icon-account-contact:before {
  content: "";
  float: left;
  height: 22px;
  width: 22px;
  margin-right: 8px;
  background: url(./assets/chat.svg)
    no-repeat 0 1px;
  background-size: 22px;
}
#btn-account-contact:active,
#btn-account-contact:focus,
#btn-account-contact:hover {
  border: 1px solid #cbcbcb;
}
@media screen and (max-width: 945px) {
  #btn-account-contact {
    width: 41px;
  }
  #btn-account-contact .account-contact-btn-text {
    display: none;
  }
}
@media screen and (max-width: 766px) {
  #btn-account-contact {
    height: 30px;
    width: 31px;
    padding: 5px 5px 5px 7px;
    margin-top: 14px;
  }
  #btn-account-contact .account-contact-btn-text {
    display: none;
  }
  #btn-account-contact .icon-account-contact:before {
    width: 17px;
    height: 17px;
    background-size: 17px;
  }
}
.account-contact-menu {
  padding: 4px 0 16px;
  font-size: 15px;
  width: 94%;
  position: relative;
  right: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: none;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.13), 0 2px 8px 0 rgba(0, 0, 0, 0.13);
  margin-left: -190px;
  margin-top: 50px;
  min-width: 300px;
}
.account-contact-menu .profile-image {
  display: inline-block;
  padding: 20px 16px 0px 24px;
}
.account-contact-menu .profile-image img {
  display: inline-block;
  height: 64px;
  width: 64px;
}
.account-contact-menu .welcome-text {
  display: inline-block;
  vertical-align: middle;
  margin-top: 4px;
}
.account-contact-menu .title {
  font-size: 25px;
  height: 28px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
}
.account-contact-menu .title-text {
  font-size: 13px;
  margin-top: -5px;
}
.account-contact-menu .menu-div {
  box-sizing: border-box;
  height: 1px;
  background: #e9edf0;
  margin: 12px 16px;
}
.account-contact-menu .menu-text {
  padding: 8px 24px 8px 24px;
  font-size: 13px;
}
.account-contact-menu .menu-link {
  padding: 8px 20px 12px 24px;
  display: block;
  clear: both;
  color: #33424f;
  white-space: nowrap;
}
.account-contact-menu .menu-link:hover,
.account-contact-menu .menu-link:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.account-contact-menu .menu-link:hover .icon-link-call:before {
  background: url(./assets/phonecall.svg)
    no-repeat;
}
.account-contact-menu .menu-link:hover .icon-link-message:before {
  background: url(./assets/telegram.svg)
    no-repeat;
}
.account-contact-menu .menu-link:hover .icon-external:before {
  background: url(./assets/cut.svg)
    no-repeat;
}
.account-contact-menu .link-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;
}
.account-contact-menu .link-text {
  color: #7a8691;
  font-size: 11px;
  margin-bottom: 0;
}
.account-contact-menu .note {
  border: 1px solid #ffbe37;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 4px;
}
.account-contact-menu .note-banner:before {
  content: "";
  float: left;
  margin: 8px 12px 8px 8px;
  height: 61px;
  width: 10px;
  border-radius: 2px;
  background-color: #ffbe37;
}
@media screen and (max-width: 766px) {
  .account-contact-menu .note-banner:before {
    height: 26px;
  }
}
@media screen and (max-width: 656px) {
  .account-contact-menu .note-banner:before {
    height: 42px;
  }
}
@media screen and (max-width: 388px) {
  .account-contact-menu .note-banner:before {
    height: 61px;
  }
}
.account-contact-menu .note-text {
  color: #33424f;
  font-size: 13px;
  line-height: 18px;
  padding: 11px 12px 13px 30px;
}
.account-contact-menu .note-color-bar {
  height: 61px;
  width: 10px;
  border-radius: 2px;
  background-color: #ffbe37;
}
.account-contact-menu .icon-link:before {
  content: "";
  float: left;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  margin-top: 5px;
  background-size: 30px;
}
.account-contact-menu .icon-link-message:before {
  background: url(./assets/tele.svg)
    no-repeat;
}
.account-contact-menu .icon-link-call:before {
  background: url(./assets/phone.svg)
    no-repeat;
}
.account-contact-menu .icon-external:before {
  content: "";
  float: right;
  height: 24px;
  width: 24px;
  margin-top: 15px;
  background-size: 24px;
  background: url(./assets/darkcut.svg)
    no-repeat;
}
.account-contact-menu .pointer:before {
  top: 1px;
  border-bottom: 6px solid white;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.account-contact-menu .pointer {
  display: block;
  position: absolute;
  top: -12px;
  left: 12px;
  border-bottom: 12px solid #ddd;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: 8px;
  left: 150px;
}
.account-contact-menu .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.account-contact-menu .pointer:before {
  top: 1px;
  left: -12px;
  border-bottom: 12px solid white;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
@media (min-width: 768px) {
  .account-contact-menu .pointer {
    display: none;
  }
}
.account-contact-menu .outer {
  width: 100%;
}
.account-contact-menu .inner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .account-contact-menu {
    position: absolute;
    left: 0;
    margin-left: 15px;
    margin-top: 5px;
    width: 320px;
  }
}
.account-setup {
  position: relative;
  border-radius: 0.5rem;
  max-width: 1200px;
  margin: 0 auto 3rem auto;
}
@media screen and (max-width: 660px) {
  .account-setup {
    margin: 0 auto 2rem auto;
  }
}
.account-setup .account-setup-hide {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.account-setup .account-setup-hide__button {
  color: #33424f;
  padding: 0.5rem;
}
.account-setup .account-setup-hide__button:hover,
.account-setup .account-setup-hide__button:focus,
.account-setup .account-setup-hide__button:active {
  background: none;
  outline: none;
  box-shadow: none;
}
.account-setup .account-setup__container {
  display: flex;
  max-width: 1050px;
}
.account-setup .account-setup__container .account-setup-header__illustration {
  flex-shrink: 0;
  background-image: url(./assets/verified.svg);
  background-size: 120px 120px;
  background-position: 0;
  width: 120px;
  height: 120px;
  display: inline-block;
  position: relative;
  margin: 0 0.5rem 0 0;
}
.account-setup .account-setup__container .account-setup__body {
  display: inline-block;
  flex-grow: 1;
}
.account-setup .account-setup__container .account-setup__body .setup-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  counter-reset: step-counter;
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step {
  display: flex;
  counter-increment: step-counter;
  font-size: 1rem;
  padding-right: 1rem;
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step__container {
  max-width: 180px;
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step
  .setup-step-counter {
  width: 16px;
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step
  .setup-step-counter__in-progress::after {
  font-weight: 700;
  content: counter(step-counter);
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step
  .setup-step-counter__complete {
  margin-top: 0.1rem;
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step.step-complete {
  color: #b4bfc9;
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step.step-complete
  .setup-step__action {
  color: inherit;
  cursor: default;
}
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step.step-complete
  .setup-step__action:hover,
.account-setup
  .account-setup__container
  .account-setup__body
  .setup-steps
  .setup-step.step-complete
  .setup-step__action:focus {
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 900px) {
  .account-setup .account-setup__container .account-setup__body {
    max-width: 350px;
    margin: 0 auto;
  }
  .account-setup .account-setup__container .account-setup__body .setup-steps {
    flex-direction: column;
  }
  .account-setup
    .account-setup__container
    .account-setup__body
    .setup-steps
    .setup-step {
    padding-bottom: 0.5rem;
    padding-right: 0;
  }
  .account-setup
    .account-setup__container
    .account-setup__body
    .setup-steps
    .setup-step__container {
    max-width: none;
  }
}
@media screen and (max-width: 600px) {
  .account-setup .account-setup__container .account-setup__body {
    max-width: 350px;
    margin: 0 auto;
  }
  .account-setup .account-setup__container .account-setup__body .setup-steps {
    flex-direction: column;
  }
  .account-setup
    .account-setup__container
    .account-setup__body
    .setup-steps
    .setup-step {
    padding-bottom: 0.5rem;
    padding-right: 0;
  }
  .account-setup
    .account-setup__container
    .account-setup__body
    .setup-steps
    .setup-step__container {
    max-width: none;
  }
}
@media (max-width: 1020px) {
  .account-setup .account-setup__container {
    flex-direction: column;
  }
  .account-setup .account-setup__container .account-setup-header__illustration {
    margin: 0 auto;
  }
}
.drawer__content .dangerous-goods-drawer,
.drawer__content .first-send-form,
.drawer__content .payment-details-panel {
  padding: 1.5rem;
}
.drawer__content .first-send-form {
  display: block;
}
.drawer__content .first-send-form .panel-formgroup .panel-heading {
  color: #33424f;
  font-size: 1.25rem;
  font-weight: bold;
}
.drawer__content .first-send-form.panel-formgroup .panel-heading,
.drawer__content .dangerous-goods-panel.panel-formgroup .panel-heading,
.drawer__content .payment-details-panel.panel-formgroup .panel-heading {
  color: #33424f;
  font-size: 1.25rem;
  font-weight: bold;
}
.drawer__content .first-send-form .full-width,
.drawer__content .first-send-form .full-width + span,
.drawer__content .dangerous-goods-panel .full-width,
.drawer__content .dangerous-goods-panel .full-width + span,
.drawer__content .payment-details-panel .full-width,
.drawer__content .payment-details-panel .full-width + span {
  width: 100% !important;
}
.drawer__content .dangerous-goods-drawer .dangerous-goods-panel {
  margin-bottom: 1rem;
}
.drawer__content .payment-details-panel {
  padding: 1.5rem;
}
.drawer__content .payment-details-veryify-card {
  margin: 1.5rem 1.5rem 0 1.5rem !important;
}
.drawer__content .form .form-group.submit-group {
  border-top: none;
  margin: 0;
}
.drawer__content .form .form-group.submit-group .first-send-form__submit {
  float: left;
}
.drawer__content .form .dangerous-goods-points {
  list-style-type: none;
}
.drawer__content .form .dangerous-goods-points .dangerous-goods-point::before {
  content: "•";
  color: #33424f;
  font-weight: bold;
  display: inline-block;
  width: 1.5rem;
  margin-left: -1.5rem;
}
.account-setup-welcome-banner {
  margin: 3rem auto 0 auto;
  max-width: 1200px;
  margin-bottom: 120px;
}
@media screen and (max-width: 660px) {
  .account-setup-welcome-banner {
    margin-bottom: 3rem;
  }
}
.account-setup-welcome-banner__heading {
  margin-bottom: 0;
}
.account-setup-welcome-banner__description {
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
}
.account-setup-welcome-banner__intro-actions {
  display: flex;
  justify-content: left;
  margin-bottom: 1.5rem;
}
.account-setup-pro-welcome-banner {
  padding: 40px 1rem;
  font-size: 1rem;
  text-align: center;
  background: #f7f8fa;
}
.account-setup-pro-welcome-banner__heading {
  margin: 0 0 0.75rem;
}
.account-setup-pro-welcome-banner__description {
  margin-bottom: 1rem;
  line-height: 1.5;
}
.account-setup-pro-welcome-banner__intro-actions {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.account-setup-pro-welcome-banner__api-key {
  margin-bottom: 0.5rem;
}
.account-setup-pro-welcome-banner__banner {
  width: 430px;
  max-width: 100%;
}
@media (min-width: 992px) {
  .account-setup-pro-welcome-banner__banner {
    width: 600px;
    max-width: 100%;
  }
}
.alert.alert-failure {
  padding: 20px 15px;
  border: 1px solid #f4b3b4;
  background: #fff5f6;
  color: #a8282b;
}
.alert.alert-failure .alert-heading {
  color: #77191b;
  margin: 0 0 15px 0;
}
.alert.alert-failure p {
  margin: 0 0 20px 0;
}
.alert.alert-failure .alert-link {
  color: #a8282b;
  text-decoration: underline;
  font-weight: normal;
  display: block;
  margin: 5px 0 0 2px;
}
.alert.alert-failure .alert-link:hover {
  color: #7f1e20;
}
.alert.alert-failure .alert-conjunction {
  display: none;
}
.alert.alert-failure .alert-actions {
  margin-top: 20px;
}
.alert.alert-failure .error-message {
  margin-top: 10px;
  display: block;
  font-weight: bold;
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.alert.alert-failure.saving .error-message {
  visibility: hidden;
  opacity: 0;
}
.alert.alert-failure .loading {
  display: inline;
  margin: 0 0 0 4px;
}
.alert.alert-failure .btn-danger {
  margin-right: 5px;
  padding: 8px 15px;
  position: relative;
  top: -3px;
}
@media (min-width: 768px) {
  .alert.alert-failure {
    padding: 30px 25px;
  }
  .alert.alert-failure .alert-conjunction {
    display: inline;
  }
  .alert.alert-failure .alert-link {
    display: inline;
    margin: 0;
  }
}
.anchor-point {
  display: block;
  visibility: hidden;
  height: 0;
  width: 0;
  position: relative;
  top: -70px;
}
@media (min-width: 768px) {
  .anchor-point {
    top: -90px;
  }
}
.announcement {
  line-height: 22px;
  padding: 15px 18px 12px 18px;
  margin-bottom: 20px;
  border: 1px solid #fff3d5;
  border-radius: 2px;
  background-color: #fffbf0;
  color: #35424f;
  border-left: 4px solid #fecf40;
}
.announcement strong {
  background-color: #fce8ab;
  color: #54430e;
  padding: 3px 5px;
}
.announcement p {
  margin: 0 0 5px;
}
#api-settings-container .api-settings-intro {
  color: #82909b;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  margin: 0 0 15px 0;
}
@media (min-width: 768px) {
  #api-settings-container .api-settings-intro {
    margin: 0 15px 15px 15px;
  }
}
#api-settings-container .api-settings-panel {
  padding-left: 0px;
}
#api-settings-container .api-settings-panel.col-md-12 {
  padding-right: 0px;
}
#api-settings-container .api-settings-panel .btn-default,
#api-settings-container .api-settings-panel .btn-close {
  color: #33424f;
  border: 1px solid #a5b1be;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  border-radius: 2px;
}
#api-settings-container .api-settings-panel .col-md-12,
#api-settings-container .api-settings-panel .col-md-6 {
  padding-left: 0px;
}
@media (min-width: 768px) {
  #api-settings-container .api-settings-panel .col-md-6 {
    max-width: 375px;
  }
}
#api-settings-container .api-settings-panel .label {
  position: relative;
  top: 0px;
  left: 0px;
  height: 19px;
  padding: 0 0;
  margin: 0 0 4px 0;
  border: none;
  background: none;
  color: #7b8891;
  font-size: 14px;
  text-align: left;
  line-height: 19px;
}
#api-settings-container .api-settings-panel .key-value {
  font-size: 15px;
  font-weight: normal;
  line-height: 40px;
  padding: 0px 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #a5b1be;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  border-radius: 2px;
  overflow: hidden;
}
#api-settings-container .api-settings-panel .key-value .btn {
  margin-top: 1px;
}
#api-settings-container .api-settings-panel .key-value.copy-key {
  padding: 0;
}
#api-settings-container .api-settings-panel .key-value.copy-key .input-wrapper {
  display: block;
  width: auto;
  margin-left: 10px;
  margin-right: 100px;
}
#api-settings-container .api-settings-panel .key-value.copy-key input {
  border: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
}
#api-settings-container .api-settings-panel .key-value.copy-key .btn {
  margin: 0px;
  height: 40px;
  border: 0px;
  border-left: 1px solid #a5b1be;
  float: right;
  font-size: 15px;
}
#api-settings-container .api-settings-panel .key-value.copy-key .btn:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 17px;
  margin-right: 3px;
  background-size: contain;
  background: url(https://assets.sendle.com/assets/dashboard/api-clipboard-453a6cd3010283696410712eb8510ecfe53ddde491f250ec03c60a71841ffef9.svg)
    no-repeat;
  position: relative;
  top: 1px;
}
#api-settings-container .api-settings-panel .key-action {
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}
#api-settings-container .api-settings-panel .key-action .btn-default,
#api-settings-container .api-settings-panel .key-action .btn-close {
  font-weight: 700;
  border-radius: 2px;
  font-size: 15px;
  padding: 5px 12px;
  line-height: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  #api-settings-container .api-settings-panel .key-action .btn-default,
  #api-settings-container .api-settings-panel .key-action .btn-close {
    width: auto;
  }
}
#api-settings-container .modal-dialog {
  margin-top: 10%;
  font-size: 15px;
  line-height: 24px;
  padding: 10px;
}
#api-settings-container .modal-dialog .confirmation-modal-body {
  padding: 24px 32px;
}
#api-settings-container .modal-dialog .confirmation-modal-body h3 {
  color: #33424f;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}
#api-settings-container .modal-dialog .confirmation-modal-footer {
  background-color: #f7f8fa;
  border-top: none;
  text-align: center;
}
#api-settings-container .modal-dialog .confirmation-modal-footer a {
  font-size: 13px;
  padding-left: 0px;
  margin-top: 10px;
}
#api-settings-container .modal-dialog .confirmation-modal-footer button {
  width: 100%;
}
@media (min-width: 768px) {
  #api-settings-container .modal-dialog .confirmation-modal-footer {
    text-align: left;
  }
  #api-settings-container .modal-dialog .confirmation-modal-footer a {
    padding-left: 25px;
    margin-top: 0px;
  }
  #api-settings-container .modal-dialog .confirmation-modal-footer button {
    width: auto;
  }
}
#api-settings-container .modal-dialog .btn-danger {
  font-weight: bold;
}
appcues-checklist {
  z-index: 1040;
}
.auto-suggest__list-box {
  background: #ffffff;
  border: 0.0625rem solid #b4bfc9;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 8px 0px rgba(51, 66, 79, 0.2);
}
.auto-suggest__list-box-list {
  list-style: none;
  padding: 0.25rem 0.5rem;
  margin: 0;
}
.auto-suggest__list-box-item {
  padding: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.auto-suggest__list-box-item--hovered {
  background-color: #e9edf0;
}
.auto-suggest__list-box-footer {
  display: flex;
  justify-content: end;
  padding: 0.25rem;
  align-items: center;
}
.auto-suggest__list-box-loading {
  cursor: default;
  color: #6d7883;
  font-style: italic;
}
.auto-suggest__list-box-footer-image {
  background: url(https://assets.sendle.com/assets/logos/partners/google_on_white-14006e57e902f4e3e734a42c017be2463fb7d14324c0d7324fc45fa780870753.png)
    no-repeat;
  height: 18px;
  width: 59px;
}
.auto-suggest__list-box-footer-powered-by {
  margin-right: 0.125rem;
  color: #b4bfc9;
}
.auto-suggest__list-box-item--disabled {
  cursor: default;
  color: #6d7883;
  font-style: italic;
}
.top-banner {
  top: 0;
  min-height: 30px;
  padding: 5px 30px 5px 30px;
  border-width: 0 0 1px;
  border: 1px solid transparent;
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.billing-page {
  font-size: 1rem;
  margin: 0 -10px 30px -10px;
  background: #f7f8fa;
}
@media (min-width: 768px) {
  .billing-page {
    margin: 0 -40px 40px -40px;
  }
}
.billing-page .page-heading {
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .billing-page .page-heading {
    width: 50%;
    display: inline-block;
  }
}
.billing-page p {
  margin-bottom: 0px;
}
.billing-page p.sub-heading {
  margin-bottom: 0.5rem;
}
.billing-page .billing-cycle .col-md-6:nth-child(2),
.billing-page .payment-method .col-md-6:nth-child(2) {
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .billing-page .billing-cycle .col-md-6:nth-child(2),
  .billing-page .payment-method .col-md-6:nth-child(2) {
    margin-top: 0px;
  }
}
.billing-page .billing-selection {
  display: block;
}
@media (min-width: 768px) {
  .billing-page .billing-selection {
    float: right;
    display: inline-block;
    margin-top: 1.5rem;
  }
}
.billing-page .billing-selection .currency-select {
  width: 68px;
  display: inline-block;
}
.billing-page .summary-section {
  max-width: 880px;
  margin: auto;
  padding: 1rem 1rem 1.5rem 1rem;
}
.billing-page .summary-section .section {
  margin: 1rem 0px;
}
.billing-page .balance {
  color: #007f48;
  margin-left: 1rem;
}
@media (min-width: 768px) {
  .billing-page .balance {
    margin-left: 1.5rem;
  }
}
.billing-page .stripe-logo {
  float: right;
  display: block;
}
.contact-description {
  font-size: 1rem;
}
.payment-history {
  background-color: #ffffff;
  margin: auto;
  max-width: 1200px;
}
.payment-history .loading,
.payment-history .failed {
  text-align: center;
  padding: 1.5rem;
}
.payment-history .loading .glyphicon,
.payment-history .loading .panel-loading:after,
.payment-history .loading .panel-loading-plain:after,
.payment-history .loading .btn-loading:after,
.payment-history .failed .glyphicon,
.payment-history .failed .panel-loading:after,
.payment-history .failed .panel-loading-plain:after,
.payment-history .failed .btn-loading:after {
  font-size: 80px;
  margin-bottom: 1.5rem;
}
.payment-history .panel-loading {
  margin-bottom: 0;
  border: 0;
  box-shadow: none;
  background: none;
}
.payment-history th.text-right,
.payment-history td.text-right {
  text-align: right;
}
.payment-history td.status {
  text-transform: capitalize;
}
.payment-history td.invoice-number {
  word-break: break-all;
}
.payment-history td.invoice-period {
  color: #6d7883;
  word-break: break-all;
}
.payment-history .empty-message {
  color: #33424f;
  padding: 2rem 0;
  text-align: center;
}
.payment-history .empty-message svg {
  width: 12rem;
  margin-bottom: 2rem;
}
.payment-history__header {
  display: flex;
}
.payment-history__header-heading {
  margin-top: 0;
}
.payment-history__header-actions {
  display: flex;
  flex-wrap: nowrap;
  margin-left: auto;
}
.payment-history__header-actions .download-csv {
  text-align: center;
  margin: 0 0 1rem auto;
  padding: 0;
}
.payment-history__header-actions .download-csv .icon-download {
  opacity: 0.6;
}
.payment-history__header-actions .download-csv .caret {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .payment-history__header-actions .download-csv {
    text-align: right;
  }
}
@media screen and (max-width: 1220px) {
  .payment-history__header-actions .download-csv .sds-button,
  .payment-history__header-actions
    .download-csv
    .pagination
    li
    > a:not(.sds-button),
  .pagination
    .payment-history__header-actions
    .download-csv
    li
    > a:not(.sds-button) {
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
  }
}
.payment-history__header-actions .sort-panel {
  margin: 0 0 1rem 1rem;
}
@media (max-width: 375px) {
  .payment-history__header {
    flex-wrap: wrap;
  }
}
.payment-history .pagination__previous {
  margin-right: 1rem;
}
@media (min-width: 768px) {
  .payment-history .pagination {
    float: left;
    width: auto;
    padding: 0.25rem 0 0 0;
  }
}
@media screen and (max-width: 880px) {
  .payment-history__table {
    border-top: 0.5rem solid #e9edf0;
  }
  .payment-history__table.sds-table--bold {
    border-top: 0.5rem solid #33424f;
  }
  .payment-history__table.sds-table--subtle {
    border-top-width: 0.0625rem;
  }
  .payment-history__table.sds-table--headless {
    border-top-width: 0.0625rem;
  }
  .payment-history__table .sds-table__table {
    display: block;
  }
  .payment-history__table .sds-table__table thead,
  .payment-history__table .sds-table__table tbody,
  .payment-history__table .sds-table__table tfoot {
    display: block;
  }
  .payment-history__table .sds-table__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .payment-history__table .sds-table__row .sds-table__cell:first-of-type,
  .payment-history__table .sds-table__row .sds-table__header:first-of-type {
    padding-left: 1rem;
  }
  .payment-history__table .sds-table__row .sds-table__cell:last-of-type,
  .payment-history__table .sds-table__row .sds-table__header:last-of-type {
    padding-right: 1rem;
  }
  .payment-history__table .sds-table__row--header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  .payment-history__table .sds-table__cell {
    flex-basis: 100%;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .payment-history__table .sds-table__cell:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .payment-history__table.sds-table--spacious .sds-table__row {
    padding: 0.75rem 0;
  }
  .payment-history__table.sds-table--spacious .sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .payment-history__table.sds-table--compact .sds-table__row {
    padding: 0.25rem 0;
  }
  .payment-history__table.sds-table--compact .sds-table__cell {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .payment-history__table .payment-row .amount {
    text-align: left;
  }
  .payment-history__table .payment-row .status {
    text-align: right;
  }
}
.payment-row .date {
  width: 15%;
  white-space: nowrap;
}
.payment-row .items {
  width: 10%;
  text-align: right;
}
.payment-row .status {
  width: 10%;
  text-align: left;
  white-space: nowrap;
}
.payment-row .amount {
  width: 10%;
  text-align: right;
}
.payment-row .receipt {
  width: 10%;
  text-align: right;
}
.billing-details .default-indicator {
  color: #6d7883;
  font-style: italic;
}
.billing-panel {
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 880px;
  color: #33424f;
}
.billing-panel .billing-panel-heading {
  margin-top: 0px;
  margin-bottom: 1rem;
}
.billing-panel .tip {
  color: #6d7883;
}
@media (min-width: 768px) {
  .billing-panel {
    padding: 2rem;
  }
}
.btn-link {
  font-size: 1rem;
  line-height: 1.5;
  padding: 0px;
  vertical-align: baseline;
  color: #008dcc;
}
.import-errors {
  margin-top: -10px;
  padding-left: 1px;
  color: #d94c4c;
}
.bulk-order-summary {
  padding: 1.5rem 0.5rem 2rem;
  background: #f7f8fa;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .bulk-order-summary {
    padding: 2rem 3rem 3rem;
  }
}
.bulk-order-summary__container {
  display: flex;
  flex-wrap: wrap;
}
@supports (gap: 1rem) {
  .bulk-order-summary__container {
    gap: 1.5rem;
  }
}
.bulk-order-summary__item {
  display: flex;
  flex: 1;
  margin: -1.5rem;
}
@supports (gap: 1rem) {
  .bulk-order-summary__item {
    margin: 0;
  }
}
.bulk-order-summary__item > * {
  margin: 1.5rem;
}
@supports (gap: 1rem) {
  .bulk-order-summary__item > * {
    margin: 0;
  }
}
.bulk-order-summary__item:first-of-type {
  flex: 0 1 33%;
}
.bulk-order-summary__item--even-width:first-of-type {
  flex: 1;
}
@media screen and (max-width: 1400px) {
  .bulk-order-summary__item {
    flex-wrap: wrap;
  }
  .bulk-order-summary__item:first-of-type {
    flex: 100%;
  }
}
.bulk-order-summary .summary-panel {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: #ffffff;
  padding: 1.5rem;
}
.bulk-order-summary .summary-panel__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}
.bulk-order-summary .summary-panel__content {
  display: flex;
}
@supports (gap: 1rem) {
  .bulk-order-summary .summary-panel__content {
    gap: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .bulk-order-summary .summary-panel__content {
    flex-wrap: wrap;
  }
  @supports (gap: 1rem) {
    .bulk-order-summary .summary-panel__content {
      gap: 1rem;
    }
  }
}
.bulk-order-summary .summary-panel__content > * {
  margin: 3rem;
}
@supports (gap: 1rem) {
  .bulk-order-summary .summary-panel__content > * {
    margin: 0;
  }
}
.bulk-order-summary .summary-panel__content--column-layout {
  flex-direction: column;
  gap: 0;
}
.bulk-order-summary
  .summary-panel__content--column-layout
  > *:not(:last-child) {
  margin-bottom: 1rem;
}
.bulk-order-summary .summary-panel__action-button {
  padding: 0;
  background: none;
  color: #008dcc;
  text-decoration: none;
  font-weight: bold;
  border: 0;
}
.bulk-order-summary .summary-panel__action-button:focus,
.bulk-order-summary .summary-panel__action-button:hover {
  text-decoration: underline;
  color: #008dcc;
  outline-offset: 0.25rem;
}
.bulk-order-summary .summary-panel__column {
  flex: 100%;
}
.bulk-order-summary .summary-panel__column--50 {
  flex: 50%;
}
.bulk-order-summary .summary-panel__column > div:not(:first-of-type) {
  margin-top: 1rem;
}
.btn-pill {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -khtml-border-radius: 50px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  padding: 6px 14px;
  font-size: 13px;
  line-height: 13px;
  text-decoration: none;
}
.btn-pill:hover {
  text-decoration: none;
}
.btn-upgrade {
  background: #ffbe37;
  color: white;
}
.btn-upgrade:active,
.btn-upgrade:focus,
.btn-upgrade:hover {
  background: #ffad04;
  color: white;
}
.btn-secondary {
  background: #67cdcc;
  color: white;
}
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background: #41c0bf;
  color: white;
}
.btn-auxiliary {
  color: #9fa7ae;
  background-color: #fff;
  border-color: #9fa7ae;
}
.btn-auxiliary:hover {
  background: #9fa7ae;
  color: white;
}
.btn-simple {
  background: white;
  border-color: silver;
  color: black;
}
.btn-simple:active,
.btn-simple:focus,
.btn-simple:hover {
  background: #676767;
  color: black;
}
.btn-close {
  font-weight: normal;
}
.btn-icon {
  line-height: 26px;
}
.btn-icon .icon,
.btn-icon #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .btn-icon form:before,
.btn-icon #support #modal-search form:before,
#support #modal-search .btn-icon form:before,
.btn-icon #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .btn-icon form:before,
.btn-icon .navbar-form.search-form:after {
  float: left;
}
.btn-select {
  background: #ff6800;
  color: white;
}
.btn-select[disabled] {
  background: #aaa;
}
.btn-select[disabled]:hover,
.btn-select[disabled]:active,
.btn-select[disabled]:focus {
  background: #888;
}
.btn-select:hover,
.btn-select:active,
.btn-select:focus {
  background: #e65e00;
  color: white;
}
.btn-submit {
  font-size: 13px;
  line-height: 25px;
  font-weight: bold;
  color: white;
  background: url(https://assets.sendle.com/assets/icons/sendle-arrow-white-4de48f0a91c805cbbfa29ab58a5628127692b74aed883ca2afcf31c751f1a079.svg)
    no-repeat 91% center #ff6800;
  background-size: 16px 24px;
  padding: 5px 37px 5px 14px;
  outline: none !important;
}
.btn-submit:hover,
.btn-submit:active,
.btn-submit:focus {
  color: white;
  background: url(https://assets.sendle.com/assets/icons/sendle-arrow-white-4de48f0a91c805cbbfa29ab58a5628127692b74aed883ca2afcf31c751f1a079.svg)
    no-repeat 92% center #e65e00;
  background-size: 16px 24px;
}
.btn-fileinput {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.btn-delete {
  border-color: #e44144;
  color: #e44144;
  background: white;
}
.btn-delete:hover {
  background: #e44144;
  color: white;
}
.btn-disconnect {
  border-color: transparent;
  color: #33424f;
  background: white;
  font-weight: normal;
}
.btn-disconnect:hover {
  background: #e44144;
  color: white;
}
.btn-inline {
  font-size: 11px;
  padding: 3px 10px;
}
.btn-primary[type="submit"],
.btn-secondary[type="submit"] {
  min-width: 100px;
}
.btn-add-card {
  color: white;
  background: url(https://assets.sendle.com/assets/dashboard/add-card-e3d4ddd7f6e633d1ec4df74c93d1a353f4efc7753e26df1e747658a68d87fb13.png)
    no-repeat 10px 6px #ff6800;
  background-size: 27px 19px;
  padding: 8px 10px 8px 45px;
}
.btn-add-card:hover,
.btn-add-card:active,
.btn-add-card:focus {
  color: white;
  background: url(https://assets.sendle.com/assets/dashboard/add-card-e3d4ddd7f6e633d1ec4df74c93d1a353f4efc7753e26df1e747658a68d87fb13.png)
    no-repeat 10px 6px #cc5300;
  background-size: 27px 19px;
}
.btn-loading {
  position: relative;
  border: 1px solid #bec5ca !important;
  text-align: center;
}
.btn-loading:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #bec5ca;
  z-index: 2;
}
.btn-loading:after {
  content: "\e031";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -7px;
  margin-left: -7px;
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 14px;
  height: 14px;
  width: 14px;
  z-index: 3;
}
.btn-block-header {
  border-color: #fff;
  color: #fff;
  background: transparent;
}
.btn-block-header:hover,
.btn-block-header:focus {
  background: #fff;
  color: #67cdcc;
}
.sds-button[data-toggle="dropdown"]:focus,
.pagination li > a[data-toggle="dropdown"]:focus:not(.sds-button),
.sds-button[data-toggle="dropdown"]:active,
.pagination li > a[data-toggle="dropdown"]:active:not(.sds-button) {
  box-shadow: 0 0 0 0.2rem rgba(0, 157, 227, 0.5);
}
.card-details .card {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 32px;
  height: 20px;
  margin-bottom: -5px;
}
.card-details .card:before {
  content: "";
  background: url(https://assets.sendle.com/assets/dashboard/credit-cards-c24eb8e4c14ac4c62ff94d59bb5a0a6d3c7f4994857e5a03ecc03f14f4ee247b.png)
    no-repeat;
  background-size: 32px 150px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card-details .card.card-visa:before {
  background-position: 0 -39px;
}
.card-details .card.card-american-express:before {
  background-position: 0 -80px;
}
.card-details .card.card-mastercard:before {
  background-position: 0 -120px;
}
.card-details .embedded {
  line-height: 2;
}
.card-actions .loading {
  display: inline-block;
  margin: 0 0 0 10px;
}
.card-actions .btn-link.edit {
  padding: 0;
}
.combo-box__list-box {
  background: #ffffff;
  border: 0.0625rem solid #b4bfc9;
}
.combo-box__list-box-list {
  list-style: none;
  padding: 0.25rem 0.5rem;
  margin: 0;
}
.combo-box__list-box-item {
  padding: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
}
.combo-box__list-box-item--hovered {
  background-color: #e9edf0;
}
.combo-box__list-box-item--disabled {
  cursor: default;
  color: #6d7883;
  font-style: italic;
}
.confirmation-modal-position {
  top: 50%;
  margin-top: -135px;
}
@media (min-width: 768px) {
  .confirmation-modal-position {
    left: 50%;
    margin-left: -50%;
    margin-top: -175px;
  }
}
.confirmation-modal-content {
  width: 100%;
}
@media (min-width: 768px) {
  .confirmation-modal-content {
    margin: 0 auto;
  }
}
.confirmation-modal-body {
  display: table;
  padding: 25px 20px;
  width: 100%;
  text-align: left;
}
.confirmation-modal-body strong {
  font-weight: bold;
}
.confirmation-modal-body h3 {
  color: #e44144;
  margin-top: 0px;
  font-size: 18px;
  font-weight: normal;
}
.confirmation-modal-body p {
  color: #33424f;
  font-size: 14px;
  line-height: 1.4em;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .confirmation-modal-body {
    padding: 50px;
  }
}
@media (min-width: 350px) {
  .confirmation-modal-body {
    padding: 35px;
  }
}
.confirmation-modal-footer {
  background-color: #f4f4f4;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 25px 20px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
@media (min-width: 768px) {
  .confirmation-modal-footer {
    padding: 25px 50px 40px 50px;
  }
}
@media (min-width: 350px) {
  .confirmation-modal-footer {
    padding: 25px 35px;
  }
}
.confirmation-modal-footer a {
  color: #33424f;
  text-decoration: underline;
  display: inline-block;
  padding-top: 7px;
  padding-left: 17px;
}
@media (min-width: 350px) {
  .confirmation-modal-footer a {
    padding-left: 25px;
  }
}
.confirmation-modal-footer .btn-danger {
  padding-top: 8px;
  padding-bottom: 8px;
  float: left;
}
.dashboard-csv-popover {
  padding: 16px 20px 18px 20px;
}
.dashboard-csv-popover .popover-date {
  font-size: 12px;
  font-style: italic;
  color: #9fa7ae;
  text-align: left;
  margin-bottom: 21px;
}
.dashboard-csv-popover p {
  font-size: 14px;
}
.dashboard-csv-popover .popover-heading {
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  margin: 5px 0 16px 0;
}
.dashboard-csv-popover .popover-heading strong {
  display: block;
}
.dashboard-csv-popover .special {
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
}
.dead_integrations {
  text-align: center;
}
.definitions-inline {
  margin-bottom: 0;
}
.definitions-inline dt {
  float: left;
  margin: 0 5px 4px 0;
  line-height: 20px;
}
.definitions-inline dd {
  margin-bottom: 4px;
  line-height: 20px;
}
.definitions-inline dd:after {
  content: "";
  display: block;
  clear: left;
}
.definitions-inline .btn-link {
  padding: 0 0 0 10px;
  margin-top: -2px;
}
.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
  animation: DrawerShow 0.2s ease forwards;
  z-index: 1030;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.drawer__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #e9edf0;
  margin: 0;
  padding: 1rem;
}
.drawer__title {
  display: inline-flex;
  color: #33424f;
  font-weight: bold;
  margin: 0 1rem 0 0;
  text-decoration: none;
}
.drawer__content {
  padding: 0;
  overflow: auto;
}
.drawer__footer {
  margin-top: auto;
  padding: 1rem 1.5rem;
  background-color: #f7f8fa;
  border-top: 0.0625rem solid #e9edf0;
}
.drawer__close-button {
  border: 0;
  background: transparent;
  color: #6d7883;
}
.drawer__close-button:hover,
.drawer__close-button:focus {
  color: #33424f;
}
@media screen and (max-width: 991px) {
  .drawer {
    width: 100%;
    animation: DrawerShowMobile 0.2s ease forwards;
  }
}
@keyframes DrawerShow {
  from {
    transform: translateX(0.25rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes DrawerShowMobile {
  from {
    transform: translateY(0.25rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.ecommerce-connect-screen a.icon-link-connect {
  margin: 15px 0;
}
.ecommerce-connect-screen .title {
  line-height: 1.4em;
  margin: 0 10px 20px 10px;
}
.ecommerce-connect-screen .connect-ebay-button {
  margin-left: 28px;
}
.ecommerce-connect-screen .connect-ebay-div {
  text-align: center;
}
.ecommerce-connect-screen .compatible-logo {
  display: inline;
  width: 110px;
  margin-left: 39px;
}
.ecommerce-connect-screen .terms {
  padding-left: 0;
  text-align: center;
  margin-top: 20px;
}
.ecommerce-connect-screen .terms p {
  font-size: 11px;
  color: #7a8691;
}
.ecommerce-connect-screen .terms a {
  text-decoration: underline;
  color: #7a8691;
}
.ecommerce-connect-screen .promotion-desc {
  margin: 0 auto;
  max-width: 470px;
}
.ecommerce-connect-screen .hero-img {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 470px;
}
.ecommerce-connect-screen .hero-img-background {
  width: 100%;
}
.ecommerce-connect-screen .hero-img-gif {
  position: absolute;
  top: 8.5%;
  left: 8%;
  width: 84.5%;
}
.ecommerce-connect-screen .promotion-intro-text {
  text-align: center;
}
.ecommerce-connect-screen .compatible-application-img {
  margin: auto;
}
.ecommerce-connect-screen .connect-form {
  text-align: center;
}
.ecommerce-connect-screen .inputs {
  padding: 0 15px 0 0;
  display: flex;
}
.ecommerce-connect-screen .url {
  padding-left: 4px;
  padding-top: 10px;
}
.ecommerce-connect-screen .use-the-same {
  font-size: smaller;
}
.ecommerce-connect-screen .connect-button {
  margin-left: 0px;
  margin-top: 13px;
}
.ecommerce-connect-screen .connect-button:before {
  background-position: -240px -1px;
}
.ecommerce-connect-screen .connect-button.active:before,
.ecommerce-connect-screen .connect-button.btn-primary:before {
  background-position: -240px -40px;
}
.ecommerce-connect-screen .shop {
  text-align: left;
  margin: 25px auto 15px;
  max-width: 410px;
}
.ecommerce-connect-screen .input {
  width: 100%;
}
.ecommerce-connect-screen .logo {
  margin: auto;
}
@media (min-width: 768px) {
  .ecommerce-connect-screen .shop-input {
    width: 292px;
  }
  .ecommerce-connect-screen .connect-button {
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .ecommerce-connect-screen .promotion-intro-text {
    padding: 15px 10px 0 0;
  }
}
@media (min-width: 992px) {
  .ecommerce-connect-screen .promotion-intro-text {
    text-align: left;
  }
  .ecommerce-connect-screen .title {
    margin: 0 0 20px 0;
  }
  .ecommerce-connect-screen .terms {
    text-align: left;
  }
  .ecommerce-connect-screen .promotion-intro-image {
    padding: 0 15px;
  }
  .ecommerce-connect-screen .connect-ebay-div {
    text-align: left;
  }
  .ecommerce-connect-screen .promotion-desc {
    margin: 0;
  }
  .ecommerce-connect-screen .shop {
    margin: 25px 0 15px;
  }
  .ecommerce-connect-screen .connect-form {
    text-align: left;
  }
}
.etsy-trademark {
  font-size: 13px;
  color: #33424f;
}
.ecommerce-order-list * {
  box-sizing: border-box;
}
.ecommerce-order-list .other-carrier,
.ecommerce-order-list .international-order {
  display: block;
  text-align: center;
  min-height: 32px;
  line-height: 32px;
}
.ecommerce-order-list .reset-link {
  margin-left: 16px;
}
.ecommerce-order-list .filter-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.ecommerce-order-list .orders-count {
  display: inline-block;
  margin-top: 7px;
}
.ecommerce-order-list .filters {
  display: flex;
  align-items: center;
  font-size: 1rem;
  order: 2;
  padding: 0.5rem 0;
}
.ecommerce-order-list .filters span {
  margin-right: 1rem;
}
.ecommerce-order-list .refresh-button {
  padding: 0.5rem 0;
  width: 100%;
  text-align: right;
}
@media (max-width: 767px) {
  .ecommerce-order-list .refresh-button .sds-button,
  .ecommerce-order-list .refresh-button .pagination li > a:not(.sds-button),
  .pagination .ecommerce-order-list .refresh-button li > a:not(.sds-button) {
    width: 100%;
  }
}
.ecommerce-order-list .bulk-actions-panel {
  margin: 0 0 0.5rem 0;
}
.ecommerce-order-list .bulk-actions-panel .batch-button-container {
  margin: 0 0 0.5rem 0;
}
.ecommerce-order-list
  .bulk-actions-panel
  .batch-button-container
  .batch-button
  .batch-send-parcels {
  margin: 0 1rem;
}
@media (max-width: 767px) {
  .ecommerce-order-list
    .bulk-actions-panel
    .batch-button-container
    .batch-button {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .ecommerce-order-list
    .bulk-actions-panel
    .batch-button-container
    .batch-button
    .batch-send-parcels {
    margin: 0 0 0 auto;
  }
}
.ecommerce-order-list
  .bulk-actions-panel
  .batch-button-container
  .batch-order-limit {
  white-space: nowrap;
}
.ecommerce-order-list .block-header {
  padding: 0;
}
.ecommerce-order-list .block-header .col-xs-8 .title {
  line-height: 25px;
  margin-left: 16px;
}
.ecommerce-order-list .block-header .col-xs-8.total {
  padding: 0 30px;
  text-align: left;
}
.ecommerce-order-list .block-header .col-xs-8.refresh {
  margin-top: 7px;
  padding: 0 30px;
}
@media (min-width: 768px) {
  .ecommerce-order-list .block-header .col-xs-8.refresh {
    margin-top: 0;
  }
}
.ecommerce-order-list .block-header .col-sm-2.logo {
  height: 115px;
  display: flex;
}
.ecommerce-order-list .block-header .col-sm-2.logo.ebay-img-responsive {
  background-color: #f4f4f4;
  background: url(https://assets.sendle.com/assets/partners/ebay/ebay-rightnow-logo-7ade46224782d039cb7c5fbd17f372b8e0e66b86615ff6626197bd15e8f519b5.png)
    #f4f4f4 50% no-repeat;
}
.ecommerce-order-list .block-header .col-sm-2.logo.shopify-img-responsive {
  background-color: #f4f4f4;
  background: url(https://assets.sendle.com/assets/partners/shopify/shopify_logo_110-a8bec93ef47452a7b5e33b988d1cebd31f0b791ce888bf2f2864b75a00a0570c.png)
    #f4f4f4 50% no-repeat;
}
.ecommerce-order-list .block-header .col-sm-2.logo.woocommerce-img-responsive {
  background-color: #f4f4f4;
  background: url(https://assets.sendle.com/assets/partners/woocommerce/woocommerce_logo-f87c890386c58a409338991c9ba4d58e65c6f19f97cae09ff9b48add9b83a569.png)
    #f4f4f4 50% no-repeat;
  background-size: 60%;
}
.ecommerce-order-list .block-header .col-sm-2.logo.etsy-img-responsive {
  background-color: #f4f4f4;
  background: url(https://assets.sendle.com/assets/partners/etsy/etsy_logo-ecad479c7007eb5b3fc901f9608dc64a868d906f52fd918c20fec59bfd335246.png)
    #f4f4f4 50% no-repeat;
  background-size: 60%;
}
.ecommerce-order-list .block-header .col-sm-2.logo.squarespace-img-responsive {
  background-color: #f4f4f4;
  background: url(https://assets.sendle.com/assets/partners/squarespace/squarespace_logo-a010f475163adad5ef67f6918c4322fa94384c2ca23e70da20892adbc1a8a4b2.png)
    #f4f4f4 50% no-repeat;
  background-size: 60%;
}
@media (min-width: 768px) {
  .ecommerce-order-list .block-header .col-sm-2.logo {
    height: 90px;
    display: flex;
  }
  .ecommerce-order-list .block-header .col-sm-2.total {
    padding: 36px 5px;
    text-align: right;
  }
  .ecommerce-order-list .block-header .col-sm-2.refresh {
    padding: 36px 5px;
  }
}
.ecommerce-order-list .center-text {
  text-align: center;
}
.ecommerce-order-list .row {
  margin-right: 0;
}
.ecommerce-order-list .ecommerce-order-header {
  height: 115px;
  min-width: 360px;
}
@media (min-width: 768px) {
  .ecommerce-order-list .ecommerce-order-header {
    height: 90px;
  }
}
.ecommerce-order-list .ecommerce-order-header .heading {
  margin: 36px;
}
@media (min-width: 768px) {
  .ecommerce-order-list .ecommerce-order-header .heading {
    margin: 23px 0;
    padding-left: 40px;
  }
}
.ecommerce-order-list .ecommerce-order-row .btn-new-parcel-ref .sendle-arrow {
  display: inline-block;
  background: url(https://assets.sendle.com/assets/icons/sendle-arrow-orange-small-ecd5ae87b937dabea6c8835effc46f750132bda0c002c8cc7d62c47efd405066.png)
    no-repeat left;
  width: 20px;
  height: 18px;
  vertical-align: middle;
}
.ecommerce-order-list .ecommerce-order-row .btn-new-parcel-ref span.sendle-ref {
  display: inline-block;
  vertical-align: middle;
}
.ecommerce-order-list .ecommerce-order-row .sds-button,
.ecommerce-order-list .ecommerce-order-row .pagination li > a:not(.sds-button),
.pagination .ecommerce-order-list .ecommerce-order-row li > a:not(.sds-button) {
  white-space: nowrap;
}
.ecommerce-order-list .ecommerce-order-row .ecommerce-order-row-description {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  white-space: nowrap;
}
.ecommerce-order-list .ecommerce-order-row .no-wrap {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .ecommerce-order-list .ecommerce-order-row .btn-new-parcel {
    width: 100%;
  }
}
.ecommerce-order-list .ecommerce-order-table .sds-table__table {
  table-layout: fixed;
}
@media screen and (max-width: 1130px) {
  .ecommerce-order-list .ecommerce-order-table {
    border-top: 0.5rem solid #e9edf0;
  }
  .ecommerce-order-list .ecommerce-order-table.sds-table--bold {
    border-top: 0.5rem solid #33424f;
  }
  .ecommerce-order-list .ecommerce-order-table.sds-table--subtle {
    border-top-width: 0.0625rem;
  }
  .ecommerce-order-list .ecommerce-order-table.sds-table--headless {
    border-top-width: 0.0625rem;
  }
  .ecommerce-order-list .ecommerce-order-table .sds-table__table {
    display: block;
  }
  .ecommerce-order-list .ecommerce-order-table .sds-table__table thead,
  .ecommerce-order-list .ecommerce-order-table .sds-table__table tbody,
  .ecommerce-order-list .ecommerce-order-table .sds-table__table tfoot {
    display: block;
  }
  .ecommerce-order-list .ecommerce-order-table .sds-table__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .ecommerce-order-list
    .ecommerce-order-table
    .sds-table__row
    .sds-table__cell:first-of-type,
  .ecommerce-order-list
    .ecommerce-order-table
    .sds-table__row
    .sds-table__header:first-of-type {
    padding-left: 1rem;
  }
  .ecommerce-order-list
    .ecommerce-order-table
    .sds-table__row
    .sds-table__cell:last-of-type,
  .ecommerce-order-list
    .ecommerce-order-table
    .sds-table__row
    .sds-table__header:last-of-type {
    padding-right: 1rem;
  }
  .ecommerce-order-list .ecommerce-order-table .sds-table__row--header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  .ecommerce-order-list .ecommerce-order-table .sds-table__cell {
    flex-basis: 100%;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .ecommerce-order-list .ecommerce-order-table .sds-table__cell:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .ecommerce-order-list
    .ecommerce-order-table.sds-table--spacious
    .sds-table__row {
    padding: 0.75rem 0;
  }
  .ecommerce-order-list
    .ecommerce-order-table.sds-table--spacious
    .sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .ecommerce-order-list
    .ecommerce-order-table.sds-table--compact
    .sds-table__row {
    padding: 0.25rem 0;
  }
  .ecommerce-order-list
    .ecommerce-order-table.sds-table--compact
    .sds-table__cell {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
.ecommerce-order-list .ecommerce-order-row-mobile-check-all {
  display: none;
}
@media (max-width: 767px) {
  .ecommerce-order-list .ecommerce-order-row-mobile-check-all {
    display: inline-block;
    height: 1rem;
  }
}
.ecommerce-order-list
  .ecommerce-order-row--check-all
  .check-all.some-checked
  .sds-checkbox_faux::after,
.ecommerce-order-list
  .ecommerce-order-row-mobile-check-all
  .check-all.some-checked
  .sds-checkbox_faux::after {
  background: url(https://assets.sendle.com/assets/icons/select-clear-d629578d5a98e036e71fe3f74cf567f9b2e89299ea6b21153996467b4fd3e1fe.svg)
    no-repeat 0 3px !important;
}
.ecommerce-order-list .ecommerce-order-row--check-all,
.ecommerce-order-list .ecommerce-order-row--checkbox {
  width: 1rem;
}
.ecommerce-order-list .ecommerce-order-row--check-all .sds-checkbox,
.ecommerce-order-list .ecommerce-order-row--checkbox .sds-checkbox {
  height: 1rem;
}
.ecommerce-order-list .ecommerce-empty-table {
  text-align: center;
}
.ecommerce-order-list .ecommerce-empty-table td {
  background-color: #fff;
}
.ecommerce-order-list .ecommerce-empty-table h3 {
  font-weight: lighter;
  color: #6d767e;
}
.ecommerce-order-list .ecommerce-empty-table p {
  font-size: 15px;
  color: #6d767e;
}
.ecommerce-order-list .ecommerce-empty-table .error-illustration {
  display: block;
  background: url(https://assets.sendle.com/assets/partners/ecommerce/error-illustration-no-results-17c9617d47c1665c7df27ce51683d11367e4a0b7cd3a7cdb4d2918dc158717be.svg)
    no-repeat;
  width: 182px;
  height: 105px;
  margin: 35px auto 20px auto;
}
.ecommerce-order-list .ecommerce-order-footer {
  display: flex;
  flex-wrap: wrap;
}
.ecommerce-order-list .ecommerce-disconnect {
  margin: 0px 15px 20px auto;
  line-height: 20px;
  color: #e44144;
}
.ecommerce-order-list .ecommerce-disconnect:hover {
  color: #fff;
}
.ecommerce-order-list .pagination {
  flex: 1 0 auto;
  text-align: left;
  margin-top: 0;
  width: auto;
}
.ecommerce-order-list .pagination a {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ecommerce-order-list .pagination .dots:hover,
.ecommerce-order-list .pagination .dots:active {
  background: inherit;
  cursor: initial;
}
.ecommerce-order-list .pagination .number-disabled {
  background: #33424f;
  color: #ffffff;
  border-color: #33424f;
}
.ecommerce-order-list .modal-content {
  max-width: 475px;
}
@media (min-width: 850px) {
  .ecommerce-order-list .ecommerce-orders-loader {
    left: 50%;
  }
  .ecommerce-order-list .filters {
    order: 1;
    padding: 1.5rem 0;
  }
  .ecommerce-order-list .refresh-button {
    width: auto;
    padding: 1.5rem 0;
    order: 2;
  }
}
.ecommerce-order-list--actions > :first-child {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .ecommerce-order-list--actions > :first-child {
    margin-bottom: 0;
  }
}
.ecommerce-order-list--actions > * {
  width: 100%;
}
@media (min-width: 768px) {
  .ecommerce-order-list--actions > * {
    width: auto;
  }
}
.confirm-disconnect-modal .description {
  margin-top: 10px;
}
.ecommerce-orders-spinner {
  color: #b7bdc2;
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.ecommerce-orders-spinner .description {
  margin-left: 10px;
}
@media (min-width: 768px) {
  .ecommerce-orders-spinner {
    margin-top: 0.5rem;
  }
  .ecommerce-orders-spinner .glyphicon-refresh {
    margin-top: 120px;
  }
}
.ecommerce-orders-loader {
  position: absolute;
  padding: 3px 10px;
  top: 120px;
  left: 50vw;
  transform: translate(-50%, -50%);
  background-color: #e5f5f9;
}
.ecommerce-orders-loader .spinning,
.ecommerce-orders-loader .panel-loading:after,
.ecommerce-orders-loader .panel-loading-plain:after,
.ecommerce-orders-loader .btn-loading:after {
  margin-right: 10px;
}
.ecommerce-orders-loader.show-loader {
  display: inline-block;
}
.ecommerce-orders-loader.hide-loader {
  display: none;
}
.editing .editable-fields {
  display: inherit;
}
.editing .non-editable-fields {
  display: none;
}
.editable-fields {
  display: none;
}
.non-editable-fields {
  display: inherit;
}
.listings-disabled {
  text-align: center;
}
.listings-disabled h3 {
  font-weight: lighter;
  color: #6d767e;
}
.listings-disabled p {
  font-size: 15px;
  color: #6d767e;
}
.listings-disabled .error-illustration {
  display: block;
  background: url(https://assets.sendle.com/assets/dashboard/error-illustration-17c9617d47c1665c7df27ce51683d11367e4a0b7cd3a7cdb4d2918dc158717be.svg)
    no-repeat;
  width: 182px;
  height: 105px;
  margin: 35px auto 20px auto;
}
.export-csv {
  margin: 20px 10px 60px 0;
  text-align: center;
}
.export-csv .export-options {
  width: auto;
  display: inline-block;
  margin-right: 0.5rem;
}
.export-csv .export-options select {
  padding-right: 2rem;
}
@media (min-width: 768px) {
  .export-csv {
    text-align: right;
  }
}
.sds-form-element__hint {
  margin-bottom: 0 !important;
}
.panel-formgroup {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0 0 6px 0;
}
.panel-formgroup .panel-heading {
  color: #ff6800;
  font-size: 16px;
  padding: 0;
  margin-bottom: 15px;
  font-weight: normal;
}
.panel-formgroup .panel-body {
  padding: 0;
}
.pac-container {
  z-index: 1030 !important;
}
.form .heading-label {
  color: #ff6800;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 7px;
}
.form .form-group.submit-group {
  border-top: 2px solid #eee;
  text-align: right;
  padding: 15px 0 0 0;
}
.form .form-group.read-only-group input {
  border: 0;
}
.form .form-group .read-only {
  background-color: #fff;
  border-right: 0;
  height: 24px;
  line-height: 24px;
}
.form .form-group .horizontal-right-space {
  padding-right: 20px;
}
.form .form-group .checkbox-label {
  font-size: 13px;
  font-weight: normal;
  color: black;
  padding-top: 10px;
}
.form .form-group .field-addon {
  position: absolute;
  padding: 31px 10px;
  display: table-cell;
  width: auto;
  color: #000;
  background-color: transparent;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
.form .form-group .field-addon:not(.right) ~ .field .form-control {
  padding-left: 50px;
}
.form .form-group .field-addon.right {
  right: 25px;
}
@media (min-width: 768px) {
  .form .form-group .field-addon:not(.right) {
    left: 126px;
    padding: 11px 5px;
  }
  .form .form-group .field-addon:not(.right) ~ .field .form-control {
    padding-left: 170px;
  }
  .form .form-group .field-addon.right {
    padding: 11px 5px;
  }
}
.form .form-introduction,
.form .form-radio-group {
  margin: 0 0 20px 0;
}
.form .billing-address-title {
  margin-top: 0;
}
.form .field-group {
  margin-bottom: 20px;
}
.form .field-group .form-group {
  margin-bottom: 5px;
}
.form .form-control:disabled,
.form
  .select2-container.select2-container--sds.select2-container--disabled
  .form-control.select2-selection__rendered,
.select2-container.select2-container--sds.select2-container--disabled
  .form
  .form-control.select2-selection__rendered {
  background-color: #f9f9fb;
}
.form .form-row.form-row-centered {
  margin: 0 auto;
  text-align: center;
}
.form .form-control-static {
  padding: 0;
}
.form .radio,
.form .checkbox {
  margin-top: 0;
}
.form .checkbox.declaration {
  margin-bottom: 15px;
}
.form .checkbox.declaration label {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  border-radius: 2px;
  background: #f9f9f9;
  border: 1px solid #eee;
  padding: 20px 20px 22px 0;
  color: #66839c;
  position: relative;
}
.form .checkbox.declaration label span {
  display: block;
  margin-left: 48px;
}
.form .checkbox.declaration input[type="checkbox"] {
  position: absolute;
  top: 43%;
  left: 16px;
  margin: 0;
}
.form .checkbox.declaration a {
  color: #66839c;
  text-decoration: underline;
}
.form .checkbox.declaration a:hover {
  color: #51697d;
}
.form .checkbox.declaration .checkbox:nth-child(2) {
  padding-top: 0px;
  margin-top: -16px;
  border-top: none;
}
.form .checkbox.declaration .checkbox:nth-child(2) label {
  border-top: none;
}
.form .checkbox.declaration .help-block {
  background-color: #fedcd9;
  position: absolute;
  width: 100%;
  left: 0;
  margin: 10px 0 10px 0;
  padding: 2px 5px;
}
.form .has-error .checkbox.declaration label {
  border-color: #e44144;
}
@media (min-width: 768px) {
  .form .field-group .form-group {
    margin-bottom: 3px;
  }
  .form .form-group.submit-group {
    margin: 40px 0 0 0;
  }
  .form .read-only-group .form-control-static {
    padding: 3px 0 0 130px;
    text-align: left;
  }
  .form .checkbox.declaration label span {
    margin-left: 72px;
  }
  .form .checkbox.declaration label span .checkbox-input {
    left: -30px;
  }
  .form .checkbox.declaration input[type="checkbox"] {
    left: 30px;
  }
}
.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}
.form-horizontal .checkbox-group .field {
  float: left;
  padding-top: 0;
}
.form-horizontal .checkbox-group .checkbox {
  padding-top: 0;
}
.form-horizontal .checkbox-group .control-label {
  float: left;
  line-height: 20px;
  margin-right: 15px;
}
@media (min-width: 768px) {
  .form-horizontal .pickup-date .sds-form-element {
    width: 50%;
  }
}
.form-horizontal .pickup-date .help-panel {
  margin-top: -10px;
  width: auto;
}
.form-horizontal .help-tip,
.form-horizontal .hint-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #66839c;
}
.form-horizontal .image-group {
  overflow: hidden;
  white-space: nowrap;
}
.form-horizontal .image-group .btn-fileinput {
  margin-right: 5px;
}
.form-horizontal .image-group .filename {
  vertical-align: middle;
}
.form-horizontal .csv-select-btn {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-horizontal .csv-select-btn p {
  font-size: 1rem;
}
.form-horizontal .hide-csv-select-btn {
  display: none;
}
.form-horizontal .hide-csv-file-input {
  opacity: 0;
}
.form-horizontal .hide-csv-file-input .form-group {
  margin-bottom: 0;
}
.form-horizontal .non-editable-fields .form-group.instructions {
  padding-top: 7px;
}
@media (min-width: 768px) {
  .form-horizontal .form-group {
    position: relative;
  }
  .form-horizontal .control-label {
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 38px;
    line-height: 38px;
    width: 120px;
    background: #f6f9fc;
    color: #657582;
    border-right: 1px solid #e9eef3;
    padding: 0 8px;
    text-align: left;
    z-index: 4;
  }
  .form-horizontal .form-control {
    padding-left: 130px;
    text-align: left;
  }
  .form-horizontal .no-label {
    padding-left: 8px;
  }
  .form-horizontal .checkbox-group .field {
    margin-left: 130px;
    float: none;
  }
  .form-horizontal .checkbox-group .control-label {
    position: static;
    background: transparent;
    float: left;
    height: auto;
    margin-right: 0;
    padding-top: 0;
    border-right: 0;
  }
  .form-horizontal .image-group {
    border: 1px solid #82909b;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    height: 38px;
  }
  .form-horizontal .image-group .control-label {
    top: 0;
    left: 0;
  }
  .form-horizontal .image-group .field {
    margin: 2px 0 0 130px;
  }
  .form-horizontal .image-group .btn-fileinput {
    margin-right: 10px;
  }
  .form-horizontal .image-group.has-error {
    border: 1px solid #d94c4c;
  }
  .form-horizontal .button-group,
  .form-horizontal .info-group {
    padding-left: 130px;
  }
  .form-horizontal select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(https://assets.sendle.com/assets/dashboard/select-arrows-863fcab538cdf1d5ca0f9bce8ac3bfd0d04baa455afadadce59ff4e0b8ee7798.png)
      no-repeat right center;
    background-size: 16px 15px;
  }
  .form-horizontal .select2-container {
    width: 100% !important;
  }
  .form-horizontal .select2-container input {
    outline: none;
  }
  .form-horizontal .select2-container .select2-results__option {
    font-size: 15px;
  }
  .form-horizontal .non-editable-fields .control-label {
    position: static;
    line-height: 22px;
    width: auto;
    height: auto;
    padding: 0;
    background: transparent;
  }
  .form-horizontal .help-text,
  .form-horizontal .help-block,
  .form-horizontal .help-tip,
  .form-horizontal .hint-block {
    font-size: 12px;
    text-align: left;
    margin: 5px 0 10px 2px;
    display: block;
  }
  .form-horizontal .error-msg {
    font-size: 12px;
    line-height: 15px;
    display: block;
    color: #d94c4c;
    padding: 0 3px;
    margin: -10px 0 10px 2px;
  }
  .form-horizontal .has-error .control-label {
    background: #d94c4c;
    color: white;
    font-size: 11px;
  }
}
@media screen and (max-width: 991px) {
  .form-horizontal .select2-container {
    max-width: none;
    width: 100% !important;
  }
}
.select2-container input:focus {
  outline: none;
}
.icon-pending:before {
  content: "";
  background: url(https://assets.sendle.com/assets/dashboard/pending-cf3caf0326d397e2f3f2c46ec4363d30589889dcc77cf4ec6233eb7a062e4adc.svg)
    no-repeat;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  float: left;
  margin: 0 0.5rem 0 0;
}
.icon-success:before {
  content: "";
  background: url(https://assets.sendle.com/assets/dashboard/success-bbd6ee67edf12abcaa56492a3c92ac67ecb5602007cf4a83d1175a0696cf80ef.svg)
    no-repeat;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  float: left;
  margin: 0 0.5rem 0 0;
}
.icon-error:before {
  content: "";
  background: url(https://assets.sendle.com/assets/dashboard/error-ecdf983d01d6bb448322a9907f87b217cc0867409aabab60f479bf5641a89a4c.svg)
    no-repeat;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  float: left;
  margin: 0 0.5rem 0 0;
}
.btn-fileinput input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}
.field .twitter-typeahead {
  width: 100%;
}
.tt-suggestion.validation-lookup:hover {
  background-color: #0095b5 !important;
  color: #ffffff;
}
.edit-toggle {
  padding: 0 5px 10px 5px;
}
.submit-group.submit-panel {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  background: #f9f8f7;
  border: 1px solid #ddd;
  padding: 20px;
  text-align: right;
  margin-top: 30px;
  color: #9fa7ae;
}
.submit-group.submit-panel .promotion-message {
  text-align: left;
}
.submit-group.submit-panel .promotion-message strong {
  color: #33424f;
}
.submit-group.submit-panel .promotion-message p {
  font-size: 11px;
}
.submit-group.submit-panel .edit-order {
  float: left;
}
.submit-group.submit-panel .payment-confirmation-details.empty-card {
  margin-bottom: -15px;
}
.submit-group.submit-panel .invoice-confirmation-details {
  margin: 0 0 -12px 0;
}
.submit-group.submit-panel .payment-total {
  color: #33424f;
  line-height: 18px;
}
.submit-group.submit-panel .payment-amount {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 5px 2px;
}
.submit-group.submit-panel .btn-disabled {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  background: #bbc1c7;
  text-align: center;
  width: 147px;
  height: 37px;
  margin-top: 20px;
}
.submit-group.submit-panel .btn-disabled .glyphicon,
.submit-group.submit-panel .btn-disabled .panel-loading:after,
.submit-group.submit-panel .btn-disabled .panel-loading-plain:after,
.submit-group.submit-panel .btn-disabled .btn-loading:after {
  color: white;
}
.submit-group.submit-panel .btn-submit,
.submit-group.submit-panel .btn-add-card,
.submit-group.submit-panel .btn-loading {
  margin-top: 20px;
  width: 147px;
}
.submit-group.submit-panel .payment-message a {
  color: #9fa7ae;
  text-decoration: underline;
  margin-top: 3px;
  display: block;
}
.submit-group.submit-panel .payment-message a:hover {
  color: #76818b;
}
.submit-group.submit-panel .payment-error {
  color: #e44144;
  padding: 10px 0 0 0;
}
.submit-group.submit-panel .payment-error p {
  margin: 0;
}
@media (min-width: 768px) {
  .submit-group.submit-panel {
    min-height: 80px;
    padding: 20px;
  }
  .submit-group.submit-panel .edit-order {
    position: absolute;
    width: 75px;
    left: 15px;
    top: 20px;
    text-align: left;
  }
  .submit-group.submit-panel .payment-confirmation-details {
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 0;
  }
  .submit-group.submit-panel .payment-confirmation-details.empty-card {
    margin-top: 8px;
  }
  .submit-group.submit-panel .invoice-confirmation-details {
    margin: 7px 155px 0 0;
  }
  .submit-group.submit-panel .payment-message a {
    display: inline;
    margin: 0 0 0 4px;
  }
  .submit-group.submit-panel .parcel-count {
    position: absolute;
    top: 28px;
  }
  .submit-group.submit-panel .btn-add-card,
  .submit-group.submit-panel .btn-submit,
  .submit-group.submit-panel .btn-loading {
    float: right;
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .submit-group.submit-panel .edit-order {
    top: 29px;
    left: 20px;
    width: auto;
  }
}
.section .section-intro {
  color: #9fa7ae;
  font-size: 15px;
}
@media (min-width: 768px) {
  .section .section-intro {
    margin-bottom: 35px;
  }
}
.section .section-heading {
  font-weight: normal;
  margin-top: 36px;
  margin-bottom: 13px;
}
.section.section-signup {
  margin-top: 50px;
}
@media (min-width: 768px) {
  .section.section-signup {
    margin-top: 0;
  }
}
.section .section-footer {
  color: #9fa7ae;
  font-size: 15px;
  margin-top: 36px;
}
@media screen and (max-width: 660px) {
  .form .form-group .horizontal-right-space {
    padding-right: 7px;
  }
}
.sds-form-element .dropdown-menu {
  border-radius: 0.25rem;
  font-size: 0.875rem;
}
.sds-form-element .dropdown-menu > li > a {
  padding: 0.5rem 1.5rem;
}
.sds-form-element .dropdown-menu > li > a:hover {
  background: #e9edf0;
}
.sds-form-element + .help-panel {
  color: #33424f;
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: #e6f7ff;
  border-color: #009de3;
  padding: 1rem;
}
.sds-form-element + .help-panel > p {
  color: inherit;
  font-size: 1rem;
}
.sds-form-element + .help-panel .pointer {
  border-bottom-color: #009de3;
}
.sds-form-element + .help-panel .pointer:before {
  border-bottom-color: #e6f7ff;
}
div.row.unified .panel-formgroup {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
}
div.row.unified .panel-formgroup .panel-heading {
  color: #33424f;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: normal;
  line-height: 1.1em;
}
div.row.unified .panel-formgroup .panel-body .panel-head-link {
  width: 100%;
  height: 0;
  font-size: 0.875rem;
}
div.row.unified .panel-formgroup .panel-body .panel-head-link .panel-help-link {
  float: right;
  right: 32px;
  position: absolute;
  margin-top: -35px;
}
@media screen and (max-width: 1200px) {
  div.row.unified .panel-formgroup .panel-body .panel-head-link {
    height: 25px;
  }
  div.row.unified
    .panel-formgroup
    .panel-body
    .panel-head-link
    .panel-help-link {
    position: relative;
    height: 25px;
    margin-top: 0;
    right: 0;
    float: left;
  }
}
div.row.unified .field-group {
  margin-bottom: 0;
}
div.row.unified .form-group {
  margin: 0 0 17px 0;
}
div.row.unified .form-group.disabled .control-label {
  color: #a5b1be;
}
div.row.unified .form-group.disabled .field input,
div.row.unified .form-group.disabled .field .select2-selection {
  border-color: #a5b1be;
  color: #7a8691;
}
div.row.unified .form-group.disabled .field .input-group-btn button {
  border-color: #a5b1be;
  color: #7a8691;
}
div.row.unified .form-group.has-error .control-label {
  color: #e93d45;
}
div.row.unified .form-group.has-error .field input,
div.row.unified .form-group.has-error .field .select2-selection {
  border-color: #e93d45;
}
div.row.unified .form-group.has-error .field .help-block {
  background-color: #fedcd9;
  margin: 0;
  padding: 4px;
  border-radius: 2px;
}
div.row.unified .form-group.has-error .field .help-block .invalid-red-link {
  color: #e44144;
  text-decoration: underline;
}
div.row.unified
  .form-group.has-error
  .field
  .help-block
  .invalid-red-link:hover {
  text-decoration: none;
}
div.row.unified .form-group.has-error .field .input-group-btn button {
  border-color: #e93d45;
}
div.row.unified .form-group:focus-within .control-label {
  color: #009de3;
  text-decoration: none;
  background-color: transparent;
}
div.row.unified
  .form-group:focus-within
  .field
  .input-group
  .input-group-btn
  button {
  border: 1px solid #009de3;
}
div.row.unified
  .form-group:focus-within
  .field
  .input-group
  .input-group-btn
  .dropdown-menu {
  border: 1px solid #009de3;
}
div.row.unified .form-group:focus-within .field .input-group input {
  border: 1px solid #009de3;
}
div.row.unified .form-group.open .control-label {
  color: #009de3;
}
div.row.unified .form-group label {
  position: relative;
  top: 0px;
  left: 0px;
  height: 19px;
  padding: 0 0;
  margin: 0 0 4px 0;
  border: none;
  background: none;
  color: #7a8691;
  font-size: 14px;
  text-align: left;
  line-height: 19px;
}
div.row.unified .form-group .field-addon {
  padding: 31px 10px;
  display: table-cell;
  width: auto;
  color: #000;
  background-color: transparent;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: bold;
  margin-top: 24px;
  position: absolute;
}
div.row.unified .form-group .field-addon:not(.right) ~ .field .form-control {
  padding-left: 50px;
}
div.row.unified
  .form-group
  .field-addon.form-icon-search:not(.right)
  ~ .field
  .form-control {
  padding-left: 35px;
}
div.row.unified .form-group .field-addon.right {
  right: 4px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  div.row.unified .form-group .field-addon {
    margin-top: 2px;
  }
}
@media (min-width: 768px) {
  div.row.unified .form-group .field-addon:not(.right) {
    left: 4px;
    padding: 11px 5px;
  }
  div.row.unified .form-group .field-addon:not(.right) ~ .field .form-control {
    padding-left: 50px;
  }
  div.row.unified
    .form-group
    .field-addon.form-icon-search:not(.right)
    ~ .field
    .form-control {
    padding-left: 35px;
  }
  div.row.unified .form-group .field-addon.right {
    padding: 11px 5px;
  }
  div.row.unified .form-group .control-label {
    width: 100%;
  }
}
div.row.unified .form-group .field .input-group:focus {
  outline: none;
}
div.row.unified .form-group .field .form-control {
  padding-left: 9px;
  text-align: left;
}
div.row.unified .form-group .field .form-control::-moz-placeholder {
  font-style: italic;
  font-size: 13px;
}
div.row.unified .form-group .field .form-control::-webkit-input-placeholder {
  font-style: italic;
  font-size: 13px;
}
div.row.unified .form-group .field .form-control:-ms-input-placeholder {
  font-style: italic;
  font-size: 13px;
}
div.row.unified .form-group .field .form-control:-moz-placeholder {
  font-style: italic;
  font-size: 13px;
}
div.row.unified .form-group .field input {
  border: 1px solid #a5b1be;
  border-radius: 2px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
div.row.unified .form-group .field input:focus {
  border: 1px solid #009de3;
  box-shadow: none;
  -webkit-box-shadow: none;
}
div.row.unified .form-group .field input.address-lookup {
  border: 2px solid #677280;
}
div.row.unified .form-group .field input.address-lookup:focus {
  border: 2px solid #009de3;
}
div.row.unified .form-group .field input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}
div.row.unified .form-group .field input[type="text"],
div.row.unified .form-group .field input[type="submit"],
div.row.unified .form-group .field input[type="email"],
div.row.unified .form-group .field input[type="password"] {
  -webkit-appearance: none;
}
div.row.unified .form-group .field input::-webkit-outer-spin-button,
div.row.unified .form-group .field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
div.row.unified .form-group .field textarea {
  -webkit-appearance: none;
  font-size: 15px;
}
div.row.unified .form-group .field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(https://assets.sendle.com/assets/dashboard/select-arrow-ecbb5240c5f9fde962b4d55ee4fc0ba0420d9aefc1995f52cd42d06addc1f210.png)
    no-repeat right center;
  background-size: 16px 15px;
  border: 1px solid #a5b1be;
  border-radius: 2px;
  height: 40px;
}
div.row.unified .form-group .field .select2 {
  color: #aeb8c4;
}
div.row.unified
  .form-group
  .field
  .select2.select2-container--open
  .select2-selection,
div.row.unified
  .form-group
  .field
  .select2.select2-container--focus
  .select2-selection {
  border: 1px solid #009de3;
}
div.row.unified .form-group .field .select2 .select2-selection {
  height: 40px;
  border: 1px solid #a5b1be;
  border-radius: 2px;
}
div.row.unified .form-group .field .select2 .select2-selection:focus {
  outline: none;
}
div.row.unified
  .form-group
  .field
  .select2
  .select2-selection
  .select2-selection__rendered {
  line-height: 40px;
  font-size: 15px;
}
div.row.unified
  .form-group
  .field
  .select2
  .select2-selection
  .select2-selection__rendered:focus {
  outline: none;
}
div.row.unified
  .form-group
  .field
  .select2
  .select2-selection
  .select2-selection__arrow {
  height: 100%;
  right: 4px;
}
div.row.unified .form-group .field .input-group-btn button {
  height: 40px;
  border: 1px solid #a5b1be;
  border-radius: 0 2px 2px 0;
  background-color: #ffffff;
  height: 40px;
}
div.row.unified .form-group .field .input-group-btn button:focus {
  border: 1px solid #009de3;
  outline: none;
}
div.row.unified .form-group .field .input-group-btn button:focus span {
  color: #009de3;
}
div.row.unified .form-group .field .input-group-btn .dropdown-menu {
  border-radius: 2px;
  margin-top: 0;
  top: 98%;
  right: 1px;
}
@-moz-document url-prefix() {
  div.row.unified .form-group .field .input-group-btn .dropdown-menu {
    right: 0;
  }
}
div.row.unified .form-group .field .has-error .control-label {
  background: #d94c4c;
  color: #7a8691;
  font-size: 11px;
}
div.row.unified .form-check {
  margin: 0 0 17px 0;
}
div.row.unified .form-check label {
  font-size: 14px;
}
.select2-container--open .select2-dropdown {
  border: 1px solid #009de3;
  border-top: none;
}
.select2-container--open .select2-dropdown .select2-search__field {
  border: 1px solid #009de3;
}
.select2-container--open .select2-dropdown.select2-dropdown--above {
  border-top: 1px solid #009de3;
}
.select2-container--open
  .select2-results
  .select2-results__option.select2-results__option--highlighted {
  background-color: #008dcc;
}
form#new_event .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
form#new_event .custom-file-input {
  max-width: 90px;
  border: none;
  color: transparent;
}
form#new_event .custom-file-input::before {
  content: "Add file";
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  background-color: #eaedf0;
  padding: 0px 18px;
  border-radius: 4px;
  color: #333;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}
form#new_event .custom-file-input:hover::before {
  background-color: #ddd;
}
form#new_event .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
form#new_event .custom-file-input:focus {
  outline: none;
}
form#new_event .custom-file-input-row {
  display: flex;
  align-items: center;
}
form#new_event .custom-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 400;
  color: #33424f;
}
form#new_event .custom-file-no-upload {
  color: #a2a7ae;
  font-size: 15px;
  font-weight: 400;
  margin-left: 20px;
}
.frameless .navbar-nav li .profile-link {
  margin-right: 30px;
}
.gd-opt-in-container {
  max-width: 640px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
  font-size: 15px;
}
.gd-opt-in-container .header {
  width: 100%;
  text-align: center;
  background-color: white;
}
.gd-opt-in-container .header img {
  max-width: 300px;
  margin: auto;
}
.gd-opt-in-container .header h1 {
  margin-top: 32px;
}
.gd-opt-in-container .header .subheading {
  margin-top: 22px;
  font-size: 18px;
}
.gd-opt-in-container p,
.gd-opt-in-container ul,
.gd-opt-in-container ol {
  margin-bottom: 18px;
}
.gd-opt-in-container p a,
.gd-opt-in-container ul a,
.gd-opt-in-container ol a {
  color: #008dcc;
}
.gd-opt-in-container .well {
  margin: 30px 0 20px 0;
  padding: 25px 45px;
  background: #f9f9f9;
}
.gd-opt-in-container .well .well-heading {
  margin-bottom: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.gd-opt-in-container .well a {
  color: #33424f;
  text-decoration: underline;
}
.gd-opt-in-container .opt-in-buttons {
  text-align: center;
}
.gd-opt-in-container ul.next-steps li {
  margin-bottom: 1em;
}
.gd-opt-in-container .centered {
  margin: auto;
}
.gd-opt-in-container .action-button {
  margin: 10px 20px 0 20px;
}
.account-settings .delivery-guarantee-status {
  margin: 0 15px;
  border: 1px solid #e9edf0;
  padding: 10px;
  font-size: 12px;
  color: #66839c;
}
.account-settings .delivery-guarantee-status img {
  height: 52px;
  float: left;
  margin-right: 10px;
}
.account-settings .delivery-guarantee-status p {
  margin-bottom: 0px;
}
.account-settings .delivery-guarantee-status a {
  color: #008dcc;
}
.account-settings .delivery-guarantee-status .heading {
  font-weight: bold;
}
.hinttip {
  all: unset;
  position: relative;
}
.hinttip .hinttip-content {
  position: absolute;
  display: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -khtml-border-radius: 8px;
  border-radius: 8px;
  background: white;
  border: 1px solid rgba(50, 66, 80, 0.3);
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  z-index: 3;
  bottom: 34px;
  right: 0;
  padding: 18px 14px;
  width: 300px;
  text-align: center;
}
.hinttip .hinttip-content.hinttip-overrides {
  width: 275px;
  font-weight: 500;
}
.hinttip:hover:not(.hinttip--on-click) .hinttip-content,
.hinttip--on-click.hinttip--visible .hinttip-content {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.hinttip--snazzy {
  vertical-align: inherit;
}
.hinttip--snazzy > * {
  display: block;
}
.hinttip--snazzy .hinttip-content {
  opacity: 0;
  display: block;
  pointer-events: none;
  visibility: hidden;
  width: max-content;
  max-width: 400px;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  font-size: 0.875rem;
  padding: 1rem;
  text-align: left;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.16);
  border-color: #e9edf0;
}
.hinttip--snazzy .hinttip-content .pointer {
  border-bottom-color: #ffffff;
  border-top-color: #ffffff;
}
.hinttip--snazzy .hinttip-content .pointer:before {
  border-bottom-color: #ffffff;
  border-top-color: #ffffff;
}
.hinttip--below .hinttip-content {
  top: 40px;
  bottom: auto;
  right: auto;
  left: 12px;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .hinttip--below .hinttip-content {
    max-width: 75vw;
    transform: translateX(-50%);
  }
}
.hinttip--below .hinttip-content .pointer {
  display: block;
  position: absolute;
  top: -12px;
  left: 12px;
  border-bottom: 12px solid #e9edf0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: 50%;
  transform: translateX(-50%);
}
.hinttip--below .hinttip-content .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.hinttip--below .hinttip-content .pointer:before {
  top: 1px;
  left: -12px;
  border-bottom: 12px solid #ffffff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.hinttip--above .hinttip-content {
  right: auto;
  left: 12px;
  bottom: 40px;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .hinttip--above .hinttip-content {
    max-width: 75vw;
    transform: translateX(-25%);
  }
}
.hinttip--above .hinttip-content .pointer {
  display: block;
  position: absolute;
  bottom: -12px;
  border-top: 12px solid #e9edf0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 1px;
}
.hinttip--above .hinttip-content .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.hinttip--above .hinttip-content .pointer:before {
  bottom: 1px;
  left: -12px;
  border-top: 12px solid #ffffff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
@media (max-width: 767px) {
  .hinttip--above .hinttip-content .pointer {
    left: 25%;
  }
}
.hinttip--left .hinttip-content {
  transform: translate(5%, 3%);
}
.hinttip--left .hinttip-content .pointer {
  display: block;
  position: absolute;
  bottom: -12px;
  border-top: 12px solid #e9edf0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  right: 8px;
  left: auto;
}
.hinttip--left .hinttip-content .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.hinttip--left .hinttip-content .pointer:before {
  bottom: 1px;
  left: -12px;
  border-top: 12px solid #ffffff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.hinttip--dark .hinttip-content {
  border: none;
  color: #33424f;
  background: #e9edf0;
  box-shadow: 0 2px 4px 0 rgba(51, 66, 79, 0.23);
  line-height: 1.2;
}
.hinttip--dark .hinttip-content .pointer {
  border-bottom-color: #e9edf0;
  border-top-color: #e9edf0;
}
.hinttip--dark .hinttip-content .pointer:before {
  border-bottom-color: #e9edf0;
  border-top-color: #e9edf0;
}
.hinttip .hinttip-icon-medium {
  margin: 0 0.25rem;
  width: 16px;
}
.ie11-notice--hidden {
  display: none;
}
.instruction-set {
  padding: 18px 0 0 0;
  margin: 18px 0;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1053px) {
  .instruction-set {
    flex-direction: column;
    align-items: center;
  }
}
.instruction-item {
  text-align: left;
  margin: 0 0.5rem 1rem 0.5rem;
  border: 1px solid #e9edf0;
  border-radius: 0.5rem;
  min-width: 270px;
  max-width: 340px;
  padding: 1.5rem;
}
@media screen and (max-width: 1053px) {
  .instruction-item {
    max-width: none;
    width: 100%;
  }
}
.instruction-item h4 {
  margin-bottom: 14px;
}
.instruction-item p {
  font-size: 1rem;
}
.instruction-item .instruction-text {
  color: #9fa7ae;
  line-height: 21px;
}
.integrations {
  font-size: 1rem;
}
.integration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e9edf0;
  box-shadow: 0 1px 0.5rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  max-width: 800px;
}
.integration:hover {
  -webkit-box-shadow: 0 1px 0.5rem rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 0.5rem rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 0.5rem rgba(0, 0, 0, 0.08);
}
.integration__actions {
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .integration__actions {
    margin: auto 0;
  }
}
.integration__actions__connect {
  pointer-events: all;
  width: 100%;
}
@media (min-width: 768px) {
  .integration__actions__connect {
    width: auto;
  }
}
.integration__title {
  padding: 0 1rem;
}
@media (min-width: 768px) {
  .integration {
    flex-direction: row;
  }
}
section.international_dangerous_goods_terms {
  padding: 32px 48px;
  text-align: center;
}
@media (min-width: 768px) {
  section.international_dangerous_goods_terms {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }
}
section.international_dangerous_goods_terms h2 {
  font-size: 18px;
}
section.international_dangerous_goods_terms p {
  font-size: 15px;
}
section.international_dangerous_goods_terms .agreement {
  font-size: 11px;
  color: #7b8891;
  max-width: 413px;
  margin: 8px auto 0 auto;
}
section.international_dangerous_goods_terms .goods,
.dangerous-goods-panel .goods {
  display: block;
}
section.international_dangerous_goods_terms .pickup,
.dangerous-goods-panel .pickup {
  display: inline-block;
}
section.international_dangerous_goods_terms .goods,
section.international_dangerous_goods_terms .pickup,
.dangerous-goods-panel .goods,
.dangerous-goods-panel .pickup {
  color: #3f4a56;
  font-size: 15px;
  text-align: center;
  max-width: 388px;
  min-width: 310px;
}
section.international_dangerous_goods_terms .goods .icon,
section.international_dangerous_goods_terms
  .goods
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  form:before,
section.international_dangerous_goods_terms
  .goods
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  form:before,
section.international_dangerous_goods_terms
  .goods
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  form:before,
section.international_dangerous_goods_terms
  .goods
  .navbar-form.search-form:after,
section.international_dangerous_goods_terms .pickup .icon,
section.international_dangerous_goods_terms
  .pickup
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .navbar-form.search-form:after,
.dangerous-goods-panel .goods .icon,
.dangerous-goods-panel .goods #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .goods form:before,
.dangerous-goods-panel .goods #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods form:before,
.dangerous-goods-panel .goods #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .goods form:before,
.dangerous-goods-panel .goods .navbar-form.search-form:after,
.dangerous-goods-panel .pickup .icon,
.dangerous-goods-panel .pickup #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup .navbar-form.search-form:after {
  display: block;
  height: 46px;
  width: auto;
  margin: 0;
  background: no-repeat center;
  margin-bottom: 8px;
  min-width: 160px;
}
section.international_dangerous_goods_terms .goods .col-md-3,
section.international_dangerous_goods_terms .pickup .col-md-3,
.dangerous-goods-panel .goods .col-md-3,
.dangerous-goods-panel .pickup .col-md-3 {
  width: 49%;
  height: 117px;
}
section.international_dangerous_goods_terms .goods .col-md-3,
.dangerous-goods-panel .goods .col-md-3 {
  display: inline-block;
}
section.international_dangerous_goods_terms .goods .col-md-3.mobile-hidden,
.dangerous-goods-panel .goods .col-md-3.mobile-hidden {
  display: none;
}
section.international_dangerous_goods_terms .goods:first-of-type,
.dangerous-goods-panel .goods:first-of-type {
  margin-top: 56px;
}
section.international_dangerous_goods_terms .goods .icon:before,
section.international_dangerous_goods_terms
  .goods
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  form:before,
section.international_dangerous_goods_terms
  .goods
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  form:before,
section.international_dangerous_goods_terms
  .goods
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  form:before,
.dangerous-goods-panel .goods .icon:before,
.dangerous-goods-panel .goods #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .goods form:before,
.dangerous-goods-panel .goods #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods form:before,
.dangerous-goods-panel .goods #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .goods form:before {
  content: "";
  display: block;
  height: 55px;
  width: 55px;
  background: ./assets/icontwo.svg)
    no-repeat no-repeat white;
  background-size: 797px 469px;
  position: relative;
  margin: 0 auto;
}
section.international_dangerous_goods_terms .goods .corrosives .icon:before,
section.international_dangerous_goods_terms
  .goods
  .corrosives
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .corrosives
  form:before,
section.international_dangerous_goods_terms
  .goods
  .corrosives
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .corrosives
  form:before,
section.international_dangerous_goods_terms
  .goods
  .corrosives
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .corrosives
  form:before,
.dangerous-goods-panel .goods .corrosives .icon:before,
.dangerous-goods-panel
  .goods
  .corrosives
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .corrosives
  form:before,
.dangerous-goods-panel .goods .corrosives #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .corrosives form:before,
.dangerous-goods-panel
  .goods
  .corrosives
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .corrosives
  form:before {
  background-position: -3px -177px;
}
section.international_dangerous_goods_terms .goods .gases .icon:before,
section.international_dangerous_goods_terms
  .goods
  .gases
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .gases
  form:before,
section.international_dangerous_goods_terms
  .goods
  .gases
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .gases
  form:before,
section.international_dangerous_goods_terms
  .goods
  .gases
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .gases
  form:before,
.dangerous-goods-panel .goods .gases .icon:before,
.dangerous-goods-panel .goods .gases #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .goods .gases form:before,
.dangerous-goods-panel .goods .gases #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .gases form:before,
.dangerous-goods-panel .goods .gases #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .goods .gases form:before {
  background-position: -61px -174px;
}
section.international_dangerous_goods_terms
  .goods
  .flamable-liquids
  .icon:before,
section.international_dangerous_goods_terms
  .goods
  .flamable-liquids
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .flamable-liquids
  form:before,
section.international_dangerous_goods_terms
  .goods
  .flamable-liquids
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .flamable-liquids
  form:before,
section.international_dangerous_goods_terms
  .goods
  .flamable-liquids
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .flamable-liquids
  form:before,
.dangerous-goods-panel .goods .flamable-liquids .icon:before,
.dangerous-goods-panel
  .goods
  .flamable-liquids
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .flamable-liquids
  form:before,
.dangerous-goods-panel
  .goods
  .flamable-liquids
  #support
  #modal-search
  form:before,
#support
  #modal-search
  .dangerous-goods-panel
  .goods
  .flamable-liquids
  form:before,
.dangerous-goods-panel
  .goods
  .flamable-liquids
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .flamable-liquids
  form:before {
  background-position: -118px -176px;
}
section.international_dangerous_goods_terms .goods .organic .icon:before,
section.international_dangerous_goods_terms
  .goods
  .organic
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .organic
  form:before,
section.international_dangerous_goods_terms
  .goods
  .organic
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .organic
  form:before,
section.international_dangerous_goods_terms
  .goods
  .organic
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .organic
  form:before,
.dangerous-goods-panel .goods .organic .icon:before,
.dangerous-goods-panel
  .goods
  .organic
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .organic
  form:before,
.dangerous-goods-panel .goods .organic #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .organic form:before,
.dangerous-goods-panel
  .goods
  .organic
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .organic
  form:before {
  background-position: -178px -174px;
}
section.international_dangerous_goods_terms .goods .explosives,
.dangerous-goods-panel .goods .explosives {
  top: -42px;
}
section.international_dangerous_goods_terms .goods .explosives .icon:before,
section.international_dangerous_goods_terms
  .goods
  .explosives
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .explosives
  form:before,
section.international_dangerous_goods_terms
  .goods
  .explosives
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .explosives
  form:before,
section.international_dangerous_goods_terms
  .goods
  .explosives
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .explosives
  form:before,
.dangerous-goods-panel .goods .explosives .icon:before,
.dangerous-goods-panel
  .goods
  .explosives
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .explosives
  form:before,
.dangerous-goods-panel .goods .explosives #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .explosives form:before,
.dangerous-goods-panel
  .goods
  .explosives
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .explosives
  form:before {
  background-position: -233px -177px;
}
section.international_dangerous_goods_terms .goods .misc .icon:before,
section.international_dangerous_goods_terms
  .goods
  .misc
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .misc
  form:before,
section.international_dangerous_goods_terms
  .goods
  .misc
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .misc
  form:before,
section.international_dangerous_goods_terms
  .goods
  .misc
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .misc
  form:before,
.dangerous-goods-panel .goods .misc .icon:before,
.dangerous-goods-panel .goods .misc #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .goods .misc form:before,
.dangerous-goods-panel .goods .misc #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .misc form:before,
.dangerous-goods-panel .goods .misc #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .goods .misc form:before {
  background-position: -295px -177px;
}
section.international_dangerous_goods_terms
  .goods
  .flamable-solids
  .icon:before,
section.international_dangerous_goods_terms
  .goods
  .flamable-solids
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .flamable-solids
  form:before,
section.international_dangerous_goods_terms
  .goods
  .flamable-solids
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .flamable-solids
  form:before,
section.international_dangerous_goods_terms
  .goods
  .flamable-solids
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .flamable-solids
  form:before,
.dangerous-goods-panel .goods .flamable-solids .icon:before,
.dangerous-goods-panel
  .goods
  .flamable-solids
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .flamable-solids
  form:before,
.dangerous-goods-panel
  .goods
  .flamable-solids
  #support
  #modal-search
  form:before,
#support
  #modal-search
  .dangerous-goods-panel
  .goods
  .flamable-solids
  form:before,
.dangerous-goods-panel
  .goods
  .flamable-solids
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .flamable-solids
  form:before {
  background-position: -349px -177px;
}
section.international_dangerous_goods_terms
  .goods
  .oxidising-materials
  .icon:before,
section.international_dangerous_goods_terms
  .goods
  .oxidising-materials
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .oxidising-materials
  form:before,
section.international_dangerous_goods_terms
  .goods
  .oxidising-materials
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .oxidising-materials
  form:before,
section.international_dangerous_goods_terms
  .goods
  .oxidising-materials
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .oxidising-materials
  form:before,
.dangerous-goods-panel .goods .oxidising-materials .icon:before,
.dangerous-goods-panel
  .goods
  .oxidising-materials
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .oxidising-materials
  form:before,
.dangerous-goods-panel
  .goods
  .oxidising-materials
  #support
  #modal-search
  form:before,
#support
  #modal-search
  .dangerous-goods-panel
  .goods
  .oxidising-materials
  form:before,
.dangerous-goods-panel
  .goods
  .oxidising-materials
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .oxidising-materials
  form:before {
  background-position: -409px -174px;
}
section.international_dangerous_goods_terms .goods .infectious .icon:before,
section.international_dangerous_goods_terms
  .goods
  .infectious
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .infectious
  form:before,
section.international_dangerous_goods_terms
  .goods
  .infectious
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .infectious
  form:before,
section.international_dangerous_goods_terms
  .goods
  .infectious
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .infectious
  form:before,
.dangerous-goods-panel .goods .infectious .icon:before,
.dangerous-goods-panel
  .goods
  .infectious
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .infectious
  form:before,
.dangerous-goods-panel .goods .infectious #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .infectious form:before,
.dangerous-goods-panel
  .goods
  .infectious
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .infectious
  form:before {
  background-position: -469px -174px;
}
section.international_dangerous_goods_terms .goods .toxic .icon:before,
section.international_dangerous_goods_terms
  .goods
  .toxic
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .toxic
  form:before,
section.international_dangerous_goods_terms
  .goods
  .toxic
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .toxic
  form:before,
section.international_dangerous_goods_terms
  .goods
  .toxic
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .toxic
  form:before,
.dangerous-goods-panel .goods .toxic .icon:before,
.dangerous-goods-panel .goods .toxic #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .goods .toxic form:before,
.dangerous-goods-panel .goods .toxic #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .toxic form:before,
.dangerous-goods-panel .goods .toxic #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .goods .toxic form:before {
  background-position: -525px -176px;
}
section.international_dangerous_goods_terms .goods .magnetised .icon:before,
section.international_dangerous_goods_terms
  .goods
  .magnetised
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .magnetised
  form:before,
section.international_dangerous_goods_terms
  .goods
  .magnetised
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .magnetised
  form:before,
section.international_dangerous_goods_terms
  .goods
  .magnetised
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .magnetised
  form:before,
.dangerous-goods-panel .goods .magnetised .icon:before,
.dangerous-goods-panel
  .goods
  .magnetised
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .magnetised
  form:before,
.dangerous-goods-panel .goods .magnetised #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .magnetised form:before,
.dangerous-goods-panel
  .goods
  .magnetised
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .magnetised
  form:before {
  background-position: -584px -178px;
}
section.international_dangerous_goods_terms .goods .radioactive .icon:before,
section.international_dangerous_goods_terms
  .goods
  .radioactive
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .radioactive
  form:before,
section.international_dangerous_goods_terms
  .goods
  .radioactive
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .radioactive
  form:before,
section.international_dangerous_goods_terms
  .goods
  .radioactive
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .radioactive
  form:before,
.dangerous-goods-panel .goods .radioactive .icon:before,
.dangerous-goods-panel
  .goods
  .radioactive
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .radioactive
  form:before,
.dangerous-goods-panel .goods .radioactive #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .radioactive form:before,
.dangerous-goods-panel
  .goods
  .radioactive
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .radioactive
  form:before {
  background-position: -643px -174px;
}
section.international_dangerous_goods_terms .goods .batteries .icon:before,
section.international_dangerous_goods_terms
  .goods
  .batteries
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .batteries
  form:before,
section.international_dangerous_goods_terms
  .goods
  .batteries
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .batteries
  form:before,
section.international_dangerous_goods_terms
  .goods
  .batteries
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .batteries
  form:before,
.dangerous-goods-panel .goods .batteries .icon:before,
.dangerous-goods-panel
  .goods
  .batteries
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .dangerous-goods-panel
  .goods
  .batteries
  form:before,
.dangerous-goods-panel .goods .batteries #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .batteries form:before,
.dangerous-goods-panel
  .goods
  .batteries
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .batteries
  form:before {
  background-position: -699px -174px;
}
section.international_dangerous_goods_terms .goods .oxygen .icon:before,
section.international_dangerous_goods_terms
  .goods
  .oxygen
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .oxygen
  form:before,
section.international_dangerous_goods_terms
  .goods
  .oxygen
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .oxygen
  form:before,
section.international_dangerous_goods_terms
  .goods
  .oxygen
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .goods
  .oxygen
  form:before,
.dangerous-goods-panel .goods .oxygen .icon:before,
.dangerous-goods-panel .goods .oxygen #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .goods .oxygen form:before,
.dangerous-goods-panel .goods .oxygen #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .goods .oxygen form:before,
.dangerous-goods-panel .goods .oxygen #tracking-batch #modal-search form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .goods
  .oxygen
  form:before {
  background-position: -759px -175px;
}
section.international_dangerous_goods_terms .pickup,
.dangerous-goods-panel .pickup {
  margin-top: 56px;
}
section.international_dangerous_goods_terms .pickup .col-md-3,
.dangerous-goods-panel .pickup .col-md-3 {
  height: auto;
  margin: 0 auto;
}
section.international_dangerous_goods_terms .pickup .icon,
section.international_dangerous_goods_terms
  .pickup
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .navbar-form.search-form:after,
.dangerous-goods-panel .pickup .icon,
.dangerous-goods-panel .pickup #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup .navbar-form.search-form:after {
  height: 55px;
  margin-bottom: 0;
  margin-top: 16px;
}
section.international_dangerous_goods_terms .pickup .icon:before,
section.international_dangerous_goods_terms
  .pickup
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
section.international_dangerous_goods_terms
  .pickup
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  form:before,
.dangerous-goods-panel .pickup .icon:before,
.dangerous-goods-panel .pickup #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .pickup form:before,
.dangerous-goods-panel .pickup #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .pickup form:before {
  content: "";
  display: block;
  height: 55px;
  width: 55px;
  background: ./assets/icontwo.svg)
    no-repeat no-repeat white;
  background-size: 797px 469px;
  position: relative;
  margin: 0 auto;
}
section.international_dangerous_goods_terms .pickup .one .icon:before,
section.international_dangerous_goods_terms
  .pickup
  .one
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .one
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .one
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .one
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .one
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .one
  form:before,
.dangerous-goods-panel .pickup .one .icon:before,
.dangerous-goods-panel .pickup .one #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .pickup .one form:before,
.dangerous-goods-panel .pickup .one #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .pickup .one form:before,
.dangerous-goods-panel .pickup .one #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .pickup .one form:before {
  background-position: -2px -229px;
}
section.international_dangerous_goods_terms .pickup .two .icon:before,
section.international_dangerous_goods_terms
  .pickup
  .two
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .two
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .two
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .two
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .two
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .two
  form:before,
.dangerous-goods-panel .pickup .two .icon:before,
.dangerous-goods-panel .pickup .two #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .pickup .two form:before,
.dangerous-goods-panel .pickup .two #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .pickup .two form:before,
.dangerous-goods-panel .pickup .two #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .pickup .two form:before {
  background-position: -60px -225px;
}
section.international_dangerous_goods_terms .pickup .three .icon:before,
section.international_dangerous_goods_terms
  .pickup
  .three
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .three
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .three
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .three
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .three
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .three
  form:before,
.dangerous-goods-panel .pickup .three .icon:before,
.dangerous-goods-panel .pickup .three #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .pickup .three form:before,
.dangerous-goods-panel .pickup .three #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .pickup .three form:before,
.dangerous-goods-panel .pickup .three #tracking-batch #modal-search form:before,
#tracking-batch
  #modal-search
  .dangerous-goods-panel
  .pickup
  .three
  form:before {
  background-position: -116px -225px;
}
section.international_dangerous_goods_terms .pickup .four .icon:before,
section.international_dangerous_goods_terms
  .pickup
  .four
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .four
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .four
  #support
  #modal-search
  form:before,
#support
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .four
  form:before,
section.international_dangerous_goods_terms
  .pickup
  .four
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  section.international_dangerous_goods_terms
  .pickup
  .four
  form:before,
.dangerous-goods-panel .pickup .four .icon:before,
.dangerous-goods-panel .pickup .four #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .dangerous-goods-panel .pickup .four form:before,
.dangerous-goods-panel .pickup .four #support #modal-search form:before,
#support #modal-search .dangerous-goods-panel .pickup .four form:before,
.dangerous-goods-panel .pickup .four #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .dangerous-goods-panel .pickup .four form:before {
  background-position: -172px -224px;
}
@media (min-width: 970px) {
  section.international_dangerous_goods_terms .goods,
  section.international_dangerous_goods_terms .pickup,
  .dangerous-goods-panel .goods,
  .dangerous-goods-panel .pickup {
    display: block;
    width: auto;
  }
  section.international_dangerous_goods_terms .goods,
  .dangerous-goods-panel .goods {
    margin-top: 56px;
    max-width: none;
  }
  section.international_dangerous_goods_terms .goods .icon,
  section.international_dangerous_goods_terms
    .goods
    #tracking-tools
    #modal-search
    form:before,
  #tracking-tools
    #modal-search
    section.international_dangerous_goods_terms
    .goods
    form:before,
  section.international_dangerous_goods_terms
    .goods
    #support
    #modal-search
    form:before,
  #support
    #modal-search
    section.international_dangerous_goods_terms
    .goods
    form:before,
  section.international_dangerous_goods_terms
    .goods
    #tracking-batch
    #modal-search
    form:before,
  #tracking-batch
    #modal-search
    section.international_dangerous_goods_terms
    .goods
    form:before,
  section.international_dangerous_goods_terms
    .goods
    .navbar-form.search-form:after,
  .dangerous-goods-panel .goods .icon,
  .dangerous-goods-panel .goods #tracking-tools #modal-search form:before,
  #tracking-tools #modal-search .dangerous-goods-panel .goods form:before,
  .dangerous-goods-panel .goods #support #modal-search form:before,
  #support #modal-search .dangerous-goods-panel .goods form:before,
  .dangerous-goods-panel .goods #tracking-batch #modal-search form:before,
  #tracking-batch #modal-search .dangerous-goods-panel .goods form:before,
  .dangerous-goods-panel .goods .navbar-form.search-form:after {
    min-width: auto;
  }
  section.international_dangerous_goods_terms .goods .col-md-3,
  .dangerous-goods-panel .goods .col-md-3 {
    width: 25%;
  }
  section.international_dangerous_goods_terms .goods .col-md-3.mobile-hidden,
  .dangerous-goods-panel .goods .col-md-3.mobile-hidden {
    display: block;
  }
  section.international_dangerous_goods_terms .goods .explosives,
  .dangerous-goods-panel .goods .explosives {
    top: 0;
  }
  section.international_dangerous_goods_terms .pickup,
  .dangerous-goods-panel .pickup {
    max-width: none;
  }
  section.international_dangerous_goods_terms .pickup .icon,
  section.international_dangerous_goods_terms
    .pickup
    #tracking-tools
    #modal-search
    form:before,
  #tracking-tools
    #modal-search
    section.international_dangerous_goods_terms
    .pickup
    form:before,
  section.international_dangerous_goods_terms
    .pickup
    #support
    #modal-search
    form:before,
  #support
    #modal-search
    section.international_dangerous_goods_terms
    .pickup
    form:before,
  section.international_dangerous_goods_terms
    .pickup
    #tracking-batch
    #modal-search
    form:before,
  #tracking-batch
    #modal-search
    section.international_dangerous_goods_terms
    .pickup
    form:before,
  section.international_dangerous_goods_terms
    .pickup
    .navbar-form.search-form:after,
  .dangerous-goods-panel .pickup .icon,
  .dangerous-goods-panel .pickup #tracking-tools #modal-search form:before,
  #tracking-tools #modal-search .dangerous-goods-panel .pickup form:before,
  .dangerous-goods-panel .pickup #support #modal-search form:before,
  #support #modal-search .dangerous-goods-panel .pickup form:before,
  .dangerous-goods-panel .pickup #tracking-batch #modal-search form:before,
  #tracking-batch #modal-search .dangerous-goods-panel .pickup form:before,
  .dangerous-goods-panel .pickup .navbar-form.search-form:after {
    margin-bottom: 8px;
    margin-top: 0;
  }
  section.international_dangerous_goods_terms .pickup .col-md-3,
  .dangerous-goods-panel .pickup .col-md-3 {
    width: 25%;
  }
}
.invoice-list.table td {
  vertical-align: middle;
}
.invoice-list .glyphicon,
.invoice-list .panel-loading:after,
.invoice-list .panel-loading-plain:after,
.invoice-list .btn-loading:after {
  margin-right: 8px;
}
.list-group {
  margin: 0 -10px;
  table-layout: fixed;
}
@media (min-width: 768px) {
  .list-group {
    display: table;
    width: 100%;
    margin: 0;
  }
}
.list-group-item {
  border-top: 1px solid #eee;
  text-align: center;
  position: relative;
}
.list-group-item:hover {
  background: #eff9f9;
}
.list-group-item a {
  display: block;
  color: #33424f;
}
.list-group-item a:hover {
  color: #33424f;
  text-decoration: none;
}
.list-group-item .other-party {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
  min-width: 30%;
}
.list-group-item .other-party .avatar {
  margin-right: 7px;
}
.list-group-item .description {
  font-size: 11px;
  line-height: 15px;
  margin: 0 65px 8px 35px;
  text-align: left;
}
.list-group-item .reference {
  font-size: 12px;
  color: #9fa7ae;
  margin-left: 10px;
}
.list-group-item .state {
  margin: 11px auto 0 auto;
  width: 290px;
}
.list-group-item .resend {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 61.5px;
}
.list-group-item .more {
  position: absolute;
  top: 15px;
  right: 10px;
}
@media (min-width: 768px) {
  .list-group-item {
    display: table-row;
    border-top: 0;
    text-align: left;
  }
  .list-group-item .other-party {
    line-height: 24px;
    display: table-cell;
    vertical-align: middle;
    padding: 5px 0;
    border-top: 1px solid #eee;
  }
  .list-group-item .description {
    display: none;
    font-size: 13px;
    line-height: 1.428571429;
    border-top: 1px solid #eee;
    vertical-align: middle;
    padding: 10px 20px 10px 30px;
    margin: 0;
  }
  .list-group-item .state {
    border-top: 1px solid #eee;
    display: table-cell;
    vertical-align: middle;
    width: 305px;
    padding: 8px 0 5px 0;
  }
  .list-group-item .resend {
    position: static;
    border-top: 1px solid #eee;
    vertical-align: middle;
    display: table-cell;
    width: 5%;
  }
  .list-group-item .more {
    position: static;
    text-align: right;
    border-top: 1px solid #eee;
    vertical-align: middle;
    display: table-cell;
    width: 5%;
  }
  .list-group-item .more .icon,
  .list-group-item .more #tracking-tools #modal-search form:before,
  #tracking-tools #modal-search .list-group-item .more form:before,
  .list-group-item .more #support #modal-search form:before,
  #support #modal-search .list-group-item .more form:before,
  .list-group-item .more #tracking-batch #modal-search form:before,
  #tracking-batch #modal-search .list-group-item .more form:before,
  .list-group-item .more .navbar-form.search-form:after {
    margin: 5px 0 0 0;
  }
  .list-group-item .btn-resend {
    border: 0;
    padding: 0;
    position: relative;
    visibility: hidden;
    background: transparent;
    text-align: right;
  }
  .list-group-item .btn-resend:hover,
  .list-group-item .btn-resend:active {
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .list-group-item .btn-resend:before {
    content: "";
    display: inline-block;
    background: url(./assets/icons.svg)
      no-repeat;
    background-size: 1650px 100px;
    background-position: -1535px -68px;
    height: 16px;
    width: 20px;
  }
  .list-group-item .btn-resend span {
    display: none;
  }
  .list-group-item:hover .btn-resend {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .list-group-item .description {
    display: table-cell;
  }
}
.order-list-table.sds-table {
  overflow: visible;
}
.order-list-table .sds-table__table {
  table-layout: fixed;
}
.order-list-table.sds-table--highlight-hover tr:hover {
  cursor: pointer;
}
.order-list-table .sds-table__row.selected {
  background-color: #e6f7ff;
}
.order-list-table.bulk-actions-enabled .order-list-item--created-at,
.order-list-table.bulk-actions-enabled .order-list-item--name,
.order-list-table.bulk-actions-enabled .order-list-item--resend,
.order-list-table.bulk-actions-enabled .order-list-item--description,
.order-list-table.bulk-actions-enabled .order-list-item--status,
.order-list-table.bulk-actions-enabled .order-list-item--resend {
  margin-left: 3rem;
}
.order-list-table .description-container {
  display: flex;
  white-space: nowrap;
}
.order-list-table .description-container__description {
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
  max-width: 24rem;
}
@media screen and (max-width: 1220px) {
  .order-list-table .description-container__description {
    max-width: 15rem;
  }
}
.order-list-table .description-container__reference {
  flex: 0 0 auto;
}
.order-list-table .status-container {
  display: flex;
  white-space: nowrap;
}
.order-list-table .status-container--delivery-status {
  margin-left: 1rem;
}
@media screen and (max-width: 1220px) {
  .order-list-table {
    border-top: 0.5rem solid #e9edf0;
  }
  .order-list-table.sds-table--bold {
    border-top: 0.5rem solid #33424f;
  }
  .order-list-table.sds-table--subtle {
    border-top-width: 0.0625rem;
  }
  .order-list-table.sds-table--headless {
    border-top-width: 0.0625rem;
  }
  .order-list-table .sds-table__table {
    display: block;
  }
  .order-list-table .sds-table__table thead,
  .order-list-table .sds-table__table tbody,
  .order-list-table .sds-table__table tfoot {
    display: block;
  }
  .order-list-table .sds-table__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .order-list-table .sds-table__row .sds-table__cell:first-of-type,
  .order-list-table .sds-table__row .sds-table__header:first-of-type {
    padding-left: 1rem;
  }
  .order-list-table .sds-table__row .sds-table__cell:last-of-type,
  .order-list-table .sds-table__row .sds-table__header:last-of-type {
    padding-right: 1rem;
  }
  .order-list-table .sds-table__row--header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  .order-list-table .sds-table__cell {
    flex-basis: 100%;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .order-list-table .sds-table__cell:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .order-list-table.sds-table--spacious .sds-table__row {
    padding: 0.75rem 0;
  }
  .order-list-table.sds-table--spacious .sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .order-list-table.sds-table--compact .sds-table__row {
    padding: 0.25rem 0;
  }
  .order-list-table.sds-table--compact .sds-table__cell {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
.order-list-table--loading {
  opacity: 0.5;
}
.order-list-item--name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 18%;
}
.order-list-item--name strong {
  margin-left: 1.5rem;
}
@media screen and (min-width: 768px) and (max-width: 1220px) {
  .order-list-item--name {
    flex-basis: auto !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    order: -1;
  }
}
@media screen and (max-width: 1220px) {
  .order-list-item--name {
    margin-right: 1.5rem;
  }
  .order-list-item--name strong {
    margin-left: 0;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1200px) {
  .order-list-item--name {
    width: 30%;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1200px) {
  .order-list-item--description {
    display: none;
  }
}
@media screen and (max-width: 1220px) {
  .order-list-item--blank {
    padding: 0 !important;
  }
}
.order-list-item--description {
  width: 28%;
}
.order-list-item--created-at {
  width: 6rem;
  white-space: nowrap;
}
@media screen and (min-width: 768px) and (max-width: 1220px) {
  .order-list-item--created-at {
    flex-basis: auto;
    order: -1;
    max-width: fit-content;
  }
}
@media screen and (min-width: 768px) and (max-width: 1220px) and (-ms-high-contrast: none),
  screen and (min-width: 768px) and (max-width: 1220px) and (-ms-high-contrast: active) {
  .order-list-item--created-at {
    max-width: 115px;
  }
}
.order-list-item--status {
  width: 30%;
}
@media screen and (min-width: 1220px) and (max-width: 1500px) {
  .order-list-item--status {
    width: 20%;
  }
  .order-list-item--status .state--complete,
  .order-list-item--status .state--incomplete {
    display: none;
  }
}
@media screen and (min-width: 1220px) {
  .order-list-item--resend.sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
.order-list-empty-state {
  border-top: 0.0625rem solid #e9edf0;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
tr.batch-order-item {
  position: relative;
}
tr.batch-order-item td.batch-order-item__icon {
  vertical-align: middle;
}
@media screen and (max-width: 1220px) {
  tr.batch-order-item td.batch-order-item__icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
}
.order-summary {
  clear: both;
  margin-bottom: 3rem;
}
.empty-list {
  padding: 5px 0 15px 35px;
  color: #aaa;
}
.order-summary-footer {
  margin: 1rem 0;
  font-size: 1rem;
}
.btn-resend {
  border: 1px solid #33424f;
  color: #33424f;
  background: white;
  padding: 3px 8px;
}
.btn-resend:hover {
  background: #f1f4f6;
}
.order-list-bulk-action-check-all .sds-form-element__label {
  white-space: nowrap;
  padding-left: 1.5rem;
  font-size: 0.875rem;
}
.order-list-bulk-action-check-all .sds-form-element__label span {
  padding-left: 0.5rem;
}
@media screen and (max-width: 375px) {
  .order-list-bulk-action-check-all .sds-form-element__label span:last-of-type {
    display: none;
  }
}
@media screen and (max-width: 1220px) {
  .order-list-bulk-action-check-all .sds-form-element__label {
    padding-left: 1.125rem;
  }
}
.order-list-bulk-action-check-all.some-checked .sds-checkbox_faux::after {
  background: url(https://assets.sendle.com/assets/icons/select-clear-d629578d5a98e036e71fe3f74cf567f9b2e89299ea6b21153996467b4fd3e1fe.svg)
    no-repeat 0 3px !important;
}
.order-list-item--bulk-action {
  width: 4rem;
  cursor: default;
}
.order-list-item--bulk-action .sds-form-element__label {
  padding-left: none;
}
.order-list-item--bulk-action .sds-checkbox {
  height: 1rem;
}
@media screen and (max-width: 1220px) {
  .order-list-item--bulk-action {
    position: absolute;
  }
  .order-list-item--bulk-action .sds-form-element__label {
    padding-left: 0.125rem;
  }
}
.bulk-actions-header-row {
  display: flex;
  align-items: center;
  min-height: 4rem;
}
@media screen and (max-width: 1920px) {
  .bulk-actions-header-row {
    justify-content: space-between;
  }
}
.bulk-actions-header-row__title-content {
  display: flex;
  margin: 1rem 0 0.5rem;
  align-items: center;
}
.bulk-actions-header-row__title-content__title {
  white-space: nowrap;
  margin: 0;
}
.bulk-actions-header-row__title-content__subtitle {
  margin-left: 0.25rem;
  white-space: nowrap;
  font-size: initial;
}
.bulk-actions-header-row__action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
}
@media screen and (max-width: 1920px) {
  .bulk-actions-header-row__action-buttons {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 1920px) {
  .bulk-actions-header-row__action-buttons .actions-dropdown__button {
    margin-right: 1rem;
  }
}
.bulk-actions-header-row__action-buttons--with-bulk-actions {
  justify-content: flex-end;
}
@media screen and (max-width: 1220px) {
  .bulk-actions-header-row__action-buttons__sort .filter-menu__name,
  .bulk-actions-header-row__action-buttons__filter .filter-menu__name {
    display: none;
  }
}
.bulk-actions-header-row__action-buttons__sort__menu-item {
  padding: 0.5rem !important;
}
.bulk-actions-header-row__action-buttons__sort-filter-panel {
  display: flex;
}
.bulk-actions-header-row__action-buttons__filter {
  margin-left: 1rem;
}
.bulk-actions-header-row__action-buttons__filter__header {
  font-size: 1rem;
}
.bulk-actions-header-row__action-buttons__filter__header__select-all {
  margin-right: 1rem;
}
.actions-dropdown {
  display: none;
}
.actions-dropdown .actions-label {
  font-size: 0.875rem;
}
.actions-dropdown .dropdown-toggle {
  padding: 0.875rem 0.25rem;
  height: 1.5rem;
  width: 2rem;
  background: #f7f8fa;
}
.actions-dropdown .dropdown-menu {
  right: 0;
  left: auto;
  min-width: 200px;
  border: 0;
  padding: 0 0.5rem;
}
.actions-dropdown .dropdown-menu a,
.actions-dropdown .dropdown-menu button {
  display: flex;
  color: #008dcc;
  margin: 0.5rem 0;
  justify-content: start;
  padding-left: 0.5rem;
  background-color: transparent;
  width: 100%;
}
.actions-dropdown .dropdown-menu a:hover,
.actions-dropdown .dropdown-menu a:focus,
.actions-dropdown .dropdown-menu button:hover,
.actions-dropdown .dropdown-menu button:focus {
  background-color: #f7f8fa;
}
.actions-dropdown .dropdown-menu a .sds-button__text,
.actions-dropdown .dropdown-menu button .sds-button__text {
  text-align: left;
}
.actions-dropdown .dropdown-menu a.dropdown-item--destructive,
.actions-dropdown .dropdown-menu button.dropdown-item--destructive {
  color: #ba3037;
}
.actions-dropdown .dropdown-menu a.dropdown-item--destructive:hover,
.actions-dropdown .dropdown-menu a.dropdown-item--destructive:focus,
.actions-dropdown .dropdown-menu button.dropdown-item--destructive:hover,
.actions-dropdown .dropdown-menu button.dropdown-item--destructive:focus {
  background: #fcebec;
}
.actions-dropdown .dropdown-menu a.dropdown-item--disabled,
.actions-dropdown .dropdown-menu button.dropdown-item--disabled {
  color: rgba(51, 66, 79, 0.45);
}
.actions-dropdown--inline {
  display: inline-block;
}
@media screen and (max-width: 1920px) {
  .actions-dropdown {
    display: inline-block;
  }
}
@media screen and (max-width: 1920px) {
  .bulk-actions-row {
    display: none;
  }
}
.available-event-type-alert {
  margin-bottom: 1rem;
  width: fit-content;
  display: inline-flex;
}
.available-event-type-alert .btn-link {
  color: inherit;
  text-decoration: underline;
}
.order-cancel-modal {
  font-size: 1rem;
}
.order-cancel-modal div ul.red {
  list-style-type: none;
  margin-left: 1rem;
  padding-left: 1rem;
}
.order-cancel-modal div ul.red li::before {
  content: "•";
  color: #e93d45;
  font-weight: bold;
  display: inline-block;
  width: 1.5rem;
  margin-left: -1.5rem;
}
.register-returns-interest-modal {
  font-size: 1rem;
}
.register-returns-interest-modal .text-block {
  font-size: 1rem;
}
.order-merge-label-modal {
  font-size: 1rem;
}
.order-merge-label-modal .print-label-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.order-merge-label-modal .print-label {
  overflow: visible;
  display: flex;
  flex-wrap: nowrap;
}
.order-merge-label-modal .print-label:before,
.order-merge-label-modal .print-label:after {
  content: "";
  display: table;
}
.order-merge-label-modal .print-label:after {
  clear: both;
}
.order-merge-label-modal .btn-close {
  display: block;
  position: relative;
  top: 2px;
  top: 0.125rem;
}
.order-merge-label-modal .btn-print {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 0.875rem;
  line-height: 22px;
  background: #ff6800;
  float: left;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  margin-right: 0 !important;
}
.order-merge-label-modal .btn-label-options {
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #34424f;
  color: white;
  font-size: 11px;
  font-weight: normal;
  line-height: 22px;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.order-merge-label-modal .btn-label-options .caret {
  margin-left: 0.25rem;
}
.order-merge-label-modal .label-options {
  float: left;
}
.order-merge-label-modal .label-options .dropdown-menu {
  right: 0.5rem;
}
.order-merge-label-modal .label-options li a {
  text-align: right;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
}
.order-merge-label-modal .label-options li a .option-label {
  float: left;
}
.order-merge-label-modal .label-options li a .icon,
.order-merge-label-modal
  .label-options
  li
  a
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .order-merge-label-modal
  .label-options
  li
  a
  form:before,
.order-merge-label-modal .label-options li a #support #modal-search form:before,
#support #modal-search .order-merge-label-modal .label-options li a form:before,
.order-merge-label-modal
  .label-options
  li
  a
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .order-merge-label-modal
  .label-options
  li
  a
  form:before,
.order-merge-label-modal .label-options li a .navbar-form.search-form:after {
  margin-top: 0.25rem;
}
.order-merge-label-modal .btn-loading {
  margin-right: 0.5rem !important;
  border-radius: 0.5rem;
}
.order-merge-label-modal .btn-loading:before {
  background: #c85200;
}
.loading-screen {
  text-align: center;
  padding: 15px 0 40px 0;
}
.loading-screen .image-award {
  margin: 0 auto 25px auto;
  position: relative;
  left: -10px;
}
.loading-screen h1,
.loading-screen p {
  margin-bottom: 15px;
}
.loading-screen p {
  font-size: 17px;
  color: #9fa7ae;
}
.loading-screen .glyphicon,
.loading-screen .panel-loading:after,
.loading-screen .panel-loading-plain:after,
.loading-screen .btn-loading:after {
  text-align: left;
  font-size: 18px;
  color: #9fa7ae;
}
.image-award {
  display: block;
  background: url(https://assets.sendle.com/assets/dashboard/happy-sendle-doc-2d0afc5053f803a4d63ae70a50c4e9ef9108a4900b304608df68ccd65a42522f.svg)
    no-repeat;
  background-size: 242px 235px;
  height: 235px;
  width: 242px;
}
.panel.panel-form {
  margin: 0 -10px 15px -10px;
  border: 0;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}
.panel.panel-form .panel-body {
  padding-bottom: 8px;
}
@media (min-width: 768px) {
  .panel.panel-form {
    border: 1px solid #ddd;
    margin: 0 0 15px 0;
  }
  .panel.panel-form .panel-body {
    padding: 25px 25px 8px 25px;
  }
}
.account-opt-link {
  line-height: 22px;
  display: block;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .account-opt-link {
    display: inline;
    margin: 0;
    border-left: 1px solid #ddd;
    padding: 0 7px 0 10px;
  }
  .account-opt-link:first-child {
    border-left: 0;
  }
}
.sendle-advantage-section {
  background: #f6f9fc;
  margin: 60px 0 0 0;
  padding: 0 0 80px 0;
}
.sendle-advantage-section .icon,
.sendle-advantage-section #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .sendle-advantage-section form:before,
.sendle-advantage-section #support #modal-search form:before,
#support #modal-search .sendle-advantage-section form:before,
.sendle-advantage-section #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .sendle-advantage-section form:before,
.sendle-advantage-section .navbar-form.search-form:after {
  margin-top: 35px;
}
.sendle-advantage-section.signup {
  margin-top: 30px;
  padding-bottom: 50px;
}
.sendle-advantage-section .sendle-advantage-heading {
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  position: relative;
  padding-bottom: 5px;
}
.sendle-advantage-section .sendle-advantage-heading.sendle {
  background: url(https://assets.sendle.com/assets/dashboard/tick-rosette-36ec90cf7df03c836967a99abe2d6c287df1f390a67006bb9fa6f4c045ca3a70.png)
    center top no-repeat;
  background-size: 50px;
  padding-top: 60px;
  top: -25px;
  margin: 0;
}
.sendle-advantage-section p {
  color: #7a8792;
  font-size: 15px;
  line-height: 24px;
  max-width: 370px;
  margin: -15px auto 0 auto;
}
.sendle-advantage-section .sendle-advantage {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .sendle-advantage-section {
    margin: 80px 0 0 0;
  }
}
.signup-sendle-neto {
  background: url(https://assets.sendle.com/assets/dashboard/sendle-neto-logo-6f9168cad8eeb65bd8b788a7c72c070c848a5dc52ff1cb142db95791d442f20d.png)
    center top no-repeat;
  height: 42px;
  background-size: auto 42px;
  margin-top: 35px;
  margin-bottom: 35px;
}
.signup-nav {
  margin-left: 0;
  padding-bottom: 0;
}
.signup-nav.main.under {
  box-shadow: -1px -1px 6px 1px rgba(200, 200, 200, 0.8);
}
.signup-nav.main.under:before {
  display: none;
}
.logged-out-page {
  padding-bottom: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
}
.navbar-logged-out .navbar-header {
  margin-left: -10px;
}
@media (min-width: 768px) {
  .navbar-logged-out .navbar-header {
    margin-left: -4px;
  }
}
.invalid-white-link {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}
.filter-menu .sds-button__text {
  white-space: nowrap;
}
.filter-menu--inline {
  display: inline;
  font-size: 0.875rem;
}
@media screen and (max-width: 1220px) {
  .filter-menu__name--compress-for-mobile {
    display: none;
  }
}
.filter-menu__button.sds-button,
.pagination li > a.filter-menu__button:not(.sds-button) {
  background-color: #f7f8fa;
}
@media screen and (max-width: 1220px) {
  .filter-menu__button--compress-for-mobile.sds-button,
  .pagination li > a.filter-menu__button--compress-for-mobile:not(.sds-button) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.filter-menu__button--active.sds-button,
.pagination li > a.filter-menu__button--active:not(.sds-button) {
  background-color: #33424f;
  color: #ffffff;
}
.sds-button.filter-menu__button--link,
.pagination li > a.filter-menu__button--link:not(.sds-button) {
  padding: 0;
  background: none;
  color: #008dcc;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: capitalize;
}
.sds-button.filter-menu__button--link svg,
.pagination li > a.filter-menu__button--link:not(.sds-button) svg {
  margin-left: 6px;
  transition: transform 0.2s ease-in-out;
}
.open .sds-button.filter-menu__button--link svg,
.open .pagination li > a.filter-menu__button--link:not(.sds-button) svg,
.pagination .open li > a.filter-menu__button--link:not(.sds-button) svg {
  transform: rotate(-180deg);
}
.sds-button.filter-menu__button--link:hover,
.pagination li > a.filter-menu__button--link:hover:not(.sds-button) {
  background: none;
  text-decoration: underline !important;
  color: #008dcc;
}
.filter-menu__menu-list {
  margin: 0.625rem 0.25rem;
  white-space: nowrap;
  right: 0;
  left: auto;
  min-width: 200px;
}
.filter-menu--inline .filter-menu__menu-list {
  margin-left: 0;
  left: 0;
  right: auto;
}
.filter-menu__header {
  padding: 0.5rem 0 1rem;
  margin: 0 0.5rem 1rem;
  border-bottom: 0.0625rem solid #e9edf0;
}
.filter-menu__footer {
  margin: 0.5rem;
}
.filter-menu__menu-list__menu-item {
  background-color: transparent;
  display: block;
  border: none;
  font-size: 1rem;
  margin: 0.125rem 0.25rem;
  padding: 0 0.25rem;
}
.filter-menu--inline .filter-menu__menu-list__menu-item {
  font-size: 0.875rem;
  padding: 0.5rem;
  margin: 0;
  line-height: 1;
  text-transform: capitalize;
}
.filter-menu__menu-list__menu-item:hover,
.filter-menu__menu-list__menu-item:focus {
  cursor: pointer;
  background-color: #f7f8fa;
}
.filter-menu__menu-list__menu-item--active {
  font-weight: 700;
}
.mobile-footer {
  font-size: 12px;
  padding: 15px 10px 75px 10px;
  text-align: center;
  margin: 40px -10px 0;
  background: #f4f4f4;
}
.mobile-footer .mf-link {
  color: #1f2830;
  margin-right: 8px;
}
.mobile-footer .copyright {
  color: #bbb;
}
.nav-popover {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  width: 300px;
  padding: 25px 45px 33px 45px;
  background: white;
  border: 1px solid rgba(50, 66, 80, 0.3);
  -webkit-box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  z-index: 3;
  top: 60px;
  right: 10px;
  text-align: center;
  animation-duration: 1.8s;
  animation-name: slideUpFadeIn;
  animation-iteration-count: 1;
  animation-easing: cubic-bezier(0.17, 0.67, 0.45);
}
.nav-popover .pointer {
  display: block;
  position: absolute;
  top: -12px;
  left: 12px;
  border-bottom: 12px solid rgba(50, 66, 80, 0.3);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  right: 8px;
  left: auto;
  right: 9px;
}
.nav-popover .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.nav-popover .pointer:before {
  top: 1px;
  left: -12px;
  border-bottom: 12px solid white;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.nav-popover .btn-close-popover {
  color: #b7bdc2;
  font-size: 18px;
  line-height: 35px;
  position: absolute;
  top: 7px;
  right: 19px;
  display: block;
  width: 40px;
  height: 40px;
  text-align: right;
}
.nav-popover .btn-close-popover:hover {
  color: #8e979f;
  text-decoration: none;
}
.nav-popover .stamp {
  margin: 0 auto 5px auto;
}
.nav-popover .intro {
  font-size: 17px;
  line-height: 24px;
  margin: 0 0 15px 0;
}
.nav-popover .intro strong {
  display: block;
}
.nav-popover .info {
  color: #b7bdc2;
  line-height: 22px;
}
@media (min-width: 768px) {
  .nav-popover {
    right: 36px;
  }
  .nav-popover .pointer {
    right: 13px;
  }
}
.navbar-header {
  padding: 5px 0 0 0;
}
@media (min-width: 768px) {
  .navbar-header {
    padding: 10px 0 11px 0;
  }
}
.navbar-brand {
  width: 148px;
  height: 45px;
  padding: 15px;
  margin-top: 6px;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  background: url(https://assets.sendle.com/assets/logos/logo-bt-oa-41facab210a6137c7ab943ddbba580174c32b64f19d22d39effbc2cb4e89225b.svg)
    center center no-repeat;
  background-size: 130px 36px;
}
@media (min-width: 768px) {
  .navbar-brand {
    width: 160px;
    margin-bottom: 7px;
    margin-top: 0;
  }
}
.navbar-form.search-form {
  display: block;
  padding: 10px 15px 11px 15px;
  position: relative;
}
.navbar-form.search-form:after {
  content: "";
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}
.navbar-form.search-form input[type="search"] {
  width: 100%;
  font-size: 12px;
  line-height: 20px;
}
.navbar-form.search-form .twitter-typeahead {
  width: 100%;
}
@media (min-width: 768px) {
  .navbar-form.search-form {
    width: 42%;
    padding: 10px 15px 11px 79px;
  }
}
@media (min-width: 992px) {
  .navbar-form.search-form {
    width: 44%;
  }
}
.navbar-nav {
  margin: 0px -15px;
}
.navbar-nav li .profile-link {
  font-size: 12px;
  line-height: 30px;
}
.navbar-nav li .profile-link .profile-image {
  display: inline-block;
  float: left;
  margin-right: 6px;
}
.navbar-nav .open .dropdown-menu > li > a,
.navbar-nav .open .dropdown-menu > li > .disabled-text {
  padding-left: 45px;
}
@media (min-width: 768px) {
  .navbar-nav {
    padding-right: 5px;
  }
  .navbar-nav li .profile-link {
    padding: 20px 15px 21px 15px;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu > li > .disabled-text {
    padding-left: 25px;
  }
}
.navbar-default .locale-toggle {
  font-size: 0.75rem;
  padding: 0.5rem;
}
.navbar-default .navbar-toggle {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  border-radius: 2px;
  background: #f4f4f4;
  border: 0;
  margin: 10px 7px 0 10px;
  padding: 9px 10px 20px 10px;
  z-index: 3;
}
.navbar-default .navbar-toggle:active,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background: #f4f4f4;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #ff6800;
}
.navbar-default .navbar-toggle.collapsed {
  padding: 13px 10px;
  background: white;
}
.navbar-default .navbar-collapse {
  background: #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  box-shadow: 0px 3px 2px rgba(51, 66, 79, 0.12);
  max-height: none;
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .navbar-default .navbar-collapse {
    margin-right: initial;
    margin-left: initial;
  }
}
.navbar-default .nav-items__center-items {
  display: flex;
  align-items: center;
  position: absolute;
  top: 14px;
  left: 151px;
  z-index: 2;
}
@media (min-width: 768px) {
  .navbar-default {
    border-bottom: 0;
  }
  .navbar-default .nav-items {
    display: flex;
    justify-content: space-between;
  }
  .navbar-default .nav-items__center-items {
    position: unset;
    justify-content: space-between;
    flex-grow: 1;
    margin-right: 0.5rem;
  }
  .navbar-default .navbar-collapse {
    background: white;
    border-bottom: 0;
    box-shadow: none;
  }
}
.dropdown-menu > li > a {
  padding: 6px 20px;
}
@media (max-width: 766px) {
  .navbar-collapse {
    max-height: calc(100vh - 60px) !important;
  }
  .navbar-nav.navbar-right .profile-link .caret {
    display: none;
  }
  .navbar-nav.navbar-right .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    display: block;
    padding-top: 0;
  }
  .navbar-nav.navbar-right .dropdown-menu li a {
    padding-top: 0;
    padding-left: 45px;
  }
}
@keyframes upanddown {
  0% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(2px);
  }
}
.new-order-summary {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.new-order-summary .panel-formgroup {
  margin: 0 0 1.5rem 0;
}
@media (min-width: 768px) {
  .new-order-summary .panel-formgroup .panel-heading,
  .new-order-summary .panel-formgroup .panel-body {
    padding: 0 1rem 0 0;
  }
}
.new-order-summary .panel-body {
  margin: 0 0 0 1rem;
}
.new-order-summary .summary-item {
  margin-bottom: 6px;
  position: relative;
  left: -14px;
}
.new-order-summary .summary-item:before {
  content: "";
  width: 25px;
  height: 25px;
  margin: 0 1rem 0 0;
  position: relative;
  top: 0.25rem;
  float: left;
  background: url(./assets/icons.svg)
    no-repeat;
  background-size: 1650px 100px;
  background-position: -1236px 0;
}
.new-order-summary .summary-item.complete:before {
  background-position: -1236px -40px;
}
.new-order-summary .summary-panel {
  border: 1px solid #f7f8fa;
  padding: 0.5rem 0 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
}
.new-order-summary .summary-panel .submit-group .glyphicon-refresh {
  font-size: 1.25rem;
  margin: 3px 2px 0 5px;
}
.new-order-summary .summary-label {
  color: #33424f;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin: 0;
  font-weight: bold;
  display: inline-block;
  position: relative;
  width: 90px;
}
.new-order-summary .summary-value {
  display: block;
  overflow: hidden;
  padding-bottom: 1rem;
  margin: 0 0 7px 40px;
  border-bottom: 1px solid #e9edf0;
  text-align: right;
}
.new-order-summary .summary-value > span {
  float: left;
}
.new-order-summary .last .summary-value {
  border-bottom: 0;
}
.new-order-summary .summary-error {
  color: #ba3037;
  padding: 0px 1rem 1rem;
}
.new-order-summary .summary-error a {
  color: #ba3037;
  display: block;
  text-decoration: underline;
  margin-top: 0.25rem;
}
.new-order-summary .summary-total {
  border-top: 1px solid #f7f8fa;
  background: #f9f8f7;
  padding: 10px 1rem 4px 1rem;
  text-align: right;
}
.new-order-summary .summary-total .quote-line-items {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.new-order-summary .summary-total .quote-line-items .quote-line-item {
  text-align: left;
  margin-bottom: 0.5rem;
}
.new-order-summary .summary-total .quote-line-items .quote-line-item .price {
  font-weight: bold;
  float: right;
}
.new-order-summary .unselected {
  color: #b4bfc9;
}
.new-order-summary .surcharge-message,
.new-order-summary .order-form-guaranteed-delivery {
  color: #33424f;
  text-align: center;
}
.new-order-summary .surcharge-message {
  padding: 10px 8px 0 8px;
}
@media (min-width: 992px) {
  .new-order-summary {
    width: 250px;
  }
  .new-order-summary .panel-heading {
    margin-left: 1rem;
  }
}
.form .new-order-summary .form-group.submit-group {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 0;
  border: 0;
  padding: 0 1rem 1rem 1rem;
  background: #f9f8f7;
}
.order-form-quote .message {
  font-size: 12px;
}
.order-form-quote .message.failed {
  color: #e44144;
}
.order-form-quote .line {
  overflow: hidden;
  text-align: right;
  font-size: 1.25rem;
}
.order-form-quote .description {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}
.order-form-quote .price {
  font-size: 1.25rem;
  margin: 0 2px 0 5px;
}
.order-form-quote .price.empty {
  color: #9fa7ae;
  font-weight: normal;
}
.order-form-guaranteed-delivery {
  margin: 2rem 0 1rem 0;
  font-size: 0.875rem;
}
.order-form-guaranteed-delivery .badge-eligible {
  display: block;
  height: 48px;
  margin: 0 auto;
}
.order-form-guaranteed-delivery .badge-eligible {
  background: url(https://assets.sendle.com/assets/dashboard/delivery-gtee-eligible-icon-6c888f52ce41f5224348b041ea3a5082ca17443675a2dce3fe174bece5854731.svg)
    no-repeat top center;
  background-size: auto 48px;
}
.order-form-guaranteed-delivery .message-header {
  margin-top: 0.5rem;
  font-weight: bold;
  text-align: left;
  font-size: 0.875rem;
  color: #33424f;
  display: block;
  margin-left: 2rem;
}
.order-form-guaranteed-delivery .message-header .icon-badge-eligible {
  display: inline-block;
}
.order-form-guaranteed-delivery .message-header span {
  margin-left: 0.25rem;
  position: relative;
  display: inline-block;
}
.order-form-guaranteed-delivery .message-body {
  text-align: left;
  padding: 0 1rem;
  margin-top: 0.5rem;
}
.order-form-guaranteed-delivery .message-body a {
  color: #008dcc;
}
.order-form-guaranteed-delivery.australia .badge-ineligible {
  display: block;
  height: 48px;
  margin: 0 auto;
}
.order-form-guaranteed-delivery.australia .message-header {
  margin-top: 5px;
  font-weight: bold;
  text-align: center;
  margin-left: 0;
  color: #82909b;
  display: block;
}
.order-form-guaranteed-delivery.australia .message-body {
  text-align: center;
}
.order-form-guaranteed-delivery.australia .message-body a {
  color: #008dcc;
}
.order-form-guaranteed-delivery.australia .badge-ineligible {
  background: url(https://assets.sendle.com/assets/dashboard/delivery-gtee-ineligible-icon-1dfa127e8d2cd30cf1209fd64017f37913ede46927aa4858e818757965a5aa40.svg)
    no-repeat top center;
  background-size: auto 48px;
}
.order-form-guaranteed-delivery.australia.ineligible {
  color: #ba3037;
}
.order-form-guaranteed-delivery.australia.ineligible a {
  color: inherit;
  text-decoration: underline;
}
div.row.unified .summary-panel {
  border: none;
  border-left: 4px solid #b4bfc9;
  border-radius: 0px;
  padding: 0.5rem 0 0 0;
  background-color: #f7f8fa;
  font-size: 0.875rem;
}
div.row.unified .summary-panel .submit-group .glyphicon-refresh {
  font-size: 1.25rem;
  margin: 3px 2px 0 5px;
}
div.row.unified .summary-panel .btn-submit {
  float: right;
}
div.row.unified .summary-panel .summary-item {
  margin-bottom: 6px;
  position: relative;
  left: -14px;
}
div.row.unified .summary-panel .summary-item:before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 6px 1rem 0 0;
  float: none;
  background: url(./assets/box.svg)
    no-repeat;
  background-size: 840px 502px;
  background-position: 0 -462px;
}
div.row.unified .summary-panel .summary-item.complete:before {
  background-position: -48px -462px;
}
div.row.unified .summary-panel.complete {
  border-left-color: #62cccc;
}
div.row.unified .summary-panel.complete .summary-item.complete:before {
  background-position: -72px -462px;
}
div.row.unified .summary-quick-edit {
  position: relative;
  display: inline-block;
  text-align: right;
  width: calc(100% - 130px);
}
div.row.unified .summary-value {
  display: block;
  overflow: hidden;
  padding-bottom: 1rem;
  margin: 0 0 0 40px;
  border-bottom: 1px solid #e9edf0;
  text-align: left;
  position: relative;
}
div.row.unified .summary-value > span {
  float: left;
}
div.row.unified .summary-total {
  border: none;
  background-color: #f7f8fa;
  padding: 0.5rem 1rem 0.25rem 1rem;
  text-align: right;
}
div.row.unified .summary-total .quote-line-items {
  padding: 0 0 0.25rem 1rem;
  margin-bottom: 0.25rem;
  color: #7a8691;
}
div.row.unified .summary-total .quote-line-items .display {
  font-size: 11px;
  text-align: right;
}
div.row.unified .summary-total .quote-line-items .quote-line-item {
  text-align: right;
  margin-bottom: 0.25rem;
}
div.row.unified .summary-total .quote-line-items .quote-line-item .price {
  font-weight: bold;
  float: right;
  font-size: 0.875rem;
  padding-left: 0.25rem;
  color: #33424f;
}
div.row.unified .summary-total .quote-line-items .quote-line-item .type {
  color: #33424f;
}
div.row.unified
  .summary-total
  .quote-line-items
  .quote-line-item.delivery
  .type {
  font-size: 0.875rem;
}
div.row.unified
  .summary-total
  .quote-line-items
  .quote-line-item.delivery
  .price {
  font-size: 1rem;
}
div.row.unified
  .summary-total
  .quote-line-items
  .quote-line-item.discount
  .type {
  font-size: 0.875rem;
}
div.row.unified
  .summary-total
  .quote-line-items
  .quote-line-item.discount
  .price {
  font-size: 0.875rem;
  color: #5bc976;
}
div.row.unified
  .summary-total
  .quote-line-items
  .quote-line-item.warranty
  .type {
  font-size: 0.875rem;
}
div.row.unified
  .summary-total
  .quote-line-items
  .quote-line-item.warranty
  .price:before {
  content: "+";
}
div.row.unified
  .summary-total
  .quote-line-items
  .quote-line-item.warranty
  .price {
  font-size: 0.875rem;
}
div.row.unified .form .new-order-summary .form-group.submit-group {
  border-radius: 0px;
  margin: 0;
  border: 0;
  padding: 0 1rem 1rem 1rem;
  text-align: center;
}
div.row.unified .order-form-quote .currency {
  font-size: 13pt;
  display: inline;
  margin-right: -3px;
}
div.row.unified .order-form-quote .currency span {
  display: inline-block;
}
div.row.unified .order-form-quote .price {
  font-size: 24px;
  margin: 0 2px 0 5px;
  color: #33424f;
}
div.row.unified .order-form-quote .price.empty {
  color: #33424f;
  font-weight: normal;
}
div.row.unified .order-form-quote span {
  display: none;
}
.no-results {
  font-size: 1rem !important;
  text-align: center;
}
.no-results__reset-link {
  display: block;
  margin-top: 0.5rem;
}
@keyframes attention-seek {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  60% {
    transform: scale(0, 0);
    opacity: 0;
  }
  80% {
    transform: scale(1.8, 1.8);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes delay-display {
  0% {
    visibility: hidden;
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.notifications-toggle {
  display: inline-block;
  border: 1px solid #e9edf0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  height: 30px;
  width: 31px;
}
.notifications-toggle:hover {
  border-color: #cbd4db;
}
.notifications-toggle:before {
  content: "";
  width: 17px;
  height: 18px;
  margin: 5px auto;
  display: inline-block;
  background: url(https://assets.sendle.com/assets/dashboard/notifications-sprite-6a0c323f0dc752344f9874bd77f8fd9b9e5872507b35c7d465e7b354d23a872c.svg)
    no-repeat;
  background-size: 54px 34px;
  background-position: -8px -8px;
}
@media (min-width: 768px) {
  .notifications-toggle {
    height: 41px;
    width: 41px;
  }
  .notifications-toggle:before {
    margin: 10px auto;
  }
}
.notifications-unread .notifications-toggle:hover {
  border-color: #828f9c;
}
.notifications-unread .notifications-toggle:before {
  background-size: 54px 34px;
  background-position: -30px -8px;
}
.notifications-badge {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  padding: 4px 7px 5px 7px;
  position: relative;
  left: -3px;
  top: -12px;
  background: #e44144;
  color: white;
  animation: attention-seek 1s ease-out;
  animation-iteration-count: 1;
}
@media (min-width: 768px) {
  .notifications-badge {
    left: -5px;
    top: -18px;
  }
}
.notifications-popover {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -khtml-border-radius: 6px;
  border-radius: 6px;
  position: fixed;
  z-index: 3;
  width: 98vw;
  left: 1vw;
  margin-top: 45px;
  -webkit-box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
}
.notifications-popover .pointer {
  display: block;
  position: absolute;
  top: -12px;
  left: 12px;
  border-bottom: 12px solid rgba(50, 66, 80, 0);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: 8px;
  left: 195px;
}
.notifications-popover .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.notifications-popover .pointer:before {
  top: 1px;
  left: -12px;
  border-bottom: 12px solid #324250;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.notifications-popover .title {
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 18px 26px;
  background: #324250;
  color: white;
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .notifications-popover {
    width: 300px;
    position: absolute;
    left: 0;
    margin-top: 45px;
  }
  .notifications-popover .pointer {
    left: 13px;
  }
}
.notifications-popover .inner {
  background: white;
  border: 1px solid rgba(50, 66, 80, 0.3);
  border-top: none;
  -webkit-border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.notifications-popover .loading {
  padding: 30px 0px;
  color: #324250;
  text-align: center;
  animation: delay-display 1s;
  animation-iteration-count: 1;
  margin: 0 26px;
  border-top: 1px solid #e9edf0;
}
.notifications-popover .loading:first-child {
  padding: 40px 0px;
  border: none;
}
.notifications-popover .loading:first-child .glyphicon,
.notifications-popover .loading:first-child .panel-loading:after,
.notifications-popover .loading:first-child .panel-loading-plain:after,
.notifications-popover .loading:first-child .btn-loading:after {
  font-size: 15px;
  line-height: 17px;
  height: 15px;
}
.notification-list {
  -webkit-border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow-y: auto;
  max-height: 75vh;
}
.notification-list ol {
  list-style-type: none;
  margin: 0;
  padding: 0 0 10px 0;
}
.notification-list .empty {
  margin: 25px 20px 30px 20px;
  text-align: center;
}
.notification-list .empty h4 {
  font-size: 14px;
  font-weight: normal;
  color: #555555;
  margin: 0 0 15px 0;
}
.notification-list .empty p {
  font-size: 12px;
  color: #9fa7ae;
}
@media (min-width: 768px) {
  .notification-list {
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
  }
}
.notification-item {
  padding: 15px 26px 0 26px;
  cursor: pointer;
  position: relative;
}
.notification-item .date {
  display: inline-block;
  color: #b7bdc2;
  margin-bottom: 5px;
}
.notification-item .status {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  top: 21px;
  left: 9px;
}
.notification-item .status-unread {
  background: #009de3;
  border: 1px solid #0ea0d8;
  -webkit-box-shadow: inset 0px 1px 2px 0px #00d1ef;
  -moz-box-shadow: inset 0px 1px 2px 0px #00d1ef;
  box-shadow: inset 0px 1px 2px 0px #00d1ef;
}
.notification-item .message {
  line-height: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9edf0;
}
.notification-item:last-child .message {
  border-bottom: none;
}
.notification-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: transparent;
  border: 0.0625rem solid #b4bfc9;
  border-radius: 0.25rem;
  color: #b4bfc9;
}
.notification-button__icon {
  height: 1.25rem;
}
.notification-button__badge,
#support-modal .unread-badge {
  position: absolute;
  top: -12%;
  transform: translate(110%, 0%);
  min-width: 1rem;
  font-size: 0.75rem;
  font-weight: normal;
  text-align: center;
  line-height: 1;
  color: #ffffff;
  background: #ff6800;
  border-radius: 0.25rem;
  padding: 0.125rem;
  box-shadow: 0 0 0 2px #ffffff;
  text-shadow: 1px 1px 1px rgba(51, 66, 79, 0.3);
}
.notification-button__badge:empty,
#support-modal .unread-badge:empty {
  display: none;
}
@media (min-width: 768px) {
  .notification-button__badge,
  #support-modal .unread-badge {
    transform: translate(120%, 120%);
  }
}
.notification-button:hover,
.notification-button:focus {
  color: #6d7883;
  border-color: currentColor;
}
.notification-button--unread {
  color: #ff6800;
}
.notification-button--unread:hover,
.notification-button--unread:focus {
  color: #ff6800;
  border-color: currentColor;
}
.notification-badge {
  color: #ffffff;
  background-color: #ff6800;
  border-radius: 0.5rem;
}
.notification-feed {
  padding: 0 1rem 3.5rem;
}
.notification-feed ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.notification-feed--empty {
  margin-top: 5rem;
}
.notification-feed--empty svg path,
.notification-feed--empty svg ellipse,
.notification-feed--empty svg circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: emptyIllustrationDash 1s ease-in forwards,
    emptyIllustrationFill 0.5s ease-in forwards;
}
.notification-feed--empty button {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 0;
  color: #008dcc;
}
.notification-feed--empty button:focus:not(:focus-visible),
.notification-feed--empty button:active {
  outline: none;
}
.notification-feed--empty button:hover {
  text-decoration: underline;
}
.notification-feed__controls {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #f7f8fa;
  font-size: 0.875rem;
}
.notification-feed__controls button {
  text-align: left;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 0;
  color: #008dcc;
}
.notification-feed__controls button:focus:not(:focus-visible),
.notification-feed__controls button:active {
  outline: none;
}
.notification-feed__controls button:hover:not(:disabled) {
  text-decoration: underline;
}
.notification-feed__controls button:disabled,
.notification-feed__controls
  .select2-container.select2-container--sds.select2-container--disabled
  button.select2-selection__rendered,
.select2-container.select2-container--sds.select2-container--disabled
  .notification-feed__controls
  button.select2-selection__rendered {
  color: #6d7883;
}
.notification-feed__footer {
  display: flex;
  align-items: center;
}
.notification-feed__footer--hidden {
  visibility: hidden;
}
.notification-feed-item {
  padding: 1rem 0.5rem;
  border-bottom: 0.0625rem solid #e9edf0;
  display: flex;
  position: relative;
  color: #33424f;
}
.notification-feed-item:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.notification-feed-item:hover {
  cursor: pointer;
}
.notification-feed-item:hover .notification-feed-item__indicator {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 0 0 0.25rem #ffefe5;
}
.notification-feed-item__indicator {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: #ff6800;
}
.notification-feed-item__body {
  width: 100%;
  font-size: 0.875rem;
}
.notification-feed-item--read {
  color: #6d7883;
}
.notification-feed-item--read .notification-feed-item__indicator {
  background-color: #b4bfc9;
}
.notification-feed-item--read:hover {
  cursor: initial;
}
.notification-feed-item--read:hover .notification-feed-item__indicator {
  box-shadow: none;
}
.notification-feed-item--skeleton {
  pointer-events: none;
  border-color: #f7f8fa;
}
.notification-feed-item--skeleton .notification-feed-item__body * {
  color: transparent;
  background: #f7f8fa;
  border-radius: 0.25rem;
}
.notification-feed-item--skeleton .notification-feed-item__indicator {
  background: #f7f8fa;
}
.notification-feed-item--skeleton:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #fff 50%,
    transparent 100%
  );
  animation: shineEffect 1s ease-in-out infinite;
}
@keyframes shineEffect {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}
#support-modal .unread-badge {
  top: 0;
  right: auto;
  transform: translate(33%, -50%);
}
@media screen and (max-width: 500px) {
  .table--collapse-mobile {
    border-top: 0.5rem solid #e9edf0;
  }
  .table--collapse-mobile.sds-table--bold {
    border-top: 0.5rem solid #33424f;
  }
  .table--collapse-mobile.sds-table--subtle {
    border-top-width: 0.0625rem;
  }
  .table--collapse-mobile.sds-table--headless {
    border-top-width: 0.0625rem;
  }
  .table--collapse-mobile .sds-table__table {
    display: block;
  }
  .table--collapse-mobile .sds-table__table thead,
  .table--collapse-mobile .sds-table__table tbody,
  .table--collapse-mobile .sds-table__table tfoot {
    display: block;
  }
  .table--collapse-mobile .sds-table__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .table--collapse-mobile .sds-table__row .sds-table__cell:first-of-type,
  .table--collapse-mobile .sds-table__row .sds-table__header:first-of-type {
    padding-left: 1rem;
  }
  .table--collapse-mobile .sds-table__row .sds-table__cell:last-of-type,
  .table--collapse-mobile .sds-table__row .sds-table__header:last-of-type {
    padding-right: 1rem;
  }
  .table--collapse-mobile .sds-table__row--header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  .table--collapse-mobile .sds-table__cell {
    flex-basis: 100%;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .table--collapse-mobile .sds-table__cell:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .table--collapse-mobile.sds-table--spacious .sds-table__row {
    padding: 0.75rem 0;
  }
  .table--collapse-mobile.sds-table--spacious .sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .table--collapse-mobile.sds-table--compact .sds-table__row {
    padding: 0.25rem 0;
  }
  .table--collapse-mobile.sds-table--compact .sds-table__cell {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
@media screen and (max-width: 500px) {
  .notification-preference-cell {
    text-align: left;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 500px) {
  td.notification-preference-copy {
    padding-bottom: 0;
  }
}
@keyframes emptyIllustrationFill {
  from {
    fill: transparent;
  }
}
@keyframes emptyIllustrationDash {
  to {
    stroke-dashoffset: 0;
  }
}
.order-confirmation {
  padding: 27px 10px;
}
.order-confirmation .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.order-confirmation .panel-heading {
  margin-top: 0;
}
.order-confirmation h3.panel-heading {
  margin-bottom: 5px;
}
.order-confirmation .delivery-guarantee {
  display: block;
}
.order-confirmation .delivery-guarantee .delivery-guarantee-ineligible {
  color: #e44144;
  font-weight: bold;
}
.order-confirmation .delivery-guarantee a {
  color: #008dcc;
}
.order-confirmation .img-map {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
}
@media (min-width: 768px) {
  .order-confirmation {
    padding: 25px 30px;
  }
}
@media (min-width: 992px) {
  .order-confirmation {
    padding: 50px 80px;
  }
}
.order-basic-details .icon,
.order-basic-details #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .order-basic-details form:before,
.order-basic-details #support #modal-search form:before,
#support #modal-search .order-basic-details form:before,
.order-basic-details #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .order-basic-details form:before,
.order-basic-details .navbar-form.search-form:after,
.order-basic-details .icon-international {
  float: left;
  margin: 0 15px 0 0;
}
.order-basic-details .parcel-size-name {
  font-weight: bold;
  display: block;
  margin-top: 7px;
}
.order-basic-details .customer-ref {
  color: #9fa7ae;
  margin-left: 5px;
}
.address-summary {
  max-width: 330px;
  margin: 0 auto;
  position: relative;
}
.address-summary:first-child {
  margin-bottom: 50px;
}
.address-summary .img-responsive {
  margin-bottom: 15px;
}
.address-summary .name {
  display: block;
  color: #33424f;
  padding: 3px 0;
}
.address-summary .empty {
  color: #ccc;
}
.address-summary .email,
.address-summary .phone,
.address-summary .address,
.address-summary .instructions-detail {
  padding-left: 20px;
  position: relative;
  margin-bottom: 6px;
}
.address-summary .email:before,
.address-summary .phone:before,
.address-summary .address:before,
.address-summary .instructions-detail:before {
  content: "";
  display: block;
  position: absolute;
  background: url(https://assets.sendle.com/assets/dashboard/order-confirmation-set-0a5c8551a37a47e72f63fe4b3053439793010534f810f3379559b37d4ecd3b13.svg)
    no-repeat;
  background-size: 65px 32px;
}
.address-summary .email:before {
  background-position: -24px -23px;
  height: 9px;
  width: 14px;
  left: 0;
  top: 5px;
}
.address-summary .phone:before {
  background-position: -54px -17px;
  height: 15px;
  width: 10px;
  left: 1px;
  top: 1px;
}
.address-summary .instructions-header {
  color: #9fa7ae;
  font-weight: normal;
  padding: 0 0 0 20px;
  margin: 25px 0 10px 0;
  font-size: 13px;
}
.address-summary .instructions-detail:before {
  background-position: -40px -24px;
  height: 8px;
  width: 12px;
  top: 5px;
  left: 0;
}
.address-summary .address p {
  margin: 0 0 4px 0;
}
.address-summary .address:before {
  background-position: -54px 0;
  height: 15px;
  width: 11px;
  left: 1px;
  top: 1px;
}
@media (min-width: 768px) {
  .address-summary {
    float: left;
    width: 48%;
    max-width: auto;
  }
  .address-summary:first-child {
    margin: 0 4% 0 0;
  }
}
@media (min-width: 992px) {
  .address-summary {
    width: 330px;
    position: relative;
  }
  .address-summary:first-child {
    margin: 0 78px 0 0;
  }
  .address-summary:first-child:after {
    content: "";
    position: absolute;
    background: url(https://assets.sendle.com/assets/dashboard/order-confirmation-set-0a5c8551a37a47e72f63fe4b3053439793010534f810f3379559b37d4ecd3b13.svg)
      no-repeat;
    background-size: 65px 32px;
    background-position: -24px 0;
    height: 20px;
    width: 30px;
    right: -55px;
    top: 118px;
  }
}
.unified .order-confirmation-modal .modal-dialog {
  max-width: 750px;
}
.unified .order-confirmation-modal .modal-content {
  border-radius: 2px;
  max-width: 750px;
}
.unified .order-confirmation-modal .modal-content .christmas-notice {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  background: transparent;
  margin-bottom: 24px;
  padding: 8px 8px 8px 24px;
  color: #33424f;
  border: 1px solid #ffd400;
  border-radius: 2px;
}
.unified .order-confirmation-modal .modal-content .christmas-notice:before {
  content: "";
  position: absolute;
  width: 8px;
  left: 8px;
  top: 8px;
  bottom: 8px;
  background: #ffd400;
  border-radius: 2px;
}
.unified .order-confirmation-modal .modal-content .christmas-notice .content {
  color: inherit;
}
.unified
  .order-confirmation-modal
  .modal-content
  .christmas-notice
  .content:last-of-type {
  margin-bottom: 0;
}
.unified .order-confirmation-modal .modal-content .order-confirmation {
  padding: 32px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .btn-close {
  border-radius: 2px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .order-details {
  border-bottom: 1px solid #ddd;
  margin: 0 0 16px 0;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .order-details
  .weight-column {
  min-width: 56px;
  max-width: 76px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .order-details
  .weight-column
  .parcel-size {
  margin: 0 4px;
  width: auto;
  height: auto;
  margin: 0;
  border: none;
  padding: 0;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .order-details
  .parcel-selection-name {
  font-weight: bold;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .order-details
  .parcel-selection-weight,
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .order-details
  .parcel-selection-litres {
  color: #7a8691;
  font-size: 13px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .order-details
  .order-description {
  font-weight: bold;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container {
  border-bottom: 1px solid #ddd;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container
  .name {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 4px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container
  .phone,
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container
  .email {
  display: inline-block;
  margin: 0 10px 8px 0;
  font-size: 15px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container
  .address {
  margin: 0 10px 8px 0;
  font-size: 15px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container
  .address
  div {
  display: inline-block;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container
  .address
  span.no-break {
  white-space: nowrap;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .address-summary-container
  .instructions {
  font-size: 13px;
  margin-bottom: 16px;
}
.unified .order-confirmation-modal .modal-content .order-confirmation .img-map {
  border-radius: 2px;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
  max-width: 120px;
  max-height: 120px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .schedule-container {
  font-size: 15px;
  border-bottom: 1px solid #ddd;
  margin: 0 0 16px 0;
  padding: 0 0 15px 0;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .schedule-container
  .panel-icon {
  width: 56px;
  height: 44px;
  display: inline-block;
  margin: 10px 4px;
  vertical-align: top;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .schedule-container
  .panel-icon.delivery-truck {
  margin-right: 16px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .schedule-container
  .panel-icon.first-mile-option-drop-off {
  margin-right: 16px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .schedule-container
  .panel-icon.first-mile-option-drop-off:hover:before {
  background-position: -616px 0;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .schedule-container
  .panel-body {
  padding-top: 16px;
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 80px);
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .payment-method-container {
  font-size: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .payment-method-container
  .panel-icon {
  width: 56px;
  height: 50px;
  display: inline-block;
  margin: 0 4px;
  vertical-align: top;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .payment-method-container
  .panel-icon.payment-method {
  margin-right: 16px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .payment-method-container
  .panel-body {
  display: inline-block;
  vertical-align: top;
  max-width: calc(100% - 80px);
}
@media (max-width: 400px) {
  .unified
    .order-confirmation-modal
    .modal-content
    .order-confirmation
    .payment-method-container
    .panel-body
    .to-da-left {
    margin-left: -90px !important;
    max-width: 245px !important;
  }
}
@media (max-width: 400px) {
  .unified
    .order-confirmation-modal
    .modal-content
    .order-confirmation
    .payment-method-container
    .panel-body
    .to-da-left-btn {
    margin-left: -90px !important;
  }
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .confirmation-actions-container {
  text-align: right;
  margin-top: 14px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .confirmation-actions-container
  .confirmation-total
  .confirmation-currency {
  font-size: 13pt;
  font-weight: normal;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .confirmation-actions-container
  .confirmation-total
  .confirmation-amount {
  display: block;
  font-weight: bold;
  text-align: right;
  font-size: 24px;
}
.unified
  .order-confirmation-modal
  .modal-content
  .order-confirmation
  .confirmation-actions-container
  .payment-message {
  font-size: 11px;
  font-style: italic;
  margin-top: 8px;
  color: #7a8691;
  text-align: right;
}
.order-header {
  overflow: hidden;
  margin: 0;
}
.order-header .alternate-actions {
  text-align: right;
  display: none;
}
@media (min-width: 768px) {
  .order-header .alternate-actions {
    display: block;
  }
}
.order-status {
  background: #f4f4f4;
  border-top: 2px solid #b4b4b4;
  border-bottom: 2px solid #b4b4b4;
  text-align: center;
  margin: 0 -10px 20px -10px;
  padding: 15px 0 13px 0;
}
.order-status .state-display {
  margin: 0 auto;
  width: 287px;
}
@media (min-width: 768px) {
  .order-status {
    margin: 0 0 20px 0;
    padding: 15px 0;
    min-height: 66px;
  }
  .order-status .state-display {
    width: 480px;
  }
}
.receiver-details h2 {
  margin: 0;
  line-height: 48px;
}
.order-details {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 2px solid #b4b4b4;
}
.order-details dt {
  color: #ff6800;
  float: left;
  width: 60px;
  font-weight: normal;
  padding-top: 13px;
}
.order-details dd {
  margin: 0 0 0 60px;
  overflow: hidden;
}
.order-details hr {
  border-top: 1px solid #ddd;
}
.order-details .name {
  display: block;
  padding: 6px 0 2px 0;
  margin: 0 0 0 70px;
  font-weight: bold;
}
.order-details address {
  margin: 0 0 0 70px;
}
@media (min-width: 768px) {
  .order-details dt {
    width: 100px;
    padding-top: 0;
  }
  .order-details dd {
    margin: 0 0 0 100px;
  }
  .order-details .name {
    margin: 0;
  }
  .order-details address {
    display: inline;
    margin: 0;
  }
}
.order-header .profile-image,
.order-details .profile-image {
  float: left;
  margin: 0 20px 0 0;
}
.order-header .profile-image img,
.order-details .profile-image img {
  width: 50px;
  height: 50px;
}
.order-progress-header {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.warranty-input .help-block {
  color: #d94c4c;
}
.warranty-input .help-block .help-text {
  font-size: 1rem;
  color: #33424f;
}
.warranty-input .help-block a {
  font-size: 0.875rem;
}
.warranty-input .form-group {
  margin-bottom: 0px !important;
}
.warranty-input .warranty-price {
  padding: 32px 0;
  font-size: 1rem;
}
@media (min-width: 1201px) {
  .warranty-input .panel-head-link .panel-help-link {
    margin-top: -25px !important;
  }
}
.parcel-description {
  overflow: hidden;
}
.parcel-description .icon,
.parcel-description #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .parcel-description form:before,
.parcel-description #support #modal-search form:before,
#support #modal-search .parcel-description form:before,
.parcel-description #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .parcel-description form:before,
.parcel-description .navbar-form.search-form:after {
  float: left;
  margin-right: 21px;
}
.parcel-description .parcel-weight {
  display: block;
  padding-top: 6px;
  margin-right: 40px;
  margin-bottom: 8px;
}
.parcel-description .parcel-weight strong {
  display: block;
}
.parcel-description .reference {
  text-align: left;
  padding-top: 11px;
  display: block;
  margin: 0 0 0 70px;
  font-size: 13px;
  color: #9fa7ae;
}
.parcel-description .reference-item {
  display: block;
}
.parcel-description .reference-group {
  display: inline-block;
  width: 100%;
}
.parcel-description .parcel-description-detail {
  display: block;
  padding-top: 2px;
  margin: 0 0 0 70px;
}
.parcel-description .parcel-source-ecommerce {
  width: 70px;
  padding-top: 5px;
  float: left;
}
.parcel-description .customer-reference {
  font-weight: normal;
  color: #9fa7ae;
}
@media (min-width: 1200px) {
  .parcel-description .parcel-weight {
    float: left;
    margin-bottom: 0;
  }
  .parcel-description .parcel-description-detail {
    padding-top: 6px;
    font-weight: bold;
    margin: 0;
    float: left;
    width: 55%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .parcel-description .reference {
    text-align: right;
    padding-top: 0;
    margin: 7px 0 0 0;
    float: right;
    width: 220px;
    position: absolute;
    right: 55px;
  }
  .parcel-description .reference-group {
    display: inline;
    width: 100%;
  }
}
.order-footer {
  margin-top: 30px;
  margin-bottom: 30px;
}
.order-footer .order-actions {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .order-footer .btn-secondary {
    display: none;
  }
  .order-footer .order-actions {
    margin-top: 0;
    text-align: right;
  }
}
.tracking-image {
  max-width: 150px;
}
.order-form-quote .message {
  font-size: 14px;
}
.order-form-quote .message.failed {
  font-weight: bold;
  color: #e44144;
}
.order-form-quote .line {
  overflow: hidden;
  text-align: right;
  font-size: 18px;
}
.order-form-quote .description {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}
.order-form-quote .price {
  display: inline-block;
  font-weight: bold;
}
.order-form-promotion {
  margin: 7px;
}
.block-header {
  padding: 0.5rem 1rem;
  margin: 0 -10px 20px -10px;
  background: #67cdcc;
  color: white;
}
.block-header h1 {
  font-size: 1.25rem;
  margin: 0 0 0 0.5rem;
  line-height: 1.3;
}
.block-header p {
  margin: 0 0 0 1rem;
}
@media (min-width: 768px) {
  .block-header {
    margin: 0 -40px 20px -40px;
    padding: 0 2rem;
  }
  .block-header h1 {
    font-size: 1.5rem;
    margin: 0 0 0 1.5rem;
    line-height: 1.3;
  }
  .block-header .col-md-8,
  .block-header .col-sm-4 {
    padding: 1.5rem 0;
  }
}
@media (min-width: 992px) {
  .block-header .col-md-7,
  .block-header .col-md-8,
  .block-header .col-md-10 {
    padding: 2rem 0;
  }
  .block-header .col-md-4,
  .block-header .col-md-3,
  .block-header .col-md-2 {
    padding: 2rem 0;
  }
}
.block-header.first-send-header {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  color: #33424f;
  max-width: 896px;
  margin: 2rem auto 3rem auto;
  padding: 0;
}
.block-header.first-send-header h1 {
  font-size: 2rem;
  margin: 0 0 0.5rem 0;
}
.block-header.first-send-header p {
  font-size: 1rem;
  margin: 0;
}
.block-header.first-send-header .first-send-header__body {
  margin-top: 1rem;
}
.block-header.first-send-header .first-send-header__illustration {
  background-image: url(https://assets.sendle.com/assets/dashboard/illustration-employee-tag-081ced0decbc5acb4d1bab8bd71d780c7fc0bd6206b418ff25b29ad50699998c.svg);
  background-size: 120px 108px;
  background-position: 0;
  width: 120px;
  height: 108px;
  display: inline-block;
  position: relative;
  margin: 0 0.5rem 0 0;
}
@media screen and (max-width: 560px) {
  .block-header.first-send-header {
    flex-direction: column;
  }
  .block-header.first-send-header .first-send-header__illustration {
    margin: 0 auto;
    background-size: 100px 89px;
    width: 100px;
    height: 89px;
  }
}
.switcher {
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -khtml-border-radius: 60px;
  border-radius: 60px;
  font-size: 0.875rem;
  background: #3d7b7a;
  font-weight: bold;
  padding: 0.125rem;
  height: 2rem;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .switcher {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .switcher {
    display: inline-block;
    margin-top: 0.5rem;
  }
}
.switcher a {
  display: inline-block;
  color: #ffffff;
  padding: 0.25rem 1rem;
}
.switcher a:hover,
.switcher a:active {
  color: #ffffff;
  text-decoration: none;
}
.switcher span {
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -khtml-border-radius: 60px;
  border-radius: 60px;
  display: inline-block;
  background: #ffffff;
  color: #33424f;
  padding: 0.25rem 1rem;
}
.block-header-v2 {
  background-color: #fff;
  color: #33424f;
  margin: 0;
  padding-left: 0;
}
.block-header-v2 .row {
  margin: 0;
}
.block-header-v2 .row .heading-column {
  padding-left: 0;
}
.block-header-v2 .row .heading-column h1 {
  margin-left: 0;
}
.block-header-v2 .switcher {
  background: #33424f;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .block-header-v2 {
    padding: 0;
    max-width: 1200px;
    margin: auto;
  }
  .block-header-v2 .heading-column {
    padding: 2rem 0;
  }
  .block-header-v2 .heading-column h1 {
    font-size: 2rem;
    letter-spacing: -1px;
  }
  .block-header-v2 .switcher-column {
    padding: 2rem 0;
  }
}
.international .col-md-8 {
  max-width: 800px;
}
.parcel-contents-panel .row.description .form-group {
  margin-bottom: 0px;
}
.total-parcel-value {
  font-size: 1.25rem;
}
.total-parcel-value .value {
  margin-bottom: 64px;
}
.parcel-contents .help-block .help-text {
  line-height: 1.5;
  font-size: 1rem;
  color: #33424f;
}
.parcel-contents .parcel-contents-item .item-number {
  font-size: 1rem;
}
.parcel-contents .parcel-contents-item .item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.parcel-contents .parcel-contents-item .item-field-row {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}
.parcel-contents .add-item-button-holder {
  display: flex;
  justify-content: center;
}
.parcel-contents .add-item-button-holder .add-item-button {
  font-weight: bold;
  font-size: 1rem;
}
.parcel-contents .add-item-button-holder .add-item-button span:hover {
  text-decoration: underline;
}
.parcel-contents .add-item-button-holder .add-item-button:focus {
  box-shadow: none;
}
.parcel-contents .remove-item-button {
  font-size: 0.875rem;
  padding-right: 0;
  padding-top: 0;
}
.parcel-contents .remove-item-button span {
  font-weight: normal;
}
.parcel-contents .remove-item-button span:hover {
  text-decoration: underline;
}
.parcel-contents .remove-item-button:focus {
  box-shadow: none;
}
.domestic .col-md-8 {
  max-width: 800px;
}
.help-panel.weight-summary,
.help-panel.third-party-integration {
  margin-top: 0;
  margin-bottom: 20px;
}
.help-panel.weight-summary dl,
.help-panel.third-party-integration dl {
  margin-bottom: 0;
  overflow: hidden;
}
.help-panel.weight-summary dt,
.help-panel.third-party-integration dt {
  font-weight: normal;
  float: left;
  margin: 0 0 4px 0;
  width: 130px;
}
.help-panel.weight-summary dd,
.help-panel.third-party-integration dd {
  margin: 0 0 4px 135px;
}
.help-panel.weight-summary dd strong,
.help-panel.third-party-integration dd strong {
  margin-right: 5px;
}
.help-panel.weight-summary .meta-text,
.help-panel.third-party-integration .meta-text {
  font-weight: normal;
  margin-right: 5px;
}
@media (min-width: 768px) {
  .help-panel.weight-summary dl,
  .help-panel.third-party-integration dl {
    margin-top: -4px;
  }
  .help-panel.weight-summary .small-cols dt,
  .help-panel.third-party-integration .small-cols dt {
    width: 75px;
  }
  .help-panel.weight-summary .small-cols dd,
  .help-panel.third-party-integration .small-cols dd {
    margin: 0 0 4px 80px;
  }
}
.international .weight-summary {
  position: relative;
  border-radius: 2px;
  border: 1px solid #e9edf0;
  padding: 8px 16px;
  height: auto;
  margin-bottom: 31px;
}
.international .weight-summary .summary-header {
  color: #7a8691;
  font-size: 13px;
  line-height: 18px;
}
.international .weight-summary .summary-text {
  line-height: 20px;
  font-size: 1rem;
  padding: 10px 0 20px 0;
}
.international .weight-summary .summary-text .dimensions {
  display: inline-block;
  padding: 0 3px;
}
.international .weight-summary .summary-text .dimensions:before {
  background: ./assets/icontwo.svg)
    no-repeat no-repeat white;
  background-size: 797px 469px;
  content: "";
  display: block;
  height: 41px;
  width: 37px;
  background-size: 430px auto;
  position: relative;
}
.international .weight-summary .summary-text .width:before {
  background-position: -3px -187px;
  transform: translateY(22%);
}
.international .weight-summary .summary-text .us-domestic.width {
  width: 44px;
}
.international .weight-summary .summary-text .us-domestic.width:before {
  background-position: -161px -182px;
  transform: translateY(22%);
}
.international .weight-summary .summary-text .height:before {
  background-position: -42.3px -187px;
  transform: translateY(31%);
}
.international .weight-summary .summary-text .us-domestic.height:before {
  background-position: -198px -185px;
  transform: translateY(31%);
}
.international .weight-summary .summary-text .depth:before {
  background-position: -82px -187px;
  transform: translateY(40%);
}
.international .weight-summary .summary-text.right .summary-text-header {
  display: block;
}
.international .weight-summary .summary-text.left {
  font-size: 14px;
  display: inline-block;
  min-width: 128px;
}
.international .weight-summary .summary-footer.right {
  text-align: left;
  display: block;
  width: 100%;
}
.international .weight-summary .summary-footer.left {
  display: inline-block;
  text-align: right;
  width: calc(100% - 130px);
  font-size: 1rem;
}
.international .weight-summary .col-sm-9 {
  width: 100%;
}
.international .weight-summary .col-sm-3 {
  width: 100%;
}
@media (min-width: 1150px) {
  .international .weight-summary {
    height: 110px;
  }
  .international .weight-summary .col-sm-3 {
    padding-right: 0;
    width: 25%;
  }
  .international .weight-summary .col-sm-9 {
    padding-left: 0;
    width: 75%;
  }
  .international .weight-summary .summary-text.right {
    text-align: center;
    padding-top: 0;
  }
  .international .weight-summary .summary-text.right .summary-text-header {
    display: inline-block;
  }
  .international .weight-summary .summary-text.left {
    display: block;
  }
  .international .weight-summary .summary-footer.right {
    text-align: center;
  }
  .international .weight-summary .summary-footer.left {
    display: block;
    font-size: 11px;
    text-align: left;
    width: auto;
  }
}
.non-editable-fields {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
.non-editable-fields .form-group {
  margin-bottom: 4px;
}
.tt-hint {
  color: #9fa7ae;
}
div.row.unified {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 0.5rem;
}
@media (min-width: 992px) {
  div.row.unified > form {
    display: flex;
  }
}
div.row.unified > form .new-order-summary {
  position: sticky;
  top: 90px;
}
div.row.unified .col-md-8 {
  max-width: 800px;
}
@media (min-width: 768px) {
  div.row.unified .col-md-8 {
    padding-left: 0;
  }
}
div.row.unified .line-break::before {
  content: "\A";
  white-space: pre;
}
div.row.unified .panel-formgroup {
  margin: 1rem 0 2rem 0;
}
div.row.unified .panel-formgroup .panel-heading {
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  div.row.unified .panel-formgroup.parcel-country-panel,
  div.row.unified .panel-formgroup.parcel-size-panel,
  div.row.unified .panel-formgroup.receiver-panel,
  div.row.unified .panel-formgroup.pickup-panel,
  div.row.unified .panel-formgroup.pickup-location-panel,
  div.row.unified .panel-formgroup.contact-details-panel,
  div.row.unified .panel-formgroup.pickup-instructions-panel,
  div.row.unified .panel-formgroup.dangerous-goods-panel {
    margin: 0 0 1.5rem 0;
  }
  div.row.unified .panel-formgroup.parcel-country-panel .panel-heading,
  div.row.unified .panel-formgroup.parcel-size-panel .panel-heading,
  div.row.unified .panel-formgroup.receiver-panel .panel-heading,
  div.row.unified .panel-formgroup.pickup-panel .panel-heading,
  div.row.unified .panel-formgroup.pickup-location-panel .panel-heading,
  div.row.unified .panel-formgroup.contact-details-panel .panel-heading,
  div.row.unified .panel-formgroup.pickup-instructions-panel .panel-heading,
  div.row.unified .panel-formgroup.dangerous-goods-panel .panel-heading {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
  }
}
div.row.unified .contact-details-panel {
  margin: 0 0 2rem 0;
}
div.row.unified .address-fields .field-group-collapse {
  position: relative;
  top: -16px;
  float: right;
  width: 100%;
  text-align: right;
}
div.row.unified .address-fields .sending-to-country {
  font-size: 1.25rem;
}
div.row.unified .validation-block {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  border: none;
  background: transparent;
  color: #33424f;
  padding: 0;
  font-size: 1rem;
}
div.row.unified .weight-summary {
  border: 1px solid #e9edf0;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}
div.row.unified .weight-summary.blank {
  display: none;
}
div.row.unified .weight-summary .summary-header {
  font-size: 0.875rem;
  color: #6d7883;
}
div.row.unified .weight-summary .summary-text {
  font-size: 1rem;
}
div.row.unified .weight-summary .summary-text.left {
  margin: 0.25rem 0;
}
div.row.unified .weight-summary .summary-footer {
  color: #b4bfc9;
  text-align: center;
  position: relative;
  display: block;
  top: 8px;
  left: 110px;
  width: 140px;
}
div.row.unified .weight-summary .summary-footer br {
  display: none;
}
div.row.unified .weight-summary .summary-footer.left {
  text-align: left;
  left: 0px;
  width: 100%;
}
div.row.unified .weight-summary .dimensions {
  display: inline-block;
  padding: 0 3px;
}
div.row.unified .weight-summary .dimensions:before {
  background: ./assets/icontwo.svg)
    no-repeat no-repeat white;
  background-size: 797px 469px;
  content: "";
  display: block;
  height: 41px;
  width: 37px;
  background-size: 430px auto;
  position: relative;
}
div.row.unified .weight-summary .width:before {
  background-position: -3px -187px;
  transform: translateY(22%);
}
div.row.unified .weight-summary .us-domestic.width {
  width: 44px;
}
div.row.unified .weight-summary .us-domestic.width:before {
  background-position: -161px -182px;
  transform: translateY(22%);
}
div.row.unified .weight-summary .height:before {
  background-position: -42.3px -187px;
  transform: translateY(31%);
}
div.row.unified .weight-summary .us-domestic.height:before {
  background-position: -198px -185px;
  transform: translateY(31%);
}
div.row.unified .weight-summary .depth:before {
  background-position: -82px -187px;
  transform: translateY(40%);
}
div.row.unified .weight-summary.domestic .summary-text.right {
  top: 24px;
  position: relative;
}
div.row.unified
  .weight-summary.domestic
  .summary-text.summary-dimensions.right {
  top: -3px;
  text-align: center;
}
div.row.unified .weight-summary.domestic .summary-footer {
  text-align: left;
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 768px) {
  div.row.unified .weight-summary {
    text-align: center;
  }
  div.row.unified .weight-summary .summary-text-header {
    display: block;
  }
  div.row.unified .weight-summary .summary-footer.left {
    text-align: center;
  }
  div.row.unified .weight-summary .summary-footer br {
    display: block;
  }
  div.row.unified .weight-summary .summary-footer.right {
    width: 230px;
    margin: 0 auto;
    left: auto;
    text-align: left;
  }
  div.row.unified .weight-summary.domestic .summary-text.right {
    top: 0;
    position: static;
  }
  div.row.unified .weight-summary.domestic .summary-text-header {
    display: inline;
  }
  div.row.unified .weight-summary.domestic .summary-footer {
    text-align: center;
    margin-top: 8px;
  }
  div.row.unified
    .weight-summary.domestic
    .summary-dimensions
    .summary-text-header {
    display: block;
  }
}
div.row.unified .form-group.submit-group {
  border: none;
  text-align: left;
  margin: 0 0 1.5rem 0;
}
div.row.unified .pickup-date {
  margin: 0 0 1rem 0;
}
div.row.unified .pickup-date .help-panel {
  margin-top: 0.5rem;
  border: none;
  background: transparent;
  color: #33424f;
  padding: 0;
  font-size: 1rem;
}
div.row.unified .pickup-date .help-panel p {
  font-size: inherit;
}
div.row.unified .pickup-buttons {
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  div.row.unified .pickup-buttons {
    flex-direction: row;
  }
}
div.row.unified .pickup-buttons .first-mile-button-holder {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}
@media (min-width: 768px) {
  div.row.unified .pickup-buttons .first-mile-button-holder {
    width: 40%;
    width: max-content;
  }
}
div.row.unified .pickup-buttons .first-mile-option-label {
  font-size: 1rem;
  color: inherit;
  font-weight: normal;
}
@media (min-width: 768px) {
  div.row.unified .pickup-buttons .first-mile-option-label {
    text-align: left;
  }
}
div.row.unified .pickup-buttons .first-mile-option-label:hover {
  cursor: pointer;
}
div.row.unified .pickup-select-override .help-panel {
  display: none;
}
div.row.unified .full-width,
div.row.unified .full-width + span {
  width: 100% !important;
}
div.row.unified .side-drawer-heading {
  font-size: 1rem;
}
div.row.unified .nearest-drop-off-location-main-panel {
  margin-bottom: 1.25rem;
  width: 100%;
  font-size: 1rem;
}
@media (min-width: 992px) {
  div.row.unified .nearest-drop-off-location-main-panel .hubbed {
    min-height: 139px;
  }
}
div.row.unified .nearest-drop-off-location-main-panel .loading-panel {
  margin: 0 auto;
  width: max-content;
}
div.row.unified .nearest-drop-off-location-main-panel .loading-panel .loading {
  display: inline-block;
  margin-right: 1rem;
}
div.row.unified .nearest-drop-off-location-main-panel.drop-off-unavailable {
  position: relative;
  display: inline-block;
  background: transparent;
  margin-bottom: 1.5rem;
  padding: 1rem 1rem 1rem 1.5rem;
  color: #33424f;
  border: 1px solid #e9edf0;
  border-radius: 2px;
  min-height: 0;
}
div.row.unified
  .nearest-drop-off-location-main-panel.drop-off-unavailable:before {
  content: "";
  position: absolute;
  width: 8px;
  left: 8px;
  top: 8px;
  bottom: 8px;
  background: #e9edf0;
  border-radius: 2px;
}
div.row.unified
  .nearest-drop-off-location-main-panel.drop-off-unavailable
  .panel-help-link {
  margin-left: 4px;
  font-size: 13px;
}
div.row.unified
  .nearest-drop-off-location-main-panel.drop-off-unavailable
  .content {
  color: inherit;
}
div.row.unified
  .nearest-drop-off-location-main-panel.drop-off-unavailable
  .content:last-of-type {
  margin-bottom: 0;
}
div.row.unified .nearest-drop-off-location-main-panel .try-again {
  color: #009de3;
  background: none;
  border: none;
}
div.row.unified .nearest-drop-off-location-main-panel .try-again:hover {
  text-decoration: underline;
}
div.row.unified .nearest-drop-off-location-main-panel .panel-help-link {
  font-size: 0.875rem;
}
div.row.unified .nearest-drop-off-location-header {
  margin-bottom: 1rem;
  font-size: #33424f;
}
div.row.unified .nearest-drop-off-location-header a {
  font-size: #33424f;
  width: 100%;
  float: left;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  div.row.unified .nearest-drop-off-location-header a {
    width: auto;
    float: right;
    margin-bottom: 0;
  }
}
div.row.unified .nearest-drop-off-location-header a.inline-link {
  float: none;
}
div.row.unified .nearest-drop-off-location-inner-panel {
  float: left;
  color: #5c6872;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
div.row.unified
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data {
  padding-left: 0;
}
div.row.unified
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data
  .location-address {
  display: block;
  font-size: 1rem;
  margin-bottom: 1px;
}
div.row.unified
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data
  .store-name {
  color: #33424f;
}
div.row.unified
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data
  .location-opening-hours {
  font-size: 13px;
}
div.row.unified .nearest-drop-off-location-inner-panel.other-locations span {
  display: block;
}
@media (min-width: 992px) {
  div.row.unified .nearest-drop-off-location-inner-panel.other-locations span {
    margin-left: 32px;
  }
}
div.row.unified .nearest-drop-off-location-inner-panel .other-locations-header {
  color: #33424f;
  font-size: 1rem;
  margin-bottom: 4px;
  font-weight: 600;
}
div.row.unified .nearest-drop-off-location-inner-panel .other-locations-btn {
  background: #e9edf0;
  display: block;
  padding: 5px 12px 5px 12px;
  width: 175px;
  border-radius: 3px;
  font-size: 1rem;
  line-height: 19px;
  text-align: left;
}
div.row.unified .nearest-drop-off-location-inner-panel .other-locations-btn a {
  color: #5c6872;
}
div.row.unified
  .nearest-drop-off-location-inner-panel
  .other-locations-btn
  a:hover {
  text-decoration: none;
}
div.row.unified .nearest-drop-off-location-inner-panel .other-locations-info {
  margin-top: 4px;
  color: #33424f;
  font-size: 11px;
}
@media (min-width: 992px) {
  div.row.unified .nearest-drop-off-location-inner-panel .powered-by {
    margin-left: 32px;
  }
}
div.row.unified .nearest-drop-off-location-inner-panel .powered-by img {
  margin-top: 4px;
  float: left;
}
div.row.unified .nearest-drop-off-location-inner-panel .powered-by span {
  float: left;
  margin: 5px 0 0 4px;
  color: #5c6872;
  font-size: 11px;
  font-style: italic;
  line-height: 16px;
}
div.row.unified .store-content {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  div.row.unified .store-content {
    margin-bottom: 0;
  }
}
div.row.unified .non-editing .non-editable-fields {
  border-bottom: 1px solid #e9edf0;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  div.row.unified .non-editing .non-editable-fields {
    border-bottom: none;
  }
}
div.row.unified .non-editing .non-editable-fields .panel-heading {
  font-size: 1.25rem;
}
div.row.unified .non-editing .non-editable-fields .header {
  color: #33424f;
}
div.row.unified .non-editing .non-editable-fields .row {
  margin-top: 4px;
  margin-bottom: 8px;
}
div.row.unified .non-editing .non-editable-fields .row .sender-avatar {
  display: inline-block;
  margin-left: 16px;
  position: absolute;
}
div.row.unified .non-editing .non-editable-fields .row .sender-details {
  display: inline-block;
  padding-left: 86px;
  width: calc(100% - 14px);
}
div.row.unified .non-editing .non-editable-fields .avatar {
  height: 54px;
  width: 54px;
  background-size: contain !important;
  margin-top: 4px;
  border-radius: 100%;
}
div.row.unified .non-editing .non-editable-fields p {
  font-size: 1rem;
}
div.row.unified .non-editing .non-editable-fields p.instructions {
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
div.row.unified .non-editing .non-editable-fields p .edit-toggle {
  font-size: 0.875rem;
}
div.row.unified .inline-message {
  padding: 8px;
  margin-bottom: 16px;
  position: relative;
}
div.row.unified .inline-message .main-content {
  margin-left: 18px;
}
div.row.unified .inline-message .main-content p {
  margin: 0;
}
div.row.unified .inline-message .divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0px;
  margin: 8px 8px;
}
div.row.unified .inline-message.company {
  border: 1px solid #009de3;
}
div.row.unified .inline-message.company .divider {
  border: 4px solid #009de3;
  border-left: 4px solid #009de3;
  border-right: 4px solid #009de3;
  border-radius: 2px;
}
div.row.unified .address-location {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 50%;
  padding-top: 1.625rem;
}
div.row.unified .address-location .address-location__map {
  max-width: 340px;
  background-color: #f7f8fa;
  border: 1px solid #b4bfc9;
  margin: 0 auto 1rem auto;
}
div.row.unified .address-location .address-location__instructions {
  max-width: 340px;
  margin: 0 auto;
}
div.row.unified .checkbox.declaration {
  background-color: #f7f8fa;
  padding: 12px 42px 12px 12px;
}
div.row.unified .checkbox.declaration span {
  margin-left: 50px;
  font-size: 1rem;
  font-weight: bold;
  color: #33424f;
}
div.row.unified .checkbox.declaration input[type="checkbox"] {
  visibility: hidden;
}
div.row.unified .checkbox.declaration .checkbox-input {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 15px;
  left: -41px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
}
div.row.unified .checkbox.declaration .checkbox-input:after {
  opacity: 0;
  content: "";
  position: absolute;
  width: 15px;
  height: 6px;
  background: #009de3;
  top: 7px;
  left: 4px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  border-radius: 1px;
  transform: rotate(-45deg);
}
div.row.unified
  .checkbox.declaration
  input[type="checkbox"]:checked
  + .checkbox-input {
  background: #009de3;
  border: 1px solid #009de3;
}
div.row.unified
  .checkbox.declaration
  input[type="checkbox"]:checked
  + .checkbox-input:after {
  opacity: 1;
}
div.row.unified.first-send-form {
  max-width: 896px;
  margin: 0 auto;
  padding: 0;
}
div.row.unified.first-send-form .pickup-location-panel,
div.row.unified.first-send-form .dangerous-goods-panel {
  max-width: 896px;
}
div.row.unified.first-send-form
  .pickup-location-panel
  .address-fields
  .field-group-collapse {
  position: relative;
  top: -1rem;
  float: left;
  width: 100%;
  text-align: left;
}
div.row.unified.first-send-form .dangerous-goods-panel {
  margin-bottom: 0.5rem;
}
div.row.unified.first-send-form
  .dangerous-goods-panel
  .dangerous-goods__body-points
  ul {
  padding: 0 0 0 2rem;
}
div.row.unified.first-send-form
  .dangerous-goods-panel
  .dangerous-goods__body-points
  ul
  li {
  list-style: none;
}
div.row.unified.first-send-form
  .dangerous-goods-panel
  .dangerous-goods__body-points
  ul
  li:before {
  content: "";
  border: 2px #33424f solid;
  border-radius: 50px;
  margin-top: 10px;
  margin-left: -20px;
  position: absolute;
}
div.row.unified.first-send-form .first-send-form__dangerous-goods-checkbox {
  margin-bottom: 2rem;
}
div.row.unified.first-send-form .pickup-instructions,
div.row.unified.first-send-form .address-lookup-container {
  max-width: 673px;
}
@media screen and (max-width: 991px) {
  div.row.unified.first-send-form .pickup-instructions,
  div.row.unified.first-send-form .address-lookup-container {
    max-width: 100%;
  }
}
div.row.unified.first-send-form .pickup-instructions {
  margin-top: 1.5rem;
}
div.row.unified.first-send-form .address-fields-container {
  display: flex;
}
div.row.unified.first-send-form .address-fields-container .address-fields {
  width: 50%;
  padding-right: 15px;
}
@media screen and (max-width: 770px) {
  div.row.unified.first-send-form .address-fields-container {
    flex-direction: column;
  }
  div.row.unified.first-send-form .address-fields-container .address-fields {
    width: 100%;
    padding-right: 0;
  }
  div.row.unified.first-send-form .address-fields-container .address-location {
    width: 100%;
  }
}
div.row.unified.first-send-form
  .address-fields-container
  .address-fields-container__without-autocomplete {
  width: 50%;
}
div.row.unified.first-send-form
  .address-fields-container
  .address-fields-container__without-autocomplete
  .address-fields {
  width: 100%;
  padding-right: 15px;
}
div.row.unified.first-send-form
  .address-fields-container
  .address-fields-container__without-autocomplete
  .country-field {
  padding-right: 15px;
}
@media screen and (max-width: 770px) {
  div.row.unified.first-send-form
    .address-fields-container
    .address-fields-container__without-autocomplete {
    flex-direction: column;
    width: 100%;
  }
  div.row.unified.first-send-form
    .address-fields-container
    .address-fields-container__without-autocomplete
    .address-fields {
    padding-right: 0;
  }
}
div.row.unified.first-send-form
  .address-fields-container
  .address-fields-container__location-map {
  width: 50%;
}
div.row.unified.first-send-form
  .address-fields-container
  .address-fields-container__location-map
  .address-location {
  width: 100%;
}
@media screen and (max-width: 770px) {
  div.row.unified.first-send-form
    .address-fields-container
    .address-fields-container__location-map {
    width: 100%;
  }
}
div.row.unified.first-send-form
  .panel-body
  .panel-body-inner
  .row:last-child
  > *:only-child
  .sds-form-element,
div.row.unified.first-send-form
  .panel-body
  .panel-body-inner
  .row:last-child
  > *:only-child
  .sds-form-element__hint,
div.row.unified.first-send-form
  .panel-body
  .panel-body-inner
  .pickup-instructions
  .sds-form-element,
div.row.unified.first-send-form
  .panel-body
  .panel-body-inner
  .pickup-instructions
  .sds-form-element__hint {
  margin-bottom: 0;
}
div.row.unified.first-send-form
  .panel-body
  .panel-body-inner
  .row:last-child
  > *:not(:only-child):last-child
  .sds-form-element {
  margin-bottom: 0;
}
div.row.unified.first-send-form .preferred-measurement-units .panel-heading {
  padding-top: 0.5rem;
}
div.row.unified.first-send-form .preferred-measurement-units .sds-form-element {
  margin-bottom: 1rem;
}
@media screen and (max-width: 560px) {
  div.row.unified.first-send-form .first-send-form__submit {
    width: 100%;
  }
}
.product-options-drawer .nearest-drop-off-location-main-panel .col-md-6 {
  width: 100%;
}
.underdeclaring-message {
  font-size: 1rem;
}
.v2-opt-out-feedback {
  padding: 1rem;
}
.v2-opt-out-feedback .full-width,
.v2-opt-out-feedback .full-width + span {
  width: 100% !important;
}
.v2-opt-out-feedback textarea.full-width {
  height: 8rem;
  line-height: 1.5;
  padding: 0.5rem;
}
.drawer__content {
  overflow-x: hidden;
}
#order-pricing-calculator-panel .error-message {
  color: #ff6800;
  font-size: 15px;
  margin: 0.5rem 0 0 0;
}
#order-pricing-calculator-panel .order-pricing-calculator {
  background-color: #f7f8fa;
}
#order-pricing-calculator-panel
  .order-pricing-calculator
  .sds-form-element__hint {
  margin-bottom: 0;
}
#order-pricing-calculator-panel .order-pricing-calculator .dimensions-wrapper {
  display: flex;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .dimensions-wrapper {
    display: inline-block;
    width: 75%;
    float: right;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .dimensions-wrapper
    [class^="form-group-"] {
    display: inline-block;
    width: calc(33% - 1rem);
    margin-bottom: 1rem !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .dimensions-wrapper
    .form-group-length {
    margin-left: 1rem;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .dimensions-wrapper
    .form-group-height {
    margin-right: 0;
  }
}
#order-pricing-calculator-panel .order-pricing-calculator .form {
  display: flex;
  text-align: left;
  margin: 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel .order-pricing-calculator .form {
    display: block;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .form-group-weight {
    display: inline-block;
    width: 25%;
  }
}
#order-pricing-calculator-panel
  .order-pricing-calculator
  .form
  .icon-size-weight {
  background-image: url(https://assets.sendle.com/assets/dashboard/order-form-weight-icon-23be3c2126ca3865b57c3652fd82d0a0854d33b23728f4efc4ab5743b72d95b9.svg);
  margin: 2rem 0 0 0;
  background-repeat: no-repeat;
  flex: 0 0 38px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-weight {
    display: none;
  }
}
#order-pricing-calculator-panel
  .order-pricing-calculator
  .form
  .icon-size-length {
  background-image: url(https://assets.sendle.com/assets/dashboard/order-form-size-icon-bb2f07e1a87948dfadc5b6cdb4d165e0edf5fe0808aea4fdc43922b89bdf47c0.svg);
  margin: 2rem 0 0 1rem;
  background-repeat: no-repeat;
  flex: 0 0 38px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-length {
    display: none;
  }
}
#order-pricing-calculator-panel .order-pricing-calculator .form .form-group {
  position: relative;
  overflow: initial;
  min-height: 73px;
  margin-bottom: 4px;
  flex: 5;
}
#order-pricing-calculator-panel
  .order-pricing-calculator
  .form
  .form-group
  .row {
  display: flex;
  margin: 0;
}
#order-pricing-calculator-panel
  .order-pricing-calculator
  .form
  .form-group
  .max-weight {
  margin-left: 2px;
  color: #7a8691;
  font-size: 12px;
}
#order-pricing-calculator-panel
  .order-pricing-calculator
  .form
  .form-group
  .label {
  padding-left: 2px;
  text-transform: capitalize;
  margin-bottom: 3px;
}
#order-pricing-calculator-panel
  .order-pricing-calculator
  .form
  .form-group-width,
#order-pricing-calculator-panel
  .order-pricing-calculator
  .form
  .form-group-height {
  margin-left: 16px;
}
#order-pricing-calculator-panel .order-pricing-calculator .form .form-control {
  padding-right: 32px;
  padding-left: 8px;
  margin: 3px 2px 0 2px;
  border-radius: 2px;
  border-color: #b4bfc9;
  -moz-appearance: textfield;
}
#order-pricing-calculator-panel .order-pricing-calculator .form .unit {
  display: block;
  position: absolute;
  font-weight: bold;
  top: 34px;
  width: 25px;
  right: 8px;
}
@media (min-width: 767px) and (max-width: 860px) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-length,
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-weight {
    flex: 2;
  }
}
@media (min-width: 991px) and (max-width: 1080px) {
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-length,
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-weight {
    flex: 2;
  }
}
@media screen and (max-width: 560px) {
  #order-pricing-calculator-panel .order-pricing-calculator .form {
    display: block;
    padding: 1rem;
  }
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-weight {
    display: none;
  }
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .icon-size-length {
    display: none;
  }
  #order-pricing-calculator-panel .order-pricing-calculator .form .form-group {
    display: inline-block;
    margin-left: 0;
    width: 32%;
  }
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .form-group
    .max-weight {
    margin-left: 0.125rem;
  }
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .form-group
    .label {
    padding-left: 0.125rem;
  }
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .form-group-weight {
    width: 100%;
    margin-bottom: 1rem;
  }
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .form-group-height {
    margin-left: 1rem;
  }
  #order-pricing-calculator-panel
    .order-pricing-calculator
    .form
    .form-group-width {
    margin-left: 1rem;
  }
}
#order-pricing-calculator-panel .order-pricing-calculator-collapsed .form {
  display: block;
  padding: 1rem;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .icon-size-weight {
  display: none;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .icon-size-length {
  display: none;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .form-group {
  display: inline-block;
  margin-left: 0;
  width: 32%;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .form-group
  .max-weight {
  margin-left: 0.125rem;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .form-group
  .label {
  padding-left: 0.125rem;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .form-group-weight {
  width: 100%;
  margin-bottom: 1rem;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .form-group-height {
  margin-left: 1rem;
}
#order-pricing-calculator-panel
  .order-pricing-calculator-collapsed
  .form
  .form-group-width {
  margin-left: 1rem;
}
.twitter-typeahead .tt-dataset-orders {
  display: table;
  width: 100%;
  padding: 0;
  margin-bottom: -10px;
  position: relative;
}
.twitter-typeahead .tt-dataset-orders .tt-suggestion {
  display: table-row;
  padding: 0;
}
.twitter-typeahead .tt-dataset-orders .tt-suggestion .tt-suggestion-detail,
.twitter-typeahead .tt-dataset-orders .tt-suggestion .tt-suggestion-action {
  display: table-cell;
  vertical-align: middle;
}
.twitter-typeahead .tt-dataset-orders .tt-suggestion .tt-suggestion-detail {
  padding: 5px 0 5px 5px;
}
.twitter-typeahead .tt-dataset-orders .tt-suggestion .tt-suggestion-detail a {
  display: block;
}
.twitter-typeahead .tt-dataset-orders .tt-suggestion .tt-suggestion-action {
  padding: 5px 10px 5px 0;
}
.twitter-typeahead .tt-dataset-orders .tt-suggestion .hover-link {
  visibility: hidden;
  color: white;
}
.twitter-typeahead .tt-dataset-orders .tt-suggestion:hover .hover-link,
.twitter-typeahead .tt-dataset-orders .tt-suggestion.tt-cursor .hover-link {
  visibility: visible;
}
.twitter-typeahead
  .tt-dataset-orders
  .tt-suggestion:last-child
  .tt-suggestion-detail,
.twitter-typeahead
  .tt-dataset-orders
  .tt-suggestion:last-child
  .tt-suggestion-action {
  border-bottom: 10px solid white;
}
.twitter-typeahead .tt-dataset-orders .empty-message {
  padding: 0 10px 10px 10px;
  font-weight: normal;
}
.twitter-typeahead .tt-dataset-orders .footer {
  display: table-row;
}
.twitter-typeahead .tt-dataset-orders .footer .footer-detail,
.twitter-typeahead .tt-dataset-orders .footer .footer-action {
  display: table-cell;
  padding: 7px 0;
  border-top: 10px solid white;
}
.twitter-typeahead .tt-dataset-orders .footer a {
  padding-left: 47px;
}
.order-states {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}
.order-states .state {
  border: 2px solid #b4bfc9;
  background: #ffffff;
  border-radius: 1rem;
  font-size: 0.75rem;
  flex: 0 0 auto;
  position: relative;
  width: 182px;
  text-align: center;
}
.order-states .state__label {
  font-weight: bold;
}
.order-states .state__date {
  margin-left: 0.5rem;
}
.order-states .state--complete {
  border-color: #33424f;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}
.order-states .state--complete:after {
  content: "";
  position: absolute;
  left: 100%;
  width: 1rem;
  border-bottom: 2px solid #33424f;
  top: 50%;
  transform: translate(2px, -50%);
}
.order-states .state--complete + .state--incomplete {
  margin-left: 0;
}
.order-states .state--active {
  border-color: #33424f;
  background: #33424f;
  color: #ffffff;
  padding: 0 0.5rem;
}
.order-states .state--incomplete {
  border-color: #b4bfc9;
  height: 1rem;
  width: 1rem;
  margin-left: 1rem;
}
.order-states .state--incomplete:after {
  content: "";
  top: 50%;
  width: 1rem;
  position: absolute;
  right: 100%;
  border-bottom: 2px solid #b4bfc9;
  transform: translate(-2px, -50%);
}
.order-states--active .state--complete {
  border-color: #33424f;
}
.order-states--active .state--complete:after {
  position: absolute;
  border-color: #33424f;
}
.order-states--inactive .state--complete {
  border-color: #b4bfc9;
}
.order-states--inactive .state--complete:after {
  position: absolute;
  border-color: #b4bfc9;
}
@media screen and (max-width: 767px) {
  .order-states .state--complete,
  .order-states .state--incomplete {
    display: none;
  }
}
.bulk-order-opt-out-banner {
  display: flex;
  justify-content: center;
  max-width: 585px;
  margin: auto;
}
@media screen and (max-width: 1060px) {
  .bulk-order-opt-out-banner {
    text-align: left;
  }
}
.bulk-order-opt-in-banner {
  display: flex;
  justify-content: center;
  max-width: 720px;
  margin: auto;
}
@media screen and (max-width: 1060px) {
  .bulk-order-opt-in-banner {
    text-align: left;
  }
}
.sds-alert-message--whisper {
  background-color: #f7f8fa;
  color: #33424f;
}
.sds-alert-message--whisper .sds-alert-message__content a {
  color: #008dcc;
}
.bulk-order-header {
  margin-top: 40px;
}
.bulk-order-header h1 {
  margin-top: 0;
}
.bulk-order-header h1.with-counter {
  float: left;
}
.bulk-order-header p {
  font-size: 1rem;
}
.bulk-order-header .upload-limit {
  background-color: #f7f8fa;
}
.bulk-order-header__message-container {
  display: flex;
  align-items: center;
}
.bulk-order-header__file-icon {
  flex: 25px;
  height: 40px;
}
@media screen and (max-width: 991px) {
  .bulk-order-header__file-icon {
    display: none;
  }
}
.bulk-order-header__new-template {
  text-align: left;
}
.bulk-order-header .sds-alert-message__content--batch-template-info {
  line-height: 1.5;
  flex: 90%;
}
.center-header {
  max-width: 800px;
  margin: auto;
  text-align: center;
}
.bulk-discounts {
  padding-top: 8px;
}
.bulk-actions {
  line-height: 40px;
}
.bulk-actions .cancel .gylphish {
  font-size: 14px;
}
.batch-order-table {
  margin: 1.5rem 0;
}
.batch-order-table thead th.price {
  text-align: center;
}
.batch-order-table .sds-table__cell-override {
  vertical-align: middle;
}
.batch-order-table .align-icon-and-names {
  display: flex;
  align-content: center;
}
.batch-order-table .align-icon-middle {
  vertical-align: middle;
}
.batch-order-table__status {
  text-align: center;
}
@media screen and (max-width: 1060px) {
  .batch-order-table__status {
    text-align: left;
  }
  .batch-order-table__status .status-icon {
    display: block;
    margin: 0;
  }
}
.batch-order-table__first-mile-details {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}
@media screen and (max-width: 1060px) {
  .batch-order-table__first-mile-details {
    max-width: initial;
  }
}
.batch-order-table__description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 20ch;
}
@media screen and (max-width: 1060px) {
  .batch-order-table__description {
    max-width: initial;
  }
}
@media screen and (max-width: 1060px) {
  .batch-order-table__pickup-date {
    text-align: right;
  }
}
.batch-order-table__delivery-option-first-mile {
  display: block;
}
.batch-order-table__delivery-option-first-mile:first-letter {
  text-transform: uppercase;
}
.batch-order-table__size .size-icon {
  color: #b4bfc9;
  margin-right: 0.5rem;
}
@media screen and (min-width: 1060px) and (max-width: 1193px) {
  .batch-order-table__size .size-icon {
    display: none;
  }
}
.batch-order-table__eta .eta-icon {
  color: #b4bfc9;
  margin-right: 0.5rem;
}
@media screen and (min-width: 1060px) and (max-width: 1193px) {
  .batch-order-table__eta .eta-icon {
    display: none;
  }
}
.batch-order-table__remote .remote-route-hinttip {
  width: 178px;
  height: 94px;
  bottom: 35px;
}
.batch-order-table__remote .remote-icon {
  margin-right: 0.5rem;
  width: 24px;
  height: 18px;
}
@media screen and (min-width: 1060px) and (max-width: 1193px) {
  .batch-order-table__remote .remote-icon {
    display: none;
  }
}
.batch-order-table__action-checkbox {
  width: 4rem;
  cursor: default;
}
.batch-order-table__action-checkbox .sds-form-element__label {
  padding-left: 0;
  margin-right: 0;
}
.batch-order-table__action-checkbox
  .sds-form-element__label
  .sds-checkbox_faux {
  margin-right: 0 !important;
}
.batch-order-table__action-checkbox .sds-checkbox {
  height: 1rem;
}
@media screen and (max-width: 1060px) {
  .batch-order-table__action-checkbox {
    position: absolute;
  }
  .batch-order-table__action-checkbox .sds-form-element__label {
    padding-left: 0.125rem;
  }
}
.batch-order-table .checkbox-spacing {
  margin-left: 3rem;
}
@media screen and (max-width: 1060px) {
  .batch-order-table {
    border-top: 0.5rem solid #e9edf0;
  }
  .batch-order-table.sds-table--bold {
    border-top: 0.5rem solid #33424f;
  }
  .batch-order-table.sds-table--subtle {
    border-top-width: 0.0625rem;
  }
  .batch-order-table.sds-table--headless {
    border-top-width: 0.0625rem;
  }
  .batch-order-table .sds-table__table {
    display: block;
  }
  .batch-order-table .sds-table__table thead,
  .batch-order-table .sds-table__table tbody,
  .batch-order-table .sds-table__table tfoot {
    display: block;
  }
  .batch-order-table .sds-table__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .batch-order-table .sds-table__row .sds-table__cell:first-of-type,
  .batch-order-table .sds-table__row .sds-table__header:first-of-type {
    padding-left: 1rem;
  }
  .batch-order-table .sds-table__row .sds-table__cell:last-of-type,
  .batch-order-table .sds-table__row .sds-table__header:last-of-type {
    padding-right: 1rem;
  }
  .batch-order-table .sds-table__row--header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  .batch-order-table .sds-table__cell {
    flex-basis: 100%;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .batch-order-table .sds-table__cell:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .batch-order-table.sds-table--spacious .sds-table__row {
    padding: 0.75rem 0;
  }
  .batch-order-table.sds-table--spacious .sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .batch-order-table.sds-table--compact .sds-table__row {
    padding: 0.25rem 0;
  }
  .batch-order-table.sds-table--compact .sds-table__cell {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
.bulk-action-check-all.some-checked .sds-checkbox_faux::after {
  background: url(https://assets.sendle.com/assets/icons/select-clear-d629578d5a98e036e71fe3f74cf567f9b2e89299ea6b21153996467b4fd3e1fe.svg)
    no-repeat 0 3px !important;
}
.bulk-actions-check-all-container .bulk-action-check-all {
  display: inline-block;
}
.bulk-actions-check-all-container .bulk-action-check-all .sds-checkbox__label,
.bulk-actions-check-all-container
  .bulk-action-check-all
  .sds-form-element__label {
  margin-bottom: 0;
}
@media (min-width: 1060px) {
  .bulk-actions-check-all-container .bulk-action-check-all {
    display: none;
  }
}
.order-table-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: #f7f8fa;
}
.order-table-footer__summary {
  display: flex;
  align-items: flex-end;
  text-align: right;
  padding: 0.5rem 0;
  font-size: 1.25rem;
}
.order-table-footer__tooltip-component {
  padding: 1rem !important;
  padding-bottom: 0 !important;
  margin-left: -150px !important;
  margin-top: -129px !important;
  opacity: 1 !important;
  text-align: left;
  background-color: #e9edf0 !important;
  color: #33424f !important;
  font-size: 0.875rem;
  width: 260px;
  box-shadow: 0 2px 4px 0 rgba(51, 66, 79, 0.23);
  border-radius: 8px !important;
}
.order-table-footer__tooltip-component::after {
  border-top: 12px solid !important;
  border-color: transparent !important;
  border-left-color: #e9edf0 !important;
  border-width: 12px !important;
  left: 267px !important;
  top: 50% !important;
  z-index: 1200 !important;
}
@media (max-width: 767px) {
  .order-table-footer__tooltip-component {
    margin: 12px auto !important;
    width: 240px;
  }
  .order-table-footer__tooltip-component::after {
    border-bottom-color: #e9edf0 !important;
    border-left-color: transparent !important;
    border-width: 12px !important;
    top: -24px !important;
    left: 50% !important;
    z-index: 9000;
  }
}
.order-row .hinttip {
  color: #33424f;
}
.order-row.order-invalid {
  background: #fcebec;
}
.order-row.order-invalid td {
  color: #ba3037;
}
.order-row.order-invalid .hinttip > span {
  font-weight: bold;
  border-bottom: 2px solid #d94c4c;
  margin-right: 10px;
  padding: 0 1px;
}
.order-row.order-warning {
  background: #fff8eb;
}
.order-row.order-warning .hinttip > span {
  font-weight: bold;
  border-bottom: 2px solid #554407;
  margin-right: 10px;
  padding: 0 1px;
}
.order-row.order-processing td,
.order-row.order-processing th {
  color: #9fa7ae;
}
.order-row.order-processing:hover {
  cursor: wait;
}
.order-row.order-selectable:hover {
  cursor: pointer;
  background: #e6f7ff;
}
.order-row.order-not-selectable:hover {
  cursor: not-allowed;
}
.order-row .price {
  text-align: right;
}
.order-row .price .glyphicon-star {
  color: #ffd400;
  margin-left: 5px;
}
.table > tbody > .order-row > td,
.table > tbody > .order-row > th {
  padding: 14px 8px;
}
.overflow-scroller {
  --max-fade: 3rem;
  --start: 0;
  --end: 0;
  --mask-image: linear-gradient(
    to right,
    transparent,
    black min(var(--start), var(--max-fade)),
    black,
    black calc(100% - min(var(--end), var(--max-fade))),
    transparent
  );
  display: flex;
  position: relative;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-mask-image: var(--mask-image);
  mask-image: var(--mask-image);
}
.overflow-scroller::-webkit-scrollbar {
  display: none;
}
.packing-instructions h3 {
  margin-top: 15px;
  margin-bottom: 16px;
}
.packing-instructions h4 {
  font-size: 13px;
  margin-top: 15px;
}
.packing-instructions .quoted {
  margin-bottom: 32px;
}
.packing-instructions .help-panel {
  margin-bottom: 16px;
}
.packing-instructions .print-label {
  overflow: visible;
  padding-top: 3px;
  margin-bottom: 9px;
}
.packing-instructions .print-label:before,
.packing-instructions .print-label:after {
  content: "";
  display: table;
}
.packing-instructions .print-label:after {
  clear: both;
}
.packing-instructions .btn-print {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 13px;
  line-height: 22px;
  background: #ff6800;
  float: left;
}
.packing-instructions .print-label {
  overflow: visible;
}
.packing-instructions .print-label:before,
.packing-instructions .print-label:after {
  content: "";
  display: table;
}
.packing-instructions .print-label:after {
  clear: both;
}
.packing-instructions .package-label {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  border-radius: 2px;
  background: #30414e;
  font-size: 15px;
  color: white;
  padding: 15px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.packing-instructions .package-label .direction {
  display: inline-block;
  width: 80px;
}
.packing-instructions .package-label .indent {
  padding-left: 85px;
}
.packing-instructions .btn-label-options {
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #34424f;
  color: white;
  font-size: 11px;
  font-weight: normal;
  line-height: 22px;
}
.packing-instructions .btn-label-options .caret {
  margin-left: 5px;
}
.packing-instructions .label-options {
  float: left;
}
.packing-instructions .label-options li a {
  text-align: right;
  padding: 6px 12px 6px 20px;
}
.packing-instructions .label-options li a .option-label {
  float: left;
}
.packing-instructions .label-options li a .icon,
.packing-instructions
  .label-options
  li
  a
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .packing-instructions
  .label-options
  li
  a
  form:before,
.packing-instructions .label-options li a #support #modal-search form:before,
#support #modal-search .packing-instructions .label-options li a form:before,
.packing-instructions
  .label-options
  li
  a
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .packing-instructions
  .label-options
  li
  a
  form:before,
.packing-instructions .label-options li a .navbar-form.search-form:after {
  margin-top: 4px;
}
.packing-instructions .btn-label-options {
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #34424f;
  color: white;
  font-size: 11px;
  font-weight: normal;
  line-height: 22px;
}
.packing-instructions .btn-label-options .caret {
  margin-left: 5px;
}
.packing-instructions .label-options {
  float: left;
}
.packing-instructions .label-options li a {
  text-align: right;
  padding: 6px 12px 6px 20px;
}
.packing-instructions .label-options li a .option-label {
  float: left;
}
.packing-instructions .label-options li a .icon,
.packing-instructions
  .label-options
  li
  a
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .packing-instructions
  .label-options
  li
  a
  form:before,
.packing-instructions .label-options li a #support #modal-search form:before,
#support #modal-search .packing-instructions .label-options li a form:before,
.packing-instructions
  .label-options
  li
  a
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .packing-instructions
  .label-options
  li
  a
  form:before,
.packing-instructions .label-options li a .navbar-form.search-form:after {
  margin-top: 4px;
}
.tick-list {
  padding: 0;
  margin: 0 0 14px 0;
  list-style-type: none;
}
.tick-list li {
  margin: 0 0 3px 0;
  padding: 0 0 0 0;
  text-indent: -14px;
}
.tick-list li:before {
  content: "√";
  width: 8px;
  margin-right: 6px;
  color: #b7bdc2;
  font-weight: bold;
}
.packing-illustration-container {
  background: #f4f4f4;
  padding: 20px 10px;
  margin: 20px 0 35px 0;
}
.packing-illustration-container .packaging-details {
  padding-top: 25px;
  padding-left: 30px;
  max-width: 220px;
  margin: 0 auto;
}
.packing-illustration-container .packaging-details h5 {
  margin-top: 0;
  margin-bottom: 13px;
}
.packing-illustration-container .package-example {
  margin: 0 auto;
  max-width: 250px;
  width: 100%;
}
@media (min-width: 768px) {
  .packing-illustration-container {
    padding: 20px 5px;
    margin: 38px 0 20px 0;
  }
  .packing-illustration-container .packaging-details {
    padding-top: 5px;
    padding-left: 14px;
    margin: 0;
  }
  .packing-illustration-container .package-example {
    margin: 0;
    max-width: auto;
  }
}
.pagination {
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 1rem 0;
  text-align: center;
}
.pagination li {
  display: inline-block;
  margin: 0 0.25rem 0 0;
}
.pagination li:first-child > a:not(.sds-button),
.pagination li:first-child > span:not(.sds-button),
.pagination li:last-child > a:not(.sds-button),
.pagination li:last-child > span:not(.sds-button) {
  border-radius: 0.25rem;
}
.pagination li > a:not(.sds-button):hover,
.pagination li > a:not(.sds-button):focus {
  border-color: #e9edf0;
}
.pagination li.active a:first-child {
  background: #33424f;
  border-color: #33424f;
  color: #ffffff;
  pointer-events: none;
}
@media (min-width: 768px) {
  .pagination {
    text-align: left;
  }
}
.panel.panel-step {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -khtml-border-radius: 0;
  border-radius: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
}
.panel.panel-step .panel-heading {
  padding: 5px 0 2px;
}
@media (min-width: 768px) {
  .panel.panel-step .panel-heading {
    padding: 0;
  }
}
.panel.panel-step .panel-heading a {
  padding: 10px 15px 10px 1px;
  display: block;
  color: #33424f;
}
.panel.panel-step .panel-heading a:hover {
  text-decoration: none;
  background: transparent;
}
.panel.panel-step .panel-heading.collapsable a {
  cursor: pointer;
}
.panel.panel-step .panel-heading.collapsable a:hover {
  background: #eff9f9;
}
.panel.panel-step .panel-heading .icon,
.panel.panel-step .panel-heading #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .panel.panel-step .panel-heading form:before,
.panel.panel-step .panel-heading #support #modal-search form:before,
#support #modal-search .panel.panel-step .panel-heading form:before,
.panel.panel-step .panel-heading #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .panel.panel-step .panel-heading form:before,
.panel.panel-step .panel-heading .navbar-form.search-form:after {
  float: left;
  margin-top: -3px;
}
.panel.panel-step .panel-heading .title {
  font-weight: bold;
  display: block;
}
.panel.panel-step .panel-heading .action-date {
  display: block;
  margin: 2px 10px 0 35px;
  font-size: 0.9em;
}
.panel.panel-step .panel-heading .estimated-delivery-date {
  display: inline-block;
  margin: 0 2px 0 34px;
  font-size: 0.9em;
}
.panel.panel-step .panel-heading .action-time {
  display: none;
}
@media (min-width: 768px) {
  .panel.panel-step .panel-heading a {
    overflow: hidden;
    padding: 10px 15px 5px 1px;
  }
  .panel.panel-step .panel-heading .title {
    display: block;
    float: left;
    width: 40%;
    margin-right: -1px;
  }
  .panel.panel-step .panel-heading .action-date {
    display: inline-block;
    width: 45%;
    margin: 0;
    font-size: 1em;
  }
  .panel.panel-step .panel-heading .estimated-delivery-date {
    display: inline-block;
    width: 60%;
    margin: 0;
    font-size: 1em;
  }
}
@media (min-width: 992px) {
  .panel.panel-step .panel-heading .title {
    width: 25%;
  }
  .panel.panel-step .panel-heading .action-date {
    width: 40%;
  }
}
.panel.panel-step .processing-heading {
  color: #ff6800;
  font-size: 13px;
  margin: 0 0 -10px 0;
}
.panel.panel-step .panel-body {
  padding: 0px 15px 0px 36px;
}
.panel.panel-step .panel-body h2 {
  margin: 0 0 10px 0;
}
.panel.panel-step .panel-body h2.highlight {
  margin: 5px 0 12px 0;
}
.panel.panel-step .panel-body h4 {
  margin: 0 0 10px 0;
  font-size: 13px;
}
.panel.panel-step .panel-body .panel-body-inner {
  padding: 5px 0 15px;
}
.panel.panel-step .highlight {
  color: #ff6800;
}
.panel.panel-step.panel-inactive .panel-heading .title {
  color: #aaa;
}
.panel-heading.collapsable a:after {
  font-family: Courier;
  font-size: 16px;
  font-weight: bold;
  color: #33424f;
  content: "-";
  float: right;
  color: grey;
  line-height: 16px;
  padding-left: 6px;
  margin-top: -15px;
}
@media (min-width: 768px) {
  .panel-heading.collapsable a:after {
    margin-top: 1px;
  }
}
.panel-heading.collapsable a.collapsed:after {
  content: "+";
}
.panel-prompt {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  border-radius: 2px;
  background-color: #f4fcfe;
  border: 1px solid #aee6f3;
  border-bottom: 2px solid #aee6f3;
  color: #5884a9;
}
.panel-prompt .panel-heading {
  color: #324251;
  padding: 15px 15px 0 15px;
  margin: 0;
  font-weight: normal;
}
.panel-prompt .panel-body {
  padding: 15px;
}
.panel-prompt .prompt-action {
  margin: 20px 0 10px 0;
}
.panel-plain {
  background: transparent;
  border: 1px solid transparent;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: none;
}
.panel-plain .panel-heading {
  color: #757f88;
  padding: 15px 15px 0 15px;
  margin: 0;
  font-weight: normal;
}
.panel-plain .panel-body {
  color: #9fa7ae;
}
.panel-plain .panel-body a {
  color: #9fa7ae;
  text-decoration: underline;
}
.panel-plain .panel-body a:hover {
  color: #838e97;
}
.panel-summary.panel-formgroup .panel-heading {
  padding: 20px 15px 0 15px;
  margin-bottom: 0;
}
.panel-summary.panel-formgroup .panel-body {
  padding: 10px 15px 23px 15px;
}
@media (min-width: 768px) {
  .panel-plain .panel-heading,
  .panel-summary .panel-heading,
  .panel-prompt .panel-heading {
    padding: 20px 20px 0 20px;
  }
  .panel-plain .panel-body,
  .panel-summary .panel-body,
  .panel-prompt .panel-body {
    padding: 15px 20px;
  }
  .panel-summary.panel-formgroup .panel-heading {
    padding: 20px 20px 0 20px;
  }
  .panel-summary.panel-formgroup .panel-body {
    padding: 10px 20px 23px 20px;
  }
}
.panel-loading,
.panel-loading-plain {
  text-align: center;
}
.panel-loading:after,
.panel-loading-plain:after {
  content: "\e031";
  color: #999;
  font-size: 22px;
  height: 22px;
  width: 22px;
}
.panel-loading-plain {
  box-shadow: none;
  min-height: 64px;
}
.panel-loading {
  border: 1px solid #f7f7f7;
  background: #fafafa;
  min-height: 150px;
  padding: 64px 0;
}
.help-panel {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  background: #f5fcfe;
  border: 1px solid #49ceeb;
  padding: 17px 20px 10px 20px;
  position: relative;
  margin-top: 8px;
}
.help-panel ul {
  list-style-type: none;
  padding: 0;
}
.help-panel li {
  display: inline-block;
}
.help-panel p,
.help-panel dl {
  font-size: 12px;
  color: #5985ab;
}
.help-panel p.intro {
  font-size: 13px;
}
.help-panel p.intro .help-link {
  margin-left: 8px;
}
.help-panel .button-list a {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -khtml-border-radius: 25px;
  border-radius: 25px;
  font-size: 12px;
  display: inline-block;
  background: #49ceeb;
  color: white;
  padding: 4px 8px;
  margin: 0 3px 3px 0;
}
.help-panel .button-list a:hover {
  background: #1bc2e6;
  text-decoration: none;
}
.help-panel .button-list a.selected,
.help-panel .button-list a.selected:hover {
  background: #39ce7f;
}
.help-panel .button-list button {
  margin-top: 1rem;
}
.help-panel .help-link {
  font-weight: normal;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
}
.help-panel .pointer {
  display: block;
  position: absolute;
  top: -12px;
  left: 12px;
  border-bottom: 12px solid #49ceeb;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: 8px;
  left: 20px;
}
.help-panel .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.help-panel .pointer:before {
  top: 1px;
  left: -12px;
  border-bottom: 12px solid #f5fcfe;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.parcel-meta {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #9fa7ae;
}
.parcel-meta li {
  margin-bottom: 6px;
}
.parcel-meta .eligible-promotion-message {
  width: 500px;
}
.parcel-meta .eligible-promotion-message a {
  text-decoration: underline;
  color: #9fa7ae;
}
.parcel-nav {
  max-width: 755px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.parcel-nav .nav-item {
  display: inline-block;
  width: 225px;
  height: 64px;
}
.parcel-nav .nav-item .domestic-icon,
.parcel-nav .nav-item .international-icon {
  float: right;
}
.parcel-nav .nav-item .domestic-icon:before,
.parcel-nav .nav-item .international-icon:before {
  content: "";
  display: block;
  height: 50px;
  width: 50px;
  background: ./assets/icontwo.svg)
    no-repeat no-repeat white;
  background-size: 797px 469px;
  position: relative;
  margin: 0 auto;
}
.parcel-nav .nav-item .domestic-icon {
  width: 40px;
  height: 28px;
  margin-top: -20px;
}
.parcel-nav .nav-item .domestic-icon:before {
  background-position: -4px -289px;
  background-color: transparent;
}
.parcel-nav .nav-item .international-icon {
  width: 38px;
  height: 38px;
  margin-top: -20px;
  margin-right: 5px;
}
.parcel-nav .nav-item .international-icon:before {
  background-position: -116px -289px;
  background-color: transparent;
}
.parcel-nav .nav-item a {
  color: #7a8691;
  height: 100%;
}
.parcel-nav .nav-item.active strong,
.parcel-nav .nav-item:hover strong {
  color: #30414e;
}
.parcel-nav .nav-item.active .domestic-icon:before,
.parcel-nav .nav-item:hover .domestic-icon:before {
  background-position: -61px -289px;
}
.parcel-nav .nav-item.active .international-icon:before,
.parcel-nav .nav-item:hover .international-icon:before {
  background-position: -173px -289px;
}
.parcel-nav .nav-item:hover a {
  background-color: #f4f4f4;
}
.parcel-nav .nav-item.active {
  background-color: #fff;
  border: 1px solid #aeb8c4;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
  height: 65px;
}
.parcel-nav .nav-item.active:hover a {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .parcel-nav .nav-item {
    width: 49.5%;
  }
}
@media screen and (max-width: 357px) {
  .parcel-nav .nav-item {
    width: 100%;
  }
}
.payment-method .card-remove {
  padding-left: 1rem;
}
.payment-method .card-brand {
  padding-left: 1rem;
  padding-right: 0.25rem;
}
.payment-method .card-number {
  padding-right: 1rem;
}
.payment-method .card-expiry {
  color: #6d7883;
  padding-right: 1rem;
}
.payment-method .edit-tip {
  clear: both;
  display: block;
}
.velocity-membership .btn-delete {
  float: right;
}
.pickup-list-table.sds-table--highlight-hover tr:hover {
  cursor: pointer;
}
.pickup-list-table .pickup-list-item__pickup-date,
.pickup-list-table .pickup-list-item__courier-name,
.pickup-list-table .pickup-list-item__parcel-count {
  margin-left: 3rem;
}
.pickup-list-table .courier-container {
  display: flex;
  white-space: nowrap;
}
.pickup-list-table .courier-container__courier-name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 24rem;
}
@media screen and (max-width: 1220px) {
  .pickup-list-table .courier-container__courier-name {
    min-width: 0;
    max-width: 15rem;
  }
}
@media screen and (max-width: 1220px) {
  .pickup-list-table {
    border-top: 0.5rem solid #e9edf0;
  }
  .pickup-list-table.sds-table--bold {
    border-top: 0.5rem solid #33424f;
  }
  .pickup-list-table.sds-table--subtle {
    border-top-width: 0.0625rem;
  }
  .pickup-list-table.sds-table--headless {
    border-top-width: 0.0625rem;
  }
  .pickup-list-table .sds-table__table {
    display: block;
  }
  .pickup-list-table .sds-table__table thead,
  .pickup-list-table .sds-table__table tbody,
  .pickup-list-table .sds-table__table tfoot {
    display: block;
  }
  .pickup-list-table .sds-table__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .pickup-list-table .sds-table__row .sds-table__cell:first-of-type,
  .pickup-list-table .sds-table__row .sds-table__header:first-of-type {
    padding-left: 1rem;
  }
  .pickup-list-table .sds-table__row .sds-table__cell:last-of-type,
  .pickup-list-table .sds-table__row .sds-table__header:last-of-type {
    padding-right: 1rem;
  }
  .pickup-list-table .sds-table__row--header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  .pickup-list-table .sds-table__cell {
    flex-basis: 100%;
    border: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pickup-list-table .sds-table__cell:before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .pickup-list-table.sds-table--spacious .sds-table__row {
    padding: 0.75rem 0;
  }
  .pickup-list-table.sds-table--spacious .sds-table__cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .pickup-list-table.sds-table--compact .sds-table__row {
    padding: 0.25rem 0;
  }
  .pickup-list-table.sds-table--compact .sds-table__cell {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
.pickup-list-item__parcel-count {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  width: 20%;
}
.pickup-list-item__parcel-count strong {
  margin-left: 1.5rem;
}
@media screen and (max-width: 1220px) {
  .pickup-list-item__parcel-count {
    flex-basis: auto !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    order: -1;
  }
}
@media screen and (max-width: 1220px) {
  .pickup-list-item__parcel-count {
    margin-right: 1.5rem;
  }
  .pickup-list-item__parcel-count strong {
    margin-left: 0;
  }
}
@media screen and (max-width: 1220px) {
  .pickup-list-item__blank {
    padding: 0 !important;
  }
}
.pickup-list-item__pickup-date {
  width: 6rem;
  white-space: nowrap;
}
@media screen and (max-width: 1220px) {
  .pickup-list-item__pickup-date {
    flex-basis: auto;
    order: -1;
    max-width: fit-content;
  }
}
@media screen and (max-width: 1220px) and (-ms-high-contrast: none),
  screen and (max-width: 1220px) and (-ms-high-contrast: active) {
  .pickup-list-item__pickup-date {
    max-width: 115px;
  }
}
@keyframes pop {
  50% {
    transform: scale(1.15);
  }
}
.pickup-list-item__pickup-date__wow {
  padding: 0.125rem 0.5rem;
  text-align: center;
  display: inline-block;
  background-color: #e6f7ff;
  color: #008dcc;
  line-height: 2;
  font-size: 10px;
  font-weight: 700;
  border-radius: 0.5rem;
  animation: pop 0.4s linear 1;
  animation-delay: 1s;
}
.pickup-list-item__icon {
  vertical-align: middle;
}
@media screen and (max-width: 1220px) {
  .pickup-list-item__icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
}
.scheduled-pickup-orders .overview-list__heading {
  margin: 2rem 0 1rem 0;
}
.scheduled-pickup-orders__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  margin: 3rem 0;
}
.scheduled-pickup-orders__header h2 {
  margin-bottom: 1rem;
}
.scheduled-pickup-orders__header__instructions {
  margin-bottom: 0.5rem;
}
.scheduled-pickup-orders__header__error {
  margin-bottom: 0.5rem;
}
.scheduled-pickup-orders__header__error_image {
  width: 182px;
  height: 89px;
}
.scheduled-pickup-orders__header__pickup_window {
  margin-bottom: 0.5rem;
}
.pill {
  display: inline-flex;
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: #f7f8fa;
  vertical-align: middle;
  height: 20px;
  white-space: nowrap;
}
.pill:not(:first-child) {
  margin-left: 0.25rem;
}
.pill:not(:last-child) {
  margin-right: 0.25rem;
}
.pill--risk {
  background-color: #fcebec;
  color: #ba3037;
}
.pill--warning {
  background-color: #fff8eb;
  color: #916c1f;
}
.pill--success {
  background-color: #e5f6ee;
  color: #007f48;
}
.pill--info {
  background-color: #e6f7ff;
  color: #008dcc;
}
.pill--pop {
  animation: pop 0.4s linear 1;
  animation-delay: 1s;
}
@keyframes pop {
  50% {
    transform: scale(1.15);
  }
}
.pricing-calculator-modal {
  -webkit-overflow-scrolling: auto;
  z-index: 2000;
}
.pricing-calculator-modal .modal-header {
  overflow: hidden;
  border-bottom: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 575px) {
  .pricing-calculator-modal .modal-header {
    margin-bottom: 1rem;
  }
}
.pricing-calculator-modal .modal-body {
  padding-top: 0;
}
.pricing-calculator-modal .pricing-calculator-container {
  max-width: 650px;
  margin: 0 auto;
}
.pricing-calculator-modal .pricing-calculator-container h2 {
  margin-bottom: 25px;
}
.pricing-calculator-modal .pricing-calculator-container p {
  margin-bottom: 15px;
  font-size: 1rem;
}
.pricing-calculator {
  padding-bottom: 20px;
}
.pricing-calculator .address {
  padding: 1rem 1.5rem;
  font-size: 1rem;
}
@media screen and (max-width: 575px) {
  .pricing-calculator .address {
    padding: 1rem;
  }
}
.pricing-calculator .form {
  width: 261px;
  text-align: left;
  margin: 2rem auto;
}
@media screen and (max-width: 575px) {
  .pricing-calculator .form {
    width: 100%;
  }
}
.pricing-calculator .form-group {
  position: relative;
  overflow: hidden;
  min-height: 73px;
  padding-left: 90px;
  margin-bottom: 4px;
}
.pricing-calculator .form-group .icon,
.pricing-calculator .form-group #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .pricing-calculator .form-group form:before,
.pricing-calculator .form-group #support #modal-search form:before,
#support #modal-search .pricing-calculator .form-group form:before,
.pricing-calculator .form-group #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .pricing-calculator .form-group form:before,
.pricing-calculator .form-group .navbar-form.search-form:after {
  position: absolute;
}
.pricing-calculator .form-group-width .icon,
.pricing-calculator .form-group-width #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .pricing-calculator .form-group-width form:before,
.pricing-calculator .form-group-width #support #modal-search form:before,
#support #modal-search .pricing-calculator .form-group-width form:before,
.pricing-calculator .form-group-width #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .pricing-calculator .form-group-width form:before,
.pricing-calculator .form-group-width .navbar-form.search-form:after {
  top: 7px;
  left: 7px;
}
.pricing-calculator .form-group-height .icon,
.pricing-calculator
  .form-group-height
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .pricing-calculator
  .form-group-height
  form:before,
.pricing-calculator .form-group-height #support #modal-search form:before,
#support #modal-search .pricing-calculator .form-group-height form:before,
.pricing-calculator
  .form-group-height
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .pricing-calculator
  .form-group-height
  form:before,
.pricing-calculator .form-group-height .navbar-form.search-form:after {
  top: 4px;
  left: 4px;
}
.pricing-calculator .form-group-length .icon,
.pricing-calculator
  .form-group-length
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .pricing-calculator
  .form-group-length
  form:before,
.pricing-calculator .form-group-length #support #modal-search form:before,
#support #modal-search .pricing-calculator .form-group-length form:before,
.pricing-calculator
  .form-group-length
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .pricing-calculator
  .form-group-length
  form:before,
.pricing-calculator .form-group-length .navbar-form.search-form:after {
  top: 6px;
  left: 0px;
}
.pricing-calculator .form-group-weight .icon,
.pricing-calculator
  .form-group-weight
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .pricing-calculator
  .form-group-weight
  form:before,
.pricing-calculator .form-group-weight #support #modal-search form:before,
#support #modal-search .pricing-calculator .form-group-weight form:before,
.pricing-calculator
  .form-group-weight
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .pricing-calculator
  .form-group-weight
  form:before,
.pricing-calculator .form-group-weight .navbar-form.search-form:after {
  top: 14px;
  left: 4px;
}
.pricing-calculator .form-control {
  padding-right: 40px;
}
@media screen and (max-width: 991px) {
  .pricing-calculator .from-location {
    margin-bottom: 1rem;
  }
}
.pricing-calculator .unit {
  display: block;
  position: absolute;
  font-weight: bold;
  top: 33px;
  width: 25px;
  height: 30px;
  right: 10px;
  font-size: 1rem;
}
.pricing-calculator .parcel-size-message,
.pricing-calculator .action-message,
.pricing-calculator .error-message {
  margin-top: -30px;
}
.pricing-calculator .form .form-control {
  font-size: 1rem;
}
.calculated-size {
  border-top: 1px solid #ddd;
  font-size: 1rem;
  padding-top: 20px;
  text-align: center;
  margin: 38px 0 1.5rem 0;
}
.calculated-size:before {
  content: "";
  display: block;
  height: 56px;
  width: 56px;
  background: url(./assets/box.svg)
    no-repeat no-repeat white;
  background-size: 840px 502px;
  background-position: -560px 0;
  margin: 0 auto;
  margin-top: -45px;
  margin-bottom: 45px;
}
.calculated-size.us-domestic:before {
  content: "";
  display: block;
  height: 64px;
  width: 64px;
  background: url(./assets/box2.svg)
    no-repeat no-repeat white;
  background-size: 318px 255px;
  background-position: -560px 0;
  margin: 0 auto;
  margin-top: -45px;
  margin-bottom: 45px;
}
.calculated-size.us-domestic.calculated-size-micro:before {
  background-position: 0 0;
}
.calculated-size.us-domestic.calculated-size-mini:before {
  background-position: -56px 0;
}
.calculated-size.us-domestic.calculated-size-small:before {
  background-position: -112px 0;
}
.calculated-size.us-domestic.calculated-size-medium:before {
  background-position: -168px 0;
}
.calculated-size.us-domestic.calculated-size-large:before {
  background-position: -224px 0;
}
.calculated-size.calculated-size-satchel:before {
  background-position: 0 -168px;
}
.calculated-size.calculated-size-handbag:before {
  background-position: -728px -168px;
}
.calculated-size.calculated-size-shoebox:before {
  background-position: -56px -168px;
}
.calculated-size.calculated-size-briefcase:before {
  background-position: -112px -168px;
}
.calculated-size.calculated-size-carry-on:before {
  background-position: -168px -168px;
}
.calculated-size.calculated-size-check-in:before {
  background-position: -224px -168px;
}
.calculated-size .error-message {
  color: #ff6800;
}
.modal-open .modal-backdrop {
  background: rgba(0, 0, 0, 0.7);
}
.modal-open .modal-backdrop.in {
  opacity: 1;
  z-index: 1040;
}
.progress-bar-with-label {
  margin-top: 15px;
}
.progress-bar-with-label .progress {
  height: 5px;
  margin-bottom: 8px;
}
.progress-bar-with-label .progress-bar-label {
  margin: 0 0 18px 2px;
}
.promo-popover {
  padding-bottom: 18px;
}
.promo-popover .velocity-logo {
  background: url(https://assets.sendle.com/assets/partners/velocity/velocity-logo-ee469893161aa8a7d8d29a1ae4b81c479ec2dd1eba1d244af0b1a80193a7f564.svg)
    center top no-repeat;
  background-size: 150px 62px;
  width: 150px;
  height: 62px;
  margin: 10px auto 25px auto;
}
.promo-popover .velocity-ten-x-logo {
  background: url(https://assets.sendle.com/assets/partners/velocity/10x_points-671a40bc5d522b8ff23460fdf6eddb2de06f347c0d6a187fb90805c40595beaf.png)
    center top no-repeat;
  background-size: 133px 133px;
  width: 133px;
  height: 133px;
  margin: 10px auto 25px auto;
}
.promo-popover .velocity-ten-k-logo {
  background: url(https://assets.sendle.com/assets/partners/velocity/10k_points-5312320b7500ffd26701920cdc7e8ca5e836755251c187ffb67bb876483e9444.png)
    center top no-repeat;
  background-size: 133px 133px;
  width: 133px;
  height: 133px;
  margin: 10px auto 25px auto;
}
.promo-popover .tada-logo {
  background: url(https://assets.sendle.com/assets/icons/icon_tada-fb865f0b88a8019be7a2039748b729291a6654aa4edf58c355ad7ee3eeda83b4.png)
    center top no-repeat;
  background-size: 62px 62px;
  width: 62px;
  height: 62px;
  margin: 10px auto 25px auto;
}
.promo-popover .package-logo {
  background: url(https://assets.sendle.com/assets/icons/icon_package-d357c492bcc63492eb712ee5aea5624b3b985e1117ecd7d5cfccea0befb1582a.png)
    center top no-repeat;
  background-size: 160px 160px;
  width: 160px;
  height: 160px;
  margin: 10px auto 25px auto;
}
.promo-popover .international-logo {
  background: url(https://assets.sendle.com/assets/icons/icon_international_ordering-d9d7c5869b91ef34b2c2ec1f2e805075b8054295d54e2fc642f2caf792f20056.png)
    center top no-repeat;
  background-size: 80px 80px;
  width: 80px;
  height: 80px;
  margin: 10px auto 25px auto;
}
.promo-popover p {
  margin: 0 0 20px 0;
}
.promo-popover .intro-reference {
  font-size: 11px;
  vertical-align: text-top;
}
.promo-popover .tandc-sup {
  color: #b7bdc2;
  font-size: 10px;
}
.promo-popover .tandc-link {
  color: #b7bdc2;
  text-decoration: underline;
  font-size: 10px;
}
.promo-popover .tandc-link:hover {
  color: #838383;
}
.promo-popover .footer-link {
  font-size: 10px;
}
.promo-popover .text-align-right {
  text-align: right;
}
.btn-get-quote {
  line-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
}
.quote-tool-container {
  display: inline-block;
  position: relative;
}
.quote-tool {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  width: 280px;
  padding: 15px 0 0 0;
  margin-bottom: 30px;
  background: white;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  top: 47px;
  left: -140px;
}
.quote-tool.no-address {
  padding: 15px 15px 10px 15px;
}
.quote-tool.no-address p {
  font-size: 13px;
  text-align: center;
}
.quote-tool .control-label {
  text-align: left;
  display: block;
}
.quote-tool .form-control {
  border: none;
}
.quote-tool .pointer {
  display: block;
  position: absolute;
  top: -12px;
  left: 12px;
  border-bottom: 12px solid #ddd;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  left: 8px;
  left: 150px;
}
.quote-tool .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.quote-tool .pointer:before {
  top: 1px;
  left: -12px;
  border-bottom: 12px solid white;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
@media (min-width: 768px) {
  .quote-tool .pointer {
    left: 15px;
  }
}
.quote-tool .close-btn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
  line-height: 15px;
  color: #bbb;
  padding: 5px 7px;
  height: 20px;
}
.quote-tool .close-btn:hover {
  color: #888;
  text-decoration: none;
}
@media (min-width: 768px) {
  .quote-tool {
    left: 0;
  }
}
.quote-tool .form-group {
  margin: 0 15px;
}
.quote-tool .field .help-block {
  display: none;
}
.quote-form .form-group {
  margin-top: 10px;
}
.quote-form .form-group:first-child {
  margin-top: 0;
}
.quote-list {
  list-style-type: none;
  padding: 10px 0 0 0;
  margin: 0;
}
.quote-list .sds-alert-message {
  margin: 0 1rem 0.75rem;
}
.quote-list .alert-content {
  display: inline-flex;
  align-items: center;
}
.quote-list .alert-content .alert-content__icon svg {
  width: 20px;
  height: auto;
}
.quote-list .alert-content__message {
  font-size: 13px;
  padding-left: 1rem;
  line-height: 24px;
}
.quote-empty,
.quote-error {
  font-size: 12px;
  padding: 0 15px 10px 16px;
  text-align: left;
}
.quote-empty .glyphicon,
.quote-empty .btn-loading:after,
.quote-empty .panel-loading:after,
.quote-empty .panel-loading-plain:after,
.quote-error .glyphicon,
.quote-error .btn-loading:after,
.quote-error .panel-loading:after,
.quote-error .panel-loading-plain:after {
  margin-top: 10px;
}
.quote-item {
  display: block;
  overflow: hidden;
  padding: 5px 15px;
  color: #33424f;
}
.quote-item:before {
  background: url(./assets/box.svg)
    no-repeat no-repeat white;
  background-size: 840px 502px;
  background-size: 754px 450px;
  background-color: transparent;
  content: "";
  float: left;
  display: block;
  margin: 0 5px 0 0;
  height: 50px;
  width: 50px;
}
.quote-item:after {
  background: url(./assets/icons.svg)
    no-repeat no-repeat;
  background-size: 1650px 100px;
  content: "";
  width: 10px;
  height: 14px;
  float: right;
  margin-top: 17px;
  display: inline;
  background-position: -409px 0;
}
.quote-item.quote-item-satchel:before {
  background-position: 0 0;
}
.quote-item.quote-item-satchel:hover:before {
  background-position: 0 -50px;
}
.quote-item.quote-item-shoebox:before {
  background-position: -50px 0;
}
.quote-item.quote-item-shoebox:hover:before {
  background-position: -50px -50px;
}
.quote-item.quote-item-handbag:before {
  background-position: -654px 0px;
}
.quote-item.quote-item-handbag:hover:before {
  background-position: -654px -50px;
}
.quote-item.quote-item-briefcase:before {
  background-position: -101px 0;
}
.quote-item.quote-item-briefcase:hover:before {
  background-position: -101px -50px;
}
.quote-item.quote-item-carry-on:before {
  background-position: -151px 0;
}
.quote-item.quote-item-carry-on:hover:before {
  background-position: -151px -50px;
}
.quote-item.quote-item-check-in:before {
  background-position: -201px 0;
}
.quote-item.quote-item-check-in:hover:before {
  background-position: -201px -50px;
}
.quote-item.quote-item-micro:before,
.quote-item.quote-item-mini:before,
.quote-item.quote-item-small:before {
  background-position: -302px 0;
}
.quote-item.quote-item-micro:hover:before,
.quote-item.quote-item-mini:hover:before,
.quote-item.quote-item-small:hover:before {
  background-position: -302px -50px;
}
.quote-item.quote-item-medium:before {
  background-position: -352px 0;
}
.quote-item.quote-item-medium:hover:before {
  background-position: -352px -50px;
}
.quote-item.quote-item-large:before {
  background-position: -402px 0;
}
.quote-item.quote-item-large:hover:before {
  background-position: -402px -50px;
}
.quote-item:hover {
  color: #33424f;
  background: #f5f5f5;
}
.quote-item .quote-size {
  display: block;
  float: left;
  width: 100px;
  padding: 6px 0 0 0;
}
.quote-item .quote-size > span,
.quote-item .quote-size > strong {
  display: block;
}
.quote-item .currency {
  margin-top: 16px;
  float: left;
  font-size: 9pt;
  display: inline;
  text-align: right;
}
.quote-item .price {
  display: inline;
  margin-right: 15px;
  width: 0px;
  padding: 14px 6px 0 0;
  font-weight: bold;
  float: left;
  text-align: right;
  font-size: 14px;
}
.quote-item .price .glyphicon,
.quote-item .price .btn-loading:after,
.quote-item .price .panel-loading:after,
.quote-item .price .panel-loading-plain:after {
  display: inline;
  margin-right: 10px;
}
.calculate-size-item {
  color: #33424f;
  display: block;
  background: #f5f5f5;
  padding: 14px 15px 14px 26px;
  font-size: 12px;
  margin-top: 10px;
}
.calculate-size-item span {
  line-height: 28px;
}
.calculate-size-item .icon-grey-chevron {
  position: relative;
  top: 2px;
  margin-left: 6px;
}
.calculate-size-item:before {
  background: url(./assets/icons.svg)
    no-repeat no-repeat;
  background-size: 1650px 100px;
  display: block;
  float: left;
  margin-right: 10px;
  height: 27px;
  line-height: 27px;
  width: 35px;
  content: "";
  background-position: -1275px -72px;
}
.calculate-size-item:hover {
  text-decoration: none;
}
.reset_password .control-label {
  width: 145px;
}
.reset_password .form-control {
  padding-left: 150px;
}
.reset_password .button-group {
  padding-left: 150px;
}
.modal-backdrop.scroll-modal {
  overflow-y: hidden !important;
}
.modal-backdrop.scroll-modal .modal-dialog {
  height: 97.5%;
  overflow: hidden;
}
.modal-backdrop.scroll-modal .modal-dialog .modal-content {
  height: calc(100vh - 60px);
}
@media (min-width: 768px) {
  .modal-backdrop.scroll-modal .modal-dialog {
     height: 95%;
  }
}
.modal-backdrop.scroll-modal .modal-header {
  position: absolute;
  height: 45px;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 0;
}
.modal-backdrop.scroll-modal .modal-body {
  top: 50px;
  left: 0;
  right: 0;
  bottom: 100px;
  overflow-y: scroll;
  padding-top: 0;
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  .modal-backdrop.scroll-modal .modal-body {
    max-height: calc(100vh - 94px);
  }
}
.modal-backdrop.scroll-modal .modal-body-inner {
  max-width: 600px;
  margin: 0 auto 20px auto;
}
.modal-backdrop.scroll-modal .modal-body-inner h2 {
  margin: 0 0 25px 0;
}
.modal-backdrop.scroll-modal .modal-footer {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  height: 100px;
  left: 0;
  right: 0;
  padding: 33px;
  background: #f4f4f4;
  text-align: center;
  z-index: 10;
  bottom: -40px;
}
@media (min-width: 768px) {
  .modal-backdrop.scroll-modal .modal-footer {
    bottom: -20px;
  }
}
.modal-backdrop.scroll-modal .modal-footer-inner {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}
.modal-backdrop.scroll-modal .btn-primary {
  margin-right: 12px;
}
.modal-backdrop.scroll-modal .secondary-action {
  display: inline-block;
  padding-top: 2px;
}
.self-serve-plan .header {
  text-align: center;
}
.self-serve-plan .header .progress {
  position: relative;
  height: 0.25rem;
}
.self-serve-plan .header .progress__background,
.self-serve-plan .header .progress__fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.self-serve-plan .header .progress__background {
  width: 100%;
  background-color: #e9edf0;
}
.self-serve-plan .header .progress__fill {
  background-color: #009de3;
  transition: width 0.2s ease-in-out;
}
.self-serve-plan .header__steps {
  position: relative;
  max-width: 1350px;
  margin: 1.5rem auto 0 auto;
}
.self-serve-plan .header__steps .back {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  padding-left: 1rem;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1;
}
.self-serve-plan .header__steps .back__chevron {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 0.5rem;
}
.self-serve-plan .header__steps .back__label {
  padding-left: 1rem;
}
@media only screen and (max-width: 450px) {
  .self-serve-plan .header__steps .back__label {
    display: none;
  }
}
.self-serve-plan .header__counter {
  margin-top: 1rem;
}
.self-serve-plan .header__error {
  max-width: 1350px;
  margin: 1rem auto;
}
.self-serve-plan .step {
  max-width: 620px;
  margin: 4rem auto 0 auto;
  padding: 0 1rem;
  text-align: center;
}
.self-serve-plan .step__title {
  margin: 0 0 2rem 0;
  margin-bottom: 2rem;
}
.self-serve-plan .step__title--plan {
  margin-bottom: 1rem;
}
.self-serve-plan .step__subtitle {
  margin-bottom: 0.25rem;
}
.self-serve-plan .step__hint {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}
.self-serve-plan .step__container {
  margin-bottom: 3rem;
}
.self-serve-plan .step__container--plan {
  margin: 1.5rem auto 0 auto;
}
.self-serve-plan .step__options {
  margin: 1.5rem auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.self-serve-plan .step__options .option {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  background: none;
  border: none;
  box-shadow: 0 0 0 0.0625rem #e9edf0 inset;
  border-radius: 0.25rem;
  margin-right: 1rem;
}
.self-serve-plan .step__options .option__illustration-use {
  margin-bottom: 0.5rem;
  height: 95px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}
.self-serve-plan .step__options .option__illustration-use--business {
  background-image: url(https://assets.sendle.com/assets/self_serve_plan/business-2b31546137246245ae52221e75522a7dc39e2115b10c32e76fcc9efd4df8213f.png);
}
.self-serve-plan .step__options .option__illustration-use--personal {
  background-image: url(https://assets.sendle.com/assets/self_serve_plan/personal-258771e7d67f153f86c66a82d96890344941dd394b1b3caaf55d5814e284a473.png);
}
.self-serve-plan .step__options .option__title--volume {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.166667;
}
.self-serve-plan .step__options .option__description {
  display: block;
  margin-top: 0.25rem;
  padding: 0 1.5rem;
}
.self-serve-plan .step__options .option--use {
  width: 215px;
  padding: 2rem;
}
.self-serve-plan .step__options .option--volume {
  min-height: 52px;
  padding: 0.125rem 1rem;
}
.self-serve-plan .step__options .option--type-volume {
  max-width: 150px;
}
.self-serve-plan .step__options .option--type-personal_use_type {
  max-width: 200px;
}
.self-serve-plan .step__options .option:last-child {
  margin-right: 0;
}
.self-serve-plan .step__options .option:hover {
  cursor: pointer;
  box-shadow: 0 0 0 0.125rem #009de3 inset;
}
.self-serve-plan .step__options .option.active,
.self-serve-plan .step__options .option:focus,
.self-serve-plan .step__options .option:active {
  box-shadow: 0 0 0 0.125rem #009de3 inset, 0 0 0.125rem 0.125rem #e6f7ff;
}
.self-serve-plan .step__button {
  display: block;
  margin: 2rem auto 2rem auto;
  width: 100%;
  max-width: 196px;
}
.self-serve-plan .step .card {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  padding: 1rem 1rem 1.5rem 1rem;
  margin: 0 auto;
  box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}
.self-serve-plan .step .card__illustration {
  margin-bottom: 0.5rem;
  height: 150px;
  width: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.self-serve-plan .step .card__illustration--Standard {
  background-image: url(https://assets.sendle.com/assets/self_serve_plan/Standard-362ce6f298d9f54adf7e8c5490adab038eab360a51b64905942504fe87c9c99e.gif);
}
.self-serve-plan .step .card__illustration--Premium {
  background-image: url(https://assets.sendle.com/assets/self_serve_plan/Premium-15f045bf6d902b9132deb6d487a0f3cbdd583619c6c480abe9b575de90d33b6d.gif);
}
.self-serve-plan .step .card__illustration--Pro {
  background-image: url(https://assets.sendle.com/assets/self_serve_plan/Pro-563b6babaa58da4e7411aa4a7f4d70a09a97fa969a3bbd26feee7319e594ada9.gif);
}
.self-serve-plan .step .card__title {
  margin: 0.25rem 0;
}
.self-serve-plan .step .card__subtitle {
  font-size: 1rem;
  line-height: 1.5;
}
.self-serve-plan .step .card .perks {
  margin: 1rem auto 0.5rem auto;
  padding-left: 0;
  text-align: left;
}
.self-serve-plan .step .card .perks__perk {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  list-style: none;
}
.self-serve-plan .step .card .perks__tick {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  color: #009de3;
}
.self-serve-plan .step .card__volume {
  display: block;
  text-align: center;
}
.self-serve-plan .step .contact {
  margin-top: 2rem;
}
.self-serve-plan .step .contact .contact-form {
  margin: 1.5rem auto;
  max-width: 320px;
  text-align: left;
}
@media only screen and (max-width: 450px) {
  .self-serve-plan .step {
    margin: 1.5rem 0 0 0;
    text-align: left;
  }
  .self-serve-plan .step__options {
    flex-direction: column;
  }
  .self-serve-plan .step__options .option {
    display: flex;
    flex-direction: row;
    margin: 0 0 0.5rem 0;
  }
  .self-serve-plan .step__options .option__illustration-use {
    margin-bottom: 0;
    flex-shrink: 0;
    height: 58px;
    width: 64px;
  }
  .self-serve-plan .step__options .option__container {
    padding-left: 1rem;
  }
  .self-serve-plan .step__options .option__title {
    font-size: 1rem;
    line-height: 1.5;
  }
  .self-serve-plan .step__options .option__description {
    padding: 0;
  }
  .self-serve-plan .step__options .option--use {
    width: initial;
    padding: 1rem;
    text-align: left;
  }
  .self-serve-plan .step__options .option--volume {
    max-width: unset;
    padding: 1rem;
  }
  .self-serve-plan .step__title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.333334;
    font-weight: 700;
  }
  .self-serve-plan .step__title--volume {
    text-align: left;
  }
  .self-serve-plan .step__subtitle {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700;
  }
  .self-serve-plan .step__hint {
    font-size: 0.875rem;
    line-height: 1.42857;
  }
  .self-serve-plan .step__container {
    margin-bottom: 1rem;
  }
  .self-serve-plan .step__container--plan {
    max-width: unset;
  }
  .self-serve-plan .step .card {
    max-width: unset;
  }
  .self-serve-plan .step .contact .contact-form {
    max-width: unset;
  }
}
.self-serve-plan .loading {
  margin-top: 60px;
  font-size: 1.25rem;
  text-align: center;
}
.nav-sidebar {
  margin: 10px -20px;
  border-top: 1px solid #e9edf0;
  padding: 10px 0 5px 0;
  position: relative;
  z-index: 2;
  background: inherit;
}
.nav-sidebar:first-child {
  border-top: 0;
  padding: 0 0 5px 0;
  margin: 0 -20px 0 -20px;
}
.nav-sidebar li > a,
.nav-sidebar li .sidebar-text {
  color: #33424f;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 28px;
  margin-right: 20px;
  border-radius: 0 0.5rem 0.5rem 0;
}
.nav-sidebar li > a:hover,
.nav-sidebar li > a:focus {
  background-color: #e9edf0;
}
.nav-sidebar .active {
  display: block;
  background: #324250;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 28px;
  position: relative;
  margin-right: 20px;
  border-radius: 0 0.5rem 0.5rem 0;
}
.nav-sidebar .active:hover,
.nav-sidebar .active:focus {
  background: #324250;
}
@media (min-width: 768px) {
  .nav-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 -20px;
  }
  .nav-footer .utility-links {
    border-top: 1px solid #e9edf0;
    padding: 10px 0;
  }
  .nav-footer .utility-links a {
    color: #33424f;
    padding: 2px 15px;
    font-size: 12px;
    line-height: 20px;
  }
  .nav-footer .copyright {
    display: block;
    padding: 4px 15px;
    font-size: 12px;
    line-height: 20px;
    color: #6d7883;
  }
}
.navbar-collapse .nav-sidebar {
  border-top: 0;
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin: 0 -20px;
}
.navbar-collapse .nav-sidebar:first-child {
  margin: 10px -20px 0 -20px;
}
.navbar-collapse .nav-sidebar li > a {
  padding: 7px 15px;
}
.navbar-collapse .nav-sidebar .active {
  margin-right: 0;
}
.navbar-collapse .nav-sidebar .active:after {
  display: none;
}
.glyphicon.spinning,
.btn-loading:after,
.panel-loading:after,
.panel-loading-plain:after {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.support-search {
  background: #f9f8f7;
  padding: 20px 0;
  margin-bottom: 15px;
}
.support-search .example-block {
  padding: 4px 3px;
  color: #9fa7ae;
}
.support-search .search-field-group {
  position: relative;
}
.support-search .search-field-group .icon-search,
.support-search .search-field-group #tracking-tools #modal-search form:before,
#tracking-tools #modal-search .support-search .search-field-group form:before,
.support-search .search-field-group #support #modal-search form:before,
#support #modal-search .support-search .search-field-group form:before,
.support-search .search-field-group #tracking-batch #modal-search form:before,
#tracking-batch #modal-search .support-search .search-field-group form:before,
.support-search .search-field-group .navbar-form.search-form:after {
  display: none;
}
.support-search .search-field-group .btn {
  margin: 5px 0 2px 0;
}
.support-search .search-control-lg {
  font-size: 16px;
}
@media (min-width: 768px) {
  .support-search {
    padding: 40px 0;
    margin-bottom: 30px;
  }
  .support-search .search-field-group .icon-search,
  .support-search .search-field-group #tracking-tools #modal-search form:before,
  #tracking-tools #modal-search .support-search .search-field-group form:before,
  .support-search .search-field-group #support #modal-search form:before,
  #support #modal-search .support-search .search-field-group form:before,
  .support-search .search-field-group #tracking-batch #modal-search form:before,
  #tracking-batch #modal-search .support-search .search-field-group form:before,
  .support-search .search-field-group .navbar-form.search-form:after {
    position: absolute;
    display: block;
    background-size: 1980px 120px;
    background-position: -432px 0;
    height: 23px;
    width: 23px;
    top: 14px;
    left: 14px;
  }
  .support-search .search-field-group .btn {
    visibility: hidden;
    position: absolute;
    right: 5px;
    top: 5px;
    height: 40px;
    margin: 0;
  }
  .support-search .search-control-lg {
    line-height: 50px;
    height: 50px;
    padding: 0 115px 0 44px;
  }
  .support-search .example-block {
    padding: 8px 3px;
  }
}
.support-desk h1 {
  margin: 0 0 15px 0;
}
.support-desk h2 {
  font-size: 18px;
  font-weight: normal;
}
.support-desk h2.highlight {
  color: #ff6800;
  margin-bottom: 20px;
}
.support-desk h2 .btn-pill {
  position: relative;
  top: -2px;
  margin-left: 3px;
}
.support-desk h3 {
  font-size: 14px;
  font-weight: normal;
}
.support-desk .feature-article {
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.support-desk .feature-article p a {
  color: #33424f;
  text-decoration: underline;
}
.support-desk .feature-article p a:hover {
  color: #008dcc;
}
@media (min-width: 768px) {
  .support-desk .feature-article {
    padding-bottom: 0;
  }
}
.support-link-list ul {
  list-style-type: none;
  padding: 7px 0;
  margin: 0;
}
.support-link-list li {
  margin: 0 0 12px 0;
}
.support-link-list:before {
  content: "";
  display: block;
  clear: both;
  border-bottom: 1px solid #eee;
  padding: 10px 0 0 0;
  margin: 0 0 25px 0;
}
.system-message {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}
.system-message .title {
  font-weight: bold;
}
.system-message .title .separator {
  font-weight: normal;
}
@media (min-width: 768px) {
  .system-message {
    padding: 20px;
  }
  .system-message div.title {
    float: left;
    margin-right: 10px;
  }
  .system-message h5.title {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
.system-message-notice {
  border: 1px solid #aee6f3;
  border-left: 5px solid #aee6f3;
  background: #f4fcfe;
}
.system-message-notice .title {
  color: #324251;
}
.system-message-notice .content {
  color: #5884a9;
}
.system-message-notice .content a {
  color: #5884a9;
  text-decoration: underline;
}
.system-message-notice .content a:hover {
  color: #4f7799;
}
.system-message-notice.dashboard-banner {
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  background: transparent;
  padding: 16px 16px 16px 32px;
  color: #33424f;
  border: 1px solid #57bde3;
  border-radius: 2px;
}
.system-message-notice.dashboard-banner:before {
  content: "";
  position: absolute;
  width: 8px;
  left: 8px;
  top: 8px;
  bottom: 8px;
  background: #57bde3;
  border-radius: 2px;
}
.system-message-notice.dashboard-banner a {
  color: #008dcc;
  text-decoration: none;
}
.system-message-notice.dashboard-banner a:hover {
  text-decoration: underline;
}
.system-message-notice.dashboard-banner .content {
  color: inherit;
}
.system-message-notice.dashboard-banner .content:last-of-type {
  margin-bottom: 0;
}
.system-message-warning {
  border: 1px solid #ffd02c;
  border-left: 5px solid #ffd02c;
  background: #fff8eb;
}
.system-message-warning .title {
  color: #554407;
}
.system-message-warning .content {
  color: #33424f;
}
.system-message-warning .content a {
  color: #33424f;
  text-decoration: underline;
}
.system-message-warning .content a:hover {
  color: #293540;
}
.christmas-banner--batch {
  padding: 15px 0 !important;
}
.christmas-banner--primary {
  position: relative;
  padding-bottom: 13px;
  background: #fff0e6;
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
}
.christmas-banner--primary .content {
  display: inline-block;
}
.christmas-banner--primary .header h1 {
  font-size: 24px;
  color: #ed6000;
}
.christmas-banner--primary .christmas-banner--link {
  color: #33424f;
  font-weight: 700;
  text-decoration: underline;
  font-size: 16px;
  padding: 9px 20px;
  border-radius: 2px;
}
@media (max-width: 480px) {
  .christmas-banner--primary {
    background-image: url(https://assets.sendle.com/assets/dashboard/christmas/sm_banner_left-22d98dfe60080c1e2afc072848373765b4e0927e931689754dd7c607cc0dc772.svg),
      url(https://assets.sendle.com/assets/dashboard/christmas/sm_banner_right-dffc80753e15d13ae812c51934f8dbbe156deb0a7d5e2c1d08503d29fe5103a0.svg);
    background-repeat: no-repeat no-repeat;
    background-position: bottom left, bottom right;
    background-size: 14% 50%, 42% 25%;
    padding: 0 5px 40px 5px;
  }
  .christmas-banner--primary .header {
    padding-top: 10px;
  }
  .christmas-banner--primary .header h1 {
    font-size: 20px;
  }
  .christmas-banner--primary p {
    font-size: 14px;
    padding: 5px;
  }
}
@media (min-width: 481px) and (max-width: 1299px) {
  .christmas-banner--primary {
    background-image: url(https://assets.sendle.com/assets/dashboard/christmas/md_banner_left-59c45adf45cb81fbd1d73c4c51a5256ba6719742489264930c37954f21c759d4.svg),
      url(https://assets.sendle.com/assets/dashboard/christmas/md_banner_right-89c770406ba33b388361f63ab74cc0b8b8426af59aa60006c0b3bdedd53d938e.svg);
    background-repeat: no-repeat no-repeat;
    background-position: bottom left, bottom right;
    padding-bottom: 40px;
  }
  .christmas-banner--primary .header {
    padding-top: 10px;
  }
  .christmas-banner--primary .header h1 {
    font-size: 20px;
  }
  .christmas-banner--primary .content {
    width: 60%;
  }
  .christmas-banner--primary p {
    font-size: 14px;
  }
}
@media (min-width: 1300px) {
  .christmas-banner--primary {
    background-image: url(https://assets.sendle.com/assets/dashboard/christmas/banner_left-94d22a193654a1f946e02d0098e08cb3c1a305ff69aaa99cdbb4a7e9d78679d9.svg),
      url(https://assets.sendle.com/assets/dashboard/christmas/banner_right-85d047ca744a016e9299954aeadd58c802e8b889fe96455cc160a00dd4bb6cc4.svg);
    background-repeat: no-repeat no-repeat;
    background-position: bottom left, bottom right;
  }
  .christmas-banner--primary p {
    font-size: 16px;
  }
}
.system-message-critical {
  border: 1px solid #e44144;
  border-left: 5px solid #e44144;
  background: #fbeeee;
}
.system-message-critical .title {
  color: #cb2d31;
}
.system-message-critical .content {
  color: #a93437;
}
.system-message-critical .content a {
  color: #a93437;
  text-decoration: underline;
}
.system-message-critical .content a:hover {
  color: #962e31;
}
.things-to-do {
  margin-top: 10px;
  padding: 25px 15px;
  background: #f9f8f7;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
}
@media (min-width: 768px) {
  .things-to-do {
    margin-top: 0;
  }
}
.things-to-do .intro-text {
  padding: 0 15px;
  text-align: center;
}
@media (min-width: 768px) {
  .things-to-do .intro-text {
    padding: 0 10px 0 30px;
    text-align: left;
  }
}
.things-to-do .intro-image {
  padding: 20px 10px;
}
@media (min-width: 768px) {
  .things-to-do .intro-image {
    padding: 20px 0;
  }
}
@media (min-width: 992px) {
  .things-to-do .intro-image {
    padding: 25px 30px 0 0;
  }
}
@media (min-width: 768px) {
  .things-to-do .icon-link-connect {
    margin-left: 15px;
  }
}
@media (min-width: 992px) {
  .things-to-do .icon-link-connect {
    float: left;
    margin-bottom: 10px;
  }
}
.things-to-do .intro-actions {
  padding: 11px 0 0 0;
  text-align: center;
}
.things-to-do .intro-actions > * {
  margin: 0.5rem;
}
@media (min-width: 768px) {
  .things-to-do .intro-actions > * {
    margin: 0;
  }
}
.things-to-do .intro-actions .no-address a {
  display: inline-block;
}
.things-to-do .intro-actions .quote-tool {
  left: -65px;
}
@media (min-width: 768px) {
  .things-to-do .intro-actions {
    padding: 20px 0 0 0;
    text-align: left;
    margin-bottom: 20px;
  }
  .things-to-do .intro-actions .quote-tool {
    left: 0;
  }
  .things-to-do .intro-actions .btn-primary {
    margin-right: 15px;
  }
  .things-to-do .intro-actions .sds-button,
  .things-to-do .intro-actions .pagination li > a:not(.sds-button),
  .pagination .things-to-do .intro-actions li > a:not(.sds-button) {
    position: relative;
    top: -2px;
    float: left;
  }
}
@media (min-width: 992px) {
  .things-to-do .intro-actions {
    margin-bottom: 0px;
  }
}
.things-to-do h2 {
  margin: 0 0 20px 0;
  line-height: 1.2em;
}
@media (min-width: 992px) {
  .things-to-do h2 span {
    display: block;
  }
}
.things-to-do p {
  font-size: 14px;
  line-height: 22px;
}
.things-to-do .prompt {
  margin-bottom: 20px;
}
.things-to-do .prompt a {
  display: block;
  color: #ff6800;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
}
.things-to-do .missing-orders {
  font-size: 14px;
}
.things-to-do .missing-orders ul {
  list-style: none;
  padding-left: 16px;
}
.things-to-do .missing-orders ul li {
  margin-bottom: 8px;
}
.things-to-do .missing-orders ul li:before {
  content: "•";
  color: #ff6800;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
}
.things-to-do .received-orders {
  text-align: left;
}
@keyframes pulsate {
  0% {
    transform: scale(0.3, 0.3);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}
.tracking-event-list {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
}
.tracking-event-list li {
  padding: 0 0 13px 0;
  position: relative;
}
.tracking-event-list li:before {
  content: "";
  position: absolute;
  left: 78px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #b2b3b6;
  z-index: 2;
}
.tracking-event-list .te-type {
  display: block;
  width: 85px;
  margin: 2px 11px 0 0;
  padding: 0 25px 0 0;
  text-align: center;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #b2b3b6;
  position: relative;
  float: left;
}
.tracking-event-list .te-type:after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  border-radius: 50%;
  content: "";
  display: block;
  height: 13px;
  width: 13px;
  background: white;
  border: 1px solid #b2b3b6;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.tracking-event-list li:first-child:before {
  top: 3px;
}
.tracking-event-list .te-detail {
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-left: 103px;
}
.tracking-event-list .te-description {
  text-align: left;
}
.tracking-event-list .te-route {
  font-size: 12px;
  line-height: 19px;
  color: #b2b3b6;
  display: block;
  white-space: nowrap;
  margin-top: 4px;
}
.tracking-event-list .te-destination:before {
  content: "\276f";
  margin-left: 2px;
  margin-right: 4px;
}
.tracking-event-list .te-time {
  display: block;
  margin-top: 4px;
  font-size: 11px;
  line-height: 16px;
  color: #b2b3b6;
  font-style: normal;
}
.tracking-event-list .te-event-info .te-type,
.tracking-event-list .te-event-transit .te-type,
.tracking-event-list .te-event-local-delivery .te-type {
  color: white;
}
.tracking-event-list .te-event-info .te-type:after,
.tracking-event-list .te-event-transit .te-type:after,
.tracking-event-list .te-event-local-delivery .te-type:after {
  height: 9px;
  width: 9px;
  right: 2px;
  top: 2px;
}
.tracking-event-list .te-event-booking .te-type {
  line-height: 16px;
}
.tracking-event-list .te-event-booking .te-type:after {
  height: 21px;
  width: 21px;
  background: url(https://assets.sendle.com/assets/icons/sendle-arrow-orange-small-x2-121816789ed164a977a812ede72de92e5938673020b1ec8e33edcd2eadcd8005.png)
    6px 3px no-repeat white;
  background-size: 8px 13px;
  border: 1px solid #b2b3b6;
  position: absolute;
  right: -4px;
  top: -4px;
}
.tracking-event-list .te-event-failed .te-type {
  color: #e44144;
}
.tracking-event-list .te-event-failed .te-type:after {
  background: #e44144;
  border-color: #e44144;
}
.tracking-event-list .te-event-failed .te-type,
.tracking-event-list .te-event-attempted .te-type {
  top: -5px;
}
.tracking-event-list .te-event-failed .te-type:after,
.tracking-event-list .te-event-attempted .te-type:after {
  top: 6px;
}
.tracking-event-list .te-event-current:before {
  bottom: auto;
  height: 13px;
}
.tracking-event-list .te-event-current:after {
  content: "";
  position: absolute;
  top: 15px;
  height: 15px;
  left: 78px;
  border-left: 1px dashed #d9dadb;
  z-index: 2;
}
.tracking-event-list .te-event-current.te-event-delivered .te-type {
  color: #67cdcc;
}
.tracking-event-list .te-event-current.te-event-delivered .te-type:after {
  background: #67cdcc;
  border-color: #67cdcc;
}
.tracking-event-list .te-event-current.te-event-local-delivery .te-type {
  color: white;
}
.tracking-event-list .te-event-current.te-event-local-delivery .te-type:after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  border-radius: 50%;
  content: "";
  display: block;
  height: 13px;
  width: 13px;
  border: 1px solid #67cdcc;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  background: #67cdcc;
}
.tracking-event-list .te-event-current.te-event-card-left .te-type,
.tracking-event-list .te-event-current.te-event-left-with-agent .te-type,
.tracking-event-list .te-event-current.te-event-attempted .te-type,
.tracking-event-list .te-event-current.te-event-pickup .te-type {
  color: #ff6800;
}
.tracking-event-list .te-event-current.te-event-card-left .te-type:after,
.tracking-event-list .te-event-current.te-event-left-with-agent .te-type:after,
.tracking-event-list .te-event-current.te-event-attempted .te-type:after,
.tracking-event-list .te-event-current.te-event-pickup .te-type:after {
  background: #ff6800;
  border-color: #ff6800;
  animation: pulsate 1.4s ease-out;
  animation-iteration-count: infinite;
}
.tracking-event-list .te-event-current.te-event-info .te-type:after {
  background: #ff6800;
  border-color: #ff6800;
  animation: pulsate 1.4s ease-out;
  animation-iteration-count: infinite;
}
.tracking-event-list .te-event-current.te-event-delivered:after,
.tracking-event-list .te-event-current.te-event-failed:after,
.tracking-event-list .te-event-current.te-event-local-delivery:after {
  display: none;
}
@media (min-width: 768px) {
  .tracking-event-list {
    margin: 5px 30px 5px 0;
  }
  .tracking-event-list:before {
    bottom: 7px;
  }
  .tracking-event-list .te-detail {
    text-align: right;
    overflow: hidden;
  }
  .tracking-event-list .te-description {
    float: left;
    margin: 0 15px 3px 0;
  }
  .tracking-event-list .te-route {
    float: left;
    margin: 0 15px 0 0;
  }
  .tracking-event-list .te-time {
    display: inline-block;
    margin-top: 0;
  }
}
.estimated-delivery-date {
  color: #b2b3b6;
  font-size: 12px;
  margin: 0 10px 10px 102px;
}
.panel-tracking {
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
}
.panel-tracking h4 {
  font-weight: normal;
}
.panel-tracking p {
  margin: 0 2px 0 0;
}
.panel-tracking a {
  color: #b2b3b6;
  text-decoration: underline;
}
.panel-tracking.panel-highlight {
  background: #f8fcfd;
  border-left: 3px solid #5bbcd1;
}
.panel-tracking.panel-highlight h4 {
  color: #5bbcd1;
}
.panel-tracking .collection-info {
  margin: 0 0 14px 0;
}
.panel-tracking .operating-hours {
  color: #9ba4ab;
  margin: 8px 0 0 0;
}
@media (min-width: 768px) {
  .panel-tracking {
    padding: 20px;
    margin-left: 104px;
    max-width: 600px;
  }
  .panel-tracking .operating-hours {
    margin: 4px 0 0 0;
  }
}
.panel-tracking-meta {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  margin: 10px 0 0 18px;
  padding: 3px 8px;
  float: left;
  background: #f0f7f9;
  color: #9ba4ab;
}
.twitter-typeahead .tt-menu {
  color: #33424f;
  width: 100%;
  padding: 10px 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-height: 500px;
  overflow-y: auto;
  z-index: 20;
}
.twitter-typeahead .prompt-message {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 4px;
  margin-bottom: 4px;
}
.twitter-typeahead .empty-message {
  padding: 0 10px;
}
.twitter-typeahead .tt-suggestion {
  cursor: pointer;
  padding: 5px 10px;
  font-weight: normal;
}
.twitter-typeahead .tt-suggestion p {
  margin: 0;
}
.twitter-typeahead .tt-suggestion a {
  color: #33424f;
  text-decoration: none;
  padding: 0px 5px;
}
.twitter-typeahead .tt-suggestion a img {
  float: left;
  margin-top: 4px;
}
.twitter-typeahead .tt-suggestion a .name {
  margin-left: 38px;
  font-weight: bold;
  display: block;
}
.twitter-typeahead .tt-suggestion a .state {
  margin-left: 38px;
  font-size: 12px;
  display: block;
}
.twitter-typeahead .tt-suggestion > a {
  display: block;
}
.twitter-typeahead .tt-suggestion:hover,
.twitter-typeahead .tt-suggestion.tt-cursor {
  background: #e4f8fc;
}
.twitter-typeahead .footer {
  text-align: center;
  background-color: #f9f8f7;
  padding: 5px;
  margin-bottom: -10px;
}
.link-like {
  color: #008dcc;
}
.delete-link {
  color: #d94c4c;
}
.delete-link:hover {
  color: #c82a2a;
  text-decoration: underline;
}
.spaced {
  letter-spacing: 0.05em;
}
.secondary-text {
  color: #b7bdc2;
}
.highlight {
  color: #ff6800;
}
.light-heading {
  font-weight: normal;
}
.small {
  font-size: 11px;
}
.quoted {
  font-size: 15px;
}
.quoted:before {
  content: "“";
  font-family: Georgia, serif;
  font-size: 24px;
  margin-right: 8px;
  float: left;
  line-height: 28px;
}
.unsupported {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(https://assets.sendle.com/assets/icons/sendle-arrow-orange-14c33dd40d67364ea5a0e80a4e2158ceb1aa9f1d5fa9492193a277cb4195934d.png)
    no-repeat center 35px #f4f4f4;
  text-align: center;
  z-index: 2000;
}
.unsupported form {
  display: block;
}
.unsupported input[type="submit"] {
  background: transparent;
  border: 0;
  color: #aaaaaa;
}
.unsupported input[type="submit"]:hover {
  color: #444;
}
.unsupported-content {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #d4dbdd;
  background: white;
  max-width: 600px;
  margin: 120px auto 30px auto;
  padding: 50px 50px 45px 50px;
  text-align: left;
}
.unsupported-content h2 {
  margin: 0 0 20px;
}
.unsupported-content p {
  line-height: 20px;
}
.unsupported-content ul {
  margin: 20px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.unsupported-content li {
  margin: 0 0 10px 0;
}
.unsupported-content .download-link {
  display: inline-block;
  background: url(https://assets.sendle.com/assets/dashboard/download-ce5f2a4e55614c6d585b9a3673ae9746706c9850258764bcd773489941a3544d.png)
    no-repeat left center;
  padding: 2px 0 2px 20px;
}
@keyframes slideUpFadeIn {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  56% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.premium-popover,
.satchel-popover {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  width: 300px;
  padding: 25px 45px 33px 45px;
  background: white;
  border: 1px solid rgba(50, 66, 80, 0.3);
  -webkit-box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  z-index: 3;
  top: 60px;
  right: 10px;
  text-align: center;
  animation-duration: 1.8s;
  animation-name: slideUpFadeIn;
  animation-iteration-count: 1;
  animation-easing: cubic-bezier(0.17, 0.67, 0.45);
}
.premium-popover .pointer,
.satchel-popover .pointer {
  display: block;
  position: absolute;
  top: -12px;
  left: 12px;
  border-bottom: 12px solid rgba(50, 66, 80, 0.3);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  right: 8px;
  left: auto;
  right: 9px;
}
.premium-popover .pointer:before,
.satchel-popover .pointer:before {
  content: "";
  display: block;
  position: absolute;
}
.premium-popover .pointer:before,
.satchel-popover .pointer:before {
  top: 1px;
  left: -12px;
  border-bottom: 12px solid white;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.premium-popover .close-popover,
.satchel-popover .close-popover {
  color: #b7bdc2;
  font-size: 18px;
  line-height: 35px;
  position: absolute;
  top: 7px;
  right: 19px;
  display: block;
  width: 40px;
  height: 40px;
  text-align: right;
}
.premium-popover .close-popover:hover,
.satchel-popover .close-popover:hover {
  color: #8e979f;
  text-decoration: none;
}
.premium-popover .popover-heading,
.satchel-popover .popover-heading {
  color: #ff6800;
  font-weight: normal;
  font-size: 21px;
  line-height: 30px;
  margin: 5px 0 16px 0;
}
.premium-popover .popover-heading strong,
.satchel-popover .popover-heading strong {
  display: block;
}
.premium-popover .stamp,
.satchel-popover .stamp {
  margin: 0 auto 5px auto;
}
.premium-popover .intro,
.satchel-popover .intro {
  font-size: 17px;
  line-height: 24px;
  margin: 0 0 15px 0;
}
.premium-popover .intro strong,
.satchel-popover .intro strong {
  display: block;
}
.premium-popover .info,
.satchel-popover .info {
  color: #b7bdc2;
  line-height: 22px;
}
.premium-popover .info .special,
.satchel-popover .info .special {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -khtml-border-radius: 1px;
  border-radius: 1px;
  font-weight: bold;
  display: inline-block;
  background: #fff1bf;
  color: #bd8016;
  padding: 0 8px;
  margin: 4px 0;
}
.premium-popover .info .special:before,
.premium-popover .info .special:after,
.satchel-popover .info .special:before,
.satchel-popover .info .special:after {
  content: "";
  display: block;
  height: 1px;
}
.premium-popover .action,
.satchel-popover .action {
  margin: 16px 0;
}
.premium-popover .footer a,
.satchel-popover .footer a {
  color: #b7bdc2;
  text-decoration: underline;
}
.premium-popover .footer a:hover,
.satchel-popover .footer a:hover {
  color: #9ba4ab;
}
@media (min-width: 768px) {
  .premium-popover,
  .satchel-popover {
    right: 36px;
  }
  .premium-popover .pointer,
  .satchel-popover .pointer {
    right: 13px;
  }
}
div.edit-modal .modal-body {
  height: calc(100vh - 120px);
}
.v2-edit-form {
  max-width: 800px;
  margin: 0 auto;
}
div.modal-body-inner.row.unified {
  max-width: 600px;
}
div.modal-body-inner.row.unified .panel-formgroup {
  margin: 0 0 24px 0;
}
div.modal-body-inner.row.unified .panel-formgroup.parcel-country-panel:before,
div.modal-body-inner.row.unified .panel-formgroup.parcel-size-panel:before,
div.modal-body-inner.row.unified .panel-formgroup.receiver-panel:before,
div.modal-body-inner.row.unified .panel-formgroup.pickup-panel:before {
  left: 85px;
}
div.modal-body-inner.row.unified
  .panel-formgroup.parcel-country-panel
  .panel-help-link,
div.modal-body-inner.row.unified
  .panel-formgroup.parcel-size-panel
  .panel-help-link,
div.modal-body-inner.row.unified
  .panel-formgroup.receiver-panel
  .panel-help-link,
div.modal-body-inner.row.unified
  .panel-formgroup.pickup-panel
  .panel-help-link {
  float: right;
  right: 172px;
  position: absolute;
  margin-top: -35px;
}
div.modal-body-inner.row.unified .panel-formgroup.pickup-panel {
  margin-bottom: 85px;
}
@media screen and (max-width: 990px) {
  div.modal-body-inner.row.unified .panel-formgroup.parcel-country-panel:before,
  div.modal-body-inner.row.unified .panel-formgroup.parcel-size-panel:before,
  div.modal-body-inner.row.unified .panel-formgroup.receiver-panel:before,
  div.modal-body-inner.row.unified .panel-formgroup.pickup-panel:before {
    left: 10px;
  }
  div.modal-body-inner.row.unified
    .panel-formgroup.parcel-country-panel
    .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.parcel-size-panel
    .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.receiver-panel
    .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.pickup-panel
    .panel-heading {
    padding-left: 55px;
  }
  div.modal-body-inner.row.unified
    .panel-formgroup.parcel-country-panel
    .panel-help-link,
  div.modal-body-inner.row.unified
    .panel-formgroup.parcel-size-panel
    .panel-help-link,
  div.modal-body-inner.row.unified
    .panel-formgroup.receiver-panel
    .panel-help-link,
  div.modal-body-inner.row.unified
    .panel-formgroup.pickup-panel
    .panel-help-link {
    position: relative;
    height: 25px;
    margin-top: 0;
    right: 0;
    float: left;
  }
}
@media screen and (max-width: 767px) {
  div.modal-body-inner.row.unified .panel-formgroup.parcel-country-panel,
  div.modal-body-inner.row.unified .panel-formgroup.parcel-size-panel,
  div.modal-body-inner.row.unified .panel-formgroup.receiver-panel,
  div.modal-body-inner.row.unified .panel-formgroup.pickup-panel,
  div.modal-body-inner.row.unified .panel-formgroup.pickup-location-panel,
  div.modal-body-inner.row.unified .panel-formgroup.pickup-instructions-panel,
  div.modal-body-inner.row.unified .panel-formgroup.dangerous-goods-panel {
    margin: 0 0 24px 0;
  }
  div.modal-body-inner.row.unified .panel-formgroup.parcel-country-panel:before,
  div.modal-body-inner.row.unified .panel-formgroup.parcel-size-panel:before,
  div.modal-body-inner.row.unified .panel-formgroup.receiver-panel:before,
  div.modal-body-inner.row.unified .panel-formgroup.pickup-panel:before,
  div.modal-body-inner.row.unified
    .panel-formgroup.pickup-location-panel:before,
  div.modal-body-inner.row.unified
    .panel-formgroup.pickup-instructions-panel:before,
  div.modal-body-inner.row.unified
    .panel-formgroup.dangerous-goods-panel:before {
    display: none;
  }
  div.modal-body-inner.row.unified
    .panel-formgroup.parcel-country-panel
    .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.parcel-size-panel
    .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.receiver-panel
    .panel-heading,
  div.modal-body-inner.row.unified .panel-formgroup.pickup-panel .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.pickup-location-panel
    .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.pickup-instructions-panel
    .panel-heading,
  div.modal-body-inner.row.unified
    .panel-formgroup.dangerous-goods-panel
    .panel-heading {
    margin: 0 0 4px 0;
    font-size: 18px;
    padding-left: 0;
  }
}
div.modal-body-inner.row.unified .panel-formgroup .field-addon.right {
  right: 4px;
  font-weight: bold;
}
@media (min-width: 768px) {
  div.modal-body-inner.row.unified .panel-formgroup .field-addon:not(.right) {
    left: 19px;
    padding: 11px 5px;
  }
}
@media (min-width: 992px) {
  div.modal-body-inner.row.unified .panel-formgroup .field-addon:not(.right) {
    left: 18px;
    padding: 11px 5px;
  }
  div.modal-body-inner.row.unified
    .panel-formgroup
    .field-addon.form-icon-search:not(.right) {
    left: 155px;
  }
}
@media (min-width: 768px) {
  div.modal-body-inner.row.unified .select2-container {
    max-width: 340px;
    width: 100% !important;
  }
  div.modal-body-inner.row.unified .select2-container input {
    outline: none;
  }
  div.modal-body-inner.row.unified .select2-container .select2-results__option {
    font-size: 15px;
  }
}
@media screen and (max-width: 991px) {
  div.modal-body-inner.row.unified .select2-container {
    max-width: none;
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  div.modal-body-inner.row.unified .select2-container {
    max-width: 340px;
    width: 100% !important;
  }
  div.modal-body-inner.row.unified .select2-container input {
    outline: none;
  }
  div.modal-body-inner.row.unified .select2-container .select2-results__option {
    font-size: 15px;
  }
}
@media screen and (max-width: 991px) {
  div.modal-body-inner.row.unified .select2-container {
    max-width: none;
    width: 100% !important;
  }
}
.icon-header i {
  margin: 0 auto 14px auto;
}
.icon-header span {
  display: block;
}
.velocity-promo {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  background: white;
  padding: 17px 15px 2px 15px;
  margin: 15px 10px;
  font-size: 12px;
  line-height: 20px;
  box-shadow: inset 0px 3px 0px #67cdcc, 0px 3px 5px rgba(50, 66, 79, 0.1);
}
.velocity-promo .velocity-header {
  overflow: hidden;
  text-indent: 125%;
  white-space: nowrap;
  margin: 0 0 15px 0;
  background: url(https://assets.sendle.com/assets/partners/velocity/velocity-logo-ee469893161aa8a7d8d29a1ae4b81c479ec2dd1eba1d244af0b1a80193a7f564.svg)
    center top no-repeat;
  background-size: 121px 50px;
  width: 100%;
  height: 50px;
}
.velocity-promo .terms-text {
  color: #9fa7ae;
  font-size: 10px;
}
.velocity-promo p {
  margin: 0 0 15px 0;
}
.velocity-settings {
  background: url(https://assets.sendle.com/assets/partners/velocity/velocity-logo-ee469893161aa8a7d8d29a1ae4b81c479ec2dd1eba1d244af0b1a80193a7f564.svg)
    no-repeat;
  background-position: 100% 30px;
  background-size: 60px 25px;
  margin-top: 35px;
}
.velocity-settings .form-introduction {
  margin: 0 80px 20px 0;
}
.velocity-settings .terms-text {
  color: #9fa7ae;
}
.velocity-settings .terms-text a {
  color: #008dcc;
  text-decoration: none;
}
.velocity-settings .terms-text a:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .velocity-settings {
    background-position: 100% 22px;
    background-size: 200px 80px;
  }
  .velocity-settings .form-introduction {
    margin: 0 255px 20px 0;
  }
}
body.wizard {
  padding-top: 0;
  min-width: 200px;
  height: unset;
}
body.wizard .navbar {
  max-width: 1350px;
  margin: 0 auto;
}
body.wizard .container {
  width: 100%;
}
.formik-option-select .sds-form-element__item,
.formik-option-select
  .select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered,
.select2-container.select2-container--sds.select2-container--disabled
  .formik-option-select
  .select2-selection__rendered,
.formik-option-select
  .select2-container.select2-container--sds
  .select2-selection
  .select2-selection__rendered,
.select2-container.select2-container--sds
  .select2-selection
  .formik-option-select
  .select2-selection__rendered {
  margin: 0;
}
.formik-option-select .sds-form-element__control,
.formik-option-select
  .select2-container.select2-container--sds
  .select2-search--dropdown
  .select2-search__field,
.select2-container.select2-container--sds
  .select2-search--dropdown
  .formik-option-select
  .select2-search__field,
.formik-option-select
  .select2-container.select2-container--sds
  .select2-selection,
.select2-container.select2-container--sds
  .formik-option-select
  .select2-selection {
  border: 0;
}
.formik-option-select__input {
  padding: 0 !important;
}
.formik-option-select__dropdown {
  position: relative;
}
.formik-option-select__dropdown__button {
  background: transparent;
  font-weight: normal !important;
  border: 0;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: 1px solid #b4bfc9 !important;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.global-address__address-line-1 .warning.glyphicon.glyphicon-alert,
.global-address__address-line-1 .warning.glyphicon-alert.btn-loading:after,
.global-address__address-line-1 .warning.glyphicon-alert.panel-loading:after,
.global-address__address-line-1
  .warning.glyphicon-alert.panel-loading-plain:after {
  color: #f8ce46;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #33424f;
  width: 0;
  height: 0;
}
.global-address__address-line-1 .warning.glyphicon.glyphicon-alert:after,
.global-address__address-line-1 .warning.glyphicon-alert.btn-loading:after,
.global-address__address-line-1 .warning.glyphicon-alert.panel-loading:after,
.global-address__address-line-1
  .warning.glyphicon-alert.panel-loading-plain:after {
  content: "\e209";
  margin-left: -6px;
}
.global-address__address-line-1 .warning.glyphicon.glyphicon-alert:before {
  content: none;
}
.product-order-form .address__postcode-empty-results {
  padding: 0.5rem;
  font-size: 1rem;
}
.address__postcode--with-link-annotation span {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.address__postcode--with-link-annotation .sds-form-element__label {
  width: 100%;
}
.address__postcode-annotation .product-order-form__link-button {
  line-height: 0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.cover {
  opacity: 0;
  animation: fadeIn 600ms ease-in forwards;
}
.cover__row {
  display: flex;
  flex-direction: row;
}
.cover__row__column {
  width: 50%;
}
.cover__price {
  line-height: 2;
}
.cover__max-cover-amount {
  margin-bottom: 1rem;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .cover__max-cover-amount {
    margin-bottom: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.product-order-form__section-title--delivery-instructions {
  margin-top: 2rem;
}
.product-order-form .product-order-form-group__row--delivery-instructions {
  grid-template-columns: 1fr;
}
.delivery-instructions-fields {
  opacity: 0;
  animation: fadeIn 600ms ease-in forwards;
  position: relative;
  z-index: 1;
}
.delivery__instructions {
  margin-bottom: 1rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.product-order-form__drop-off-location {
  opacity: 0;
  animation: fadeIn 600ms ease-in forwards;
}
.product-order-form__drop-off-location .loading-panel {
  display: flex;
  justify-content: center;
}
.product-order-form__drop-off-location .loading-panel .loading {
  margin-right: 0.5rem;
}
.product-order-form__drop-off-location .nearest-drop-off-location-header {
  margin-bottom: 1rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.confirmation-summary {
  font-size: 1rem;
  max-width: 800px;
  margin: auto;
}
.confirmation-summary__header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}
@media screen and (min-width: 992px) {
  .confirmation-summary__header {
    flex-direction: row;
  }
}
@media screen and (max-width: 992px) {
  .confirmation-summary__header-title {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .confirmation-summary__header-total {
    margin: auto;
  }
}
.confirmation-summary__card {
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}
.confirmation-summary__card-header {
  display: flex;
  justify-content: flex-end;
}
.confirmation-summary__card-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #33424f;
  line-height: 1.5;
}
@media screen and (min-width: 992px) {
  .confirmation-summary__card-details {
    flex-direction: row;
  }
}
.confirmation-summary__card-details--payment {
  flex-direction: column;
}
.confirmation-summary__receiver-details,
.confirmation-summary__parcel-details {
  flex: 50%;
}
.confirmation-summary__card-edit {
  position: absolute;
  margin-top: 0.25rem;
}
.confirmation-summary__card-details-title {
  margin: 0.5rem 0;
}
.confirmation-summary__card-details-title--add-credit-card {
  margin-bottom: 0;
}
.confirmation-summary__meta {
  align-items: center;
  display: flex;
}
.confirmation-summary__meta--delivery {
  align-items: flex-start;
}
.confirmation-summary__icon {
  color: #b4bfc9;
  margin-right: 0.5rem;
}
.confirmation-summary__icon-email,
.confirmation-summary__icon-mobile-phone {
  width: 1rem;
}
.order-metadata-fields {
  padding-bottom: 0.5rem;
}
.product-order-form-summary__item {
  position: relative;
  padding-left: 1rem;
}
.product-order-form-summary {
  top: 140px;
  position: sticky;
  background: #f7f8fa;
  border-left: 0.25rem solid #b4bfc9;
}
.product-order-form-summary__item-details {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0 1rem;
  border-bottom: 0.0625rem solid #e9edf0;
  position: relative;
}
.product-order-form-summary__item-status {
  position: absolute;
  left: -15.5px;
  top: 50%;
  transform: translateY(-50%);
}
.product-order-form-summary__item-empty {
  color: #b4bfc9;
}
.product-order-form-summary__price-item-amount--discount {
  color: #5bc976;
}
.product-order-form-summary__price-item-info {
  color: #6d7883;
}
.product-order-form-summary__item-total-heading {
  margin-top: 1rem;
}
.product-order-form-summary__item-total-info {
  color: #6d7883;
  margin-bottom: 1rem;
}
.product-order-form-summary__pricing {
  text-align: right;
  padding: 1rem;
}
.product-order-form-summary__price-item-with-tooltip {
  display: flex;
  justify-content: end;
}
.product-order-form-summary__item-remote-route {
  display: flex;
  justify-content: end;
  color: #6d7883;
}
.product-order-form-summary__item-remote-route__icon {
  width: 24px;
}
.product-order-form-summary__item-remote-route .remote-route-circle-info {
  width: 12px;
  margin: 4px 4px;
}
.product-order-form-summary__item-remote-route .remote-route-hinttip {
  width: 178px;
  height: 94px;
  line-height: 1.25rem;
}
.product-order-form-summary__tooltip {
  margin: 0 0.25rem;
  vertical-align: middle;
}
.product-order-form-summary__tooltip svg {
  width: 12px;
}
.product-order-form-summary__tooltip-component {
  padding: 1rem !important;
  padding-bottom: 0 !important;
  opacity: 1 !important;
  text-align: left;
  background-color: #e9edf0 !important;
  color: #33424f !important;
  font-size: 0.875rem;
  width: 260px;
  box-shadow: 0 2px 4px 0 rgba(51, 66, 79, 0.23);
  border-radius: 8px !important;
}
.product-order-form-summary__tooltip-component::after {
  border-bottom-color: #e9edf0 !important;
  border-width: 12px !important;
  top: -12px !important;
}
@media (max-width: 991px) {
  .product-order-form-summary__tooltip-component {
    margin-left: -150px !important;
    margin-top: -129px !important;
  }
  .product-order-form-summary__tooltip-component::after {
    border-top: 12px solid !important;
    border-color: transparent !important;
    border-left-color: #e9edf0 !important;
    left: 267px !important;
    top: 50% !important;
    z-index: 1200 !important;
  }
}
.product-order-form-summary__tooltip-popout {
  font-size: 0.875rem;
}
.product-order-form-summary__tooltip-title-text {
  margin-bottom: 0.25rem;
}
@media screen and (min-width: 992px) {
  .parcel-contents .product-order-form-group__row--parcel-content-details {
    grid-template-columns: 49% 4fr 1fr;
  }
}
.parcel-contents__add-item {
  display: flex;
  justify-content: center;
}
.parcel-contents__header {
  display: flex;
  justify-content: space-between;
}
.parcel-contents__total {
  display: flex;
  align-items: baseline;
}
.parcel-contents__total-amount {
  font-size: 1.25rem;
}
.parcel-size-panel__form {
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
}
.parcel-size-panel__group-label {
  margin-bottom: 0.5rem;
  float: left;
  width: 100%;
  font-size: 1rem;
  border-bottom: none;
}
.parcel-size-panel__fields {
  display: flex;
  gap: 0.5rem;
  clear: both;
}
.parcel-size-panel__fields :first-letter {
  text-transform: uppercase;
}
.parcel-size-panel__fields label,
.parcel-size-panel__fields .sds-form-element__help .field-name {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.parcel-size-panel__operator {
  margin-top: 0.5rem;
}
.parcel-size-panel__summary {
  border: 1px solid #e9edf0;
  border-radius: 0.5rem;
}
.parcel-size-panel__summary__title {
  color: #7a8691;
  font-size: 0.875rem;
}
.parcel-size-panel__summary__breakdown {
  color: #33424f;
  display: flex;
}
.parcel-size-panel__summary__add_saved_size_link {
  font-size: 1rem;
  font-weight: 700;
}
.parcel-size-panel__header-text {
  margin-bottom: 0.5rem;
}
.parcel-size-panel__tips {
  padding-left: 1.5rem;
}
.parcel-size-panel__tips li {
  list-style-type: disclosure-closed;
  margin-bottom: 0.5rem;
}
.parcel-size-panel__tips li::marker {
  font-size: 0.875rem;
  color: #9aa1a8;
}
.parcel-size-panel__saved-sizes-notice-description {
  line-height: 1.66667;
}
.parcel-size-panel__subtitle {
  font-size: 1rem;
}
.parcel-size-panel__menu {
  display: flex;
  margin-bottom: 1rem;
}
.parcel-size-panel__menu-item {
  padding: 1rem 0;
}
.parcel-size-panel__menu-item:not(:first-child) {
  margin-left: 2rem;
}
.parcel-size-panel__menu-item--selected {
  border-bottom: 2px solid #ff6800;
}
.parcel-size-panel__menu-item-link {
  color: #33424f;
}
.parcel-size-panel__menu-item-link:hover,
.parcel-size-panel__menu-item-link:focus {
  color: #33424f;
  text-decoration: none;
  cursor: pointer;
}
.parcel-size-panel__content {
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.parcel-size-panel__content--selected {
  opacity: 1;
}
.parcel-size-panel__parcel-size-presets {
  padding: 0.5rem;
  border: 1px solid #e9edf0;
  border-radius: 0.5rem;
  width: 100%;
}
.parcel-size-panel__parcel-size-presets__button {
  height: 100%;
  line-height: 1.42857;
  border: 0;
  background: none;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
  margin: auto;
}
.parcel-size-panel__parcel-size-presets__button-name {
  font-size: 0.875rem;
}
.parcel-size-panel__parcel-size-presets-meta-pane {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.parcel-size-panel__parcel-size-presets-buttons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.parcel-size-panel__parcel-size-presets-buttons--col-6 {
  grid-template-columns: repeat(6, 1fr);
}
.parcel-size-panel__parcel-size-presets-buttons--centered {
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
}
@media (max-width: 1285px) {
  .parcel-size-panel__parcel-size-presets-buttons {
    grid-template-columns: repeat(auto-fill, minmax(33.3%, 1fr));
  }
}
@media (max-width: 455px) {
  .parcel-size-panel__parcel-size-presets-buttons {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
.parcel-size-panel__additional-info {
  display: flex;
  justify-content: center;
  padding: 0.25rem;
}
.parcel-size-panel__warning {
  margin: 1rem 0;
}
.parcel-size-panel__warning .sds-alert-message__content {
  font-size: 0.875rem;
}
.parcel-size-panel-drawer {
  width: 351px;
  font-size: 1rem;
}
.parcel-size-panel-drawer .parcel-size-panel__form {
  display: block;
}
.parcel-size-panel-drawer .parcel-size-panel__container {
  flex-wrap: wrap;
}
.parcel-size-panel-drawer .parcel-size-panel__parcel-size-presets-buttons {
  grid-template-columns: repeat(auto-fill, minmax(33.3%, 1fr));
}
.parcel-size-panel-drawer .product-order-form-group__row {
  grid-template-columns: 1fr;
}
.parcel-size-panel-drawer .parcel-size-panel__summary__breakdown {
  flex-direction: column;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.pickup-dates {
  opacity: 0;
  animation: fadeIn 600ms ease-in forwards;
}
.pickup-dates__label {
  margin-right: 0.25rem;
}
.pickup-dates__date-select {
  white-space: nowrap;
  max-width: 366px;
  line-height: 24px;
}
@media (max-width: 992px) {
  .pickup-dates__date-select {
    max-width: 100%;
    white-space: normal;
  }
}
.modal .pickup-dates__date-select {
  white-space: normal;
}
@media (max-width: 410px) {
  .pickup-dates__date-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.pickup-dates .sds-form-element {
  margin-bottom: 1rem;
}
.pickup-dates__error {
  color: #ba3037;
  font-size: 0.875rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.product-options {
  padding-right: 0.5rem;
}
.modal-body .product-options:last-child {
  margin-bottom: 100px;
}
#support #support-modal .product-options__group-title,
.product-options__group-title {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
.product-options__group-title:first-child {
  margin-top: 0;
}
.product-options__group-description {
  font-size: 1rem;
  line-height: 1.42857;
  margin-bottom: 0.5rem;
}
.product-options .product-option {
  --border-size: 3px;
  opacity: 0;
  animation: fadeIn 600ms ease-in forwards;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  min-height: 91px;
  width: 100%;
  background: #ffffff;
  align-items: center;
  border: var(--border-size) solid #e9edf0;
  border-radius: 8px;
  padding: 1rem 1.5rem 1rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: border 100ms ease-in-out, box-shadow 0.2s ease-in-out;
}
.product-options .product-option:nth-of-type(2) {
  animation-delay: 0.2s;
}
.product-options .product-option:nth-of-type(3) {
  animation-delay: 0.4s;
}
.product-options .product-option:nth-of-type(4) {
  animation-delay: 0.6s;
}
.product-options .product-option__content {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 1fr 1fr;
}
@media screen and (max-width: 1100px) {
  .product-options .product-option__content {
    grid-template-columns: max-content 1fr;
  }
}
.product-options .product-option:not(:last-child) {
  margin-bottom: 1rem;
}
.product-options
  .product-option:focus-within:not(
    .product-options .product-option--selected
  ):not(.product-options .product-option--has-errors),
.product-options
  .product-option:hover:not(.product-options .product-option--selected):not(
    .product-options .product-option--has-errors
  ) {
  border-color: #b4bfc9;
  box-shadow: 0 4px 8px -4px rgba(31, 40, 47, 0.1),
    0 8px 16px -4px rgba(31, 40, 47, 0.06);
  z-index: 3;
}
.product-options .product-option__select-trigger {
  all: unset;
  position: absolute;
  inset: calc(var(--border-size) * -1);
  width: 100%;
  cursor: pointer;
}
.product-options .product-option__discount {
  display: inline-block;
  margin-left: 1rem;
}
.product-options .product-option__discount--small-device {
  text-align: left;
  display: none;
  flex-basis: 100%;
}
.product-options .product-option__promo-details {
  display: flex;
  width: 100%;
}
.product-options .product-option--promo {
  justify-content: start;
  cursor: default;
}
.product-options .product-option--promo:hover {
  box-shadow: none;
}
.product-options .product-option--selected {
  border-color: #009de3;
  z-index: 2;
  position: relative;
}
.product-options .product-option__select-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 100%;
  width: 24px;
  height: 24px;
  grid-column: -1;
  align-items: center;
  justify-content: center;
  background: #009de3;
  color: #ffffff;
  border-radius: 999px;
}
.product-options .product-option__select-icon--selected {
  display: flex;
  animation: selectIconAppear 0.2s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
@keyframes selectIconAppear {
  0% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0.25);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
.product-options .product-option__details-eta-container {
  display: flex;
  flex: 1;
  align-items: center;
}
.product-options .product-option__details-name {
  grid-column: 1;
  font-size: 1.25rem;
  padding-right: 1rem;
  display: inline-flex;
}
.product-options .product-option__details-logo {
  grid-column: 1;
  display: block;
  color: #33424f;
}
.product-options .product-option__eta {
  grid-column: 2;
  grid-row: 1/4;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0.5rem 0;
}
.product-options .product-option__pickup-dates {
  grid-column: 1;
  display: inline-flex;
  font-size: 0.875rem;
}
.product-options .product-option__eta-icon {
  color: #b4bfc9;
  height: 22px;
  flex: 0 0 20px;
  margin-right: 0.5rem;
}
.product-options .product-option__badges {
  grid-column: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  padding-top: 0.5rem;
}
.product-options .product-option__price {
  grid-column: 3;
  grid-row: 1/4;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  justify-content: end;
  white-space: nowrap;
  align-self: center;
  align-items: flex-end;
}
.product-options .product-option__price-currency {
  font-size: 0.875rem;
}
.product-options .product-option__price-value {
  font-size: 1.25rem;
}
.product-options .product-option__price-discount {
  font-size: 0.875rem;
  color: #9fa7ae;
  text-decoration: line-through;
  text-align: right;
}
.product-options .product-option__description {
  font-size: 0.875rem;
  margin-right: 2rem;
  line-height: 1.5;
}
.product-options .product-option--has-errors {
  cursor: default;
  background: #f7f8fa;
}
.product-options .product-option--has-errors .product-option__details-name {
  display: flex;
}
.product-options .product-option--has-errors .product-option__errors {
  text-align: left;
  color: #ba3037;
  font-size: 0.875rem;
}
.product-options .product-option--has-errors:hover {
  box-shadow: none;
}
.product-options .product-badge {
  display: inline-flex;
  align-items: center;
}
.product-options .product-badge__icon {
  display: flex;
}
.product-options .product-badge__label {
  font-size: 0.75rem;
  color: #6d7883;
  padding-left: 0.5rem;
}
.product-options__notice-panel {
  display: flex;
  border: 0.0625rem solid #009de3;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
.product-options__notice-panel__blue-box {
  width: 8px;
  border-radius: 0.25rem;
  background-color: #009de3;
  margin-right: 1rem;
}
.product-options__notice-panel__text {
  padding: 0.5rem 0;
  margin-bottom: 0;
}
.product-options__notice-panel__text--italic {
  font-style: italic;
}
@media (max-width: 1100px) {
  .product-options .product-option {
    align-items: flex-start;
  }
  .product-options .product-option__details-eta-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .product-options .product-option__details-name {
    grid-column: 1/3;
  }
  .product-options .product-option__details {
    flex: 1;
    margin-bottom: 0.5rem;
  }
  .product-options .product-option__eta {
    grid-column: 1;
    grid-row: 3;
    justify-content: start;
  }
  .product-options .product-option__promo-details {
    flex-flow: row wrap;
  }
  .product-options .product-option__discount {
    margin-top: 0.5rem;
  }
  .product-options .product-option__badges {
    grid-column: 1/4;
    gap: 0.5rem;
  }
  .product-options .product-option__price {
    align-self: start;
  }
}
@media (max-width: 460px) {
  .product-options .product-option__discount--large-device {
    display: none;
  }
  .product-options .product-option__discount--small-device {
    display: block;
    margin-top: 1rem;
  }
}
.product-options-drawer {
  font-size: 1rem;
}
.product-options-drawer .product-option {
  align-items: flex-start;
}
.product-options-drawer .product-option__details-eta-container {
  flex-direction: column;
  align-items: start;
  margin-right: -6rem;
}
.product-options-drawer .product-option__details {
  margin-bottom: 1rem;
}
.product-options-drawer .product-option__weight {
  margin-bottom: 0.5rem;
}
.product-options-drawer .product-option__price {
  align-self: center;
}
.product-options-drawer .product-option__eta {
  grid-column: 1;
  grid-row: 3;
  justify-content: start;
}
.product-options-drawer .minimal-product-render .product-option {
  align-items: center;
}
.product-options-drawer .minimal-product-render .product-option__details {
  margin-bottom: 0;
}
html[data-test-env="true"] .product-option__select-trigger {
  all: revert;
  padding: 0.25rem;
}
.product-order-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  font-size: 1rem;
  width: 1200px;
  max-width: 100%;
}
.product-order-form__form {
  flex: 1 1 800px;
  max-width: 100%;
}
.product-order-form__summary {
  flex: 1 0 30ch;
  padding-left: 1rem;
}
.product-order-form__link-button {
  height: fit-content;
}
.product-order-form__link-button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
  text-decoration: none;
}
.product-order-form__link-button--alert {
  color: inherit;
  text-decoration: underline;
}
.product-order-form__link-button--alert:focus,
.product-order-form__link-button--alert:hover {
  text-decoration: underline;
  color: inherit;
}
.product-order-form__link-button--small {
  font-size: 0.875rem;
}
.product-order-form__section-title {
  margin-bottom: 1rem;
}
.product-order-form__fields {
  width: 785px;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .product-order-form__fields {
    margin-right: 2rem;
  }
}
.product-order-form .sds-heading--medium,
.product-order-form #tracking-tools #help-content .article-body h1,
#tracking-tools #help-content .article-body .product-order-form h1,
.product-order-form #support #help-content .article-body h1,
#support #help-content .article-body .product-order-form h1,
.product-order-form #tracking-batch #help-content .article-body h1,
#tracking-batch #help-content .article-body .product-order-form h1,
.product-order-form .bulk-actions-header-row__title-content__title {
  margin: 1rem 0;
}
.product-order-form-group__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
}
@media screen and (min-width: 992px) {
  .product-order-form-group__row {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  .product-order-form-group__row--large-right {
    grid-template-columns: 1fr;
    margin-bottom: 1px;
  }
}
@media (min-width: 1200px) {
  .product-order-form-group__row--large-right {
    grid-template-columns: 2fr 3fr;
  }
}
.product-order-form-group__row--full-width-column {
  grid-template-columns: 1fr;
}
.product-order-form-group .select2-container {
  width: 100% !important;
}
.product-order-form-group .hscode-hinttip .hinttip-content {
  width: 260px;
}
.single-product-order-form {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  .single-product-order-form {
    flex-direction: row;
  }
}
#receiver-fields {
  scroll-margin-top: 105px;
}
.receiver-fields__receiver-header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}
@media screen and (min-width: 992px) {
  .receiver-fields__receiver-header {
    flex-direction: row;
  }
}
.receiver-fields__receiver-header__info-usage {
  margin-bottom: 0;
}
@media screen and (max-width: 992px) {
  .receiver-fields__receiver-header__info-usage {
    margin-bottom: 0.5rem;
  }
}
@media screen and (min-width: 992px) {
  .global-address .product-order-form-group__row--suburb-details {
    grid-template-columns: 48% 25% 18%;
  }
}
@media screen and (min-width: 992px) {
  .global-address
    .product-order-form-group__row--suburb-details-with-even-distribution {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 992px) {
  .product-order-form-group__row--suburb-details-with-even-distribution {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#sender-fields {
  scroll-margin-top: 125px;
}
.sender__instructions .warning.glyphicon.glyphicon-alert,
.sender__instructions .warning.glyphicon-alert.btn-loading:after,
.sender__instructions .warning.glyphicon-alert.panel-loading:after,
.sender__instructions .warning.glyphicon-alert.panel-loading-plain:after {
  color: #f8ce46;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #33424f;
  width: 0;
  height: 0;
}
.sender__instructions .warning.glyphicon.glyphicon-alert:after,
.sender__instructions .warning.glyphicon-alert.btn-loading:after,
.sender__instructions .warning.glyphicon-alert.panel-loading:after,
.sender__instructions .warning.glyphicon-alert.panel-loading-plain:after {
  content: "\e209";
  margin-left: -6px;
}
.sender__instructions .warning.glyphicon.glyphicon-alert:before {
  content: none;
}
.sender-address-display {
  display: flex;
  flex-direction: column;
}
.sender-address-display__container {
  display: flex;
}
.sender-address-display__instructions {
  margin-top: 1rem;
}
.sender-address-display__avatar {
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
}
.sender-address-display__address-container {
  flex-grow: 1;
}
.sender-address-display__address {
  display: flex;
  align-items: center;
}
.sender-address-display__address .hinttip-content {
  top: 30px;
  left: 9px;
}
.sender-address-display__verification {
  all: unset;
  position: relative;
}
.sender-address-display__icon {
  display: block;
  cursor: pointer;
}
.product-order-form-group__row--sender-contact {
  grid-template-columns: 1fr;
}
@media screen and (min-width: 992px) {
  .product-order-form-group__row--sender-contact {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .product-order-form-group__row--sender-contact {
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
  }
}
.product-order-form-group__row--pickup-instructions {
  grid-template-columns: 1fr;
}
.sender-side-drawer .product-order-form-group__row--sender-contact {
  grid-template-columns: 1fr;
}
@media screen and (min-width: 992px) {
  .sender-side-drawer .product-order-form-group__row--sender-contact {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .sender-side-drawer .product-order-form-group__row--sender-contact {
    grid-template-columns: 1fr;
    row-gap: 0;
  }
}
@media screen and (min-width: 992px) {
  .sender-side-drawer .product-order-form-group__row {
    grid-template-columns: 1fr;
    row-gap: 0;
  }
}
.sender-side-drawer .product-order-form-group__row--pickup-instructions {
  grid-template-columns: 1fr;
}
.sender-edit-disabled {
  cursor: not-allowed !important;
}
.sender-address-display {
  display: flex;
  justify-content: space-between;
}
.sds-select-wrap {
  position: relative;
  width: 100%;
}
.sds-select-wrap::after {
  position: absolute;
  top: 40%;
  content: "";
  display: block;
  right: 0.5rem;
  width: 1rem;
  height: 0.5rem;
  background: url("data:image/svg+xml;utf8,<svg width='12px' height='8px' viewBox='0 0 12 8' xmlns='http://www.w3.org/2000/svg'><path fill='%236d7883' d='M6,5.58578644 L1.70710678,1.29289322 C1.31658249,0.902368927 0.683417511,0.902368927 0.292893219,1.29289322 C-0.0976310729,1.68341751 -0.0976310729,2.31658249 0.292893219,2.70710678 L5.29289322,7.70710678 C5.68341751,8.09763107 6.31658249,8.09763107 6.70710678,7.70710678 L11.7071068,2.70710678 C12.0976311,2.31658249 12.0976311,1.68341751 11.7071068,1.29289322 C11.3165825,0.902368927 10.6834175,0.902368927 10.2928932,1.29289322 L6,5.58578644 Z'></path></svg>")
    no-repeat;
  pointer-events: none;
}
.sds-select {
  height: 2.625rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 1.5rem;
}
.sds-select--placeholder,
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__placeholder,
.sds-select:disabled,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-select.select2-selection__rendered {
  color: #6d7883;
  font-style: italic;
}
.select2-container * {
  box-sizing: border-box;
}
.select2-container.select2-container--sds .select2-selection {
  outline: 0;
  background-color: #ffffff;
  height: 2.625rem;
  color: #33424f !important;
}
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 2.5rem;
  margin-right: 1.5rem;
  padding-right: 0px;
}
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__arrow {
  height: 100%;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__arrow
  b {
  position: absolute;
  height: 0;
  width: 0;
  margin-top: -2px;
  margin-left: -8px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__arrow
  b:before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-left: 0;
  position: absolute;
  top: -6px;
  left: -4px;
  background: url(./assets/search.svg)
    no-repeat no-repeat;
  background-position: -0px -80px;
}
.select2-container.select2-container--sds[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}
.select2-container.select2-container--sds[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container.select2-container--sds.select2-container--disabled {
  pointer-events: none;
}
.select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered
  .select2-selection__clear {
  display: none;
}
.select2-container.select2-container--sds.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b:before {
  transform: rotate(180deg);
}
.select2-container.select2-container--sds.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container.select2-container--sds.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container.select2-container--sds.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container.select2-container--sds.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container.select2-container--sds .select2-search--dropdown {
  padding: 0.5rem;
}
.select2-container.select2-container--sds
  .select2-search--dropdown
  .select2-search__field {
  color: #33424f !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.select2-container.select2-container--sds
  .select2-search--dropdown
  .select2-search__field:focus,
.select2-container.select2-container--sds
  .select2-search--dropdown
  .select2-search__field:active {
  background-color: #ffffff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 157, 227, 0.5);
}
.select2-container.select2-container--sds
  .select2-search--inline
  .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container.select2-container--sds
  .select2-results
  > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container.select2-container--sds
  .select2-results__option
  .select2-results__option {
  padding-left: 1em;
}
.select2-container.select2-container--sds
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container.select2-container--sds
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container.select2-container--sds
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container.select2-container--sds
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container.select2-container--sds
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container.select2-container--sds
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container.select2-container--sds .select2-results__option--group {
  padding: 0;
}
.select2-container.select2-container--sds .select2-results__option--disabled {
  color: #6d7883;
}
.select2-container.select2-container--sds.select2-container--open
  .select2-results
  .select2-results__option--highlighted {
  color: #33424f;
  background-color: #e9edf0;
}
.select2-container.select2-container--sds.select2-container--open
  .select2-results
  .select2-results__option[aria-selected="true"] {
  color: #ffffff;
  background-color: #008dcc;
}
.select2-container.select2-container--sds.select2-container--open
  .select2-dropdown {
  border-color: #b4bfc9;
}
.select2-container.select2-container--sds .select2-results__group {
  cursor: default;
  display: block;
  padding: 0.5rem;
}
.select2-container .select2-dropdown {
  border-radius: 0.25rem;
}
.select2-container--focus .select2-selection {
  background-color: #ffffff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 157, 227, 0.5);
}
.page-footer {
  text-align: center;
  color: #8a9299;
  background-color: #33424f;
  padding: 20px 0 60px 0;
  font-size: 14px;
}
.page-footer a {
  color: inherit;
  text-decoration: underline;
}
.page-footer a:hover {
  color: #ccc;
}
.page-footer nav {
  border-top: 1px solid #4e545a;
  padding: 25px 0 0 0;
  line-height: 1em;
}
.page-footer nav .copyright {
  display: inline-block;
  padding: 0 5px 0 0;
}
.page-footer nav a {
  display: inline-block;
  border-left: 1px solid;
  padding: 0 5px 0 7px;
}
@media screen and (max-width: 560px) {
  .page-footer nav .copyright {
    display: block;
    padding: 0 0 10px 0;
  }
  .page-footer nav a {
    border: 0;
    padding: 0 5px 10px 5px;
  }
}
nav.our-supporters-nav {
  border-top: 0px solid #4e545a;
  padding: 25px 0 0 0;
  line-height: 1em;
}
nav.our-supporters-nav .copyright {
  display: inline-block;
  padding: 0 5px 0 0;
}
nav.our-supporters-nav a {
  display: inline-block;
  border-left: 1px solid;
  padding: 0 5px 0 7px;
  margin: 0;
}
@media screen and (max-width: 560px) {
  nav.our-supporters-nav .copyright {
    display: block;
    padding: 0 0 10px 0;
  }
  nav.our-supporters-nav a {
    border: 0;
    padding: 0 5px 10px 5px;
  }
}
.our-supporters .our-supporters-logos {
  margin: 0 0 20px 0;
}
.our-supporters .our-supporters-logos a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
}
.our-supporters .our-supporters-logos a:first-child {
  margin-left: 0;
}
.our-supporters .our-supporters-logos a:last-child {
  margin-right: 0;
}
.our-supporters .our-supporters-logos img {
  display: block;
  height: auto;
}
.our-supporters .our-supporter-tushare {
  width: 110px;
}
.our-supporters .our-supporter-climate-friendly {
  width: 100px;
}
.our-supporters .our-supporter-nrma {
  width: 110px;
}
.our-supporters .our-supporter-bcorp {
  width: 40px;
}
@media screen and (max-width: 560px) {
  .our-supporters .our-supporters-logos {
    margin: 0 0 10px 0;
  }
  .our-supporters .our-supporters-logos a {
    margin: 0 10px;
  }
  .our-supporters .our-supporters-logos a:first-child {
    margin-left: 0;
  }
  .our-supporters .our-supporters-logos a:last-child {
    margin-right: 0;
  }
  .our-supporters .our-supporter-tushare {
    width: 55px;
  }
  .our-supporters .our-supporter-climate-friendly {
    width: 50px;
  }
  .our-supporters .our-supporter-nrma {
    width: 55px;
  }
  .our-supporters .our-supporter-bcorp {
    width: 20px;
  }
}
.our-supporters-tagline {
  margin: 0 0 20px 0;
}
@keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.button {
  background: #f7f8fa;
  color: #33424f;
  display: inline-block;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 700;
  margin-right: 0.25em;
  border: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  transition: background 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 1px transparent, inset 0 0 0 1px transparent;
}
.button:hover {
  cursor: pointer;
}
.button:hover,
.button:focus {
  background: #e6e7ea;
  text-decoration: none;
  box-shadow: 0 0 0 1px #ddd, inset 0 0 0 1px #ddd;
}
.button:focus {
  outline: 0;
}
.button:active {
  background: #f7f8fa;
  box-shadow: 0 0 0 2px #ddd, inset 0 0 0 1px #ddd;
}
.button--primary {
  background: #ff6800;
  color: #fff;
  display: inline-block;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 700;
  margin-right: 0.25em;
  border: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  transition: background 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 1px transparent, inset 0 0 0 1px transparent;
}
.button--primary:hover {
  cursor: pointer;
}
.button--primary:hover,
.button--primary:focus {
  background: #ee5700;
  text-decoration: none;
  box-shadow: 0 0 0 1px #f59a66, inset 0 0 0 1px #f59a66;
}
.button--primary:focus {
  outline: 0;
}
.button--primary:active {
  background: #f17933;
  box-shadow: 0 0 0 2px #f59a66, inset 0 0 0 1px #f59a66;
}
.button--secondary {
  background: #33424f;
  color: #ffffff;
  display: inline-block;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 700;
  margin-right: 0.25em;
  border: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  transition: background 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 1px transparent, inset 0 0 0 1px transparent;
}
.button--secondary:hover {
  cursor: pointer;
}
.button--secondary:hover,
.button--secondary:focus {
  background: #1f282f;
  text-decoration: none;
  box-shadow: 0 0 0 1px #33424f, inset 0 0 0 1px #33424f;
}
.button--secondary:focus {
  outline: 0;
}
.button--secondary:active {
  background: #33424f;
  box-shadow: 0 0 0 2px #33424f, inset 0 0 0 1px #33424f;
}
.button--large {
  padding: 1rem 2rem;
  font-size: 24px;
}
.button--small {
  padding: 0.5rem 1rem;
}
.button--block {
  display: block;
  width: 100%;
  max-width: 100%;
}
.button--disabled.button {
  background: #e9edf0;
  color: #979797;
  border-color: #e9edf0;
  pointer-events: none;
}
.button--text-inside .button__label {
  display: block;
  font-size: 13px;
  font-weight: 400;
  margin-top: 0.25rem;
}
.button--text-outside {
  text-align: center;
}
.button--text-outside .button {
  display: block;
  width: 100%;
}
.button--text-outside .button__label {
  color: #7a8691;
  display: block;
  font-size: 13px;
  font-weight: 400;
  margin-top: 0.25rem;
  padding: 0 1rem;
}
.bg--dark .button--secondary {
  background: #1f282f;
  color: #ffffff;
  display: inline-block;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 700;
  margin-right: 0.25em;
  border: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  transition: background 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 1px transparent, inset 0 0 0 1px transparent;
}
.bg--dark .button--secondary:hover {
  cursor: pointer;
}
.bg--dark .button--secondary:hover,
.bg--dark .button--secondary:focus {
  background: #33424f;
  text-decoration: none;
  box-shadow: 0 0 0 1px #1f282f, inset 0 0 0 1px #1f282f;
}
.bg--dark .button--secondary:focus {
  outline: 0;
}
.bg--dark .button--secondary:active {
  background: #1f282f;
  box-shadow: 0 0 0 2px #1f282f, inset 0 0 0 1px #1f282f;
}
#new-tracking-page .flex-grid {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 450px) {
  #new-tracking-page .flex-grid {
    display: block;
  }
}
@media (max-width: 450px) {
  #new-tracking-page .flex-grid--small {
    display: flex;
  }
}
#new-tracking-page .flex-grid .cell {
  flex: 1;
  position: relative;
}
#new-tracking-page .flex-grid .cell--3 {
  flex: 0 0 25%;
}
#new-tracking-page .flex-grid .cell--4 {
  flex: 0 0 33.3333%;
}
#new-tracking-page .flex-grid .cell--6 {
  flex: 0 0 50%;
}
#new-tracking-page .flex-grid .cell--8 {
  flex: 0 0 66.6661%;
}
#new-tracking-page .flex-grid .cell--12 {
  flex: 0 0 100%;
}
#new-tracking-page .flex-grid--cell-gap [class*="cell"] {
  padding: 1rem;
}
@media (max-width: 450px) {
  #new-tracking-page .flex-grid--cell-gap [class*="cell"] {
    padding: 0.5rem;
  }
}
#new-tracking-page .flex-grid--centered {
  justify-content: center;
}
#new-tracking-page .header {
  background: #fff;
  color: #33424f;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  font-size: 1rem;
  padding: 0;
  padding: 1rem 0;
  border-bottom: none;
}
@media screen and (max-width: 1195px) {
  #new-tracking-page .header .button.button--primary {
    display: none;
  }
}
#new-tracking-page .header a:hover {
  text-decoration: none;
}
#new-tracking-page .header--fixed {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
}
#new-tracking-page .header__inner {
  max-width: 100%;
  width: 1350px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 1350px) {
  #new-tracking-page .header__inner {
    padding: 0 1rem;
  }
}
#new-tracking-page .header__logo {
  outline: 0;
  position: relative;
  max-width: 150px;
  flex: 0 0 auto;
  margin-right: 2em;
  color: inherit;
  outline: 0;
}
@media screen and (max-width: 1090px) {
  #new-tracking-page .header__logo {
    margin-right: 0;
  }
}
#new-tracking-page .header__logo img {
  width: 100%;
  max-width: 100%;
  display: block;
}
#new-tracking-page .header__nav-link {
  display: inline-block;
  text-decoration: none;
  color: currentColor;
  padding: 1.5em 1em;
  font-weight: 700;
}
@media screen and (max-width: 1090px) {
  #new-tracking-page .header__nav-link {
    padding: 0.25em;
    margin-right: 1rem;
  }
  #new-tracking-page .header__nav-link .icon--chevron {
    display: none;
  }
}
#new-tracking-page .header__nav-link:hover,
#new-tracking-page .header__nav-link:focus {
  color: #ff6800;
}
#new-tracking-page .header__nav-link:hover svg path,
#new-tracking-page .header__nav-link:focus svg path {
  fill: currentColor;
}
@media screen and (min-width: 1089px) {
  #new-tracking-page .header__nav-item--has-dropdown:hover:before {
    content: "";
    display: block;
    height: 0.5rem;
    width: 70%;
    width: calc(100% - 2rem);
    background: #ff6800;
    bottom: 0;
    position: absolute;
    left: 1rem;
    z-index: 110;
    animation: dropdown 0.3s ease forwards;
  }
  #new-tracking-page .header__nav-item--has-dropdown:hover ul {
    display: block;
    animation: dropdown 0.3s ease forwards;
  }
}
#new-tracking-page .header__toggle {
  display: none;
  background: transparent;
  border: 0;
  color: currentColor;
  padding: 0.25em;
  margin: 0;
  margin-left: 1rem;
  outline: 0;
}
@media screen and (max-width: 560px) {
  #new-tracking-page .header__toggle {
    margin-left: auto;
  }
}
#new-tracking-page .header__toggle .icon--close {
  display: none;
}
#new-tracking-page .header__toggle .icon--menu {
  display: inline-block;
}
#new-tracking-page .header__toggle > svg path {
  fill: currentColor;
}
@media screen and (max-width: 1090px) {
  #new-tracking-page .header__toggle {
    display: inline-block;
  }
}
#new-tracking-page .header__toggle:hover {
  cursor: pointer;
}
#new-tracking-page .header__dropdown {
  display: none;
  position: absolute;
  background: #fff;
  top: 100%;
  margin-top: -0.5rem;
  border-radius: 0.25rem;
  list-style-type: none;
  /* list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); */
  padding: 1.5em 0 1em 0;
  min-width: 100%;
  z-index: 100;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1090px) {
  #new-tracking-page .header__dropdown {
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    line-height: 1;
    transform: translateX(0);
  }
}
#new-tracking-page .header__dropdown:hover,
#new-tracking-page .header__dropdown:focus {
  display: block;
}
#new-tracking-page .header__dropdown a {
  padding: 0.25em 1.5em;
  color: #7a8691;
  display: block;
  text-decoration: none;
  font-weight: 400;
  white-space: nowrap;
}
#new-tracking-page .header__dropdown a:hover,
#new-tracking-page .header__dropdown a:focus {
  color: #ff6800;
}
@media screen and (max-width: 1090px) {
  #new-tracking-page .header__dropdown a {
    color: #f7f8fa;
  }
}
#new-tracking-page .header__nav {
  padding: 0;
  display: flex;
  list-style: none;
  margin: 0;
  align-items: center;
}
#new-tracking-page .header__nav li {
  flex: 0 0 auto;
  position: relative;
}
@media screen and (max-width: 1090px) {
  #new-tracking-page .header__nav {
    display: none;
  }
}
#new-tracking-page .header__actions {
  padding: 0;
  display: flex;
  list-style: none;
  margin: 0;
  align-items: center;
  margin-left: auto;
}
#new-tracking-page .header__actions a {
  flex-grow: 0;
  min-width: 7.7em;
}
#new-tracking-page .header__actions li {
  flex: 0 0 auto;
  position: relative;
}
#new-tracking-page .header__actions li:first-of-type a {
  margin-right: 0.5em;
}
#new-tracking-page .header__actions--with-locale-toggle li:nth-of-type(2) a {
  margin-right: 0.5em;
}
@media screen and (max-width: 560px) {
  #new-tracking-page .header__actions {
    display: none;
  }
}
@media screen and (max-width: 1090px) {
  #new-tracking-page .header--is-open {
    color: #fff;
  }
  #new-tracking-page .header--is-open .button.button--primary {
    display: inline-block;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__logo {
    order: -1;
  }
  #new-tracking-page .header--is-open .locale-dropdown {
    margin-top: -1rem;
    order: -1;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__inner {
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    padding: 1rem;
    z-index: 100;
    background: #33424f;
    color: #fff;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__logo {
    flex: 0 0 auto;
    margin-bottom: 1rem;
  }
  #new-tracking-page
    .header--is-open
    #new-tracking-page
    .header__logo
    svg
    #logo-text
    path {
    fill: currentColor;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin-left: auto;
  }
  #new-tracking-page
    .header--is-open
    #new-tracking-page
    .header__toggle
    .icon--close {
    display: inline-block;
  }
  #new-tracking-page
    .header--is-open
    #new-tracking-page
    .header__toggle
    .icon--menu {
    display: none;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__nav,
  #new-tracking-page .header--is-open #new-tracking-page .header__actions {
    display: block;
    flex: 0 0 100%;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__actions li {
    display: inline-block;
    margin-top: 1rem;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__nav-link {
    padding: 0.5rem 0;
    display: inline-block;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__dropdown {
    padding: 0;
    display: block;
    position: static;
    background: transparent;
    color: inherit;
    box-shadow: none;
  }
  #new-tracking-page
    .header--is-open
    #new-tracking-page
    .header__dropdown:before {
    display: none;
  }
  #new-tracking-page .header--is-open #new-tracking-page .header__dropdown a {
    display: inline-block;
    padding: 0.5rem 1rem;
  }
  #new-tracking-page
    .header--is-open
    #new-tracking-page
    .header__dropdown
    a:hover,
  #new-tracking-page
    .header--is-open
    #new-tracking-page
    .header__dropdown
    a:focus {
    background: transparent;
    color: #ff6800;
  }
}
#new-tracking-page .header .header__nav-link {
  padding: 1.6em 1em;
}
#new-tracking-page .header .header__logo:hover {
  color: inherit;
}
#new-tracking-page .header .header__actions a {
  min-width: initial;
}
#new-tracking-page .header .button--small {
  padding: 0.8rem 1.6rem;
}
#new-tracking-page .header .header__toggle {
  display: none;
  margin: 0;
}
#new-tracking-page .header .header__toggle .icon-bar {
  background-color: #ff6800;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  margin: 0.25rem 0;
  padding: 1px 0px;
}
#new-tracking-page .header .header__locale-toggle {
  padding: 0.5rem;
}
#new-tracking-page .header .header__toggle-open {
  display: block;
}
#new-tracking-page .header .header__toggle-close {
  display: none;
  background: #fff;
  display: none;
}
@media screen and (max-width: 510px) {
  #new-tracking-page .header .header__toggle {
    display: block;
  }
  #new-tracking-page .header #nav-signup-button,
  #new-tracking-page .header #nav-login-button {
    display: none;
  }
}
#new-tracking-page .header--is-open .header__inner {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  padding: 1rem;
  z-index: 10000;
  background: #fff;
  color: #33424f;
}
#new-tracking-page .header--is-open .header__actions {
  display: flex;
  flex-basis: 100%;
  margin-top: 1rem;
}
#new-tracking-page .header--is-open .header__toggle {
  position: absolute;
  right: 0;
  top: 1rem;
}
#new-tracking-page .header--is-open .header__toggle-open,
#new-tracking-page .header--is-open .header__locale-toggle {
  display: none;
}
#new-tracking-page .header--is-open #nav-signup-button,
#new-tracking-page .header--is-open #nav-login-button,
#new-tracking-page .header--is-open .header__toggle-close {
  display: block;
}
#new-tracking-page .btn {
  font-size: 15px;
  padding: 16px 24px;
  border: none;
  transition: all 0.1s ease;
}
#new-tracking-page .btn:hover,
#new-tracking-page .btn:active,
#new-tracking-page .btn:focus {
  color: #fff;
  outline: none;
  box-shadow: none;
  border: none;
}
#new-tracking-page .btn-primary {
  background-color: #ff6800;
}
#new-tracking-page .btn-primary:hover,
#new-tracking-page .btn-primary:focus {
  background-color: #ea6408;
}
#new-tracking-page .btn-secondary {
  background-color: #62cccc;
}
#new-tracking-page .btn-secondary:hover,
#new-tracking-page .btn-secondary:focus {
  background: #5dbebf;
}
#new-tracking-page .btn-grey {
  background-color: #eee;
}
#new-tracking-page .btn-grey:hover,
#new-tracking-page .btn-grey:focus {
  background-color: #62cccc;
}
#new-tracking-page .footer {
  padding-top: 4rem;
  background: #fff;
  font-size: 14px;
  color: #7a8691;
}
#new-tracking-page .footer .cell {
  padding: 1rem;
}
@media screen and (max-width: 750px) {
  #new-tracking-page .footer .cell {
    min-width: 10rem;
  }
}
@media screen and (max-width: 750px) {
  #new-tracking-page .footer .cell:first-of-type {
    min-width: 100%;
  }
}
#new-tracking-page .footer__image {
  margin-bottom: 2rem;
}
@media screen and (max-width: 750px) {
  #new-tracking-page .footer__image {
    margin-bottom: 0rem;
  }
}
#new-tracking-page .footer__image img {
  margin: 0 auto;
  width: 160px;
}
#new-tracking-page .footer__logo {
  color: #33424f;
  margin-bottom: 1.15rem;
}
@media screen and (max-width: 750px) {
  #new-tracking-page .footer__logo {
    display: none;
  }
}
#new-tracking-page .footer__body {
  padding: 1rem 0;
}
#new-tracking-page .footer__body p {
  line-height: 1.5;
}
@media screen and (max-width: 750px) {
  #new-tracking-page .footer__body p {
    text-align: center;
  }
}
#new-tracking-page .footer__body .cert-images {
  display: flex;
  align-items: center;
}
#new-tracking-page .footer__body .cert-images__image {
  margin: 0.5rem;
  max-width: 100px;
  min-width: 35px;
}
@media screen and (max-width: 750px) {
  #new-tracking-page .footer__body .cert-images {
    justify-content: center;
  }
}
#new-tracking-page .footer__body h5 {
  font-size: 1rem;
  letter-spacing: initial;
  color: #33424f;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 750px) {
  #new-tracking-page .footer__body h5 {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
}
#new-tracking-page .footer__body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#new-tracking-page .footer__body ul a {
  color: currentColor;
  line-height: 1.5rem;
}
#new-tracking-page .footer__body ul a:hover {
  color: #ff6800;
  text-decoration: none;
}
#new-tracking-page .footer__lower {
  background: #f7f8fa;
  padding: 1rem 0;
}
#new-tracking-page .footer__lower .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#new-tracking-page .footer.footer-globalised .footer__lower .copyright a {
  margin-right: 1.5em;
}
#new-tracking-page .footer.footer-globalised .change-locale__icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  display: inline-block;
}
#new-tracking-page .footer.footer-globalised .change-locale__switch-text {
  display: inline;
}
@media (max-width: 750px) {
  #new-tracking-page .footer.footer-globalised .container {
    display: block;
  }
  #new-tracking-page .footer.footer-globalised .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #new-tracking-page .footer.footer-globalised .change-locale__icon {
    margin-left: 0;
  }
  #new-tracking-page .footer.footer-globalised .change-locale__switch-text {
    display: none;
  }
  #new-tracking-page .footer.footer-globalised .social-icons {
    margin-top: 5px;
  }
}
#new-tracking-page .footer .footer__body {
  padding: 2rem 0;
}
#new-tracking-page .footer .footer__body ul a {
  line-height: 2.5rem;
}
#new-tracking-page .footer .footer__body ul a:hover {
  color: #008dcc;
  text-decoration: underline;
}
#new-tracking-page .footer .footer__body h5 {
  margin-top: 1rem;
  margin-bottom: 2.8rem;
  font-weight: 700;
  font-size: 1.5rem;
}
#new-tracking-page .footer .footer__logo {
  margin-bottom: 2rem;
}
#new-tracking-page .footer .footer__lower {
  padding: 2.2rem 0;
}
#new-tracking-page .footer a.text--subtle {
  color: currentColor;
}
#new-tracking-page .footer a.text--subtle:hover {
  color: #008dcc;
}
#new-tracking-page .footer .cell {
  padding: 2rem;
}
#new-tracking-page .footer img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
#new-tracking-page .footer .icon,
#new-tracking-page .footer #tracking-tools #modal-search form:before,
#tracking-tools #modal-search #new-tracking-page .footer form:before,
#new-tracking-page .footer #support #modal-search form:before,
#support #modal-search #new-tracking-page .footer form:before,
#new-tracking-page .footer #tracking-batch #modal-search form:before,
#tracking-batch #modal-search #new-tracking-page .footer form:before,
#new-tracking-page .footer .navbar-form.search-form:after {
  display: inline-block;
  color: inherit;
}
#new-tracking-page .footer .social-icons {
  display: none;
}
#new-tracking-page .footer .change-locale {
  display: none;
}
#new-tracking-page .alert-success-div {
  padding: 32px 16px 0;
}
#new-tracking-page .alert-success {
  max-width: 800px;
  margin: auto;
  background-color: #e5f6ef;
  border-color: #e5f6ef;
  color: #009a57;
}
#new-tracking-page .alert-success .close {
  font-size: 21px;
  opacity: 1;
  color: #00ab61;
}
#new-tracking-page .alert-success .close:hover {
  opacity: 1;
  color: #009a57;
}
#new-tracking-page .tracking-requestor-icon {
  content: url(https://assets.sendle.com/assets/icons/icon-requester-9c11e6222e58e6b033b26f52dcf1ef9ec6554a19f2ecdaf53db5e1660bb49b96.svg);
  padding-top: 5px;
  background-size: 13px 13px;
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  left: 1px;
  padding-right: 3px;
}
#new-tracking-page .tracking-collected-text {
  line-height: 18px;
  font-size: 13px;
  margin: 8px 0;
  color: #7a8691;
}
#new-tracking-page .tracking-collected-title {
  font-weight: 600;
}
#new-tracking-page .tracking-requestor {
  font-size: 13px;
}
#new-tracking-page #flash {
  padding: 0 15px;
}
#new-tracking-page #flash .btn-close-alert {
  display: none;
}
#new-tracking-page #flash .alert-info {
  max-width: 800px;
  margin: auto;
  padding: 15px 32px;
}
.authenticated_receiver .tracking-module > div,
.authenticated_sender .tracking-module > div,
.semiauthenticated_receiver .tracking-module > div,
.semiauthenticated_sender .tracking-module > div,
.unauthenticated .tracking-module > div,
#verify-non-delivery .tracking-module > div {
  padding: 24px 32px;
}
.authenticated_receiver .panel,
.authenticated_sender .panel,
.semiauthenticated_receiver .panel,
.semiauthenticated_sender .panel,
.unauthenticated .panel,
#verify-non-delivery .panel {
  border: solid 1px #eee;
  margin-bottom: 0px;
  box-shadow: none;
}
.authenticated_receiver .panel-heading,
.authenticated_sender .panel-heading,
.semiauthenticated_receiver .panel-heading,
.semiauthenticated_sender .panel-heading,
.unauthenticated .panel-heading,
#verify-non-delivery .panel-heading {
  padding: 10px 32px;
  border: none;
}
.authenticated_receiver .panel-body,
.authenticated_sender .panel-body,
.semiauthenticated_receiver .panel-body,
.semiauthenticated_sender .panel-body,
.unauthenticated .panel-body,
#verify-non-delivery .panel-body {
  padding: 0;
}
.authenticated_receiver .panel-default,
.authenticated_sender .panel-default,
.semiauthenticated_receiver .panel-default,
.semiauthenticated_sender .panel-default,
.unauthenticated .panel-default,
#verify-non-delivery .panel-default {
  padding: 0;
  background-color: white;
}
.authenticated_receiver .panel-raised,
.authenticated_sender .panel-raised,
.semiauthenticated_receiver .panel-raised,
.semiauthenticated_sender .panel-raised,
.unauthenticated .panel-raised,
#verify-non-delivery .panel-raised {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}
.authenticated_receiver .panel-footer,
.authenticated_sender .panel-footer,
.semiauthenticated_receiver .panel-footer,
.semiauthenticated_sender .panel-footer,
.unauthenticated .panel-footer,
#verify-non-delivery .panel-footer {
  border-top: 1px solid #e9edf0;
}
.authenticated_receiver .panel-talk,
.authenticated_sender .panel-talk,
.semiauthenticated_receiver .panel-talk,
.semiauthenticated_sender .panel-talk,
.unauthenticated .panel-talk,
#verify-non-delivery .panel-talk {
  border: solid 1px #62cccc;
  width: 100%;
  box-shadow: none;
  margin-bottom: 20px;
  padding: 8px !important;
  border-radius: 2px;
  display: table;
}
.authenticated_receiver .panel-whisper,
.authenticated_sender .panel-whisper,
.semiauthenticated_receiver .panel-whisper,
.semiauthenticated_sender .panel-whisper,
.unauthenticated .panel-whisper,
#verify-non-delivery .panel-whisper {
  border: 1px solid #e9edf0;
  box-shadow: none;
  width: 100%;
  margin-bottom: 20px;
  padding: 8px !important;
  border-radius: 2px;
  display: table;
}
.authenticated_receiver .panel-talk-rectangle,
.authenticated_sender .panel-talk-rectangle,
.semiauthenticated_receiver .panel-talk-rectangle,
.semiauthenticated_sender .panel-talk-rectangle,
.unauthenticated .panel-talk-rectangle,
#verify-non-delivery .panel-talk-rectangle {
  display: table-cell;
  width: 8px;
  border-radius: 2px;
  background-color: #62cccc;
}
.authenticated_receiver .panel-whisper-rectangle,
.authenticated_sender .panel-whisper-rectangle,
.semiauthenticated_receiver .panel-whisper-rectangle,
.semiauthenticated_sender .panel-whisper-rectangle,
.unauthenticated .panel-whisper-rectangle,
#verify-non-delivery .panel-whisper-rectangle {
  display: table-cell;
  width: 8px;
  border-radius: 2px;
  background-color: #e9edf0;
}
.authenticated_receiver .panel-notification-body,
.authenticated_sender .panel-notification-body,
.semiauthenticated_receiver .panel-notification-body,
.semiauthenticated_sender .panel-notification-body,
.unauthenticated .panel-notification-body,
#verify-non-delivery .panel-notification-body {
  margin: 8px 16px;
}
.authenticated_receiver .progress-event-destination:before,
.authenticated_sender .progress-event-destination:before,
.semiauthenticated_receiver .progress-event-destination:before,
.semiauthenticated_sender .progress-event-destination:before,
.unauthenticated .progress-event-destination:before,
#verify-non-delivery .progress-event-destination:before {
  content: "\276f";
  margin-left: 2px;
  margin-right: 4px;
  color: #ff6800;
}
.authenticated_receiver .btn-primary-outline,
.authenticated_sender .btn-primary-outline,
.semiauthenticated_receiver .btn-primary-outline,
.semiauthenticated_sender .btn-primary-outline,
.unauthenticated .btn-primary-outline,
#verify-non-delivery .btn-primary-outline {
  background-color: #fff;
  border: 1px solid #ff6800;
  color: #ff6800;
}
.authenticated_receiver .btn-primary-outline:hover,
.authenticated_receiver .btn-primary-outline:focus,
.authenticated_sender .btn-primary-outline:hover,
.authenticated_sender .btn-primary-outline:focus,
.semiauthenticated_receiver .btn-primary-outline:hover,
.semiauthenticated_receiver .btn-primary-outline:focus,
.semiauthenticated_sender .btn-primary-outline:hover,
.semiauthenticated_sender .btn-primary-outline:focus,
.unauthenticated .btn-primary-outline:hover,
.unauthenticated .btn-primary-outline:focus,
#verify-non-delivery .btn-primary-outline:hover,
#verify-non-delivery .btn-primary-outline:focus {
  color: #fff;
  background-color: #ff6800;
}
.authenticated_receiver .detail-well,
.authenticated_sender .detail-well,
.semiauthenticated_receiver .detail-well,
.semiauthenticated_sender .detail-well,
.unauthenticated .detail-well,
#verify-non-delivery .detail-well {
  margin: 12px 0;
  padding: 4px 20px;
  border-radius: 2px;
  background-color: rgba(98, 204, 204, 0.1);
}
.semiauthenticated_receiver,
.semiauthenticated_sender,
.unauthenticated,
#verify-non-delivery {
  background-image: url(https://assets.sendle.com/assets/tracking/tracking-bg-4e2f9835a32400e04f0bbf1c250e9d9e41032fad6ac8df02e525ae83fc61764c.svg);
  background-repeat: no-repeat;
  background-position: center top;
}
.authenticated_sender .order-header .btn {
  font-weight: 400;
}
@media (max-width: 767px) {
  .semiauthenticated_receiver,
  .semiauthenticated_sender,
  .unauthenticated,
  #verify-non-delivery {
    background-image: none;
  }
  .authenticated_receiver h1.text-center,
  .authenticated_sender h1.text-center {
    margin-top: 24px;
  }
  .authenticated_receiver .state-label-complete:after,
  .authenticated_receiver .state-label-current:after,
  .authenticated_receiver .state-label-incomplete:after,
  .authenticated_sender .state-label-complete:after,
  .authenticated_sender .state-label-current:after,
  .authenticated_sender .state-label-incomplete:after {
    width: 15px;
  }
  .authenticated_receiver .container,
  .authenticated_sender .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  .unauthenticated h1.text-center {
    margin: 56px 0 30px 0;
  }
  .semiauthenticated_receiver .tracking-module > div,
  .semiauthenticated_sender .tracking-module > div,
  .authenticated_receiver .tracking-module > div,
  .authenticated_sender .tracking-module > div,
  .unauthenticated .tracking-module > div {
    padding: 24px 16px;
  }
  .semiauthenticated_receiver .status-indicator,
  .semiauthenticated_sender .status-indicator,
  .authenticated_receiver .status-indicator,
  .authenticated_sender .status-indicator {
    margin-bottom: 32px;
  }
  #new-tracking-page footer .cell {
    min-width: 100%;
  }
  #new-tracking-page header .header__actions {
    display: inherit;
    font-size: 1rem;
  }
  #new-tracking-page #flash {
    padding: 0 15px;
  }
  #new-tracking-page #flash .btn-close-alert {
    display: none;
  }
  #new-tracking-page #flash .alert-info {
    max-width: 800px;
    margin: auto;
    padding: 15px 16px;
  }
}
@media (min-width: 768px) {
  .authenticated_receiver > div .tracking-module,
  .authenticated_receiver > div .subheading,
  .authenticated_sender > div .tracking-module,
  .authenticated_sender > div .subheading,
  .semiauthenticated_receiver > div .tracking-module,
  .semiauthenticated_receiver > div .subheading,
  .semiauthenticated_sender > div .tracking-module,
  .semiauthenticated_sender > div .subheading,
  .unauthenticated > div .tracking-module,
  .unauthenticated > div .subheading {
    max-width: 800px;
    margin: auto;
  }
  .authenticated_receiver .alert-warning,
  .authenticated_sender .alert-warning,
  .semiauthenticated_receiver .alert-warning,
  .semiauthenticated_sender .alert-warning,
  .unauthenticated .alert-warning {
    max-width: 900px;
    margin: auto;
  }
}
.authenticated_sender body,
.authenticated_receiver body {
  color: #33424f;
  font-weight: 300;
}
.authenticated_sender h1,
.authenticated_receiver h1 {
  font-size: 31px;
  line-height: 36px;
}
.authenticated_sender h2,
.authenticated_receiver h2 {
  font-size: 26px;
  line-height: 28px;
}
.authenticated_sender h3,
.authenticated_receiver h3 {
  font-size: 22px;
  line-height: 24px;
  margin: 40px 0 8px 0;
}
.authenticated_sender h4,
.authenticated_receiver h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #3a4147;
}
.authenticated_sender h1,
.authenticated_sender h2,
.authenticated_sender h3,
.authenticated_receiver h1,
.authenticated_receiver h2,
.authenticated_receiver h3 {
  font-weight: 700;
}
.authenticated_sender .strong,
.authenticated_receiver .strong {
  font-weight: 600;
}
.authenticated_sender .heavy,
.authenticated_receiver .heavy {
  font-weight: 700;
}
.authenticated_sender p,
.authenticated_receiver p {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}
.authenticated_sender .secondary-text,
.authenticated_receiver .secondary-text {
  color: #33424f;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}
.authenticated_sender small,
.authenticated_receiver small {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
.authenticated_sender li,
.authenticated_receiver li {
  font-weight: 400;
}
@media (max-width: 767px) {
  .authenticated_sender h3,
  .authenticated_receiver h3 {
    line-height: 24px;
    margin: 16px 0 8px 0;
  }
}
#tracking-cancel {
  padding-bottom: 24px;
  overflow: auto;
  position: relative;
}
#tracking-cancel .modal-body .cancel-btn-copy {
  margin-top: 6px;
}
#tracking-cancel .modal-body p {
  color: #33424f;
  text-align: left;
  font-size: 15px;
  margin-bottom: 16px;
  padding-left: 0;
  line-height: 20px;
}
#tracking-cancel .modal-body .well {
  padding: 16px 24px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f7f8fa;
  border: none;
  box-shadow: none;
  font-size: 15px;
}
#tracking-cancel .modal-body .well li {
  margin: 8px 0;
}
#tracking-cancel .modal-body ul {
  list-style: none;
  margin-top: 8px;
  padding: 0px 16px;
}
#tracking-cancel .modal-body .confirmation-text {
  margin-top: -6px;
}
#tracking-cancel .modal-body li::before {
  content: "•";
  color: #e93d45;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  text-align: left;
}
#tracking-cancel .modal-body .btn-close {
  line-height: 22px;
}
#tracking-cancel .modal-body .button_to {
  display: inline-block;
}
#tracking-cancel .modal-body h4 {
  margin-top: 0;
}
#toggle-cancel {
  display: flex;
  align-items: center;
  width: auto;
  float: left;
  padding: 5px 24px;
  margin: 24px 4px 4px 0;
  font-size: 15px;
  font-weight: 600;
  border: none;
  height: 48px;
  color: #33424f;
  border-radius: 2px;
  background-color: #f7f8fa;
  text-decoration: none;
}
#toggle-cancel:hover {
  background-color: #fcebec;
}
.scrollable {
  overflow: auto !important;
}
.modal-backdrop {
  background-color: white;
}
.modal-backdrop.in {
  opacity: 0;
}
@media (max-width: 991px) {
  #tracking-cancel p {
    max-width: 80%;
  }
}
@media (max-width: 767px) {
  #tracking-cancel p {
    margin-top: 12px;
    max-width: 67%;
  }
  #tracking-cancel .modal-body p {
    margin-top: 0;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  #tracking-cancel p {
    text-align: right;
  }
}
@media screen and (max-width: 991px) {
  #tracking-cancel {
    top: 0px;
  }
}
#tracking-drop_off > div {
  margin: 24px 0;
  padding: 0;
}
#tracking-drop_off .panel-body {
  padding: 24px 32px;
}
#tracking-drop_off .panel-heading {
  background-color: #f7f8fa;
}
#tracking-drop_off .drop-off-locations .nearest-drop-off-location-main-panel {
  margin-left: initial;
  margin-right: initial;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-main-panel
  .loading-panel {
  width: 253px;
  margin: 0 auto;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-main-panel
  .loading-panel
  .loading {
  float: left;
  width: 18px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-main-panel.drop-off-unavailable {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  background: transparent;
  margin-bottom: 24px;
  padding: 16px 16px 16px 32px;
  color: #33424f;
  border: 1px solid #e9edf0;
  border-radius: 2px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-main-panel.drop-off-unavailable:before {
  content: "";
  position: absolute;
  width: 8px;
  left: 8px;
  top: 8px;
  bottom: 8px;
  background: #e9edf0;
  border-radius: 2px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-main-panel.drop-off-unavailable
  .panel-help-link {
  margin-left: 4px;
  font-size: 13px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-main-panel.drop-off-unavailable
  .content {
  color: inherit;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-main-panel.drop-off-unavailable
  .content:last-of-type {
  margin-bottom: 0;
}
#tracking-drop_off .drop-off-locations .nearest-drop-off-location-header {
  font-size: 15px;
  margin-bottom: 15px;
  color: #33424f;
}
#tracking-drop_off .drop-off-locations .nearest-drop-off-location-header a {
  display: none;
}
#tracking-drop_off .drop-off-locations .nearest-drop-off-location-inner-panel {
  color: #5c6872;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data {
  padding-left: 0;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data
  .location-address {
  display: block;
  font-size: 15px;
  margin-bottom: 1px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data
  .store-name {
  color: #33424f;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel.nearest-drop-off-location-store-data
  .location-opening-hours {
  font-size: 13px;
  color: #b7bdc2;
  margin: 8px 0;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel.other-locations {
  padding: 20px 0 0 15px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel.other-locations
  span {
  display: block;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel.other-locations
  .other-locations-header {
  color: #33424f;
  font-size: 15px;
  margin-bottom: 4px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .other-locations-btn,
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .panel-help-link {
  background: #e9edf0;
  display: block;
  color: #5c6872;
  padding: 10px 12px 12px 12px;
  border-radius: 3px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  font-weight: 600;
  margin: 16px 0;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .other-locations-btn
  a,
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .other-locations-btn
  a:visited,
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .panel-help-link
  a,
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .panel-help-link
  a:visited {
  color: #5c6872;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .other-locations-btn
  a:hover,
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .other-locations-btn:hover,
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .panel-help-link
  a:hover,
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .panel-help-link:hover {
  text-decoration: none;
  background-color: #b4bfc9;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .other-locations-btn {
  width: 188px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .panel-help-link {
  width: 168px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .other-locations-info {
  margin-top: 5px;
  color: #33424f;
  font-size: 15px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .powered-by {
  margin-top: 16px;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .powered-by
  span {
  float: right;
  display: none;
}
#tracking-drop_off
  .drop-off-locations
  .nearest-drop-off-location-inner-panel
  .powered-by:after {
  margin-left: 4px;
  color: #5c6872;
  font-size: 11px;
  font-style: italic;
  line-height: 16px;
  content: "Locations";
}
@media (min-width: 992px) {
  #tracking-drop_off
    .drop-off-locations
    .nearest-drop-off-location-inner-panel.other-locations:last-child {
    padding: 0 0 0 32px;
  }
  #tracking-drop_off .drop-off-locations .nearest-drop-off-location-store-data {
    border-right: 1px solid #e9edf0;
  }
}
@media (max-width: 991px) {
  #tracking-drop_off
    .drop-off-locations
    .nearest-drop-off-location-inner-panel {
    padding-left: 0;
  }
  #tracking-drop_off
    .drop-off-locations
    .nearest-drop-off-location-inner-panel.other-locations {
    padding: 20px 0 0 0;
  }
}
@media (max-width: 843px) {
  #tracking-drop_off .panel-heading {
    padding: 12px 16px;
  }
  #tracking-drop_off .panel-body {
    padding: 12px 16px;
  }
}
#tracking-label h4 {
  margin-top: 0;
}
#tracking-label #tracking-order_pickup-label {
  margin: 16px 0 18px 0;
}
#tracking-label #pickup-date-desc {
  font-size: 22px;
  color: #ff6800;
}
#tracking-label #delivery-estimate p {
  margin: 0;
}
#tracking-label .pro-pickup-panel {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
#tracking-label .pro-pickup-panel__banner {
  width: 430px;
  max-width: 100%;
  margin-bottom: 1rem;
}
#tracking-label .pro-pickup-panel__heading {
  font-size: 1.25rem;
  margin: 1rem 0;
  text-align: center;
}
#tracking-label .pro-pickup-panel__tip {
  font-size: 15px;
  text-align: center;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  #tracking-label .pro-pickup-panel__banner {
    width: 600px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  #tracking-label .packing-instructions:first-child {
    border-right: solid 1px #e9edf0;
  }
}
@media (min-width: 992px) {
  #tracking-label .packing-instructions:last-child {
    padding-left: 32px;
  }
}
@media (max-width: 991px) {
  #tracking-label .packing-instructions:last-child h4 {
    margin-top: 24px;
  }
}
#tracking-status {
  position: relative;
}
#tracking-status h4 {
  margin: 8px 0 4px;
}
#tracking-status p {
  margin: 0;
}
#tracking-status small {
  color: #7a8691;
}
#tracking-status .loader.active {
  opacity: 1;
}
#tracking-status img {
  float: left;
  margin-right: 8px;
}
#tracking-status .col-xs-4 {
  padding-right: 0;
}
#tracking-status .col-xs-8 {
  padding-left: 0;
}
#tracking-status p.delivery-info {
  margin-top: 0px;
  line-height: 20px;
  color: #7a8691;
}
#tracking-status .event-date-time {
  color: #7a8792;
}
#tracking-status .tracking-requestor-icon {
  content: url(https://assets.sendle.com/assets/icons/icon-requester-9c11e6222e58e6b033b26f52dcf1ef9ec6554a19f2ecdaf53db5e1660bb49b96.svg);
  padding-top: 5px;
  background-size: 13px 13px;
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  left: 1px;
  padding-right: 3px;
}
#tracking-status .tracking-collected-text {
  line-height: 18px;
  font-size: 13px;
  margin: 8px 0;
  color: #33424f;
}
#tracking-status .tracking-collected-text .detail-well {
  padding: 16px 20px 8px;
}
#tracking-status .tracking-collected-text p {
  font-size: 13px;
  margin: 8px 0;
  color: #33424f;
  line-height: 18px;
}
#tracking-status .tracking-collected-aside {
  color: #7a8691;
  font-style: italic;
}
#tracking-status .tracking-collected-title {
  font-weight: 600;
}
#tracking-status .tracking-requestor {
  font-size: 13px;
}
#tracking-status .unauthenticated-message {
  font-size: 13px;
  font-style: italic;
  line-height: 18px;
  margin: 8px 0;
}
#tracking-status .secondary-text p {
  color: #b7bdc2;
}
#tracking-status #tracking-history-link a {
  color: #008dcc;
  font-size: 13px;
}
#tracking-status #tracking-history-link a .tracking-sprite-jump-link {
  height: 1em;
  max-height: 16px;
}
#tracking-status #login-or-signup {
  margin: 6px 0;
}
#tracking-status .delivered-additional-note {
  padding: 24px 32px 4px;
}
#tracking-status .delivered-additional-note p {
  font-size: 13px;
  line-height: 16px;
}
#tracking-status .col-sm-6 {
  padding-top: 12px;
}
.tracking-status-heading .tracking-sprite-status {
  height: 1em;
  max-height: 24px;
}
.tracking-icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
}
.tracking-icon--risk {
  background: #ba3037;
}
.tracking-icon--warning {
  background: #916c1f;
}
.tracking-icon-parcel-clock {
  mask: url(https://assets.sendle.com/assets/tracking/parcel-clock-daa97553eba7574c926703258a83f55e26471c8ff9d114d2e191b829957669df.svg)
    no-repeat;
  mask-size: cover;
  -webkit-mask: url(https://assets.sendle.com/assets/tracking/parcel-clock-daa97553eba7574c926703258a83f55e26471c8ff9d114d2e191b829957669df.svg)
    no-repeat;
  -webkit-mask-size: cover;
}
.sds-notice-panel.archived-banner {
  max-width: 800px;
  margin: auto;
  display: flex;
}
.returns-panel {
  padding: 1.5rem 0;
  font-size: 1rem;
}
.returns-panel.panel:nth-of-type(2) {
  margin-top: 1.5rem;
}
.returns-panel.panel:nth-of-type(3) {
  margin-bottom: 1.5rem;
}
.returns-panel .return-description {
  font-size: 1rem;
}
.returns-panel .return-instructions:nth-child(2) {
  border-left: solid 1px #e9edf0;
}
.returns-panel .return-instructions .print-btn {
  background-color: #33424f;
  color: #ffffff;
}
.returns-panel .return-instructions .header {
  font-size: 18px;
}
.returns-panel .return-instructions .returns-points .returns-point {
  list-style: none;
  margin-top: 0.5rem;
}
.returns-panel .return-instructions .returns-points .returns-point:before {
  content: "·";
  width: 0.5rem;
  margin-right: 0.5rem;
  font-weight: bold;
  position: absolute;
  margin-left: -1.5rem;
}
@media (min-width: 768px) {
  #tracking-history-link {
    text-align: right;
  }
}
#tracking-insurance-claim {
  margin-top: 24px;
  border: 1px solid #62cccc;
}
#tracking-insurance-claim p {
  margin: 0px;
}
#tracking-insurance-claim ol {
  padding-left: 15px;
  margin: 9px 0;
}
#tracking-insurance-claim ol li {
  padding-bottom: 8px;
  font-size: 15px;
}
#tracking-insurance-claim h4 {
  margin-top: 0;
}
#tracking-insurance-claim .btn {
  padding: 6px 30px;
  float: left;
}
#tracking-insurance-claim #claim-expiry {
  float: left;
  padding: 6px 30px;
}
.claim-type {
  margin-bottom: 15px;
}
#tracking-delivery_summary {
  margin-top: 24px;
}
#tracking-delivery_summary h3 {
  margin-bottom: 24px;
}
#tracking-delivery_summary img {
  margin-top: 4px;
  height: 64px;
  width: 64px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
}
#tracking-delivery_summary .customer-info > div {
  float: left;
  margin: 0 0 0 16px;
  max-width: 74%;
}
#tracking-delivery_summary p:not(.heavy) {
  margin: 0;
}
#tracking-delivery_summary .delivery-summary-info {
  margin-top: 24px;
}
#tracking-delivery_summary .delivery-summary-info .secondary-text {
  font-style: italic;
  color: #6d7883;
}
#tracking-delivery_summary
  .delivery-summary-info
  .delivery-summary-price-adjusted.tooltip {
  font-style: italic;
}
#tracking-delivery_summary .delivery-summary-info .measurement-text {
  font-size: 13px;
  color: #6d7883;
}
#tracking-delivery_summary .delivery-summary-info .measurement-aside {
  font-size: 15px;
  color: #6d7883;
}
#tracking-delivery_summary .delivery-summary-info .tooltip {
  opacity: 100;
  font-size: 15px;
  margin-top: 0;
  display: inline-block;
  position: relative;
  z-index: initial;
}
#tracking-delivery_summary .delivery-summary-info .place-bottom::after {
  border-bottom-color: transparent !important;
}
#tracking-delivery_summary .delivery-summary-info a.tooltip-icon {
  position: relative;
  top: 2px;
}
#tracking-delivery_summary .delivery-summary-info a.tooltip-icon::after {
  content: " ";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
}
#tracking-delivery_summary .delivery-summary-info .parcel-content-section {
  padding-bottom: 1rem;
}
#tracking-delivery_summary .delivery-summary-info .tooltip-component {
  opacity: 1;
  width: 260px;
  padding: 16px;
  color: transparent !important;
  background: transparent !important;
  border: 0px !important;
  margin-top: 0;
}
#tracking-delivery_summary .delivery-summary-info .tooltip-container {
  opacity: 1;
  width: 260px;
  margin-top: 10px;
  padding: 16px;
  background-color: #e9edf0;
  border-radius: 8px;
  transform: translate(-15px, -19px);
  box-shadow: 0 2px 4px 0 rgba(51, 66, 79, 0.23);
}
#tracking-delivery_summary .delivery-summary-info .tooltip-container::after {
  content: " ";
  position: absolute;
  bottom: 87%;
  left: 50%;
  margin-left: -13px;
  border-width: 14px;
  border-style: solid;
  border-color: #e9edf0;
  transform: rotate(45deg);
  z-index: -1;
}
@media (max-width: 991px) {
  #tracking-delivery_summary .delivery-summary-info .tooltip-container::after {
    transform: translateX(26px) rotate(45deg);
  }
}
@media (max-width: 767px) {
  #tracking-delivery_summary .delivery-summary-info .tooltip-container::after {
    transform: translateX(81px) rotate(45deg);
  }
}
@media (max-width: 991px) {
  #tracking-delivery_summary .delivery-summary-info .tooltip-container {
    transform: translate(-42px, -28px);
  }
  #tracking-delivery_summary .delivery-summary-info .tooltip-component {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  #tracking-delivery_summary .delivery-summary-info .tooltip-container {
    transform: translate(-98px, -28px);
  }
}
#tracking-delivery_summary .delivery-summary-info .tooltip-help-text p {
  font-size: 13px !important;
  line-height: 1.5;
  margin-top: 0;
  color: #33424f;
}
#tracking-delivery_summary .delivery-summary-info .tooltip-help-text p:hover {
  visibility: visible !important;
  opacity: 1 !important;
}
#tracking-delivery_summary .delivery-summary-info .pre-discount-price {
  text-decoration: line-through;
  margin: 0 0.25rem;
}
#tracking-delivery_summary .delivery-summary-info .discount-applied {
  margin: 0 0 0.25rem 0.25rem;
}
#tracking-delivery_summary address {
  margin-bottom: 0;
}
#tracking-delivery_summary .refunded #formatted-price {
  text-decoration: line-through;
}
#tracking-delivery_summary p.customer-name,
#tracking-delivery_summary p.sender-name,
#tracking-delivery_summary p.receiver-name {
  margin: 0;
}
#tracking-delivery_summary .tracking-contact_info {
  margin-top: 16px;
}
#tracking-delivery_summary .tracking-contact_info a:hover {
  text-decoration: none;
}
#tracking-delivery_summary .tracking-contact_info img {
  margin-top: 0;
  height: 16px;
  width: 16px;
}
#tracking-delivery_summary .sender,
#tracking-delivery_summary .receiver {
  margin-bottom: 24px;
}
#tracking-delivery_summary #order-summary-cost .secondary-text {
  color: #b7bdc2;
}
#tracking-delivery_summary #no-avatar {
  max-width: 95%;
}
@media (max-width: 991px) {
  #tracking-delivery_summary .customer-info > div {
    max-width: 64%;
  }
}
@media (max-width: 767px) {
  #tracking-delivery_summary .order-summary-refunded {
    margin: 8px 0;
  }
}
#tracking-delivery_guarantee .panel {
  padding: 8px 12px;
  margin-bottom: 32px;
}
#tracking-delivery_guarantee .panel .panel-body {
  padding: 0;
}
#tracking-delivery_guarantee .panel p {
  border-left: 4px solid #eee;
  margin: 0px;
  padding: 0px;
  padding-left: 16px;
  line-height: 24px;
}
#tracking-delivery_guarantee .panel .delivery-guarantee-ineligible {
  font-weight: bold;
}
.tracking-investigation {
  position: relative;
  margin-top: 16px;
}
.tracking-investigation ul {
  content: "";
  display: block;
  height: 100%;
  list-style: none;
  margin-bottom: 0px;
  padding-left: 30px;
}
.tracking-investigation ul:after {
  position: absolute;
  display: block;
  content: "";
  background-color: #f7f8fa;
  top: 0;
  width: 16px;
  border-radius: 20px;
  height: inherit;
  left: 0px;
  height: calc(100% - 4px);
}
.tracking-investigation ul.all-steps-complete:after {
  height: calc(100% - 36px);
}
.tracking-investigation li {
  line-height: 20px;
  padding-bottom: 16px;
  position: relative;
}
.tracking-investigation li span {
  color: #5cb8b7;
  font-style: italic;
  size: 11px;
}
.tracking-investigation li span.step-time {
  color: #7a8691;
}
.tracking-investigation li:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 20px;
  position: absolute;
  left: -29px;
  top: 4px;
  z-index: 3;
}
.tracking-investigation li:last-child {
  padding-bottom: 0;
}
.tracking-investigation .step-complete:before {
  background-image: url(https://assets.sendle.com/assets/tracking/tick-3c8fd0e5e1cb4bf943350747617c0235cfa10bd0a448f39b3743743330a0bde4.svg);
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  background-color: #52a4a3;
}
.tracking-investigation .step-complete:after {
  position: absolute;
  display: block;
  content: "";
  background-color: #67cdcc;
  top: 2px;
  width: 16px;
  height: 100%;
  left: -30px;
  z-index: 2;
}
.tracking-investigation .step-complete:first-child:after {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  min-height: 18px;
}
.tracking-investigation .step-in-progress:before {
  background-color: #a3e1e0;
}
.tracking-investigation .step-in-progress:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 20px;
  background-color: #52a4a3;
  position: absolute;
  left: -25px;
  top: 8px;
  z-index: 10;
}
.tracking-investigation .step-complete-last:after {
  min-height: 18px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.tracking-investigation .step-pending:before {
  background-color: #e9edf0;
}
.tracking-investigation .step-pending {
  color: #7a8691;
}
@media (max-width: 582px) {
  .tracking-investigation ul:after {
    height: calc(100% - 28px);
  }
  .tracking-investigation ul.all-steps-complete:after {
    height: calc(100% - 48px);
  }
}
@media (max-width: 371px) {
  .tracking-investigation ul:after {
    height: calc(100% - 52px);
  }
  .tracking-investigation ul.all-steps-complete:after {
    height: calc(100% - 72px);
  }
}
#tracking-packing_instructions .panel:last-child {
  border: none;
  background-color: #f7f8fa;
}
#tracking-packing_instructions .panel:nth-of-type(2) {
  margin-top: 16px;
}
#tracking-packing_instructions h4 {
  margin-top: 0;
}
#tracking-packing_instructions ul {
  list-style: none;
  padding: 0;
  margin-bottom: 16px;
}
#tracking-packing_instructions ul li {
  font-size: 15px;
  margin: 8px 0;
}
#tracking-packing_instructions ul li.drop-off-details:before {
  margin: 0 8px 0 0;
}
#tracking-packing_instructions ul span {
  margin-right: 8px;
  display: inline-block;
  vertical-align: top;
}
#tracking-packing_instructions ul span:last-child {
  width: 85%;
}
#tracking-packing_instructions ul span.tracking-sprite-external:last-child {
  width: 16px;
}
#tracking-packing_instructions .tracking-sprite {
  top: 0px;
}
#tracking-packing_instructions #pickup-note {
  color: #7a8691;
}
#tracking-packing_instructions .id-instructions .row {
  padding: 5px;
}
#tracking-packing_instructions .id-instructions .col-xs-3 {
  width: 72px;
  padding: 0 8px;
}
#tracking-packing_instructions .id-instructions .col-xs-3 .icon-id {
  margin: 0;
  top: -10px;
}
#tracking-packing_instructions .id-instructions .col-xs-9 {
  width: calc(100% - 80px);
  padding: 0 8px;
}
#tracking-packing_instructions .id-packing-instructions {
  text-align: center;
}
#tracking-packing_instructions .id-packing-instructions p {
  max-width: 132px;
  margin: 0 auto;
}
#tracking-packing_instructions .id-packing-instructions .row {
  padding-top: 20px;
}
.semiauthenticated_sender #tracking-packing_instructions {
  margin-top: 16px;
}
@media (min-width: 992px) {
  #tracking-packing_instructions .packing-instructions:last-child {
    padding-left: 32px;
  }
  #tracking-packing_instructions .packing-instructions:first-child {
    border-right: 1px solid #e9edf0;
  }
  #tracking-packing_instructions .id-instructions {
    border-right: solid 1px #e9edf0;
  }
}
@media (max-width: 991px) {
  #tracking-packing_instructions .packing-instructions:last-child h4 {
    margin-top: 24px;
  }
}
#tracking-carbon_neutral {
  margin-top: 1.5rem;
}
#tracking-carbon_neutral .carbon-neutral {
  background-color: #e5f6ef;
  display: flex;
  padding: 1rem;
}
#tracking-carbon_neutral .carbon-neutral__icon {
  align-self: flex-start;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  display: inherit;
  background: url(https://assets.sendle.com/assets/tracking/carbon-neutral-62fba8db8a23d8962f5322f7d50606323a897ea219a8eb890011d0cfec958463.svg)
    no-repeat;
}
#tracking-carbon_neutral .carbon-neutral__description {
  margin-left: 0.5rem;
  color: #367846;
  padding: 0.5rem 0;
}
#tracking-carbon_neutral .carbon-neutral__description a {
  color: #367846;
  text-decoration: underline;
}
@media (max-width: 450px) {
  #tracking-carbon_neutral .carbon-neutral__icon {
    margin: 0.5rem 0;
  }
}
#tracking-progress h3 {
  margin-bottom: 24px;
  font-weight: 600;
}
#tracking-progress .panel {
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#tracking-progress .panel-body {
  padding: 0;
}
#tracking-progress #icon-external {
  margin-bottom: 4px;
}
#tracking-progress #help-centre {
  margin-top: 4px;
  padding-left: 0;
}
#tracking-progress #tracking-tools #parcel-actions a {
  width: 50%;
}
#tracking-progress .public-type-name {
  font-size: 15px;
  font-weight: 600;
  color: #2a3a47;
}
#tracking-progress .public-type-indicator:before {
  content: " ";
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  height: 16px;
  width: 16px;
  left: 94px;
  top: -1px;
  border: 12px solid #fff;
}
#tracking-progress .public-type-indicator:after {
  position: absolute;
  border-radius: 50%;
  content: "";
  height: 16px;
  width: 16px;
  border: 2px solid #b4bfc9;
  left: 98px;
  top: 3px;
  background: #fff;
  z-index: 3;
}
#tracking-progress .futile-pickup .public-type-indicator:after,
#tracking-progress .left-with-agent .public-type-indicator:after,
#tracking-progress .card-left .public-type-indicator:after,
#tracking-progress .held-at-depot .public-type-indicator:after,
#tracking-progress .return-to-sender .public-type-indicator:after,
#tracking-progress .attempted .public-type-indicator:after,
#tracking-progress .damaged .public-type-indicator:after {
  border-color: #ffbe37;
}
#tracking-progress .cancelled .public-type-indicator:after,
#tracking-progress .failed .public-type-indicator:after,
#tracking-progress .not-serviced .public-type-indicator:after,
#tracking-progress .unable-to-transport .public-type-indicator:after {
  border-radius: 3px;
  content: "";
  height: 14px;
  width: 14px;
  left: 99px;
  border: 2px solid #e93d45;
  background: #e93d45;
}
#tracking-progress .lost .public-type-indicator:after {
  border-radius: 3px;
  content: "";
  height: 14px;
  width: 14px;
  left: 99px;
  border: 2px solid #33424f;
  background: #33424f;
}
#tracking-progress .pickup .public-type-indicator:after,
#tracking-progress .transit .public-type-indicator:after,
#tracking-progress .local-delivery .public-type-indicator:after,
#tracking-progress .info .public-type-indicator:after,
#tracking-progress .weight-check .public-type-indicator:after {
  border-color: #62cccc;
}
#tracking-progress .delivered .public-type-indicator:after {
  border-radius: 3px;
  content: "";
  height: 14px;
  width: 14px;
  left: 99px;
  border: 2px solid #01d277;
  background: #01d277;
}
#tracking-progress .order-created .public-type-indicator:after {
  border-radius: 3px;
  content: "";
  height: 14px;
  width: 14px;
  left: 99px;
  border: 2px solid #62cccc;
  background: #62cccc;
}
#tracking-progress .past-event .public-type-indicator:after {
  border: 2px solid #b4bfc9;
}
#tracking-progress .past-start-event .public-type-indicator:after {
  border-radius: 3px;
  content: "";
  height: 14px;
  width: 14px;
  left: 99px;
  border: 2px solid #b4bfc9;
  background: #b4bfc9;
}
#tracking-progress .new-tracking-event-list:before {
  content: "";
  position: absolute;
  left: 90px;
  top: 15px;
  bottom: 56px;
  width: 1px;
  background: #fff;
  z-index: 2;
  border-width: 0 0 0 2px;
  border-style: dotted;
  border-color: #b4bfc9;
  border-image-source: url(https://assets.sendle.com/assets/icons/tracking-dots-47444b3e62365b6fd307dee09e333ac8adf17003f0dad6b62db6559e882a266d.svg);
  border-image-slice: 3;
  border-image-repeat: repeat;
}
#tracking-progress .new-tracking-event-list {
  position: relative;
}
#tracking-progress .progress-event {
  position: relative;
  padding: 12px 0;
}
#tracking-progress .progress-event-location {
  position: absolute;
  top: 12px;
  right: 16px;
}
#tracking-progress p {
  margin: 0;
}
#tracking-progress .detail-well {
  margin-top: 10px;
  padding: 4px 20px;
  border-radius: 2px;
  background-color: rgba(98, 204, 204, 0.1);
}
#tracking-progress .detail-dropdown ul {
  list-style-type: none;
  margin: 0;
  border: none;
  padding: 0px;
  box-shadow: 0 0 1px 0 rgba(51, 66, 79, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
#tracking-progress .detail-dropdown ul li {
  padding-left: 20px;
  padding-right: 20px;
}
#tracking-progress .detail-dropdown ul li a:before {
  content: none;
  float: left;
  margin-left: -22px;
  width: 15px;
}
#tracking-progress .detail-dropdown ul li:hover {
  background-color: #f5f5f5;
}
#tracking-progress .tracking-requestor-icon {
  content: url(https://assets.sendle.com/assets/icons/icon-requester-9c11e6222e58e6b033b26f52dcf1ef9ec6554a19f2ecdaf53db5e1660bb49b96.svg);
  padding-top: 5px;
  background-size: 13px 13px;
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  left: 1px;
  padding-right: 3px;
}
#tracking-progress .tracking-collected-text {
  line-height: 18px;
  font-size: 13px;
  margin: 8px 0;
  color: #33424f;
}
#tracking-progress .tracking-collected-text .detail-well {
  padding: 16px 20px 8px;
}
#tracking-progress .tracking-collected-text p {
  font-size: 13px;
  margin: 8px 0;
  color: #33424f;
  line-height: 18px;
}
#tracking-progress .tracking-collected-title {
  font-weight: 600;
}
#tracking-progress .tracking-collected-aside {
  color: #7a8691;
  font-style: italic;
}
#tracking-progress .tracking-requestor {
  font-size: 13px;
}
#tracking-progress .unauthenticated-message {
  font-size: 13px;
  font-style: italic;
  line-height: 18px;
  margin: 8px 0;
}
#tracking-progress .secondary-text p {
  color: #b7bdc2;
}
#tracking-progress .progress-event-date-day {
  color: #33424f;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  padding-top: 3px;
}
#tracking-progress .progress-event-date-time,
#tracking-progress .progress-event-date-local-label {
  color: #7a8792;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
}
#tracking-progress .hide-menu {
  display: none;
}
#tracking-progress .show-menu {
  display: block;
}
#tracking-progress .progress-event-date {
  display: block;
}
#tracking-progress .progress-event-date-sm {
  display: none;
}
#tracking-progress .col-sm-4.secondary-text {
  max-width: 130px;
}
.unauthenticated #tracking-progress .panel,
.semiauthenticated_receiver #tracking-progress .panel {
  padding-bottom: 70px;
}
.unauthenticated.export #tracking-progress .panel,
.semiauthenticated_receiver.export #tracking-progress .panel {
  padding-bottom: 24px;
}
.unauthenticated #tracking-progress .dropdown,
.semiauthenticated_receiver #tracking-progress .dropdown,
.semiauthenticated_sender #tracking-progress .dropdown {
  padding: 0;
}
.unauthenticated #tracking-progress .dropdown a.btn-secondary,
.semiauthenticated_receiver #tracking-progress .dropdown a.btn-secondary,
.semiauthenticated_sender #tracking-progress .dropdown a.btn-secondary {
  padding: 8px 12px;
  border-bottom: none;
}
.public-page {
  margin-top: -24px;
  margin-bottom: 24px;
}
.inline-block {
  display: inline-block;
}
.tracking-image {
  max-width: 100%;
}
#tracking-tools-progress #parcel-actions {
  font-size: 15px;
  text-align: right;
}
#tracking-tools-progress #parcel-actions > a {
  font-weight: normal;
  padding: 8px 12px;
}
#tracking-tools-progress #parcel-actions > a:active {
  box-shadow: none;
}
#tracking-tools-progress #parcel-actions i {
  margin: 2px 0 0 8px;
  float: right;
}
#tracking-tools-progress #parcel-actions .dropdown-menu {
  left: auto;
  right: 0;
  padding: 16px 16px 12px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.19);
  border: none;
  margin-bottom: 24px;
}
#tracking-tools-progress #parcel-actions .chevron-down {
  height: 8px;
  width: 16px;
  margin-left: 8px;
  display: inherit;
  background: url(https://assets.sendle.com/assets/tracking/icon-chevron-46a7c3b69a5bef72d982792b9da0d7a2f8b078c60df1f5160a1f7e64ed1c5170.svg)
    no-repeat;
}
#tracking-tools-progress .dashboard-tool,
#tracking-tools-progress .cancel-tool,
#tracking-tools-progress .create-return-label-tool,
#tracking-tools-progress .help-tool {
  display: block;
  width: 100%;
  padding: 8px 4px 4px 8px;
  margin: 4px 0;
  border-radius: 4px;
  border-bottom: none;
  color: #33424f;
  background-color: white;
  font-weight: 600;
  font-size: 15px;
}
#tracking-tools-progress .dashboard-tool:hover,
#tracking-tools-progress .cancel-tool:hover,
#tracking-tools-progress .create-return-label-tool:hover,
#tracking-tools-progress .help-tool:hover {
  background-color: #f7f8fa;
  text-decoration: none;
}
#tracking-tools-progress .dashboard-tool.btn-danger-new:hover,
#tracking-tools-progress .cancel-tool.btn-danger-new:hover,
#tracking-tools-progress .create-return-label-tool.btn-danger-new:hover,
#tracking-tools-progress .help-tool.btn-danger-new:hover {
  background-color: #fcebec;
}
#tracking-tools-progress .dashboard-tool .dashboard-tool-name,
#tracking-tools-progress .dashboard-tool .dashboard-tools-label-sprite,
#tracking-tools-progress .cancel-tool .dashboard-tool-name,
#tracking-tools-progress .cancel-tool .dashboard-tools-label-sprite,
#tracking-tools-progress .create-return-label-tool .dashboard-tool-name,
#tracking-tools-progress
  .create-return-label-tool
  .dashboard-tools-label-sprite,
#tracking-tools-progress .help-tool .dashboard-tool-name,
#tracking-tools-progress .help-tool .dashboard-tools-label-sprite {
  padding-left: 8px;
  float: left;
  margin: 4px 0;
  top: 0px;
}
#tracking-tools-progress .dashboard-tool.dashboard-tool-disabled,
#tracking-tools-progress .cancel-tool.dashboard-tool-disabled,
#tracking-tools-progress .create-return-label-tool.dashboard-tool-disabled,
#tracking-tools-progress .help-tool.dashboard-tool-disabled {
  padding: 4px 8px;
  color: #b4bfc9;
}
#tracking-tools-progress
  .dashboard-tool.dashboard-tool-disabled
  .tracking-sprite,
#tracking-tools-progress .cancel-tool.dashboard-tool-disabled .tracking-sprite,
#tracking-tools-progress
  .create-return-label-tool.dashboard-tool-disabled
  .tracking-sprite,
#tracking-tools-progress .help-tool.dashboard-tool-disabled .tracking-sprite {
  display: none;
}
#tracking-tools-progress
  .dashboard-tool.dashboard-tool-disabled
  .dashboard-tools-label-sprite,
#tracking-tools-progress
  .cancel-tool.dashboard-tool-disabled
  .dashboard-tools-label-sprite,
#tracking-tools-progress
  .create-return-label-tool.dashboard-tool-disabled
  .dashboard-tools-label-sprite,
#tracking-tools-progress
  .help-tool.dashboard-tool-disabled
  .dashboard-tools-label-sprite {
  top: 8px;
}
#tracking-tools-progress .dashboard-tool.dashboard-tool-disabled:hover,
#tracking-tools-progress .cancel-tool.dashboard-tool-disabled:hover,
#tracking-tools-progress
  .create-return-label-tool.dashboard-tool-disabled:hover,
#tracking-tools-progress .help-tool.dashboard-tool-disabled:hover {
  background-color: white;
  cursor: not-allowed;
}
#tracking-tools-progress .dashboard-tool.dashboard-tool-disabled small,
#tracking-tools-progress .cancel-tool.dashboard-tool-disabled small,
#tracking-tools-progress
  .create-return-label-tool.dashboard-tool-disabled
  small,
#tracking-tools-progress .help-tool.dashboard-tool-disabled small {
  color: #7a8691;
  line-height: 22px;
  float: left;
  margin-top: -5px;
}
@media (max-width: 767px) {
  #tracking-progress .past-start-event .public-type-indicator:after,
  #tracking-progress .delivered .public-type-indicator:after,
  #tracking-progress .pickup-scheduled .public-type-indicator:after,
  #tracking-progress .not-serviced .public-type-indicator:after,
  #tracking-progress .cancelled .public-type-indicator:after,
  #tracking-progress .order-created .public-type-indicator:after,
  #tracking-progress .failed .public-type-indicator:after {
    left: 18px;
  }
  #tracking-progress .lost .public-type-indicator:after,
  #tracking-progress .unable-to-transport .public-type-indicator:after {
    left: 18px;
  }
  #tracking-progress .new-tracking-event-list:before {
    left: 9px;
  }
  #tracking-progress .public-type-indicator:after {
    left: 17px;
  }
  #tracking-progress .public-type-indicator:before {
    left: 13px;
  }
  #tracking-progress .tracking-title {
    margin-bottom: 16px;
  }
  #tracking-progress .progress-event-date-sm {
    display: block;
  }
  #tracking-progress .progress-event-date,
  #tracking-progress .progress-event-location {
    display: none;
  }
  #tracking-progress .progress-event-date-day {
    font-weight: 400;
    color: #7a8792;
  }
  #tracking-progress .progress-event-date-time {
    font-size: 13px;
    padding-left: 4px;
  }
  #tracking-progress .col-description {
    padding-left: 50px;
  }
  .unauthenticated.export #tracking-progress .panel,
  .semiauthenticated_receiver.export #tracking-progress .panel,
  .semiauthenticated_sender.export #tracking-progress .panel,
  .authenticated_sender.export #tracking-progress .panel,
  .authenticated_receiver.export #tracking-progress .panel {
    padding-bottom: 70px;
  }
}
#tracking-progress_tracking_reference {
  max-width: 800px;
  margin: auto;
  margin-top: -3px;
}
#tracking-progress_tracking_reference .panel {
  padding: 16px 16px 16px 0;
  background-color: #f7f8fa;
  -webkit-border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-topright: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
}
#tracking-progress_tracking_reference .prompt {
  margin-top: 25px;
}
#tracking-progress_tracking_reference .login-prompt {
  margin: 10px 0 0 0;
}
#tracking-progress_tracking_reference .btn-copy {
  float: right;
  text-align: left;
  margin-top: 4px;
  font-size: 13px;
}
#tracking-progress_tracking_reference .btn {
  margin-left: 16px;
  float: right;
  width: 65px;
  font-weight: 400;
  border: 1px solid #62cccc;
  background-color: #fff;
  color: #62cccc;
  transition: all 0.1s ease;
}
#tracking-progress_tracking_reference .btn:hover,
#tracking-progress_tracking_reference .btn:active {
  background-color: #62cccc;
  color: #fff;
}
#tracking-progress_tracking_reference .col-md-4 {
  padding: 0;
}
#tracking-progress_tracking_reference .col-md-4:before {
  content: "";
  display: inline-block;
  width: 192px;
  height: 120px;
  background-image: url(https://assets.sendle.com/assets/tracking/group-extras-3b3c67ddea29ca9e8eaa707c7274dea3a587757a2f12c167697bfea48514b2fa.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  left: 42px;
  top: 12px;
  z-index: 3;
}
#tracking-progress_tracking_reference #receiver-tracking {
  margin: 0;
}
#tracking-progress_tracking_reference
  #receiver-tracking
  #receiver-tracking-link {
  margin-left: 16px;
}
#tracking-progress_tracking_reference #receiver-tracking .btn {
  border: 1px solid #62cccc;
  background-color: #fff;
  color: #62cccc;
  transition: all 0.1s ease;
}
#tracking-progress_tracking_reference #receiver-tracking .btn:hover,
#tracking-progress_tracking_reference #receiver-tracking .btn:active {
  background-color: #62cccc;
  color: #fff;
}
@media (max-width: 991px) {
  #tracking-progress_tracking_reference {
    margin-bottom: -40px;
  }
  #tracking-progress_tracking_reference .panel {
    top: -32px;
    padding: 16px 16px;
  }
  #tracking-progress_tracking_reference .col-md-4 {
    height: 40px;
  }
  #tracking-progress_tracking_reference .col-md-4:before {
    display: block;
    margin: 0 auto;
    width: 122px;
    height: 78px;
    top: -165%;
    left: 0;
  }
}
@media (max-width: 991px) {
  #tracking-progress_tracking_reference {
    margin-top: 24px;
  }
}
#status-indicator {
  max-width: 800px;
  margin: auto;
}
#status-indicator > .row {
  max-width: 550px;
  margin: auto;
  padding: 24px 0 16px;
}
#status-indicator > .row.no-delivery_estimate {
  padding: 24px 0 48px;
}
#status-indicator .row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
#status-indicator #delivery-estimate {
  padding: 0 0 16px 0;
}
#status-indicator #si-collection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 22px 0 0 16px;
}
#status-indicator #si-delivery {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 22px 16px 0 0;
}
#status-indicator #si-collection span,
#status-indicator #si-delivery span {
  width: 10px;
  height: 10px;
  float: left;
  border: 2px solid #ff6800;
  border-radius: 2px;
  margin: 5px;
}
#status-indicator #si-progress-container {
  flex-grow: 99;
  border-bottom: 1px solid black;
  margin-bottom: 8px;
  min-width: 32px;
  height: 32px;
}
#status-indicator #si-progress-icon {
  background-color: white;
  position: relative;
  width: 42px;
  height: 30px;
}
#status-indicator #si-progress {
  top: 3px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
#status-indicator #si-progress.si-progress-50 {
  margin-left: calc(50% - 40px);
}
#status-indicator #si-progress.si-progress-50 .si-road-no-anim {
  width: 20px;
}
#status-indicator #si-progress.si-progress-100 {
  margin-left: calc(100% - 51px);
}
#status-indicator #si-progress.si-progress-70 {
  margin-left: calc(70% - 40px);
}
#status-indicator #si-progress.si-progress-78 {
  margin-left: calc(80% - 40px);
}
#status-indicator #si-progress.si-progress-78.agent img {
  z-index: 4;
  margin-left: 7px;
}
#status-indicator #si-progress.si-progress-export-anim {
  top: -11px;
}
#status-indicator #si-progress .si-road-anim {
  background-image: url(https://assets.sendle.com/assets/tracking/animated-road-393dbac08d375343b504878a7f8644c0d38a41ba10e28ef86740cb86ed66ba40.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  width: 20px;
  height: 1px;
  background-color: white;
  z-index: 3;
  top: 28px;
}
#status-indicator #si-progress .si-road-anim:first-child {
  left: 4px;
}
#status-indicator #si-progress .si-road-anim:last-child {
  right: 2px;
}
#status-indicator #si-progress .si-ground-anim:first-child {
  background-image: url(https://assets.sendle.com/assets/tracking/animated-ground-91c1d785122445e995c7b679035bbc80830e5c82e09dff887b9fe8d141e126fb.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  width: 90px;
  height: 1px;
  background-color: white;
  z-index: 3;
  top: 42px;
}
#status-indicator #si-progress.rts .si-road-anim {
  background-image: url(https://assets.sendle.com/assets/tracking/animated-road-reverse-2be4e994a3cbb3ca48e49a838786b094827c02052c9575571ac8a315935c74c1.svg);
}
#status-indicator #si-progress .si-road-no-anim {
  border-bottom: 1px solid black;
  position: relative;
  top: -1px;
  z-index: 2;
  width: 4px;
}
#status-indicator #si-progress .si-road-no-anim:first-child {
  left: 4px;
}
#status-indicator #si-progress .drop-off {
  margin-left: 4px;
  height: 30px;
  width: 28px;
}
#status-indicator .truck-moving {
  animation: MoveUpDown 2s cubic-bezier(0.05, 0.58, 0.94, 0.34) infinite;
}
#status-indicator .plane-moving {
  animation: PlaneMoveUpDown 3s ease-in infinite;
}
#status-indicator .additional-footer-info {
  font-size: 13px;
  line-height: 16px;
}
.pill {
  display: inline-flex;
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  background: #f7f8fa;
  vertical-align: middle;
  height: 20px;
}
.pill:not(:first-child) {
  margin-left: 0.25rem;
}
.pill:not(:last-child) {
  margin-right: 0.25rem;
}
.pill--risk {
  background-color: #fcebec;
  color: #ba3037;
}
.pill--warning {
  background-color: #fff8eb;
  color: #916c1f;
}
.pill--success {
  background-color: #e5f6ee;
  color: #007f48;
}
@keyframes MoveUpDown {
  0% {
    bottom: 0;
  }
  10% {
    bottom: 1px;
  }
  20% {
    bottom: 0;
  }
  30% {
    bottom: 1px;
  }
  40% {
    bottom: 0;
  }
  65% {
    bottom: 0;
  }
  75% {
    bottom: 1px;
  }
  85% {
    bottom: 0;
  }
  100% {
    bottom: 0;
  }
}
@keyframes PlaneMoveUpDown {
  0% {
    bottom: 0;
  }
  25% {
    bottom: 6px;
  }
  50% {
    bottom: 0;
  }
  75% {
    bottom: 8px;
  }
  100% {
    bottom: 0;
  }
}
@media (max-width: 767px) {
  .authenticated_receiver #status-indicator .row,
  .authenticated_sender #status-indicator .row,
  .semiauthenticated_receiver #status-indicator .row,
  .semiauthenticated_sender #status-indicator .row,
  .unauthenticated #status-indicator .row {
    padding: 12px 0 32px;
  }
}
@media (min-width: 768px) {
  #status-indicator .row {
    width: 75%;
    margin: auto;
  }
}
#receiver-tracking {
  overflow: auto;
  padding: 32px 0 10px 0;
  display: block;
  top: -14px;
  position: relative;
  color: #7a8691;
}
#receiver-tracking .btn-copy {
  float: right;
  text-align: left;
  margin-top: 4px;
  font-size: 13px;
}
#receiver-tracking #receiver-tracking-link {
  margin-left: 16px;
}
#receiver-tracking .btn {
  margin-left: 16px;
  float: right;
  width: 65px;
  font-weight: 400;
  border: 1px solid #62cccc;
  background-color: #fff;
  color: #62cccc;
  transition: all 0.1s ease;
}
#receiver-tracking .btn:hover,
#receiver-tracking .btn:active {
  background-color: #62cccc;
  color: #fff;
}
@media (max-width: 767px) {
  #receiver-tracking {
    overflow: auto;
    margin: 32px 0 0px;
    padding: 0 0 0 16px;
  }
  #receiver-tracking .btn-copy {
    float: left;
  }
}
@media screen and (max-width: 992px) {
  #receiver-tracking {
    top: 0px;
  }
}
iframe[title="Button to launch messaging window"],
iframe[title="Message from company"],
iframe[title="Number of unread messages"],
iframe[title="Close message"] {
  display: none;
}
#tracking-tools,
#support {
  margin-top: 1.5rem;
}
#tracking-tools .panel-default,
#support .panel-default {
  padding: 0;
  border: 0.0625rem solid #e9edf0;
}
#tracking-tools .panel-heading,
#support .panel-heading {
  padding: 1rem 2rem;
  background-color: #f7f8fa;
  border: none;
}
#tracking-tools .panel-body,
#support .panel-body {
  padding: 1rem 2rem;
}
#tracking-tools .panel-body a,
#tracking-tools .panel-body button,
#support .panel-body a,
#support .panel-body button {
  justify-content: start;
  width: calc(50% - 16px);
  float: left;
  padding: 8px 1rem 8px;
  margin: 0.25rem;
  color: #33424f;
  background: transparent;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  border: none;
  height: 50px;
  display: flex;
  align-items: center;
}
#tracking-tools .panel-body a.btn-info,
#tracking-tools .panel-body button.btn-info,
#support .panel-body a.btn-info,
#support .panel-body button.btn-info {
  background-color: #ffffff;
}
#tracking-tools .panel-body a.btn-info:hover,
#tracking-tools .panel-body button.btn-info:hover,
#support .panel-body a.btn-info:hover,
#support .panel-body button.btn-info:hover {
  background-color: #f7f8fa;
}
#tracking-tools .panel-body a.btn-danger-new,
#tracking-tools .panel-body button.btn-danger-new,
#support .panel-body a.btn-danger-new,
#support .panel-body button.btn-danger-new {
  background-color: #ffffff;
}
#tracking-tools .panel-body a.btn-danger-new:hover,
#tracking-tools .panel-body button.btn-danger-new:hover,
#support .panel-body a.btn-danger-new:hover,
#support .panel-body button.btn-danger-new:hover {
  background-color: #fcebec;
}
#tracking-tools .panel-body a:hover,
#tracking-tools .panel-body a:focus,
#tracking-tools .panel-body button:hover,
#tracking-tools .panel-body button:focus,
#support .panel-body a:hover,
#support .panel-body a:focus,
#support .panel-body button:hover,
#support .panel-body button:focus {
  text-decoration: none;
  border-radius: 0.25rem;
}
#tracking-tools .panel-body a.dashboard-tool-disabled,
#tracking-tools .panel-body button.dashboard-tool-disabled,
#support .panel-body a.dashboard-tool-disabled,
#support .panel-body button.dashboard-tool-disabled {
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1;
  color: #b4bfc9;
}
#tracking-tools .panel-body a.dashboard-tool-disabled .tracking-sprite,
#tracking-tools .panel-body button.dashboard-tool-disabled .tracking-sprite,
#support .panel-body a.dashboard-tool-disabled .tracking-sprite,
#support .panel-body button.dashboard-tool-disabled .tracking-sprite {
  display: none;
}
#tracking-tools .panel-body a.dashboard-tool-disabled:hover,
#tracking-tools .panel-body button.dashboard-tool-disabled:hover,
#support .panel-body a.dashboard-tool-disabled:hover,
#support .panel-body button.dashboard-tool-disabled:hover {
  background-color: #ffffff;
  cursor: not-allowed;
}
#tracking-tools .panel-body a.dashboard-tool-disabled small,
#tracking-tools .panel-body button.dashboard-tool-disabled small,
#support .panel-body a.dashboard-tool-disabled small,
#support .panel-body button.dashboard-tool-disabled small {
  color: #6d7883;
}
#tracking-tools .panel-body a.dashboard-tool-disabled .chevron-right,
#tracking-tools .panel-body button.dashboard-tool-disabled .chevron-right,
#support .panel-body a.dashboard-tool-disabled .chevron-right,
#support .panel-body button.dashboard-tool-disabled .chevron-right {
  height: 10px;
  width: 10px;
  margin-left: 0.5rem;
  display: inherit;
  background: url(https://assets.sendle.com/assets/tracking/chevron-right-overcast-228138072f0592d35292bb8cc9ed8ec9b73733a68fda32b0f1496adb1efeae4e.svg)
    no-repeat;
}
#tracking-tools .panel-body a .tracking-sprite,
#tracking-tools .panel-body button .tracking-sprite,
#support .panel-body a .tracking-sprite,
#support .panel-body button .tracking-sprite {
  position: relative;
  top: 5px;
}
@media (max-width: 550px) {
  #tracking-tools .panel-body a,
  #tracking-tools .panel-body button,
  #support .panel-body a,
  #support .panel-body button {
    width: 100%;
    line-height: 1;
  }
}
#tracking-tools .panel-body p,
#support .panel-body p {
  padding-left: 1rem;
}
#tracking-tools .showModal,
#support .showModal {
  display: block;
}
#tracking-tools h4,
#support h4 {
  font-size: 1.25rem;
}
#tracking-tools p,
#support p {
  margin: 0.5rem 0;
}
#tracking-tools .secondary-text,
#support .secondary-text {
  color: #6d7883;
}
#tracking-tools .wf-optional-field,
#support .wf-optional-field {
  display: none;
}
#tracking-tools .wf-optional-field[for="company"],
#tracking-tools .wf-optional-field[for="new_company"],
#tracking-tools .wf-optional-field[for="address_line2"],
#tracking-tools .wf-optional-field[for="new_address_line2"],
#support .wf-optional-field[for="company"],
#support .wf-optional-field[for="new_company"],
#support .wf-optional-field[for="address_line2"],
#support .wf-optional-field[for="new_address_line2"] {
  display: inline;
  font-style: italic;
  padding-left: 0.25rem;
  color: inherit;
  font-weight: 400;
  font-size: 0.75rem;
}
#tracking-tools .help-block,
#support .help-block {
  margin: 0.25rem 0 -4px;
}
#tracking-tools .has-error .help-block,
#support .has-error .help-block {
  color: inherit;
  background-color: inherit;
  padding-top: 0;
}
#tracking-tools .has-error .help-text,
#support .has-error .help-text {
  background-color: #ba3037;
  padding: 0.25rem;
  margin: 0;
}
#tracking-tools .ineligible-cover,
#support .ineligible-cover {
  padding: 1rem;
  margin-bottom: 1.5rem;
}
#help-btn {
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  background: none;
  border: none;
  z-index: 1040;
}
#help-btn .button-content {
  cursor: pointer;
  width: 116px;
  height: 56px;
  border-radius: 28px;
  box-shadow: 0 2px 4px 0 rgba(51, 66, 79, 0.3);
  background: #33424f;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
#help-btn .button-text {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
}
#help-btn .shipment-question {
  display: block;
  background: url(https://assets.sendle.com/assets/dashboard/shipment-question-4fdc7d0e09af4269c8c70e12da298fc04968d3599262738d4122d552e366fba9.svg)
    no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}
#minimise-btn {
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  background: none;
  border: none;
  z-index: 1040;
}
#minimise-btn .button-content {
  cursor: pointer;
  width: 146px;
  height: 56px;
  border-radius: 28px;
  box-shadow: 0 2px 4px 0 rgba(51, 66, 79, 0.3);
  background: #33424f;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
#minimise-btn .button-text {
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
}
#minimise-btn .chevron-down {
  height: 8px;
  width: 12px;
  margin-right: 0.5rem;
  display: block;
  background: url(https://assets.sendle.com/assets/tracking/icon-chevron-46a7c3b69a5bef72d982792b9da0d7a2f8b078c60df1f5160a1f7e64ed1c5170.svg)
    no-repeat;
}
#help-btn:focus > .button-content,
#minimise-btn:focus > .button-content {
  outline: auto;
}
.button-content:focus {
  outline: none;
}
.perm-links-divider {
  background-color: #e9edf0;
  height: 1px;
  margin: 0.5rem 0 0 0;
}
.unread-messages-count {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 100;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #e93d45;
  border-radius: 50%;
}
.unread-messages-count:empty {
  display: none;
}
#support h4 {
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
}
#support .panel-body {
  padding: 0 1.5rem;
}
#support .panel-body a {
  width: calc(100% - 16px);
  padding: 0.5rem;
  margin: 0.25rem 0.5rem;
}
#support .panel-body .chevron-right {
  height: 10px;
  width: 10px;
  margin-left: 0.5rem;
  display: inherit;
  background: url(https://assets.sendle.com/assets/tracking/chevron-right-concrete-14b8c9a639d941d211ba4bdecb0fe1bb440c0971a068681da578ac91b8766b33.svg)
    no-repeat;
}
#support .panel-body .tools-row .tool-title {
  flex: 1;
}
#tracking-tools h3,
#support h3,
#tracking-batch h3 {
  margin-top: 0;
}
#tracking-tools #tools-modal,
#tracking-tools #cancellation-modal,
#tracking-tools #support-modal,
#support #tools-modal,
#support #cancellation-modal,
#support #support-modal,
#tracking-batch #tools-modal,
#tracking-batch #cancellation-modal,
#tracking-batch #support-modal {
  height: 100%;
  box-shadow: -2px 0 12px 0 rgba(0, 0, 0, 0.12);
}
#tracking-tools #tools-modal h3,
#tracking-tools #cancellation-modal h3,
#tracking-tools #support-modal h3,
#support #tools-modal h3,
#support #cancellation-modal h3,
#support #support-modal h3,
#tracking-batch #tools-modal h3,
#tracking-batch #cancellation-modal h3,
#tracking-batch #support-modal h3 {
  margin-top: 1rem;
}
#tracking-tools #tools-modal .panel,
#tracking-tools #cancellation-modal .panel,
#tracking-tools #support-modal .panel,
#support #tools-modal .panel,
#support #cancellation-modal .panel,
#support #support-modal .panel,
#tracking-batch #tools-modal .panel,
#tracking-batch #cancellation-modal .panel,
#tracking-batch #support-modal .panel {
  background-color: #f7f8fa;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 20px;
  border: none;
  margin: 1.5rem 0 12px;
  border-radius: 0.5rem;
}
#tracking-tools #tools-modal .panel p,
#tracking-tools #cancellation-modal .panel p,
#tracking-tools #support-modal .panel p,
#support #tools-modal .panel p,
#support #cancellation-modal .panel p,
#support #support-modal .panel p,
#tracking-batch #tools-modal .panel p,
#tracking-batch #cancellation-modal .panel p,
#tracking-batch #support-modal .panel p {
  margin-bottom: 0.5rem;
}
#tracking-tools #tools-modal .panel ul,
#tracking-tools #cancellation-modal .panel ul,
#tracking-tools #support-modal .panel ul,
#support #tools-modal .panel ul,
#support #cancellation-modal .panel ul,
#support #support-modal .panel ul,
#tracking-batch #tools-modal .panel ul,
#tracking-batch #cancellation-modal .panel ul,
#tracking-batch #support-modal .panel ul {
  padding-left: 1rem;
  list-style: none;
}
#tracking-tools #tools-modal .panel ul li,
#tracking-tools #cancellation-modal .panel ul li,
#tracking-tools #support-modal .panel ul li,
#support #tools-modal .panel ul li,
#support #cancellation-modal .panel ul li,
#support #support-modal .panel ul li,
#tracking-batch #tools-modal .panel ul li,
#tracking-batch #cancellation-modal .panel ul li,
#tracking-batch #support-modal .panel ul li {
  margin-bottom: 0.5rem;
}
#tracking-tools #tools-modal .panel ul li:before,
#tracking-tools #cancellation-modal .panel ul li:before,
#tracking-tools #support-modal .panel ul li:before,
#support #tools-modal .panel ul li:before,
#support #cancellation-modal .panel ul li:before,
#support #support-modal .panel ul li:before,
#tracking-batch #tools-modal .panel ul li:before,
#tracking-batch #cancellation-modal .panel ul li:before,
#tracking-batch #support-modal .panel ul li:before {
  color: #ff6800;
  content: "•";
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
}
#tracking-tools #tools-modal input[type="submit"],
#tracking-tools #cancellation-modal input[type="submit"],
#tracking-tools #support-modal input[type="submit"],
#support #tools-modal input[type="submit"],
#support #cancellation-modal input[type="submit"],
#support #support-modal input[type="submit"],
#tracking-batch #tools-modal input[type="submit"],
#tracking-batch #cancellation-modal input[type="submit"],
#tracking-batch #support-modal input[type="submit"] {
  margin-bottom: 12px;
}
#tracking-tools #tools-modal a.btn-close,
#tracking-tools #cancellation-modal a.btn-close,
#tracking-tools #support-modal a.btn-close,
#support #tools-modal a.btn-close,
#support #cancellation-modal a.btn-close,
#support #support-modal a.btn-close,
#tracking-batch #tools-modal a.btn-close,
#tracking-batch #cancellation-modal a.btn-close,
#tracking-batch #support-modal a.btn-close {
  margin-bottom: 12px;
}
#tracking-tools .support-back-link,
#support .support-back-link,
#tracking-batch .support-back-link {
  padding: 1.5rem;
  position: relative;
  font-size: 0.875rem;
}
#tracking-tools .support-back-link:before,
#support .support-back-link:before,
#tracking-batch .support-back-link:before {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  content: " ";
  background-image: url(https://assets.sendle.com/assets/icons/chevron-left-703b6973a1706b4aec39f80a8b6a1b480a14ccabfb5eb2383d714122a48f7020.svg);
  background-size: 12px 12px;
  background-repeat: no-repeat;
}
#tracking-tools #scroll-target,
#support #scroll-target,
#tracking-batch #scroll-target {
  height: 40px;
  width: 100%;
}
#tracking-tools #modal-search,
#support #modal-search,
#tracking-batch #modal-search {
  padding: 2rem 1.5rem;
  background: #f7f8fa;
}
#tracking-tools #modal-search form,
#support #modal-search form,
#tracking-batch #modal-search form {
  background-color: #ffffff;
  width: 100%;
  position: relative;
}
#tracking-tools #modal-search form:before,
#support #modal-search form:before,
#tracking-batch #modal-search form:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
}
#tracking-tools #modal-search input,
#support #modal-search input,
#tracking-batch #modal-search input {
  width: 100%;
  border: 0.0625rem solid #b4bfc9;
  border-radius: 0.25rem;
  height: 40px;
  line-height: 40px;
  font-size: 1rem;
  padding: 0 0 0 2rem;
  box-sizing: border-box;
}
#tracking-tools #modal-search input:focus,
#support #modal-search input:focus,
#tracking-batch #modal-search input:focus {
  outline: 0;
  border-color: #008dcc;
  box-shadow: 0 0 0 0.2em rgba(0, 141, 204, 0.5);
}
#tracking-tools #modal-search.search-hidden,
#support #modal-search.search-hidden,
#tracking-batch #modal-search.search-hidden {
  display: none;
}
#tracking-tools #modal-navigation,
#support #modal-navigation,
#tracking-batch #modal-navigation {
  display: inline-block;
  margin-top: 1.5rem;
  margin-left: 10px;
}
#tracking-tools #modal-navigation.navigation-hidden,
#support #modal-navigation.navigation-hidden,
#tracking-batch #modal-navigation.navigation-hidden {
  display: none;
}
#tracking-tools #support-modal-content,
#support #support-modal-content,
#tracking-batch #support-modal-content {
  margin-top: 1rem;
}
#tracking-tools #support-modal-content .workflow-event-form,
#support #support-modal-content .workflow-event-form,
#tracking-batch #support-modal-content .workflow-event-form {
  font-size: 1rem;
}
#tracking-tools
  #support-modal-content
  .workflow-event-form
  .has-error
  .help-text.text-danger,
#support
  #support-modal-content
  .workflow-event-form
  .has-error
  .help-text.text-danger,
#tracking-batch
  #support-modal-content
  .workflow-event-form
  .has-error
  .help-text.text-danger {
  color: #ba3037;
  background-color: #fcebec;
  padding: 0.25rem 0.5rem;
}
#tracking-tools
  #support-modal-content
  .workflow-event-form
  .has-error
  .form-control,
#support #support-modal-content .workflow-event-form .has-error .form-control,
#tracking-batch
  #support-modal-content
  .workflow-event-form
  .has-error
  .form-control {
  border-color: #ba3037;
}
#tracking-tools #support-modal-content .workflow-event-form .has-error label,
#support #support-modal-content .workflow-event-form .has-error label,
#tracking-batch #support-modal-content .workflow-event-form .has-error label {
  color: #6d7883;
}
#tracking-tools #help-content,
#support #help-content,
#tracking-batch #help-content {
  margin-bottom: 120px;
  margin-top: 0.0625rem;
  padding: 0 21px;
}
#tracking-tools #help-content .article-link,
#support #help-content .article-link,
#tracking-batch #help-content .article-link {
  width: 100%;
  padding: 0 0.5rem;
  margin: 0;
}
#tracking-tools #help-content .article-link.search-result,
#support #help-content .article-link.search-result,
#tracking-batch #help-content .article-link.search-result {
  margin: 0 -8px;
  width: calc(100% + 8px);
}
#tracking-tools #help-content .article-link:hover,
#support #help-content .article-link:hover,
#tracking-batch #help-content .article-link:hover {
  background-color: #f7f8fa;
}
#tracking-tools #help-content .search-results-heading,
#support #help-content .search-results-heading,
#tracking-batch #help-content .search-results-heading {
  display: inline-block;
  margin-bottom: 0.5rem;
}
#tracking-tools #help-content .article-body p,
#support #help-content .article-body p,
#tracking-batch #help-content .article-body p {
  padding: 0;
  font-size: 1rem;
}
#tracking-tools #help-content .article-body h1,
#tracking-tools #help-content .article-body h2,
#tracking-tools #help-content .article-body h3,
#tracking-tools #help-content .article-body h4,
#support #help-content .article-body h1,
#support #help-content .article-body h2,
#support #help-content .article-body h3,
#support #help-content .article-body h4,
#tracking-batch #help-content .article-body h1,
#tracking-batch #help-content .article-body h2,
#tracking-batch #help-content .article-body h3,
#tracking-batch #help-content .article-body h4 {
  padding: 0;
  font-weight: 700;
}
#tracking-tools #help-content .article-body h1,
#support #help-content .article-body h1,
#tracking-batch #help-content .article-body h1 {
  margin: 0 0 1rem;
}
#tracking-tools #help-content .article-body h2,
#support #help-content .article-body h2,
#tracking-batch #help-content .article-body h2 {
  margin: 1rem 0 1rem;
}
#tracking-tools #help-content .article-body h3,
#support #help-content .article-body h3,
#tracking-batch #help-content .article-body h3 {
  margin: 1rem 0 1rem;
}
#tracking-tools #help-content .article-body h4,
#support #help-content .article-body h4,
#tracking-batch #help-content .article-body h4 {
  margin-top: 1rem;
}
#tracking-tools #help-content .article-body a,
#support #help-content .article-body a,
#tracking-batch #help-content .article-body a {
  float: none;
  display: inline;
  padding: 0;
  margin: 0;
  color: #008dcc;
  font-weight: 400;
}
#tracking-tools #help-content .article-body a:hover,
#support #help-content .article-body a:hover,
#tracking-batch #help-content .article-body a:hover {
  text-decoration: underline;
}
#tracking-tools #help-content .article-body img,
#support #help-content .article-body img,
#tracking-batch #help-content .article-body img {
  max-width: 100%;
  display: block;
  height: auto;
}
#tracking-tools #help-content .article-body ul,
#tracking-tools #help-content .article-body ol,
#support #help-content .article-body ul,
#support #help-content .article-body ol,
#tracking-batch #help-content .article-body ul,
#tracking-batch #help-content .article-body ol {
  padding: 0;
}
#tracking-tools #help-content .article-body ul li,
#tracking-tools #help-content .article-body ol li,
#support #help-content .article-body ul li,
#support #help-content .article-body ol li,
#tracking-batch #help-content .article-body ul li,
#tracking-batch #help-content .article-body ol li {
  font-size: 1rem;
  margin: 0 1rem 1rem;
  padding: 0;
}
#tracking-tools #help-content .article-body hr,
#support #help-content .article-body hr,
#tracking-batch #help-content .article-body hr {
  border-top: 0.0625rem solid #e9edf0;
  margin: 1.5rem 0;
}
#tracking-tools #help-content .article-listing,
#support #help-content .article-listing,
#tracking-batch #help-content .article-listing {
  flex: 1;
  font-weight: normal;
}
#tracking-tools #modal-header-wrapper,
#support #modal-header-wrapper,
#tracking-batch #modal-header-wrapper {
  background: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 1060;
}
#tracking-tools #scroll-target,
#support #scroll-target,
#tracking-batch #scroll-target {
  padding-top: 65px;
}
#tracking-tools .modal-header,
#support .modal-header,
#tracking-batch .modal-header {
  display: block;
  position: relative;
  padding: 0px;
  width: 100%;
  border-bottom: 0.0625rem solid #e9edf0;
  padding: 1.5rem 1rem 0 1rem;
  margin: 0;
}
#tracking-tools .modal-header a,
#support .modal-header a,
#tracking-batch .modal-header a {
  display: inline-block;
  color: #33424f;
  font-size: 1rem;
  font-weight: bold;
  list-style: none;
  padding: 0 0 1rem 0;
  margin: 0 0.5rem 0 0.5rem;
  text-decoration: none;
}
#tracking-tools .modal-header .modal-title:before,
#tracking-tools .modal-header .modal-title:after,
#support .modal-header .modal-title:before,
#support .modal-header .modal-title:after,
#tracking-batch .modal-header .modal-title:before,
#tracking-batch .modal-header .modal-title:after {
  content: " " !important;
}
#tracking-tools .modal-header .modal-title,
#support .modal-header .modal-title,
#tracking-batch .modal-header .modal-title {
  position: relative;
}
#tracking-tools .modal-header .modal-title.active,
#support .modal-header .modal-title.active,
#tracking-batch .modal-header .modal-title.active {
  border-bottom: 0.125rem solid #ff6800;
}
#tracking-tools .modal-header .close,
#support .modal-header .close,
#tracking-batch .modal-header .close {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  text-decoration: none;
  font-weight: 400;
  font-size: 40px;
  color: #33424f;
  transform: rotate(45deg);
}
#tracking-tools .modal-body,
#support .modal-body,
#tracking-batch .modal-body {
  padding: 0 1.5rem 1.5rem;
  text-align: left;
}
#tracking-tools .modal-body h3,
#support .modal-body h3,
#tracking-batch .modal-body h3 {
  margin-bottom: 1.5rem;
}
#tracking-tools .modal-body p,
#support .modal-body p,
#tracking-batch .modal-body p {
  margin-bottom: 1rem;
}
#tracking-tools .modal-body .btn,
#support .modal-body .btn,
#tracking-batch .modal-body .btn {
  margin: 0 0.5rem 0 0;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  height: 38px;
}
#tracking-tools .modal-body .btn-close,
#support .modal-body .btn-close,
#tracking-batch .modal-body .btn-close {
  width: fit-content;
}
#tracking-tools .modal-body .btn-close:hover,
#tracking-tools .modal-body .btn-close:focus,
#support .modal-body .btn-close:hover,
#support .modal-body .btn-close:focus,
#tracking-batch .modal-body .btn-close:hover,
#tracking-batch .modal-body .btn-close:focus {
  background-color: #b4bfc9;
}
#tracking-tools .modal-body .btn-default,
#tracking-tools .modal-body .btn-close,
#support .modal-body .btn-default,
#support .modal-body .btn-close,
#tracking-batch .modal-body .btn-default,
#tracking-batch .modal-body .btn-close {
  border: none;
  background-color: #e9edf0;
}
#tracking-tools .modal-body .row.unified,
#support .modal-body .row.unified,
#tracking-batch .modal-body .row.unified {
  margin-left: -15px;
  margin-right: -15px;
}
#tracking-tools .modal-body select,
#support .modal-body select,
#tracking-batch .modal-body select {
  background: url(https://assets.sendle.com/assets/dashboard/select-arrow-ecbb5240c5f9fde962b4d55ee4fc0ba0420d9aefc1995f52cd42d06addc1f210.png)
    no-repeat 98% center;
  background-size: 16px 12px;
}
#tracking-tools .modal-content,
#tracking-tools .modal-dialog,
#support .modal-content,
#support .modal-dialog,
#tracking-batch .modal-content,
#tracking-batch .modal-dialog {
  border-radius: initial;
  height: 100%;
  width: 400px;
  border: none;
  overflow-y: auto;
  -ms-overflow-style: scrollbar;
  margin: 0;
}
#tracking-tools .modal,
#support .modal,
#tracking-batch .modal {
  top: 0;
  left: auto;
  bottom: auto;
}
#tracking-tools .modal-footer,
#support .modal-footer,
#tracking-batch .modal-footer {
  text-align: left;
  padding: 1.5rem 2rem;
  border-radius: 0 0 0.5rem 0.5rem;
  border-top: none;
}
#tracking-tools .modal-footer a.btn-default,
#tracking-tools .modal-footer a.btn-close,
#support .modal-footer a.btn-default,
#support .modal-footer a.btn-close,
#tracking-batch .modal-footer a.btn-default,
#tracking-batch .modal-footer a.btn-close {
  color: #33424f;
  background-color: #f7f8fa;
  border: solid 1px #b4bfc9;
}
#tracking-tools .modal-footer a.btn-default:hover,
#tracking-tools .modal-footer a.btn-close:hover,
#support .modal-footer a.btn-default:hover,
#support .modal-footer a.btn-close:hover,
#tracking-batch .modal-footer a.btn-default:hover,
#tracking-batch .modal-footer a.btn-close:hover {
  background-color: #b4bfc9;
}
#tracking-tools .modal-footer a,
#tracking-tools .modal-footer input,
#support .modal-footer a,
#support .modal-footer input,
#tracking-batch .modal-footer a,
#tracking-batch .modal-footer input {
  margin: 0 0.25rem 0;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}
#tracking-tools .modal-footer.success a,
#tracking-tools .modal-footer.error a,
#support .modal-footer.success a,
#support .modal-footer.error a,
#tracking-batch .modal-footer.success a,
#tracking-batch .modal-footer.error a {
  text-decoration: none;
  color: white;
  padding: 10px 68px;
}
#tracking-tools .modal-footer #modal-footer-content .unread-messages-count,
#support .modal-footer #modal-footer-content .unread-messages-count,
#tracking-batch .modal-footer #modal-footer-content .unread-messages-count {
  top: 0.5rem;
  right: 1rem;
}
#tracking-tools .modal-footer #modal-footer-content .modal-footer-chat-button,
#support .modal-footer #modal-footer-content .modal-footer-chat-button,
#tracking-batch .modal-footer #modal-footer-content .modal-footer-chat-button {
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
}
#tracking-tools .fixed-footer,
#support .fixed-footer,
#tracking-batch .fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  background-color: #f7f8fa;
  box-shadow: -4px 0 8px 0 rgba(51, 66, 79, 0.14);
}
#tracking-tools .fixed-footer a,
#support .fixed-footer a,
#tracking-batch .fixed-footer a {
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
#tracking-tools .fixed-footer a .icon-external:before,
#support .fixed-footer a .icon-external:before,
#tracking-batch .fixed-footer a .icon-external:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 1px;
  height: 12px;
  width: 12px;
  margin-left: 0.25rem;
  background: url(./assets/cut.svg)
    no-repeat;
}
#tracking-tools .modal-success .modal-body,
#support .modal-success .modal-body,
#tracking-batch .modal-success .modal-body {
  padding: 1.5rem 1.5rem 1rem;
}
#tracking-tools .modal-success .modal-footer,
#support .modal-success .modal-footer,
#tracking-batch .modal-success .modal-footer {
  text-align: center;
  padding: 0.5rem 2rem 1.5rem;
}
#tracking-tools .modal-success .tools-icon-sprite,
#support .modal-success .tools-icon-sprite,
#tracking-batch .modal-success .tools-icon-sprite {
  margin: 1rem 0 1.5rem;
}
#tracking-tools ::-webkit-input-placeholder,
#support ::-webkit-input-placeholder,
#tracking-batch ::-webkit-input-placeholder {
  font-style: italic;
}
#tracking-tools :-moz-placeholder,
#support :-moz-placeholder,
#tracking-batch :-moz-placeholder {
  font-style: italic;
}
#tracking-tools ::-moz-placeholder,
#support ::-moz-placeholder,
#tracking-batch ::-moz-placeholder {
  font-style: italic;
}
#tracking-tools :-ms-input-placeholder,
#support :-ms-input-placeholder,
#tracking-batch :-ms-input-placeholder {
  font-style: italic;
}
#tracking-tools #tools-modal,
#support #tools-modal,
#tracking-batch #tools-modal {
  padding: 0px;
}
#tracking-tools-progress #parcel-actions {
  margin: 0;
}
#tracking-tools-progress #parcel-actions .dropdown-menu {
  min-width: 270px;
}
@media (max-width: 991px) {
  #tracking-tools #parcel-actions {
    margin-top: 1.5rem;
    text-align: center;
  }
  #tracking-tools #parcel-actions .dropdown-menu {
    width: 100%;
  }
  #tracking-tools #parcel-actions a {
    min-width: 200px;
  }
  #tracking-tools-progress #parcel-actions {
    text-align: right;
  }
  #tracking-tools-progress #parcel-actions a {
    min-width: 50%;
  }
  #tracking-tools .panel,
  #tracking-tools-progress .panel {
    margin-bottom: 1rem;
  }
  #tracking-tools .panel-heading,
  #tracking-tools-progress .panel-heading {
    padding: 1rem;
  }
  #tracking-tools .panel-body,
  #tracking-tools-progress .panel-body {
    padding: 1rem;
  }
  #tracking-tools .modal-body,
  #tracking-tools-progress .modal-body {
    padding: 1.5rem;
  }
  #tracking-tools .modal-success .modal-body,
  #tracking-tools-progress .modal-success .modal-body {
    padding: 1.5rem 1.5rem 0;
  }
  #tracking-tools .modal-footer,
  #tracking-tools-progress .modal-footer {
    text-align: left;
    padding: 1rem 1.5rem 1.5rem;
    border-radius: 0 0 0.5rem 0.5rem;
    border-top: none;
  }
  #tracking-tools .modal-footer a,
  #tracking-tools-progress .modal-footer a {
    margin: 0 0.5rem 0;
    font-size: 0.875rem;
  }
}
@media (max-width: 767px) {
  #support-modal,
  #support .modal-content,
  #support .modal-dialog {
    width: 100%;
  }
  #support-modal
    #support-modal-content
    .workflow-event-form
    .has-error
    .help-text.text-danger,
  #support
    .modal-content
    #support-modal-content
    .workflow-event-form
    .has-error
    .help-text.text-danger,
  #support
    .modal-dialog
    #support-modal-content
    .workflow-event-form
    .has-error
    .help-text.text-danger {
    padding: 0.25rem 0.5rem;
    display: block;
  }
  #tracking-tools .panel-body a {
    width: calc(100% - 8px);
  }
}
@media only screen and (max-device-width: 768px) {
  #tracking-tools .modal-dialog,
  #cancellation-modal .modal-dialog {
    max-width: 100%;
  }
  #modal-header-wrapper {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  .modal-content {
    box-shadow: none;
  }
  #support .fixed-footer {
    padding: 1rem 1.5rem;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  #support .modal-content-padding {
    padding-bottom: 0.5rem;
  }
  #support #support-modal-content {
    margin-top: 0;
  }
  #help-btn,
  #minimise-btn {
    height: 48px;
    bottom: 10px;
    right: 10px;
  }
  #help-btn .button-content,
  #minimise-btn .button-content {
    width: 99px;
    height: 40px;
  }
  #help-btn .shipment-question,
  #minimise-btn .shipment-question {
    width: 18px;
    height: 18px;
  }
}
@media (min-width: 768px) {
  #tracking-tools .modal-dialog,
  #cancellation-modal .modal-dialog {
    max-width: 400px;
  }
  #tracking-tools #parcel-actions .dropdown-menu,
  #tracking-tools-progress #parcel-actions .dropdown-menu {
    min-width: 300px;
  }
  #support {
    margin-top: 80px;
  }
}
@media (min-width: 992px) {
  #tracking-tools #parcel-actions > a {
    left: auto;
    width: 100%;
  }
  #tracking-tools #parcel-actions .dropdown-menu {
    left: auto;
  }
}
.scrollable {
  overflow: auto !important;
}
.modal-backdrop {
  background-color: #ffffff;
}
.modal-backdrop.in {
  opacity: 0;
  z-index: -1;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 1%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fixed-footer,
  #modal-header-wrapper {
    position: relative !important;
  }
  #scroll-target {
    margin-bottom: -40px !important;
  }
  #help-content {
    margin-bottom: 0 !important;
  }
}
.create-return-label-tool-container {
  display: flex;
  flex-direction: column;
}
.create-return-label-tool .sds-button__text {
  flex-flow: row wrap;
}
.create-return-label-tool__icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
  color: #009de3;
}
.create-return-label-tool__used {
  margin-left: 1.75rem;
  flex-basis: 100%;
  color: #6d7883;
  font-weight: 400;
}
.create-return-label-tool.dashboard-tool-disabled
  .create-return-label-tool__icon {
  color: inherit;
}
.return-drawer {
  padding: 0 1.5rem;
  font-size: 1rem;
}
.return-drawer__header {
  font-weight: 700;
}
.return-drawer__order-details {
  margin-bottom: 1rem;
}
.return-drawer__email-icon {
  margin-right: 0.5rem;
  color: #b4bfc9;
}
.return-drawer__account-details-changed > .sds-alert-message__content {
  line-height: 1.5;
}
.return-drawer__create-label-totals {
  display: flex;
  width: 100%;
  font-size: 1rem;
  height: 3rem;
  margin-bottom: 3rem;
}
.return-drawer__create-label-totals__label,
.return-drawer__create-label-totals__value {
  flex-grow: 1;
}
.return-drawer__create-label-totals__label {
  display: flex;
  flex-direction: column;
}
.return-drawer__create-label-totals__label .header {
  line-height: 2;
  min-height: 30px;
}
.return-drawer__create-label-totals__label .subheader {
  font-size: 0.875rem;
}
.return-drawer__create-label-totals__value {
  text-align: right;
}
.return-drawer__create-label-totals__value .header {
  font-size: 0.875rem;
}
.return-drawer__create-label-totals__value .amount {
  font-size: 1.5rem;
}
.return-drawer__hide-pickup-notice {
  display: block;
  padding: 1rem;
  background-color: #fff8eb;
  color: #916c1f;
  margin-bottom: 1rem;
}
.return-drawer-download-label .print-label {
  display: flex;
  flex-wrap: nowrap;
}
.return-drawer-download-label .btn-close {
  display: block;
  position: relative;
  top: 2px;
  top: 0.125rem;
}
.return-drawer-download-label .btn-print {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 0.875rem;
  line-height: 22px;
  background: #ff6800;
  float: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 0;
}
.return-drawer-download-label .btn-label-options {
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #33424f;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 22px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.return-drawer-download-label .btn-label-options .caret {
  margin-left: 0.25rem;
}
.return-drawer-download-label .label-options {
  float: left;
}
.return-drawer-download-label .label-options li a {
  text-align: right;
  padding: 6px 12px 6px 20px;
}
.return-drawer-download-label .label-options li a .option-label {
  float: left;
}
.return-drawer-download-label .label-options li a .icon,
.return-drawer-download-label
  .label-options
  li
  a
  .navbar-form.search-form:after,
.return-drawer-download-label
  .label-options
  li
  a
  #tracking-tools
  #modal-search
  form:before,
#tracking-tools
  #modal-search
  .return-drawer-download-label
  .label-options
  li
  a
  form:before,
.return-drawer-download-label
  .label-options
  li
  a
  #support
  #modal-search
  form:before,
#support
  #modal-search
  .return-drawer-download-label
  .label-options
  li
  a
  form:before,
.return-drawer-download-label
  .label-options
  li
  a
  #tracking-batch
  #modal-search
  form:before,
#tracking-batch
  #modal-search
  .return-drawer-download-label
  .label-options
  li
  a
  form:before {
  margin-top: 4px;
}
.return-drawer-download-label .inline-icon-download:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 14px;
  margin: 0 2px;
  background: url(https://assets.sendle.com/assets/dashboard/icon-download-inline-0041e02381398c7a75ee16f0de058b4d205083de1ccaf38bfc4a3e4c286c226a.svg)
    no-repeat;
  background-size: 18px;
}
#tracking-delivery_return {
  margin-top: 1.5rem;
}
#tracking-delivery_return .delivery-return {
  padding: 1rem;
}
#tracking-batch h2.text-center {
  font-weight: bold;
}
#tracking-batch .subheading {
  margin-bottom: 32px;
}
#tracking-batch #tracking-delivery_summary {
  margin-bottom: 64px;
}
#tracking-batch .progress-bar-with-label .progress {
  height: 12px;
}
.tracking-sprite {
  background: url(https://assets.sendle.com/assets/tracking/icon-tracking-sprite-24edbc80967e96e3fd9d155bdcf133ce8d0ad335ec8b884b991901670274643e.svg)
    no-repeat;
  content: " ";
  display: inline-block;
  position: relative;
  top: 1px;
}
.tracking-sprite-tick {
  background-position: -100px -28px;
  width: 16px;
  height: 16px;
}
.tracking-sprite-phone {
  background-position: -82px -47px;
  height: 16px;
  width: 16px;
}
.tracking-sprite-email {
  background-position: -66px -43px;
  height: 16px;
  width: 16px;
}
.tracking-sprite-status {
  width: 24px;
  height: 24px;
  margin-right: 2px;
}
.tracking-sprite-in_transit,
.tracking-sprite-dropped_off,
.tracking-sprite-out_for_delivery {
  background-position: -171px 0px;
}
.tracking-sprite-delivered,
.tracking-sprite-left_with_agent {
  background-position: -67px 0px;
}
.tracking-sprite-picking_up,
.tracking-sprite-dropping_off {
  background-position: -223px 0px;
}
.tracking-sprite-dropping_off {
  transform: rotate(180deg);
}
.tracking-sprite-drop_off {
  background-position: -223px 0px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.tracking-sprite-unable_to_deliver,
.tracking-sprite-card_left,
.tracking-sprite-pickup_futile {
  background-position: -197px 0px;
}
.tracking-sprite-onforwarded {
  background-position: -93px 0px;
}
.tracking-sprite-lost,
.tracking-sprite-cancelled {
  background-position: -145px 0px;
}
.tracking-sprite-return_to_sender {
  background-position: -119px 0px;
}
.tracking-sprite-chevron-right {
  background-position: -205px -47px;
  height: 16px;
  width: 16px;
}
.tracking-sprite-jump-link {
  background-position: -205px -28px;
  height: 16px;
  width: 16px;
}
a .tracking-sprite-external {
  top: 0;
  height: 16px;
  width: 16px;
  background-position: -65px -27px;
}
a:hover .tracking-sprite-external {
  background-position: -81px -27px;
}
.dashboard-tools-label-sprite {
  background: url(https://assets.sendle.com/assets/tracking/dashboard-tool-label-sprites-09f4a0bcbd03cc9be83d7265b9e16c7f2629d6a8f9ab98be7867cc6833aefb00.svg)
    no-repeat;
  content: " ";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background-position: -24px -240px;
  min-width: 24px;
}
.dashboard-tools-label-sprite-edit-delivery-instructions {
  background-position: -24px 0px;
}
.dashboard-tools-label-sprite-redirect-parcel {
  background-position: -24px -24px;
}
.dashboard-tools-label-sprite-edit-pickup-instructions {
  background-position: -24px -48px;
}
.dashboard-tools-label-sprite-investigation,
.dashboard-tools-label-sprite-no-tracking-investigation,
.dashboard-tools-label-sprite-no-tracking-investigation-drop-off,
.dashboard-tools-label-sprite-late-parcel-investigation,
.dashboard-tools-label-sprite-late-parcel-investigation-onf,
.dashboard-tools-label-sprite-missing-parcel-investigation {
  background-position: -24px -72px;
}
.dashboard-tools-label-sprite-update-and-redeliver,
.dashboard-tools-label-sprite-redeliver-parcel-utd {
  background-position: -24px -96px;
}
.dashboard-tools-label-sprite-request-redelivery,
.dashboard-tools-label-sprite-redeliver-parcel,
.dashboard-tools-label-sprite-redeliver-parcel-utd {
  background-position: -24px -120px;
}
.dashboard-tools-label-sprite-return-to-sender {
  background-position: -24px -144px;
}
.dashboard-tools-label-sprite-reschedule-pickup {
  background-position: -24px -168px;
}
.dashboard-tools-label-sprite-help-centre {
  background-position: -24px -192px;
}
.dashboard-tools-label-sprite-support {
  background-position: -24px -216px;
}
.dashboard-tools-label-sprite-generic {
  background-position: -24px -240px;
}
.dashboard-tools-label-sprite-parcel-received {
  background-position: -24px -264px;
}
.dashboard-tools-label-sprite-cancel {
  background-position: -48px -288px;
}
.dashboard-tools-label-sprite-archive {
  background-position: -24px -312px;
}
.dashboard-tools-label-sprite-restore {
  background-position: -24px -336px;
}
.dashboard-tool-disabled .dashboard-tools-label-sprite {
  background-position-x: 0px;
  top: 14px;
}
.dashboard-tools-icon-sprite {
  background: url(https://assets.sendle.com/assets/tracking/dashboard-tools-icon-sprites-5c614ec28a42aec59065a43a27c037996a7ee887f3f49126e29039c52d1fae12.svg)
    no-repeat;
  content: " ";
  display: inline-block;
  width: 96px;
  height: 96px;
}
.dashboard-tools-icon-sprite-redirect {
  background-position: 0px 0px;
}
.dashboard-tools-icon-sprite-update-and-redeliver {
  background-position: -96px 0px;
}
.dashboard-tools-icon-sprite-generic {
  background-position: -192px 0px;
}
.dashboard-tools-icon-sprite-investigation {
  background-position: -288px 0px;
}
.dashboard-tools-icon-sprite-reschedule-pickup {
  background-position: -384px 0px;
}
.dashboard-tools-icon-sprite-return-to-sender {
  background-position: -480px 0px;
}
.dashboard-tools-icon-sprite-error {
  background-position: -576px 0px;
}
.dashboard-tools-icon-sprite-parcel-received {
  background-position: -673px 0px;
}
.dashboard-tools-icon-sprite-redirect {
  background-position: 0px 0px;
}
.tracking-tooltip-icon-sprite:before {
  background: url(https://assets.sendle.com/assets/tracking/sprite-tooltip-2c0b8aad3bd864782c80a3451021c0ad64a16607de8c58575aa88f2d6f13a90b.svg)
    no-repeat;
  background-size: 38px 16px;
  content: " ";
  display: inline-block;
  width: 20px;
  height: 16px;
}
.tracking-tooltip-icon-sprite-info:before {
  background-position: -20px 0px;
}
.tracking-tooltip-icon-sprite-warning:before {
  background-position: 0px 0px;
}
.carbon-neutral-messaging-empty-state {
  padding: 40px 1rem;
  font-size: 1rem;
  text-align: center;
  background: #f7f8fa;
}
.carbon-neutral-messaging-empty-state__heading {
  margin: 0 0 calc((0.5rem / 2) * 3);
}
.carbon-neutral-messaging-empty-state__description {
  margin-bottom: 1.5rem;
  line-height: 1.5;
}
.carbon-neutral-messaging-empty-state__intro-actions {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.carbon-neutral-messaging-empty-state__banner {
  max-width: 430px;
}
@media (min-width: 992px) {
  .carbon-neutral-messaging-empty-state__banner {
    max-width: 600px;
  }
}
.carbon-neutral-messaging-empty-state .tt-suggestion {
  text-align: left;
}
.confirm-email-alert {
  max-width: 700px;
  margin: 1.5rem auto;
}
.confirm-email {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 992px) {
  .confirm-email {
    flex-direction: row;
    align-items: flex-start;
  }
}
.confirm-email__email-sent-icon {
  margin-right: 1.5rem;
}
.confirm-email__content {
  align-items: center;
  justify-content: center;
  margin: 1rem;
  font-size: 1rem !important;
  max-width: 700px;
}
@media (min-width: 768px) {
  .confirm-email__content {
    margin: 1.5rem;
  }
}
.onboarding-articles {
  width: 100%;
  font-size: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .onboarding-articles {
    margin-top: 9rem;
  }
}
.onboarding-articles__description {
  margin-bottom: 1rem;
}
@media screen and (min-width: 992px) {
  .onboarding-articles__description {
    display: flex;
    justify-content: space-between;
  }
}
.onboarding-articles__list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
@media screen and (min-width: 992px) {
  .onboarding-articles__list {
    flex-direction: row;
    gap: 1.5rem;
  }
}
.onboarding-articles__list img {
  width: 100%;
}
.loader.active {
  display: block;
}
.loader {
  width: 32px;
  height: 32px;
}
.loader {
  display: none;
  position: absolute;
  top: 33%;
  left: 50%;
  margin: 0;
  text-align: left;
  z-index: 1000;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.loader:before {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 4px solid rgba(51, 66, 79, 0.1);
}
.loader:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: loader 0.6s linear;
  animation: loader 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 100%;
  border-color: #ff6800 transparent transparent;
  border-style: solid;
  border-width: 4px;
}
@-webkit-keyframes loader {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.sds-u-m_top--none {
  margin-top: 0 !important;
}
.sds-u-m_top--tiny {
  margin-top: 0.0625rem !important;
}
.sds-u-m_top--xxs {
  margin-top: 0.125rem !important;
}
.sds-u-m_top--xs {
  margin-top: 0.25rem !important;
}
.sds-u-m_top--small {
  margin-top: 0.5rem !important;
}
.sds-u-m_top--medium {
  margin-top: 1rem !important;
}
.sds-u-m_top--large {
  margin-top: 1.5rem !important;
}
.sds-u-m_top--xl {
  margin-top: 2rem !important;
}
.sds-u-m_top--xxl {
  margin-top: 3rem !important;
}
.sds-u-m_top--base {
  margin-top: 1rem !important;
}
.sds-u-m_right--none {
  margin-right: 0 !important;
}
.sds-u-m_right--tiny {
  margin-right: 0.0625rem !important;
}
.sds-u-m_right--xxs {
  margin-right: 0.125rem !important;
}
.sds-u-m_right--xs {
  margin-right: 0.25rem !important;
}
.sds-u-m_right--small {
  margin-right: 0.5rem !important;
}
.sds-u-m_right--medium {
  margin-right: 1rem !important;
}
.sds-u-m_right--large {
  margin-right: 1.5rem !important;
}
.sds-u-m_right--xl {
  margin-right: 2rem !important;
}
.sds-u-m_right--xxl {
  margin-right: 3rem !important;
}
.sds-u-m_right--base {
  margin-right: 1rem !important;
}
.sds-u-m_bottom--none,
.order-list-bulk-action-check-all * {
  margin-bottom: 0 !important;
}
.sds-u-m_bottom--tiny {
  margin-bottom: 0.0625rem !important;
}
.sds-u-m_bottom--xxs {
  margin-bottom: 0.125rem !important;
}
.sds-u-m_bottom--xs {
  margin-bottom: 0.25rem !important;
}
.sds-u-m_bottom--small {
  margin-bottom: 0.5rem !important;
}
.sds-u-m_bottom--medium {
  margin-bottom: 1rem !important;
}
.sds-u-m_bottom--large {
  margin-bottom: 1.5rem !important;
}
.sds-u-m_bottom--xl {
  margin-bottom: 2rem !important;
}
.sds-u-m_bottom--xxl {
  margin-bottom: 3rem !important;
}
.sds-u-m_bottom--base {
  margin-bottom: 1rem !important;
}
.sds-u-m_left--none {
  margin-left: 0 !important;
}
.sds-u-m_left--tiny {
  margin-left: 0.0625rem !important;
}
.sds-u-m_left--xxs {
  margin-left: 0.125rem !important;
}
.sds-u-m_left--xs {
  margin-left: 0.25rem !important;
}
.sds-u-m_left--small {
  margin-left: 0.5rem !important;
}
.sds-u-m_left--medium {
  margin-left: 1rem !important;
}
.sds-u-m_left--large {
  margin-left: 1.5rem !important;
}
.sds-u-m_left--xl {
  margin-left: 2rem !important;
}
.sds-u-m_left--xxl {
  margin-left: 3rem !important;
}
.sds-u-m_left--base {
  margin-left: 1rem !important;
}
.sds-u-m_around--none {
  margin: 0 !important;
}
.sds-u-m_around--tiny {
  margin: 0.0625rem !important;
}
.sds-u-m_around--xxs {
  margin: 0.125rem !important;
}
.sds-u-m_around--xs {
  margin: 0.25rem !important;
}
.sds-u-m_around--small {
  margin: 0.5rem !important;
}
.sds-u-m_around--medium {
  margin: 1rem !important;
}
.sds-u-m_around--large {
  margin: 1.5rem !important;
}
.sds-u-m_around--xl {
  margin: 2rem !important;
}
.sds-u-m_around--xxl {
  margin: 3rem !important;
}
.sds-u-m_around--base {
  margin: 1rem !important;
}
.sds-u-m--none {
  margin: 0 !important;
}
.sds-u-p_top--none {
  padding-top: 0 !important;
}
.sds-u-p_top--tiny {
  padding-top: 0.0625rem !important;
}
.sds-u-p_top--xxs {
  padding-top: 0.125rem !important;
}
.sds-u-p_top--xs {
  padding-top: 0.25rem !important;
}
.sds-u-p_top--small {
  padding-top: 0.5rem !important;
}
.sds-u-p_top--medium {
  padding-top: 1rem !important;
}
.sds-u-p_top--large {
  padding-top: 1.5rem !important;
}
.sds-u-p_top--xl {
  padding-top: 2rem !important;
}
.sds-u-p_top--xxl {
  padding-top: 3rem !important;
}
.sds-u-p_top--base {
  padding-top: 1rem !important;
}
.sds-u-p_right--none {
  padding-right: 0 !important;
}
.sds-u-p_right--tiny {
  padding-right: 0.0625rem !important;
}
.sds-u-p_right--xxs {
  padding-right: 0.125rem !important;
}
.sds-u-p_right--xs {
  padding-right: 0.25rem !important;
}
.sds-u-p_right--small {
  padding-right: 0.5rem !important;
}
.sds-u-p_right--medium {
  padding-right: 1rem !important;
}
.sds-u-p_right--large {
  padding-right: 1.5rem !important;
}
.sds-u-p_right--xl {
  padding-right: 2rem !important;
}
.sds-u-p_right--xxl {
  padding-right: 3rem !important;
}
.sds-u-p_right--base {
  padding-right: 1rem !important;
}
.sds-u-p_bottom--none {
  padding-bottom: 0 !important;
}
.sds-u-p_bottom--tiny {
  padding-bottom: 0.0625rem !important;
}
.sds-u-p_bottom--xxs {
  padding-bottom: 0.125rem !important;
}
.sds-u-p_bottom--xs {
  padding-bottom: 0.25rem !important;
}
.sds-u-p_bottom--small {
  padding-bottom: 0.5rem !important;
}
.sds-u-p_bottom--medium {
  padding-bottom: 1rem !important;
}
.sds-u-p_bottom--large {
  padding-bottom: 1.5rem !important;
}
.sds-u-p_bottom--xl {
  padding-bottom: 2rem !important;
}
.sds-u-p_bottom--xxl {
  padding-bottom: 3rem !important;
}
.sds-u-p_bottom--base {
  padding-bottom: 1rem !important;
}
.sds-u-p_left--none {
  padding-left: 0 !important;
}
.sds-u-p_left--tiny {
  padding-left: 0.0625rem !important;
}
.sds-u-p_left--xxs {
  padding-left: 0.125rem !important;
}
.sds-u-p_left--xs {
  padding-left: 0.25rem !important;
}
.sds-u-p_left--small {
  padding-left: 0.5rem !important;
}
.sds-u-p_left--medium {
  padding-left: 1rem !important;
}
.sds-u-p_left--large {
  padding-left: 1.5rem !important;
}
.sds-u-p_left--xl {
  padding-left: 2rem !important;
}
.sds-u-p_left--xxl {
  padding-left: 3rem !important;
}
.sds-u-p_left--base {
  padding-left: 1rem !important;
}
.sds-u-p_around--none {
  padding: 0 !important;
}
.sds-u-p_around--tiny {
  padding: 0.0625rem !important;
}
.sds-u-p_around--xxs {
  padding: 0.125rem !important;
}
.sds-u-p_around--xs {
  padding: 0.25rem !important;
}
.sds-u-p_around--small {
  padding: 0.5rem !important;
}
.sds-u-p_around--medium {
  padding: 1rem !important;
}
.sds-u-p_around--large {
  padding: 1.5rem !important;
}
.sds-u-p_around--xl {
  padding: 2rem !important;
}
.sds-u-p_around--xxl {
  padding: 3rem !important;
}
.sds-u-p_around--base {
  padding: 1rem !important;
}
.sds-u-p--none {
  padding: 0 !important;
}
.sds-u-bc--none {
  background-color: transparent !important;
}
.sds-u-bc--default {
  background-color: #ffffff !important;
}
.sds-u-bc--default-reverse {
  background-color: #33424f !important;
}
.sds-u-bc--alt {
  background-color: #f7f8fa !important;
}
.sds-u-bc--disabled {
  background-color: #e9edf0 !important;
}
.sds-u-bc--success {
  background-color: #007f48 !important;
}
.sds-u-bc--success-light {
  background-color: #e5f6ee !important;
}
.sds-u-bc--risk {
  background-color: #ba3037 !important;
}
.sds-u-bc--risk-light {
  background-color: #fcebec !important;
}
.sds-u-bc--warning {
  background-color: #ffbe37 !important;
}
.sds-u-bc--warning-light {
  background-color: #fff8eb !important;
}
.sds-u-bc--notice {
  background-color: #0095b5 !important;
}
.sds-u-bc--notice-light {
  background-color: #e6f7ff !important;
}
.sds-u-bc--info {
  background-color: #6d7883 !important;
}
.sds-u-bc--primary {
  background-color: #ff6800 !important;
}
.sds-u-bc--primary-light {
  background-color: #ffa466 !important;
}
.sds-u-bc--secondary {
  background-color: #33424f !important;
}
.sds-u-bc--secondary-light {
  background-color: #e9edf0 !important;
}
.sds-u-bc--premium {
  background-color: #ffbe37 !important;
}
.sds-u-tc--none {
  color: transparent !important;
}
.sds-u-tc--default {
  color: #33424f !important;
}
.sds-u-tc--default-reverse {
  color: #ffffff !important;
}
.sds-u-tc--meta,
.bulk-actions-header-row__title-content__subtitle {
  color: #6d7883 !important;
}
.sds-u-tc--meta-reverse {
  color: #b4bfc9 !important;
}
.sds-u-tc--primary {
  color: #ff6800 !important;
}
.sds-u-tc--primary-reverse {
  color: #ffa466 !important;
}
.sds-u-tc--secondary {
  color: #33424f !important;
}
.sds-u-tc--secondary-reverse {
  color: #e9edf0 !important;
}
.sds-u-tc--notice {
  color: #008dcc !important;
}
.sds-u-tc--notice-reverse {
  color: #e6f7ff !important;
}
.sds-u-tc--risk {
  color: #ba3037 !important;
}
.sds-u-tc--risk-reverse {
  color: #f18a8f !important;
}
.sds-u-tc--success {
  color: #007f48 !important;
}
.sds-u-tc--success-reverse {
  color: #e5f6ee !important;
}
.sds-u-tc--warning {
  color: #916c1f !important;
}
.sds-u-tc--warning-reverse {
  color: #fff8eb !important;
}
.sds-u-fs--xs {
  font-size: 0.75rem !important;
}
.sds-u-fs--small {
  font-size: 0.875rem !important;
}
.sds-u-fs--medium {
  font-size: 1rem !important;
}
.sds-u-fs--large {
  font-size: 1.25rem !important;
}
.sds-u-fs--xl {
  font-size: 1.5rem !important;
}
.sds-u-fs--xxl {
  font-size: 2rem !important;
}
.sds-u-fs--xxxl {
  font-size: 3rem !important;
}
.sds-u-fs--base {
  font-size: 1rem !important;
}
.sds-u-tc--default-reverse {
  text-shadow: 1px 1px 1px rgba(51, 66, 79, 0.3);
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
*,
:after,
:before {
  box-sizing: border-box;
}
html {
  height: 100%;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #ffffff;
  color: #33424f;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
p {
  margin-bottom: 1rem;
}
a {
  color: #008dcc;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #008dcc;
  text-decoration: underline;
}
a:active {
  color: #008dcc;
  text-decoration: underline;
}
.sds-heading--xl {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3rem;
  line-height: 1.166667;
  font-weight: 700;
}
.sds-heading--large {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2rem;
  line-height: 1.25;
  font-weight: 700;
}
.sds-heading--medium,
.bulk-actions-header-row__title-content__title,
#tracking-tools #help-content .article-body h1,
#support #help-content .article-body h1,
#tracking-batch #help-content .article-body h1 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 1.333334;
  font-weight: 700;
}
.sds-heading--small,
.billing-panel .billing-panel-heading,
.confirmation-summary__card-details-title,
#support #support-modal .product-options__group-title,
.product-options__group-title,
.product-order-form__section-title,
#tracking-tools #help-content .article-body h2,
#support #help-content .article-body h2,
#tracking-batch #help-content .article-body h2,
#tracking-tools #help-content .article-body h3,
#support #help-content .article-body h3,
#tracking-batch #help-content .article-body h3,
#tracking-tools #help-content .article-body h4,
#support #help-content .article-body h4,
#tracking-batch #help-content .article-body h4 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}
.sds-heading--extra-bold {
  font-weight: 800;
  letter-spacing: -1;
}
.sds-heading--normal {
  font-weight: 400;
  letter-spacing: -1;
}
.sds-meta--small,
.select2-container.select2-container--sds.select2-container--open
  .select2-results
  .select2-results__option {
  font-size: 0.875rem;
  line-height: 1.42857;
}
.sds-meta--xs {
  font-size: 0.75rem;
  line-height: 1.66667;
}
.logo-primary-wordmark {
  fill: currentColor;
}
.logo-primary-spacers {
  fill: none;
}
.logo-primary-marque-lower {
  fill: #ff6800;
}
.logo-primary-marque-upper {
  fill: #ff8e40;
}
.logo-primary--reversed {
  color: #ffffff;
}
.logo-primary--reversed .logo-primary-marque-lower {
  fill: currentColor;
}
.logo-primary--reversed .logo-primary-marque-upper {
  fill: currentColor;
  fill-opacity: 0.75;
}
.logo-secondary-marque-lower {
  fill: #ff6800;
}
.logo-secondary-marque-upper {
  fill: #ff8e40;
}
.logo-secondary--dark {
  color: #33424f;
}
.logo-secondary--dark .logo-secondary-marque-lower {
  fill: currentColor;
}
.logo-secondary--dark .logo-secondary-marque-upper {
  fill: currentColor;
  fill-opacity: 0.75;
}
.logo-secondary--reversed {
  color: #ffffff;
}
.logo-secondary--reversed .logo-secondary-marque-lower {
  fill: currentColor;
}
.logo-secondary--reversed .logo-secondary-marque-upper {
  fill: currentColor;
  fill-opacity: 0.75;
}
.sds-button,
.pagination li > a:not(.sds-button) {
  --sds-button-outline-color: currentColor;
  background: #e9edf0;
  background-clip: border-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  color: #33424f;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-appearance: none;
  white-space: normal;
}
.sds-button:hover,
.pagination li > a:hover:not(.sds-button) {
  color: #33424f;
  background: #b4bfc9;
}
.sds-button:focus,
.pagination li > a:focus:not(.sds-button),
.sds-button:focus-within,
.pagination li > a:focus-within:not(.sds-button) {
  color: #33424f;
  outline: 0;
  background: #e9edf0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px var(--sds-button-outline-color);
}
.sds-button:active,
.pagination li > a:active:not(.sds-button) {
  color: #33424f;
  text-decoration: none;
  background: #6d7883;
}
.sds-button > span ~ ::before,
.pagination li > a:not(.sds-button) > span ~ ::before {
  display: inline-flex;
  content: "";
  width: 0.5rem;
}
.sds-button__text {
  display: inline-flex;
  align-items: center;
}
.sds-button__icon {
  display: inline-flex;
}
.sds-button__icon svg {
  max-height: 1.3em;
}
.sds-button--ghost,
.pagination li > a:not(.sds-button) {
  background: #ffffff;
  box-shadow: 0 0 0 0.0625rem currentColor;
}
.sds-button--ghost:visited:not(:active):not(:hover),
.pagination li > a:visited:not(:active):not(:hover):not(.sds-button) {
  color: #33424f;
}
.sds-button--link {
  background: transparent;
}
.sds-button a,
.pagination li > a:not(.sds-button) a {
  text-decoration: none;
}
.sds-button--x-small {
  font-size: 0.75rem;
  padding: 0.125rem 1rem;
}
.sds-button--small {
  padding: 0.25rem 1rem;
}
.sds-button--large {
  font-size: 1.25rem;
  padding: 1rem;
}
.sds-button--primary {
  --sds-button-outline-color: #c85200;
  background: #ff6800;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(200, 82, 0, 0.6);
}
.sds-button--primary.sds-button--ghost,
.pagination li > a.sds-button--primary:not(.sds-button) {
  background: #ffffff;
  border-color: #ff6800;
  color: #c85200;
  text-shadow: none;
}
.sds-button--primary.sds-button--ghost:visited:not(:active):not(:hover),
.pagination
  li
  > a.sds-button--primary:visited:not(:active):not(:hover):not(.sds-button) {
  color: #c85200;
}
.sds-button--primary:hover {
  background: #c85200;
  color: #ffffff;
}
.sds-button--primary:focus,
.sds-button--primary:focus-within {
  background: #ff6800;
  box-shadow: 0 0 0 2px white, 0 0 0 4px var(--sds-button-outline-color);
  color: #ffffff;
}
.sds-button--primary:active {
  background: #993e00;
  color: #ffffff;
}
.sds-button--primary:visited {
  color: #ffffff;
}
.sds-button--secondary {
  --sds-button-outline-color: #33424f;
  background: #33424f;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(31, 40, 47, 0.6);
}
.sds-button--secondary.sds-button--ghost,
.pagination li > a.sds-button--secondary:not(.sds-button) {
  background: #ffffff;
  border-color: #33424f;
  color: #52a4a3;
  text-shadow: none;
}
.sds-button--secondary.sds-button--ghost:visited:not(:active):not(:hover),
.pagination
  li
  > a.sds-button--secondary:visited:not(:active):not(:hover):not(.sds-button) {
  color: #52a4a3;
}
.sds-button--secondary:hover:not(:active) {
  background: #1f282f;
  color: #ffffff;
}
.sds-button--secondary:focus,
.sds-button--secondary:focus-within {
  background: #33424f;
  box-shadow: 0 0 0 2px white, 0 0 0 4px var(--sds-button-outline-color);
  color: #ffffff;
}
.sds-button--secondary:active {
  background: #33424f;
  color: #ffffff;
}
.sds-button--secondary:visited {
  color: #ffffff;
}
.sds-button--risk {
  --sds-button-outline-color: #ba3037;
  background: #e93d45;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(186, 48, 55, 0.6);
}
.sds-button--risk.sds-button--ghost,
.pagination li > a.sds-button--risk:not(.sds-button) {
  background: #ffffff;
  border-color: #ba3037;
  color: #ba3037;
  text-shadow: none;
}
.sds-button--risk.sds-button--ghost:visited:not(:active):not(:hover),
.pagination
  li
  > a.sds-button--risk:visited:not(:active):not(:hover):not(.sds-button) {
  color: #ba3037;
}
.sds-button--risk.sds-button--link {
  background: transparent;
  border-color: transparent;
  color: #ba3037;
  text-shadow: none;
}
.sds-button--risk.sds-button--link:focus,
.sds-button--risk.sds-button--link:focus-within {
  color: #ba3037;
  background: transparent;
  box-shadow: 0 0 0 2px white, 0 0 0 4px var(--sds-button-outline-color);
}
.sds-button--risk:hover {
  background: #ba3037;
  color: #ffffff;
}
.sds-button--risk:focus,
.sds-button--risk:focus-within {
  background: #e93d45;
  box-shadow: 0 0 0 2px white, 0 0 0 4px var(--sds-button-outline-color);
  color: #ffffff;
}
.sds-button--risk:active {
  background: #8b2429;
  color: #ffffff;
}
.sds-button--risk:visited {
  color: #ffffff;
}
.sds-button:disabled,
.pagination li > a:disabled:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .sds-button.select2-selection__rendered,
.pagination
  .select2-container.select2-container--sds.select2-container--disabled
  li
  > a.select2-selection__rendered:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .pagination
  li
  > a.select2-selection__rendered:not(.sds-button),
.sds-button[disabled],
.pagination li > a[disabled]:not(.sds-button) {
  background: rgba(233, 237, 240, 0.75);
  color: rgba(51, 66, 79, 0.45);
  cursor: not-allowed;
  text-shadow: none;
}
.sds-button:disabled .sds-button__icon svg,
.pagination li > a:disabled:not(.sds-button) .sds-button__icon svg,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-button.select2-selection__rendered
  .sds-button__icon
  svg,
.pagination
  .select2-container.select2-container--sds.select2-container--disabled
  li
  > a.select2-selection__rendered:not(.sds-button)
  .sds-button__icon
  svg,
.select2-container.select2-container--sds.select2-container--disabled
  .pagination
  li
  > a.select2-selection__rendered:not(.sds-button)
  .sds-button__icon
  svg,
.sds-button[disabled] .sds-button__icon svg,
.pagination li > a[disabled]:not(.sds-button) .sds-button__icon svg {
  color: rgba(51, 66, 79, 0.35);
}
.sds-button:disabled:hover,
.pagination li > a:disabled:hover:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .sds-button.select2-selection__rendered:hover,
.pagination
  .select2-container.select2-container--sds.select2-container--disabled
  li
  > a.select2-selection__rendered:hover:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .pagination
  li
  > a.select2-selection__rendered:hover:not(.sds-button),
.sds-button:disabled:focus,
.pagination li > a:disabled:focus:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .sds-button.select2-selection__rendered:focus,
.pagination
  .select2-container.select2-container--sds.select2-container--disabled
  li
  > a.select2-selection__rendered:focus:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .pagination
  li
  > a.select2-selection__rendered:focus:not(.sds-button),
.sds-button:disabled:active,
.pagination li > a:disabled:active:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .sds-button.select2-selection__rendered:active,
.pagination
  .select2-container.select2-container--sds.select2-container--disabled
  li
  > a.select2-selection__rendered:active:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .pagination
  li
  > a.select2-selection__rendered:active:not(.sds-button),
.sds-button:disabled:visited,
.pagination li > a:disabled:visited:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .sds-button.select2-selection__rendered:visited,
.pagination
  .select2-container.select2-container--sds.select2-container--disabled
  li
  > a.select2-selection__rendered:visited:not(.sds-button),
.select2-container.select2-container--sds.select2-container--disabled
  .pagination
  li
  > a.select2-selection__rendered:visited:not(.sds-button),
.sds-button[disabled]:hover,
.pagination li > a[disabled]:hover:not(.sds-button),
.sds-button[disabled]:focus,
.pagination li > a[disabled]:focus:not(.sds-button),
.sds-button[disabled]:active,
.pagination li > a[disabled]:active:not(.sds-button),
.sds-button[disabled]:visited,
.pagination li > a[disabled]:visited:not(.sds-button) {
  background: rgba(233, 237, 240, 0.75);
  color: rgba(51, 66, 79, 0.45);
}
.sds-form [class^="sds-heading--"] {
  margin-bottom: 1.5rem;
}
.sds-form-fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.sds-form-legend {
  font: 700 1.5rem "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 0 1.5rem;
  padding: 0;
  border-bottom: 0 solid transparent;
}
abbr.sds-required[title] {
  border: 0;
  color: #ba3037;
  cursor: help;
  text-decoration: none;
}
.sds-form-split {
  display: flex;
  flex: 1 auto;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: end;
  -webkit-tap-highlight-color: transparent;
}
@media (min-width: 768px) {
  .sds-form-split {
    flex-direction: row;
  }
  .sds-form-split .sds-form-element:nth-child(2n + 1) {
    padding-right: 1rem;
  }
  .sds-form-split .sds-form-element:nth-child(2n) {
    padding-left: 1rem;
  }
  .sds-form-split-25 .sds-form-element {
    width: 25%;
  }
  .sds-form-split-25 .sds-form-element + .sds-form-element {
    width: 75%;
  }
  .sds-form-split-50 .sds-form-element {
    width: 50%;
  }
  .sds-form-split-75 .sds-form-element {
    width: 75%;
  }
  .sds-form-split-75 .sds-form-element + .sds-form-element {
    width: 25%;
  }
}
.sds-checkbox input[type="checkbox"] {
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.sds-checkbox input[type="checkbox"] ~ .sds-checkbox__label .sds-checkbox_faux {
  position: relative;
  background: #ffffff;
  border: 0.0625rem solid #b4bfc9;
  border-radius: 0.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  vertical-align: middle;
  top: -0.125rem;
}
.sds-checkbox
  input[type="checkbox"]:focus
  ~ .sds-checkbox__label
  .sds-checkbox_faux {
  background-color: #ffffff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 157, 227, 0.5);
}
.sds-checkbox
  input[type="checkbox"]:checked
  ~ .sds-checkbox__label
  .sds-checkbox_faux {
  background-color: #009de3;
  border-color: #009de3;
}
.sds-checkbox
  input[type="checkbox"]:checked
  ~ .sds-checkbox__label
  .sds-checkbox_faux::after {
  display: block;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  position: absolute;
  top: 0.3125rem;
  left: 0.1875rem;
  background: url("data:image/svg+xml;utf8,<svg width='12px' height='9px' viewBox='0 0 12 9' xmlns='http://www.w3.org/2000/svg'><path fill='%23ffffff' d='M0.0753232623,5.44552529 C-0.0251077541,5.22940523 -0.0251077541,4.98126737 0.0753232623,4.7651473 L0.878771394,4.08476932 C0.97920241,3.8946637 1.38092648,3.8946637 1.58178851,4.08476932 L3.9921329,6.47609783 L10.419718,0.142579211 C10.62058,-0.0475264036 11.0223041,-0.0475264036 11.1227351,0.142579211 L11.9261832,0.822957198 C12.0246056,1.03907727 12.0246056,1.28721512 11.9261832,1.50333519 L4.39385697,8.85742079 C4.16085701,9.0475264 3.8234088,9.0475264 3.59040884,8.85742079 L3.59040884,8.86742635 L0.0753232623,5.44552529 Z'></path></svg>")
    no-repeat;
}
.sds-checkbox
  input[type="checkbox"]:disabled
  ~ .sds-checkbox__label
  .sds-checkbox_faux,
.sds-checkbox
  .select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered[type="checkbox"]
  ~ .sds-checkbox__label
  .sds-checkbox_faux,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-checkbox
  .select2-selection__rendered[type="checkbox"]
  ~ .sds-checkbox__label
  .sds-checkbox_faux {
  background-color: #e9edf0;
  border-color: #b4bfc9;
}
.sds-checkbox
  input[type="checkbox"]:disabled:checked
  ~ .sds-checkbox__label
  .sds-checkbox_faux,
.sds-checkbox
  .select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered[type="checkbox"]:checked
  ~ .sds-checkbox__label
  .sds-checkbox_faux,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-checkbox
  .select2-selection__rendered[type="checkbox"]:checked
  ~ .sds-checkbox__label
  .sds-checkbox_faux {
  background-color: #b4bfc9;
  border-color: #b4bfc9;
}
.sds-checkbox--accent {
  background-color: #f7f8fa;
}
.sds-checkbox--block {
  padding: 1.5rem;
  border-radius: 0.25rem;
}
.sds-checkbox--block .sds-form-element__label {
  display: flex;
  margin-bottom: 0;
  line-height: 1.5;
}
.sds-checkbox--block .sds-form-element__label .sds-checkbox_faux {
  flex: 0 0 auto;
  align-self: center;
  margin-right: 1rem !important;
}
.sds-checkbox--block .sds-form-element__annotation {
  align-self: center;
  margin-left: auto !important;
}
.sds-checkbox--block .sds-form-element__hint {
  margin-top: 1rem;
  margin-bottom: 0;
}
.sds-checkbox--block
  input[type="checkbox"]
  ~ .sds-checkbox__label
  .sds-checkbox_faux {
  top: 0;
}
.sds-form--problem
  .sds-checkbox
  [type="checkbox"]
  ~ .sds-checkbox__label
  .sds-checkbox_faux,
.sds-form--problem.sds-checkbox
  [type="checkbox"]
  ~ .sds-checkbox__label
  .sds-checkbox_faux {
  border-color: #ba3037;
  border-width: 0.0625rem;
}
.sds-form--problem
  .sds-checkbox
  [type="checkbox"]:checked
  ~ .sds-checkbox__label
  .sds-checkbox_faux:after,
.sds-form--problem.sds-checkbox
  [type="checkbox"]:checked
  ~ .sds-checkbox__label
  .sds-checkbox_faux:after {
  border-color: #ba3037;
}
.sds-input::placeholder {
  color: #6d7883;
  font-style: italic;
}
.sds-input::-moz-placeholder {
  opacity: 1;
}
.sds-input[type="number"] {
  padding-right: 0;
}
.sds-select-wrap {
  position: relative;
  width: 100%;
}
.sds-select-wrap::after {
  position: absolute;
  top: 40%;
  content: "";
  display: block;
  right: 0.5rem;
  width: 1rem;
  height: 0.5rem;
  background: url("data:image/svg+xml;utf8,<svg width='12px' height='8px' viewBox='0 0 12 8' xmlns='http://www.w3.org/2000/svg'><path fill='%236d7883' d='M6,5.58578644 L1.70710678,1.29289322 C1.31658249,0.902368927 0.683417511,0.902368927 0.292893219,1.29289322 C-0.0976310729,1.68341751 -0.0976310729,2.31658249 0.292893219,2.70710678 L5.29289322,7.70710678 C5.68341751,8.09763107 6.31658249,8.09763107 6.70710678,7.70710678 L11.7071068,2.70710678 C12.0976311,2.31658249 12.0976311,1.68341751 11.7071068,1.29289322 C11.3165825,0.902368927 10.6834175,0.902368927 10.2928932,1.29289322 L6,5.58578644 Z'></path></svg>")
    no-repeat;
  pointer-events: none;
}
.sds-select {
  height: 2.625rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 1.5rem;
}
.sds-select--placeholder,
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__placeholder,
.sds-select:disabled,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-select.select2-selection__rendered {
  color: #6d7883;
  font-style: italic;
}
.sds-radio-container {
  display: flex;
  flex-wrap: wrap;
}
.sds-radio-container > .sds-radio {
  margin-right: 24px;
}
.sds-radio-container--vertical {
  flex-direction: column;
}
.sds-radio [type="radio"] {
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.sds-radio [type="radio"] ~ .sds-radio__label .sds-radio_faux {
  position: relative;
  background: #ffffff;
  border: 0.0625rem solid #b4bfc9;
  border-radius: 50%;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  vertical-align: middle;
  top: -0.125rem;
}
.sds-radio [type="radio"]:focus ~ .sds-radio__label .sds-radio_faux {
  background-color: #ffffff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 157, 227, 0.5);
}
.sds-radio [type="radio"]:checked ~ .sds-radio__label .sds-radio_faux {
  background-color: #ffffff;
  border-color: #009de3;
}
.sds-radio [type="radio"]:checked ~ .sds-radio__label .sds-radio_faux::after {
  display: block;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  background: url("data:image/svg+xml;utf8,<svg width='12px' height='12px' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'><circle fill='%23009de3' cx='6' cy='6' r='6'></circle></svg>")
    no-repeat;
}
.sds-radio [type="radio"]:disabled ~ .sds-radio__label .sds-radio_faux,
.sds-radio
  .select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered[type="radio"]
  ~ .sds-radio__label
  .sds-radio_faux,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-radio
  .select2-selection__rendered[type="radio"]
  ~ .sds-radio__label
  .sds-radio_faux {
  background-color: #e9edf0;
  border-color: #b4bfc9;
}
.sds-radio [type="radio"]:disabled:checked ~ .sds-radio__label .sds-radio_faux,
.sds-radio
  .select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered[type="radio"]:checked
  ~ .sds-radio__label
  .sds-radio_faux,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-radio
  .select2-selection__rendered[type="radio"]:checked
  ~ .sds-radio__label
  .sds-radio_faux {
  background-color: #e9edf0;
  border-color: #b4bfc9;
}
.sds-radio
  [type="radio"]:disabled:checked
  ~ .sds-radio__label
  .sds-radio_faux::after,
.sds-radio
  .select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered[type="radio"]:checked
  ~ .sds-radio__label
  .sds-radio_faux::after,
.select2-container.select2-container--sds.select2-container--disabled
  .sds-radio
  .select2-selection__rendered[type="radio"]:checked
  ~ .sds-radio__label
  .sds-radio_faux::after {
  background: url("data:image/svg+xml;utf8,<svg width='12px' height='12px' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'><circle fill='%23b4bfc9' cx='6' cy='6' r='6'></circle></svg>")
    no-repeat;
}
.sds-form--problem
  .sds-radio
  [type="radio"]
  ~ .sds-radio__label
  .sds-radio_faux,
.sds-form--problem.sds-radio
  [type="radio"]
  ~ .sds-radio__label
  .sds-radio_faux {
  border-color: #ba3037;
  border-width: 0.0625rem;
}
.sds-form--problem
  .sds-radio
  [type="radio"]:checked
  ~ .sds-radio__label
  .sds-radio_faux:after,
.sds-form--problem.sds-radio
  [type="radio"]:checked
  ~ .sds-radio__label
  .sds-radio_faux:after {
  border-color: #ba3037;
}
.sds-textarea {
  background-color: #ffffff;
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 0.5rem;
  resize: vertical;
  transition: border 0.2s linear, background-color 0.2s linear;
  width: 100%;
}
.sds-form-element {
  margin-bottom: 1.5rem;
  position: relative;
  font-size: 1rem;
}
.sds-form-element__label {
  display: inline-block;
  color: #33424f;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.sds-form-element__label .sds-meta--small,
.sds-form-element__label
  .select2-container.select2-container--sds.select2-container--open
  .select2-results
  .select2-results__option,
.select2-container.select2-container--sds.select2-container--open
  .select2-results
  .sds-form-element__label
  .select2-results__option {
  color: #6d7883;
  line-height: inherit;
  margin-left: 0.25rem;
}
.sds-form-element__control,
.select2-container.select2-container--sds .select2-selection,
.select2-container.select2-container--sds
  .select2-search--dropdown
  .select2-search__field {
  display: flex;
  background-color: #ffffff;
  border: 0.0625rem solid #b4bfc9;
  border-radius: 0.25rem;
  color: #33424f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.sds-form-element__control + .sds-form-element__help,
.select2-container.select2-container--sds
  .select2-selection
  + .sds-form-element__help,
.select2-container.select2-container--sds
  .select2-search--dropdown
  .select2-search__field
  + .sds-form-element__help {
  margin-top: 0.25rem;
}
.sds-form-element__item,
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__rendered,
.select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered {
  border: none;
  border-radius: 0.25rem;
  background-color: #ffffff;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5rem;
  min-height: 2.375rem;
  padding: 0 0.5rem;
  transition: border 0.2s linear, background-color 0.2s linear;
  width: 100%;
  min-width: 0;
}
.sds-form-element__item:focus,
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__rendered:focus,
.select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered:focus,
.sds-form-element__item:active,
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__rendered:active,
.select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered:active {
  background-color: #ffffff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 157, 227, 0.5);
}
.sds-form-element__item:disabled,
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__rendered:disabled,
.select2-container.select2-container--sds.select2-container--disabled
  .select2-selection__rendered,
.sds-form-element__item[disabled],
.select2-container.select2-container--sds
  .select2-selection
  .select2-selection__rendered[disabled] {
  background-color: #e9edf0;
  box-shadow: none;
}
.sds-form-element__help {
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: #fcebec;
  color: #ba3037;
  display: block;
  opacity: 0;
  height: 0;
  padding: 0;
  width: 100%;
  transition: height 0.4s 0.05s, padding 0.4s 0.05s, opacity 0.4s 0.05s;
}
.sds-form-element__help a {
  color: inherit;
  text-decoration: underline;
}
.sds-form-element__hint {
  font-size: 0.75rem;
  margin: 0.5rem 0 0 0;
}
.sds-form-element--surround-text {
  align-self: center;
  padding: 0 0.5rem;
}
.sds-form-element--surround-text.sds-form-element--surround-text-prepend,
.sds-form-element--surround-text.sds-form-element--surround-text-append {
  color: #33424f;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 3rem;
}
.sds-form-element--surround-text.sds-form-element--surround-text-prepend {
  padding-right: 0.5rem;
  border-right: 0.0625rem solid #b4bfc9;
}
.sds-form-element--surround-text.sds-form-element--surround-text-append {
  padding-left: 0.5rem;
  border-left: 0.0625rem solid #b4bfc9;
}
.sds-form-element--has-focus .sds-form-element__label {
  color: #008dcc;
}
.sds-form--problem .sds-form-element__help {
  opacity: 1;
  height: auto;
  padding: 0.25rem 0.5rem;
}
.sds-form--problem .sds-form-element__control,
.sds-form--problem .select2-container.select2-container--sds .select2-selection,
.select2-container.select2-container--sds .sds-form--problem .select2-selection,
.sds-form--problem
  .select2-container.select2-container--sds
  .select2-search--dropdown
  .select2-search__field,
.select2-container.select2-container--sds
  .select2-search--dropdown
  .sds-form--problem
  .select2-search__field {
  border-color: #ba3037;
}
.sds-notice-panel {
  border-radius: 0.25rem;
  display: flex;
  font-size: 1rem;
  background-color: #f7f8fa;
  justify-content: space-between;
}
.sds-notice-panel a {
  text-decoration: underline;
}
.sds-notice-panel__content {
  display: block;
  flex: 1 1 auto;
  padding: 1rem;
  color: #33424f;
}
.sds-notice-panel__close {
  border: 0;
  background: transparent;
  color: currentColor;
  height: initial;
  opacity: 0.8;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}
.sds-notice-panel__close > span {
  height: 1rem;
}
.sds-notice-panel__close svg {
  fill: currentColor;
}
.sds-notice-panel__close:hover,
.sds-notice-panel__close:focus {
  opacity: 1;
}
.sds-notice-panel__title {
  font-weight: 700;
}
.sds-notice-panel.sds-notice-panel--warning {
  background-color: #fff8eb;
  color: #916c1f;
}
.sds-notice-panel.sds-notice-panel--risk {
  background-color: #fcebec;
  color: #ba3037;
}
.sds-notice-panel.sds-notice-panel--risk a {
  color: inherit;
}
.sds-notice-panel.sds-notice-panel--centered {
  text-align: center;
}
.sds-notice-panel.sds-notice-panel--inline {
  display: inline-flex;
}
.sds-alert-message {
  border-radius: 0.25rem;
  display: flex;
  padding: 1rem 0;
  font-size: 1rem;
}
.sds-alert-message__content {
  padding: 0 1rem;
  line-height: 2;
}
.sds-alert-message__actions {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-left: auto;
}
.sds-alert-message__close {
  border-radius: 50%;
  color: currentColor;
  height: initial;
  opacity: 0.8;
  padding: 0.5rem;
  transition: all 0.2s ease;
}
.sds-alert-message__close > span {
  height: 0.75rem;
  width: 0.75rem;
  font-size: 0.75rem;
}
.sds-alert-message__close svg {
  fill: currentColor;
  height: 0.75rem;
  width: 0.75rem;
}
.sds-alert-message__close:hover {
  background-color: transparent;
  border: 1px solid currentColor;
  opacity: 1;
}
.sds-alert-message__title {
  font-weight: 700;
}
.sds-alert-message--inline {
  padding: 0.25rem 0;
}
.sds-alert-message a {
  color: inherit;
  text-decoration: underline;
}
.sds-alert-message.sds-alert-message--info {
  background-color: #e6f7ff;
  color: #008dcc;
}
.sds-alert-message.sds-alert-message--success {
  background-color: #e5f6ee;
  color: #007f48;
}
.sds-alert-message.sds-alert-message--warning {
  background-color: #fff8eb;
  color: #916c1f;
}
.sds-alert-message.sds-alert-message--risk {
  background-color: #fcebec;
  color: #ba3037;
}
.sds-status {
  font-size: 1em;
}
.sds-status__indicator {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #e9edf0;
}
.sds-status__text {
  margin-left: 0.5rem;
}
.sds-status--info .sds-status__indicator {
  background-color: #009de3;
}
.sds-status--success .sds-status__indicator {
  background-color: #5bc976;
}
.sds-status--warning .sds-status__indicator {
  background-color: #ffbe37;
}
.sds-status--risk .sds-status__indicator {
  background-color: #e93d45;
}
.sds-status--disabled .sds-status__indicator {
  background-color: #6d7883;
}
.sds-status--small {
  font-size: 0.875rem;
}
.sds-status--base {
  font-size: 1rem;
}
.sds-status--large {
  font-size: 1.25rem;
}
.sds-file-input {
  position: relative;
}
.sds-file-input input[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.sds-file-input__files {
  margin-left: 0.5rem;
}
.sds-file-input .sds-form-element__help {
  margin-top: 0.5rem;
}
.sds-table {
  --cell-radius: calc(0.5rem - 0.0625rem);
  border: 0.0625rem solid #e9edf0;
  border-radius: 0.5rem;
  width: 100%;
}
.sds-table__table {
  width: 100%;
  font-size: 1rem;
  border-collapse: collapse;
}
.sds-table__row {
  border-bottom: 0.0625rem solid #e9edf0;
}
.sds-table__row:not(.sds-table__row--header):last-of-type {
  border-bottom: 0;
}
.sds-table__row:not(.sds-table__row--header):focus {
  background-color: #f7f8fa;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.0625rem #009de3;
  outline: 0.0625rem solid #009de3;
}
.sds-table__row .sds-table__cell:first-of-type,
.sds-table__row .sds-table__header:first-of-type {
  padding-left: 1.5rem;
}
.sds-table__row .sds-table__cell:last-of-type,
.sds-table__row .sds-table__header:last-of-type {
  padding-right: 1.5rem;
}
.sds-table__row:first-of-type .sds-table__cell:first-of-type,
.sds-table__row:first-of-type .sds-table__header:first-of-type {
  border-radius: var(--cell-radius) 0 0 0;
}
.sds-table__row:first-of-type .sds-table__cell:last-of-type:not(:first-of-type),
.sds-table__row:first-of-type
  .sds-table__header:last-of-type:not(:first-of-type) {
  border-radius: 0 var(--cell-radius) 0 0;
}
.sds-table__row:last-of-type:not(:first-of-type) .sds-table__cell:first-of-type,
.sds-table__row:last-of-type:not(:first-of-type)
  .sds-table__header:first-of-type {
  border-radius: 0 0 0 var(--cell-radius);
}
.sds-table__row:last-of-type:not(:first-of-type)
  .sds-table__cell:last-of-type:not(:first-of-type),
.sds-table__row:last-of-type:not(:first-of-type)
  .sds-table__header:last-of-type:not(:first-of-type) {
  border-radius: 0 0 var(--cell-radius) 0;
}
.sds-table__row--header {
  color: #ffffff;
  font-weight: bold;
}
.sds-table__row--header .sds-table__cell,
.sds-table__row--header .sds-table__header {
  background: #e9edf0;
}
.sds-table__row--highlight,
.order-list-table.sds-table--highlight-hover tr:focus-within,
.pickup-list-table.sds-table--highlight-hover tr:focus-within {
  background-color: #e6f7ff;
}
.sds-table__cell {
  padding: 1rem;
  flex: 1 1 auto;
  vertical-align: top;
}
.sds-table__header {
  padding: 1rem;
  flex: 1 1 auto;
  vertical-align: top;
  color: #33424f;
}
.sds-table--spacious .sds-table__cell {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.sds-table--compact .sds-table__cell {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sds-table--compact .sds-table__header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sds-table--bold .sds-table__header {
  color: #ffffff;
  background-color: #33424f;
}
.sds-table--subtle .sds-table__header {
  color: #33424f;
  background-color: #ffffff;
}
.sds-table--headless .sds-table__row--header {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}
.sds-table--striped
  .sds-table__row:nth-last-of-type(even):not(.sds-table__row--header) {
  background-color: #f7f8fa;
}
.sds-table--highlight-hover .sds-table__row:not(.sds-table__row--header):active,
.sds-table--highlight-hover .sds-table__row:not(.sds-table__row--header):hover {
  background-color: #e6f7ff;
}
.sds-table--collapsed {
  border-top: 0.5rem solid #e9edf0;
}
.sds-table--collapsed.sds-table--bold {
  border-top: 0.5rem solid #33424f;
}
.sds-table--collapsed.sds-table--subtle {
  border-top-width: 0.0625rem;
}
.sds-table--collapsed.sds-table--headless {
  border-top-width: 0.0625rem;
}
.sds-table--collapsed .sds-table__table {
  display: block;
}
.sds-table--collapsed .sds-table__table thead,
.sds-table--collapsed .sds-table__table tbody,
.sds-table--collapsed .sds-table__table tfoot {
  display: block;
}
.sds-table--collapsed .sds-table__row {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}
.sds-table--collapsed .sds-table__row .sds-table__cell:first-of-type,
.sds-table--collapsed .sds-table__row .sds-table__header:first-of-type {
  padding-left: 1rem;
}
.sds-table--collapsed .sds-table__row .sds-table__cell:last-of-type,
.sds-table--collapsed .sds-table__row .sds-table__header:last-of-type {
  padding-right: 1rem;
}
.sds-table--collapsed .sds-table__row--header {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}
.sds-table--collapsed .sds-table__cell {
  flex-basis: 100%;
  border: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sds-table--collapsed .sds-table__cell:before {
  content: attr(data-label);
  display: block;
  font-weight: bold;
  font-size: 0.75rem;
}
.sds-table--collapsed.sds-table--spacious .sds-table__row {
  padding: 0.75rem 0;
}
.sds-table--collapsed.sds-table--spacious .sds-table__cell {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.sds-table--collapsed.sds-table--compact .sds-table__row {
  padding: 0.25rem 0;
}
.sds-table--collapsed.sds-table--compact .sds-table__cell {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
